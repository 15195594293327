<mat-dialog-content>
  <h3 class="dialog-title">{{ 'edit_password_dialog_title' | translate }}</h3>
  <div class="edit-password-form-template" style="position: relative">
    <div *ngIf="loading$ | async" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>

    <form [formGroup]="form">
      <div>
        <label class="global-label">{{'edit_password_old_password_label' | translate}}</label>
        <input class="global-input" [formControlName]="FormFields.oldPassword" type="password" placeholder="******">
      </div>

      <div>
        <label class="global-label">{{'edit_password_new_password_label' | translate}}</label>
        <input class="global-input" [formControlName]="FormFields.password" type="password" placeholder="******">
      </div>

      <div>
        <label class="global-label">{{'edit_password_repeat_password_label' | translate}}</label>
        <input class="global-input" [formControlName]="FormFields.repeatPassword" type="password" placeholder="******">
      </div>

      <p>
        <span class="global-link-green" (click)="sendResetPasswordMail()">
          {{'edit_password_resend_mail_link_text' | translate}}
        </span>
          {{'edit_password_resend_mail_text' | translate}}
      </p>
      <div *ngIf="form.controls[FormFields.repeatPassword].hasError('MatchPassword')
                && form.controls[FormFields.repeatPassword].value
                && form.controls[FormFields.repeatPassword].touched">
        <span class="error-message">{{'edit_password_error_description' | translate}}</span>
      </div>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="buttons">
    <button class="global-button default" (click)="cancel()">
      {{  'dialog_options_cancel' | translate }}
    </button>
    <button class="global-button" (click)="save()">
      {{'dialog_options_save' | translate }}
    </button>
  </div>
</mat-dialog-actions>