export interface Book {
  uid: string;
  title: string;
  annotation: string;
  isbn: string;
  recommendation: string;
  authors: string;
  collections: Partial<Collection[]>;
  bibliographicTitle: string;
  publicationYear: number;
  publicationTitle: string;
  publicationDescription: string;
  tableContents: string;
  countPages: number;
  format: string;
  bookBinding: string;
  isSqueeze?: boolean;
  imageTmp?: string;
  hasImage: boolean;
  hasAccessRead: boolean;
  hasBook: boolean;
  countLoadedPages: number;
  loadedFileName: string;
  loadedFileSize: number;
  extension: string;
  isFree?: boolean;
  hasPaperBack?: boolean;
  price: {
    digital: number,
    paperBack: number,
    digital6m: number
  };
  isCreated?: boolean;
  isPublished?: boolean;
  subscribers?: any;
  tenant?: any;
  createdDate: string;
}

export interface User {
  userName: string;
  email: string;
  name: string;
  id: string;
  phoneNumber: string;
  created: string;
  code: Array<string>;
  role: string;
  blocked: true;
}

export interface Collection {
  books: Partial<Book>[];
  price: number;
  title: string;
  uid: string;
  createdDate: string;
}

export interface Subscription {
  begin?: string;
  subscriber: Subscriber;
  books: Array<Book>;
  collections: Partial<Collection>[];
  created: string;
  createdUser: Partial<User>;
  deleted: boolean;
  end?: string;
  modified: string;
  modifiedUser: Partial<User>;
  price: string;
  remark: string;
  uid: string;
  licenseType?: string;
  individualLicenseDurationMonths?: number;
  enabled?: boolean;
}

export interface IndividualSubscription {
  uid: string;
  created: string;
  modified: string;
  createdUser: Partial<User>;
  modifiedUser: Partial<User>;
  book: Book;
  subscriber: Subscriber;
  remark: string;
  price: string;
  individualLicenseDurationMonths: any;
  disabled: boolean;
}
export interface Subscriber {
  address: string;
  code: string;
  contactPerson: string;
  created: string;
  createdUser: Partial<User>;
  deleted: boolean;
  ipAddresses: Array<string>;
  modified: string;
  modifiedUser: Partial<User>;
  phone: string;
  title: string;
  uid: string;
}

export interface MySubscription {
  uid: string;
  subscriber: string;
  address: string;
  phone: string;
  code: string;
  isActive: boolean;
}

export interface Credentials {
  userName: string;
  email?: string;
  name: string;
  token: string;
  issued: string;
  expires: string;
  role: string;
  user_id: string;
  tenants: Tenants[];
}

export interface Tenants {
  uid: string;
  name: string;
}

export interface Currency{
  title: string;
  symbol: string;
}

export interface HistoryItem {
  id: number;
  changed: string;
  changedContent: SubscriptionChanging[];
  user: {
    id: number;
    userName: string;
  };
}

export enum HistoryItemType {
  Collections = 'Collections',
  BookCount = 'BookCount',
  Books = 'Books',
  Begin = 'Begin',
  End = 'End',
  IssuedTo = 'IssuedTo',
  Code = 'Code',
  Remark = 'Remark'
}

interface SubscriptionChanging {
  member: HistoryItemType;
  before: any;
  after: any;
}

export enum SubscriptionFields {
  Admin = 'Admin',
  User = 'User'
}

export enum UserRoles {
 SuperAdmin = 'SuperAdmin',
 Admin = 'Admin',
 User = 'User'
}

interface PaginatedPageInfoResponse {
  page: {
    offset: number;
    size: number;
    sort: any; // #todo Set right type;
    filter: any; // #todo Set right type;
    totalElements: number;
  };
}
export interface PaginatedBooksResponse extends PaginatedPageInfoResponse {
  data: Book[];
}
export interface PaginatedSubscriptionResponse extends PaginatedPageInfoResponse {
  data: Subscription[];
}
export interface PaginatedCollectionsResponse extends PaginatedPageInfoResponse {
  data: Collection[];
}

export enum ErrorResponseCodes {
  USER_ALREADY_EXISTS = 'user_with_such_email_is_already_exist',
  TENANT_ALREADY_EXISTS = 'tenant_with_such_email_is_already_exist',
  ENTITY_NOT_FOUND = 'entity_not_found',
  SUPER_ADMIN_ONLY = 'super_admin_only',
  WRONG_PASSWORD = 'wrong_password',
  SOMETHING_WENT_WRONG = 'something_went_wrong',
}

declare global {
  interface Window {
    handleCredentialResponse: (response: any) => any;
  }
}
