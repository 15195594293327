import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
/**
 * Adds the local storage token to the headers
 */
@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {
    let token = '';

    const savedCredentials =
      sessionStorage.getItem('credentials') || localStorage.getItem('credentials');

    if (savedCredentials) {
      token = JSON.parse(savedCredentials).token;
    }


    const clonedRequest = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });

    return next.handle(clonedRequest);
  }
}
