<ng-container *ngIf="{
    readerMode: readerMode$ | async
} as data">
    <div class="document-cnt" *ngIf="pdfReaderService.bookPagesList?.length > 0">
        <!--        <div id="canvas_container">-->
        <!--          <canvas id="pdf_renderer"></canvas>-->
        <!--        </div>-->
        <cdk-virtual-scroll-viewport #reader_viewport
            [ngClass]="{ 'full-screen-mode': data.readerMode === ReaderMode.FullScreen }"
            itemSize="{{ getPageHeight() }}" class='reader-viewport'>
            <div #list_container *cdkVirtualFor="let item of pdfReaderService.bookPagesList; let i = index"
                class='reader-item'>
                <pdf-viewer *ngIf="item.content" [id]="item.id" [src]="item.content" [render-text]="false" [page]="1"
                    [original-size]="pdfReaderService.zoom < 1" [render-text-mode]="1"
                    [zoom-scale]="data.readerMode !== ReaderMode.FullScreen ? 'page-height' : 'page-width'"
                    [zoom]="pdfReaderService.zoom" style="display:block; margin: auto;"
                    [ngStyle]="{'height': getPageHeight() + 'px', 'width': getPageWidth() + 'px'}">
                </pdf-viewer>

                <div *ngIf="!item.content" class="empty-page-cnt" style="margin: 0 auto 10px auto;"
                    [ngStyle]="{'height': (getPageHeight() + 10) + 'px', 'width': getPageWidth() + 'px'}">
                    <div class="loading-label">Loading...</div>
                </div>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</ng-container>
