<mat-dialog-content>
  <h3 class="dialog-title">{{ 'subscription_history_dialog_title' | translate }}</h3>
  <div
    class="subscribers-edit-form-template"
    *ngIf="{
    books: state.books$ | async,
    collections: state.collections$ | async,
    loading: loading$ | async
    } as data
  "
    style="position: relative">
    <div *ngIf="data.loading" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>

    <div class="table" style="position: relative">
      <ngx-datatable
        [headerHeight]="60"
        [rowHeight]="'auto'"
        [columnMode]="'flex'"
        [rows]="history$ | async"
        [messages]="{emptyMessage: this.utils.getTableMessage('empty_table_message' | translate)}"
      >
        <ngx-datatable-column [flexGrow]="1" [name]="'column_header_date_changes' | translate" prop="changed" [sortable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span class="mobile-hidden">{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="mobile-hidden" [innerHTML]="utils.getformattedDate(row.changed)"></span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1.1" [name]="'column_header_author_of_change' | translate" prop="email" [sortable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span class="mobile-hidden">{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="mobile-hidden" [innerHTML]="row.user.email"></span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="5" [name]="'column_header_list_of_changes' | translate" prop="field" [sortable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span class="mobile-hidden">{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="mobile-hidden" style="display: flex; flex-direction: column;">
                <div style="display: flex; flex-direction: row; width: 100%">
                  <div class="mobile-hidden" style="display: flex; flex-direction: column; width: 40%; margin-right: 4px">
                    <span><strong>{{ 'column_header_field' | translate}}</strong></span>
                  </div>
                  <div class="mobile-hidden" style="display: flex; flex-direction: column; width: 30%; margin-right: 4px">
                    <span><strong>{{ 'column_header_was' | translate }}</strong></span>
                  </div>
                  <div class="mobile-hidden" style="display: flex; flex-direction: column; width: 30%">
                    <span><strong>{{ 'column_header_became' | translate }}</strong></span>
                  </div>
                </div>

                <div *ngFor="let field of row.changedContent; let i = index">
                  <div style="display: flex; flex-direction: row; width: 100%">
                    <div class="mobile-hidden" style="display: flex; flex-direction: column; width: 40%; margin-right: 4px">
                      <span [innerHTML]="field.member"></span>
                    </div>
                    <div class="mobile-hidden" style="display: flex; flex-direction: column; width: 30%; margin-right: 4px">
                      <span *ngIf="field.member === HistoryItemType.Begin || field.member === HistoryItemType.End" [innerHTML]="utils.getformattedDate(field.before)"></span>
                      <span *ngIf="field.member !== HistoryItemType.Begin && field.member !== HistoryItemType.End"  [innerHTML]="field.before"></span>
                    </div>
                    <div class="mobile-hidden" style="display: flex; flex-direction: column; width: 30%">
                      <span *ngIf="field.member === HistoryItemType.Begin || field.member === HistoryItemType.End" [innerHTML]="utils.getformattedDate(field.after)"></span>
                      <span *ngIf="field.member !== HistoryItemType.Begin && field.member !== HistoryItemType.End"  [innerHTML]="field.after"></span>
                    </div>
                  </div>
                  <hr style="opacity: 0.5;" *ngIf="row.changedContent.length > 1 && i < row.changedContent.length - 1" >
                </div>
              </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="buttons">
    <button class="global-button default" (click)="close()">
      {{ 'dialog_options_close' | translate }}
    </button>
  </div>
</mat-dialog-actions>
