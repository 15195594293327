<mat-dialog-content>
  <h3 class="dialog-title">{{ 'readers_request_header' | translate }} <b>{{subscriber?.title}}</b></h3>
  <div>
    <div *ngIf="loading$ | async" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>
    <form [formGroup]="form" class="global-flex-column-space-between-start">
      <div class="form-field-cnt global-flex-column-space-between-start">
        <span>{{'readers_request_message_title' | translate}}</span>
        <textarea matInput cdkTextareaAutosize maxlength="500"
          [placeholder]="'readers_request_message_placeholder' | translate"
          [formControlName]="FormFields.message"
        ></textarea>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="buttons">
    <button class="global-button default" (click)="close()">
      {{'dialog_options_cancel' | translate }}
    </button>
    <button class="global-button" (click)="save()">
      {{'button_text_send' | translate }}
    </button>
  </div>
</mat-dialog-actions>