<mat-dialog-content>
  <h3 class="dialog-title">{{ 'bind_accounts_dialog_title' | translate }}</h3>
  <div>
    <div *ngIf="loading$ | async" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>

    <p>
      {{'bind_accounts_dialog_description' | translate}}
    </p>

    <form [formGroup]="form">
      <div class="global-flex-column-center-start full-width">
        <span class="global-label">{{'restore_password_label' | translate}}</span>
        <input class="global-input" id="password" name="password" type="password"
              [formControlName]="FormFields.password"
              [placeholder]="'input_placeholder_password' | translate"/>
      </div>
    </form>
    <span class="reset-password" (click)="resetPassword()">
      {{'forgot_password' | translate}}
    </span>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div  class="buttons">
    <button class="global-button default" (click)="cancel()">
      {{ 'dialog_options_cancel' | translate }}
    </button>
    <button  class="global-button" (click)="save()">
      {{ 'dialog_options_save' | translate }}
    </button>
  </div>
</mat-dialog-actions>
