import {ChangeDetectionStrategy, Component} from '@angular/core';
import {environment} from 'src/environments/environment';
import {ApplicationApiService} from '../../shared/services/application-api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ConsentComponent {

  public domainName = environment.domain;
  constructor(
    public api: ApplicationApiService,
    public router: Router
  ) {

  }

  acceptConsent(): void {
    this.api.consentAgree$().subscribe(res => {
      this.router.navigate(['..']);
    });
  }

}
