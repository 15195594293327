import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from 'src/app/shared/components/confirm-dialog/component';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {CustomIcons, ApplicationUtilsService} from '../../../shared/services/application-utils.service';
import {Router} from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  finalize,
  first, Subscription,
  switchMap
} from 'rxjs';
import {Page, PageLimitOptions} from '../../../shared/app-global-variables';
import {Book} from '../../../shared/app-interfaces';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-admin-books',
  templateUrl: './admin-books.component.html',
  styleUrls: ['./admin-books.component.scss']
})
export class AdminBooksComponent implements OnDestroy {
  public readonly CustomIcons = CustomIcons;

  public columns = [];

  public loading$ = new BehaviorSubject(false);
  public pageLimitOptions = PageLimitOptions;
  public page = new Page();
  public temp = [];
  public rows: Book[];

  private subs: Subscription[] = [];
  constructor(
    public api: ApplicationApiService,
    public state: ApplicationStateService,
    public router: Router,
    public utils: ApplicationUtilsService,
    public cd: ChangeDetectorRef,
    public dialog: MatDialog,
    private cookieService: CookieService,
  ) {
    this.page.offset = 0;
    this.page.limit = 25;

    this.subs.push(
      combineLatest([this.state.bookSearchString$, this.state.bookSortingMode$])
        .pipe(debounceTime(400))
        .subscribe((res) => this.setPage({offset: 0}))
    );
  }

  refresh(): any {
    this.setPage({offset: 0});
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  setPage(pageInfo): any {
    this.loading$.next(true);
    this.page.offset = pageInfo.offset;
    this.state.getOwnPaginatedBooks(true, this.page.limit, this.page.offset * this.page.limit, {
      sort: this.getSort(),
      filter: this.getFilter()
    })
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe((res) => {
        const {data, page} = res;
        this.temp = data;
        this.page.totalElements = page.totalElements;
        this.page.totalPages = page.totalElements / this.page.limit >= 1 ? page.totalElements / this.page.limit : 1;
        this.rows = this.temp.map(book => {
          return {...book, collections: book.collections?.map(collection => collection.title).join(', ')};
        });
        this.cd.markForCheck();
      });
  }

  onLimitChange(limit: any): any {
    this.page.limit = Number(limit);
    this.refresh();
  }

  addBook(): void {
    this.state.bookEditorStep$.next(0);
    this.state.currentBook$.next(null);
    this.router.navigate(['admin/book/new']);
  }

  delete(id: string): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        disableClose: true,
        autoFocus: false,
        data: {text: 'delete_book_confirm_text', title: 'delete_book_confirm_title'}
      })
      .afterClosed()
      .pipe(first()).subscribe(yes => {
      if (!yes) {
        return;
      }
      this.loading$.next(true);
      this.api.deleteBook$(id)
        .pipe(
          first(),
          catchError((): any => {
            this.utils.showSnackbar('delete_book_error_msg');
            this.loading$.next(false);
          }),
          //finalize(() => this.loading$.next(false)),
          switchMap(() => {
            return this.state.getBooks(true);
          })
        ).subscribe(() => {
        this.state.getBooks();
        this.state.getSubscriptions();
        this.setPage({offset: this.page.offset});
        this.utils.showSnackbar('delete_book_success_msg');
        this.loading$.next(false);
      });
    });
  }

  edit(uid: string): void {
    this.router.navigate(['admin/book/edit', uid]);
  }

  getSort(): any {
    return {
      column: this.state.bookSortingMode$?.value,
      order: 'asc'
    };
  }

  getFilter(): any {
    const defaultFilter = [];
    if (this.state.bookSearchString$?.value) {
      return [
        ...defaultFilter,
        {
          column: 'title',
          method: 'contains',
          data: this.state.bookSearchString$?.value
        },
        {
          column: 'authors',
          method: 'contains',
          data: this.state.bookSearchString$?.value
        }
      ];
    } else {
      return defaultFilter;
    }
  }
}
