<div class="user-license-edit-form-template" style="position: relative">
  <div *ngIf="loading$ | async" class="global-spinner-cnt">
    <mat-spinner color="primary" diameter="50"></mat-spinner>
  </div>
  <form [formGroup]="form" class="global-flex-column-space-between-start">
    <div class="form-field-cnt global-flex-column-space-between-start">
      <span>{{'license_edit_code_label' | translate}}</span>
      <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
        <input [formControlName]="FormFields.code" matInput>
      </mat-form-field>
    </div>
  </form>
</div>
