import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {catchError, map, Observable, of, switchMap, timer} from 'rxjs';
import {ApplicationApiService} from 'src/app/shared/services/application-api.service';
import {Router} from '@angular/router';
import {ApplicationAuthService} from 'src/app/shared/services/application-auth.service';
import {ErrorResponseCodes} from 'src/app/shared/app-interfaces';

// #todo Relocate to ...interfaces;
export interface CreatePublisherPayload {
  name: string;
  email: string;
  phone?: string;
  password: string;
}

export interface Publisher {
  blocked: boolean;
  uid: string;
  name: string;
  created: string | Date | number;
  description?: string;
  descriptionSmall?: string;
  email: string;
  phone: string;
  published: boolean;
  category?: string;
  catalogPath?: string;
  currency?: currency;
}

export interface PublisherBeforeDelete extends Publisher {
  booksCount: number;
  collectionsCount: number;
  subscriptionsCount: number;
}

export interface currency{
  title: string;
  symbol: string;
}

@Component({
  selector: 'app-publisher-registration-form',
  templateUrl: './publisher-registration-form.component.html',
  styleUrls: ['./publisher-registration-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublisherRegistrationFormComponent implements OnInit {
  public form: FormGroup;
  public isAgreed: boolean = false;
  public displayEmailIsTakenError: boolean = false;

  ngOnInit(): void {
    this.buildForm();
  }

  constructor(
    private formBuilder: FormBuilder,
    private api: ApplicationApiService,
    private router: Router,
    private auth: ApplicationAuthService
  ) {}

  get isSubmitDisabled() {
    return this.form.status != 'VALID';
  }

  public removeEmailIsTakenError() {
    if (this.displayEmailIsTakenError) {
      this.displayEmailIsTakenError = false;
      this.form.controls['email'].setErrors({ emailTaken: false });
    }
  }

  submit(): void {
    this.form.markAllAsTouched();
    if (this.form.status != 'VALID') return;
    
    const { repeatPassword, ...payload } = this.form.value;
    this.api
      .createPublisher$(payload)
      .pipe(catchError((err) => of(err)))
      .subscribe((credentials) => {
        const isError = !!credentials.error;
        const tenantExists =
          credentials.error === ErrorResponseCodes.TENANT_ALREADY_EXISTS;
        const userExists =
          credentials.error === ErrorResponseCodes.USER_ALREADY_EXISTS;

        // #todo Finish error handling (to cover other error cases);
        if (isError && (tenantExists || userExists)) {
          this.displayEmailIsTakenError = true;
          this.form.controls['email'].setErrors({ emailTaken: true });
          return;
        }

        // No errors. Set credantials to the store and session storage;

        this.auth.login(credentials, true);

        // End of the registration process;
        this.form.reset();
        window.document.body.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        this.router.navigate(['/admin/manage-main']);
      });
  }

  private buildForm(): void {
    this.form = this.formBuilder.group(
      {
        name: ['', [Validators.required, Validators.minLength(3)]],
        email: ['', [Validators.required, Validators.email], this.validateEmailNotTakenAsync.bind(this)],
        password: ['', [Validators.required, Validators.minLength(6)]],
        repeatPassword: ['', [Validators.required, Validators.minLength(6)]],
      },
      {
        validator: (control: AbstractControl) => {
          const password = control.get(['password']).value;
          const confirmPassword = control.get(
            ['repeatPassword']
          ).value;
          if (password !== confirmPassword) {
            control
              .get(['repeatPassword'])
              .setErrors({ MatchPassword: true });
          } else if (password === confirmPassword && control.get(['repeatPassword']).getError( 'MatchPassword')) {
            return control
              .get(['repeatPassword'])
              .setErrors(null);
          }
        },
      }
    );
  }

  validateEmailNotTakenAsync(control: AbstractControl): Observable<any> {
    return timer(500).pipe(
      switchMap(() => {
        return this.api.checkEmailNotTaken(control.value).pipe(
          map(res => {
            return res ? null : { emailTaken: true };
          })
        );
      })
    );
  }
}
