import { NgModule } from '@angular/core';
import { HighlightSearchPipe } from './highlight.pipe';
import { TranslatePipe } from './translate.pipe';
import { CurrencyPipe } from './currency.pipe';

@NgModule({
  declarations: [
    HighlightSearchPipe,
    TranslatePipe,
    CurrencyPipe
  ],
  exports: [
    HighlightSearchPipe,
    TranslatePipe,
    CurrencyPipe 
  ],
  providers: [
    HighlightSearchPipe,
    TranslatePipe,
    CurrencyPipe
  ]
})
export class PipesModule {}
