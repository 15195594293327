<nav *ngIf="{routerUrl: state.routerUrl$ | async } as data">
  <div *ngIf="state.superAdminMode$.value && data.routerUrl?.includes('superAdmin')" class="global-main-cnt super-admin-mode">

    <a [routerLink]="['']">
      <mat-icon>
        arrow_back
      </mat-icon>
    </a>

    <a [routerLink]="['/superAdmin/manage-tenants']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">{{
      'menu_manage_tenants_button' | translate }}</a>

  </div>
  <div *ngIf="state.adminMode$.value && !data.routerUrl?.includes('superAdmin')" class="global-main-cnt admin-links admin-mode">
    <a [routerLink]="['']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
      <mat-icon>library_books</mat-icon>
      {{'menu_catalogue_button' | translate }}
    </a>
    <a [routerLink]="['/admin/manage-main']" [routerLinkActive]="'active'">
      <mat-icon [svgIcon]="CustomIcons.main_catalogue_icon"></mat-icon>
      {{ 'menu_publisher_button' | translate }}
    </a>

    <a [routerLink]="['/admin/manage-books']" [routerLinkActive]="'active'">
      <mat-icon>import_contacts</mat-icon>
      {{ 'menu_books_button' | translate }}
    </a>

    <a [routerLink]="['/admin/manage-collections']" [routerLinkActive]="'active'">
      <mat-icon>widgets</mat-icon>
      {{ 'menu_collections_button' | translate }}
    </a>

    <a [routerLink]="['/admin/manage-subscriptions']" [ngClass]="{active:isActive('/admin/manage-subscriptions')}">
      <mat-icon>vpn_key</mat-icon>
      {{ 'menu_subscriptions_button' | translate }}
    </a>
    <a [routerLink]="['/admin/manage-readers', 'all']" [ngClass]="{active:isActive('/admin/manage-readers')}">
      <mat-icon>people</mat-icon>
      {{ 'menu_readers_button' | translate }}
    </a>

    <a [routerLink]="['/admin/manage-users']" [routerLinkActive]="'active'">
      <mat-icon>people</mat-icon>
      {{ 'menu_admins_button' | translate }}
    </a>   
  </div>
</nav>
