import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ApplicationStateService} from '../../shared/services/application-state.service';
import {CustomIcons} from '../../shared/services/application-utils.service';
import {ApplicationAuthService} from '../../shared/services/application-auth.service';
import {MatDialog} from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { EditUserProfileComponent } from './edit-user-data-template/edit-user-data.component';
import { MySubscriptionsDialogComponent } from './my-subscriptions-dialog-template/my-subscriptions-dialog-template.component';
import { SwitchTenantDialogTemplateComponent } from './switch-tenant-dialog-template/switch-tenant-dialog-template.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  public readonly CustomIcons = CustomIcons;
  public environmentDomain: string = environment.domain;

  constructor(
    public authService: ApplicationAuthService,
    public router: Router,
    public dialog: MatDialog,
    public state: ApplicationStateService
  ) {}

  ngOnInit(): void {
  }

  goToAuthorization(): void {
    this.router.navigate(['/authorization']);
  }

  openMySubscriptionsDialog(): void {
    this.dialog.open(MySubscriptionsDialogComponent, {
      maxWidth: '1000px',
      width: '100%',
      minHeight: '200px',
      maxHeight: '90vh',
      autoFocus: false
    });
  }

  openProfileDialog(): void {
    this.dialog.open(EditUserProfileComponent, {
      maxWidth: '1000px',
      width: '100%',
      minHeight: '200px',
      disableClose: true,
      autoFocus: false,
      data: {user: this.authService.credentials}
    }).afterClosed().subscribe(res => {
      if(res) {
        this.authService.credentials.name = res;
      }
    });
  }

  openTenantsManagingDialog(): void {
    this.state.superAdminMode$.next(true);
    this.router.navigate(['/superAdmin/manage-tenants']);
  }

  openSwitchTenantDialog(): void {
    this.dialog.open(SwitchTenantDialogTemplateComponent, {
      maxWidth: '1000px',
      width: '100%',
      minHeight: '200px',
      autoFocus: false
    });
  }
}
