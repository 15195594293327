<mat-dialog-content>
  <h3 class="dialog-title">{{ 'edit_user_profile_dialog_title' | translate }}</h3>
  <div class="profile-edit-form-template" style="position: relative" *ngIf="{
        isLoading$: loading$ | async,
        subscribers: state.userProfileSubscribers$ | async,
        purchases: state.userProfilePurchases$ | async
    } as data">
    <div *ngIf="loading$ | async" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>

    <form [formGroup]="form" class="global-flex-column-space-between-start">
      <div class="form-field-cnt global-flex-column-space-between-start">
        <span>{{'profile_edit_name_label' | translate}}</span>
        <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
          <input
            matInput
            [formControlName]="FormFields.name"
            [placeholder]="'edit_user_data_name_placeholder' | translate">
        </mat-form-field>
      </div>

      <div class="form-field-cnt global-flex-column-space-between-start" style="opacity: 0.3">
        <span>{{ 'profile_edit_email_label' | translate }}</span>
        <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
          <input
            matInput
            type="email"
            [formControlName]="FormFields.userName"
            placeholder="E-mail"
            readonly
            disabled>
        </mat-form-field>
      </div>
      
      <!-- Password fields are temporary disabled -->
      <!-- <div class="form-field-cnt global-flex-column-space-between-start">
        <span>{{'profile_new_password_label' | translate}}</span>
        <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
          <input [formControlName]="FormFields.password" matInput type="password">
        </mat-form-field>
      </div> -->

      <!-- <div class="form-field-cnt global-flex-column-space-between-start">
        <span>{{'profile_old_password_label' | translate}}</span>
        <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
          <input [formControlName]="FormFields.newPassword" matInput type="password">
        </mat-form-field>
      </div> -->
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div style="transform: translateX(-10px)">
    <button mat-button (click)="withdraw()">
      <span style="color: #BDBDBD;">{{'withdraw_button_text' | translate}}</span>
    </button>
  </div>

  <div class="buttons">
    <button class="global-button default" (click)="cancel()">
      {{ 'dialog_options_cancel' | translate }}
    </button>
    <button class="global-button" (click)="save()">
      {{ 'dialog_options_save' | translate }}
    </button>
  </div>
</mat-dialog-actions>