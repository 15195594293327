<div *ngIf="{
    readerMode: readerMode$ | async,
    cursorMode: cursorMode$ | async
    } as data" style="height: 100%;">

    <div class="header-cnt global-flex-row-start-center">
        <div class="full-width global-flex-row-space-between-center">
            <div class="reader-title-cnt global-flex-row-start-center" [ngStyle]="{'flex': type === BookType.EPUB ? '1 0 40%' : '1'}">
                <button class="open-content_button" (click)="sidenav.toggle()"
                    [ngClass]="{'content-opened-button': sidenav.opened}" [matTooltip]="(sidenav.opened
                ? 'reader_close_contents_button_tooltip'
                : 'reader_open_contents_button_tooltip'
              ) | translate">
                    <span class="material-icons description" [ngStyle]="{ color: sidenav.opened ? '#169e16' : null }">
                        description
                    </span>
                </button>
                <span class="book-title">{{ book?.title }}</span>
            </div>
            <!-- [ngStyle]="{'flex': type === BookType.EPUB ? '1 0 60%' : '1'}" -->
            <div class="reader-controls global-flex-row-space-between-center">
                <div class="global-flex-row-space-between-center" style="width: 310px">
                    <div *ngIf="currentPage != null && totalPages != null" class="kvadro-navigation_controls">
                        <button [matTooltip]="'reader_previous_page_button_tooltip' | translate"
                            class="arrow-btn previous-page" (click)="prev()">
                            <span class="material-icons reverse">
                                forward
                            </span>
                        </button>
                        <p class="input-value">
                            <strong>{{ currentPage }}</strong>{{'/'}}
                            <strong>{{ totalPages }}</strong>
                        </p>
                        <button [matTooltip]="'reader_next_page_button_tooltip' | translate"
                            [disabled]="totalPages <= currentPage"
                            class="arrow-btn next-page" (click)="next()">
                            <span class="material-icons">
                                forward
                            </span>
                        </button>
                    </div>
                    <div *ngIf="type === BookType.PDF" class="kvadro-zoom_controls"
                        [ngStyle]="{'pointer-events': data.readerMode === ReaderMode.FullScreen ? 'none': 'auto', 'opacity': data.readerMode === ReaderMode.FullScreen ? '0.4' : '1'}">
                        <button class="zoom_out" [matTooltip]="'reader_zoom_out_button_tooltip' | translate"
                            (click)="zoomOut()">-
                        </button>
                        <p class="current-zoom">
                            {{ getZoomString() }}
                        </p>
                        <button class="zoom_in" [matTooltip]="'reader_zoom_in_button_tooltip' | translate"
                            (click)="zoomIn()">
                            +
                        </button>
                    </div>
                    <div *ngIf="type === BookType.PDF" class="kvadro-screen">
                        <button class="full-screen" (click)="switchFullScreenMode()"
                            [matTooltip]="(data.readerMode === ReaderMode.FullScreen ? 'reader_unset_mode_button_tooltip' : 'reader_full_screen_mode_button_tooltip') | translate">
                            <span class="material-icons">
                                {{data.readerMode === ReaderMode.FullScreen ? 'zoom_in_map' : 'zoom_out_map' }}
                            </span>
                        </button>
                    </div>
                    <div *ngIf="type === BookType.EPUB" class="book-reader-header-epub-page-controls">

                        <button [disabled]="!epubReaderService?.hasPrevChapter"
                                class="global-button grey default epub-btns" 
                                (click)="epubReaderService?.prev()" 
                                style="margin-right: 10px;">
                            <span>{{ 'book_reader_header_prev_button_label' | translate }}</span>
                        </button>
                        <button [disabled]="!epubReaderService?.hasNextChapter"
                                class="global-button grey default epub-btns" 
                                (click)="epubReaderService?.next()">
                            <span>{{ 'book_reader_header_next_button_label' | translate }}</span>
                        </button>
                    </div>
                </div>
                <div *ngIf="type === BookType.PDF" class="global-flex-row-space-between-center kvadro-tools" style="width: 90px">
                    <button [ngClass]="{ active: data.cursorMode === CursorMode.Mouse }"
                        [matTooltip]="'reader_index_mode_button_tooltip' | translate" class="cursor"
                        (click)="removeDragToScroll()">
                        <mat-icon class="material-icons" [svgIcon]="CustomIcons.cursor"></mat-icon>
                    </button>
                    <button [ngClass]="{ active: data.cursorMode === CursorMode.HandScroll }"
                        [matTooltip]="'reader_swipe_mode_button_tooltip' | translate" class="handTool"
                        (click)="dragToScroll()">
                        <span class="material-icons">
                            pan_tool
                        </span>
                    </button>
                    <!--            <button class="copy">-->
                    <!--              <mat-icon class="material-icons">-->
                    <!--                file_copy-->
                    <!--              </mat-icon>-->
                    <!--            </button>-->
                    <!--            <button class="print">-->
                    <!--              <span class="material-icons">-->
                    <!--                print-->
                    <!--              </span>-->
                    <!--            </button>-->
                </div>
            </div>
            <div *ngIf="type === BookType.PDF" class="menu-items">
                <button [matTooltip]="'reader_menu_button_tooltip' | translate" [matMenuTriggerFor]="menu">
                    <span class="material-icons reverse">
                        menu
                    </span>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="removeDragToScroll()">
                        <mat-icon class="material-icons" [svgIcon]="CustomIcons.cursor"
                            style="margin-right: 16px; vertical-align: middle; ">zoom_out_map
                        </mat-icon>
                        <span>{{ 'reader_index_mode_button_tooltip' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="dragToScroll()">
                        <span class="material-icons mat-icon-no-color"
                            style="margin-right: 16px; vertical-align: middle; ">pan_tool</span>
                        <span>{{ 'reader_swipe_mode_button_tooltip' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="switchFullScreenMode()">
                        <span class="material-icons mat-icon-no-color"
                            style="margin-right: 16px; vertical-align: middle; ">{{data.readerMode ===
                            ReaderMode.FullScreen ? 'zoom_in_map' : 'zoom_out_map' }}</span>
                        <span>{{ (data.readerMode === ReaderMode.FullScreen ? 'reader_unset_mode_button_tooltip' :
                            'reader_full_screen_mode_button_tooltip') | translate }}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</div>