import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-publishers-question-form',
  templateUrl: './publishers-question-form.component.html',
  styleUrls: ['./publishers-question-form.component.scss']
})
export class PublishersQuestionFormComponent implements OnInit {

  public form: FormGroup;
  public isAgreed: boolean = false;

  ngOnInit() {
    this.buildForm()
  }

  constructor(
    private formBuilder: FormBuilder
  ) {}

  submit(): void {
    console.log(this.form.value)
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      fullName: this.formBuilder.control('').addValidators([Validators.required]),
      email: this.formBuilder.control('').addValidators([Validators.compose([Validators.required, Validators.email])]),
      question: this.formBuilder.control('').addValidators([Validators.required]),
    });
  }
}
