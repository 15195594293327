import {ChangeDetectionStrategy, Component, Input, OnInit, Inject} from '@angular/core';
import {BehaviorSubject, catchError, finalize, first} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApplicationUtilsService} from '../../../shared/services/application-utils.service';
import {ApplicationAuthService} from '../../../shared/services/application-auth.service';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {ErrorResponseCodes} from '../../../shared/app-interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


enum FormFields {
  'email' = 'email'
}
@Component({
  selector: 'app-restore-password-template',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestorePasswordComponent implements OnInit {
  public form: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(false);
  public readonly FormFields = FormFields;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {email: string},
    public formBuilder: FormBuilder,
    public utils: ApplicationUtilsService,
    private api: ApplicationApiService,
    private dialogRef: MatDialogRef<RestorePasswordComponent>
  ) {}

  public ngOnInit(): void {
    this.buildLoginForm();
  }

  private buildLoginForm(): void {
    this.form = this.formBuilder.group({
      [FormFields.email]: [this?.data.email || '', [Validators.required, Validators.email]],
    });
  }
  save(): void {
    this.api.resetPassword(this.form.controls[FormFields.email].value)
      .pipe(
        first(),
        catchError((): any => {
          this.utils.showSnackbar(ErrorResponseCodes.SOMETHING_WENT_WRONG);
        }),
        finalize(() => this.loading$.next(false)),
      ).subscribe(() => {
      this.utils.showSnackbar('restore_password_success_msg');
    });
    this.dialogRef.close();
  }
  cancel():void{
    this.dialogRef.close();
  }
}
