<mat-dialog-content>
  <h3 class="dialog-title">{{ 'create_admin_dialog_title' | translate }}</h3>
  <div class="create-admin-form-template">
    <div *ngIf="loading$ | async" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>

    <form [formGroup]="form" (submit)="save()">
      <p class="error-message" *ngIf="displayEmailIsTakenError">
        {{'user_with_such_email_is_already_exist' | translate}}</p>
      <input class="global-input" formControlName="email" type="text" placeholder="example@mail.com"
            (ngModelChange)="displayEmailIsTakenError ? removeEmailIsTakenError() : null">
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="buttons">
    <button class="global-button default" (click)="cancel()">
      {{ 'dialog_options_cancel' | translate }}
    </button>
    <button type="submit" class="global-button" (click)="save()">
      {{ 'button_text_send' | translate }}
    </button>
  </div>
</mat-dialog-actions>