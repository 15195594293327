<div
  class="global-main-cnt global-flex-row-start-start"
  *ngIf="{
    currentBook: currentBook$ | async,
    adminMode: state.adminMode$ | async,
    isAuthenticated: state.isAuthenticated$ | async,
    searchString: (this.state.bookSearchString$ | async)?.trim()
  } as data"
>
  <div class="book-cnt global-flex-column-space-between-center">
    <div class="book-picture" [ngStyle]="{ 'backgroundColor': (data.currentBook?.hasImage ? '#F6F7F9' : 'white') }">
      <img *ngIf="data.currentBook?.hasImage" src="{{ utils.getBookTitleImage(data.currentBook?.uid) }}" alt="img" style="width: 250px;">
      <img *ngIf="!data.currentBook?.hasImage" src="assets/book_without_title.png" alt="img" style="width: 160px;height: 230px;">
    </div>

    <button mat-flat-button
      [ngClass]="{ disabled: !data.currentBook?.hasBook || !data.isAuthenticated || !data.currentBook?.hasAccessRead }"
      class="global-button green full-width"
      (click)="openBook(data.currentBook)"
      ><span>{{ 'read_book' | translate}}</span>
    </button>

    <span *ngIf="!data.isAuthenticated" class="read-book-hint">{{ 'book_read_user_not_authorized_warn' | translate }}</span>
    <span *ngIf="data.isAuthenticated && (data.currentBook?.hasAccessRead || data.adminMode) && !data.currentBook?.hasBook" class="read-book-hint">{{ 'book_has_not_loaded_warn' | translate }}</span>
<!--    <span *ngIf="data.isAuthenticated && !data.currentBook?.hasAccessRead" class="read-book-hint">
      {{ 'book_read_forbidden_warn' | translate }}
    </span>-->

    <div *ngIf="data.isAuthenticated && !data.currentBook?.hasAccessRead" class="access-info-container">
      <div *ngIf="!data.currentBook?.isPublished">
        {{'book_description_not_published' | translate}}
      </div>
      <div *ngIf="data.currentBook?.isPublished" class="global-flex-column-start-start" style="gap: 10px">
        <p *ngIf="this.accessToReadInfo?.onlyIntegration">{{this.accessToReadInfo.onlyIntegration | translate}}
          <b *ngFor="let subscription of this.accessToReadInfo.individualSubscriptions; let i = index"
             [matTooltip]="('book_description_read_info_subscriber_phone' | translate) + ' ' + subscription?.phone">
            <span *ngIf="i < this.accessToReadInfo.individualSubscriptions.length && i > 0">, </span>
              <a class="global-link-green" (click)="OpenReadersRequestDialog(subscription)">{{subscription.title}}</a>
          </b>
        </p>
        <p *ngIf="this.accessToReadInfo?.integration">{{this.accessToReadInfo.integration | translate}}
          <b *ngFor="let subscription of this.accessToReadInfo.individualSubscriptions; let i = index"
             [matTooltip]="('book_description_read_info_subscriber_phone' | translate) + ' ' + subscription?.phone">
          <span *ngIf="i < this.accessToReadInfo.individualSubscriptions.length && i > 0">, </span>
            <a class="global-link-green" (click)="OpenReadersRequestDialog(subscription)">{{subscription.title}}</a>
          </b>
        </p>
        <p *ngIf="this.accessToReadInfo?.regular">{{this.accessToReadInfo.regular | translate}}
          <b *ngFor="let subscription of this.accessToReadInfo.regularSubscriptions; let i = index"
             [matTooltip]="('book_description_read_info_subscriber_phone' | translate) + ' ' + subscription?.phone">
          <span *ngIf="i < this.accessToReadInfo.regularSubscriptions.length && i > 0">, </span>
            <a class="global-link-green" (click)="OpenReadersRequestDialog(subscription)">{{subscription.title}}</a>
         </b>
        </p>

        <p>{{'book_description_read_info_publisher' | translate}} <b style="font-size: 18px">{{data?.currentBook?.tenant.name}}</b><br>
          <span *ngIf="data?.currentBook?.tenant.phone">{{'book_description_read_info_publisher_phone' | translate}}
            <a class="global-link-green" href="{{'tel:' + data?.currentBook?.tenant.phone}}">{{data?.currentBook?.tenant.phone}}</a>
          </span><br>
          <span *ngIf="data?.currentBook?.tenant?.email">{{'book_description_read_info_publisher_email' | translate}}
            <a class="global-link-green" href="{{'mailto:' + data?.currentBook?.tenant.email}}">{{data?.currentBook?.tenant.email}}</a>
          </span>
        </p>
      </div>
    </div>
    <div style="margin-top: 20px" *ngIf="data.isAuthenticated && !data.currentBook?.hasAccessRead && data?.currentBook?.subscribers.length > 0">
      <a class="global-link-green consent-link" (click)="addLicense()">
        {{ 'add_subscription' | translate }}
      </a>
    </div>
  </div>

  <div style="display: flex; flex-direction: column; width: 100%;">
    <mat-card style="width: 100%; box-sizing: border-box; margin-bottom: 30px">
      <div class="book-info-cnt global-flex-column-start-start">
        <span class="global-authors">{{ data.currentBook?.authors }}</span>
        <span class="global-title">{{ data.currentBook?.title }}</span>
        <div class="book-advanced-info-cnt global-flex-row-start-start">
          <div class="book-specs-cnt global-flex-column-start-start">
            <span *ngIf="data.currentBook?.isbn">{{ 'ISBN: ' + data.currentBook?.isbn }}</span>
            <span *ngIf="data.currentBook?.publicationTitle">{{ ('book_description_publication_description_label' | translate) + ' ' + data.currentBook?.publicationTitle }}</span>
            <span *ngIf="data.currentBook?.publicationYear">{{ ('book_description_publication_year_label' | translate) + ' ' + data.currentBook?.publicationYear }}</span>
            <span *ngIf="data.currentBook?.format">{{ ('book_description_format_label' | translate) + ' ' + data.currentBook?.format }}</span>
            <span *ngIf="data.currentBook?.bookBinding">{{ ('book_description_binding_label' | translate) + ' ' + data.currentBook?.bookBinding }}</span>
            <span *ngIf="data.currentBook?.countPages">{{ ('book_description_pages_label' | translate) + ' ' + data.currentBook?.countPages }}</span>
          </div>
          <span [innerHTML]="data.currentBook?.annotation | highlight: data.searchString" class="book-annotation"></span>
        </div>
      </div>
    </mat-card>
    <div *ngIf="data?.currentBook?.bibliographicTitle" class="global-flex-column-space-between-start" style="margin-bottom: 50px">
      <span class="price" style="font-size: 16px; font-weight: 500; margin-bottom: 6px">{{ 'book_bibliography_record_label' | translate }}</span>
      <div *ngIf="data.currentBook?.bibliographicTitle">
        <span #bibliography style="font-size: 14px; color: #767070" >{{ checkDoubleDot(data.currentBook?.bibliographicTitle || '') + ' — ' + checkDoubleDot(data.currentBook?.publicationTitle) +
        ' — ' + ('book_bibliography_publication_label' | translate) +', '  + data.currentBook?.publicationYear + '. — ' +
        data.currentBook?.countPages + ' c. — ISBN ' + checkDoubleDot(data.currentBook?.isbn) + ' — ' +
        ('book_bibliography_text_format' | translate) + ' // ' + ('book_bibliography_library_label' | translate) + ' ' +
        ('book_bibliography_text_text_site' | translate) + ' — ' + 'URL: https://elibrica.com/'
        + data.currentBook?.uid + ' (' + ('book_bibliography_date' | translate) +
        (currentDate | date: 'dd.MM.yyyy') + ')' + '. — ' + ('book_bibliography_access_label' | translate)}}</span>
        <button mat-menu-item (click)="copyCode(bibliography?.innerText)" style="padding: 0 0 0 8px">
          <mat-icon>content_copy</mat-icon>
          <span>{{'book_bibliography_copy_label' | translate}}</span>
        </button>
      </div>
    </div>
    <app-book-contents *ngIf="data.currentBook?.tableContents" [bookContents]="data.currentBook?.tableContents"></app-book-contents>
  </div>
</div>