import {Clipboard} from '@angular/cdk/clipboard';
import {NestedTreeControl} from '@angular/cdk/tree';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DateAdapter} from '@angular/material/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, combineLatest, catchError, finalize, Subscription} from 'rxjs';
import { IDialogContent} from 'src/app/shared/components/form-dialog/form-dialog.component';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {ApplicationUtilsService, CustomIcons} from '../../../shared/services/application-utils.service';
import {MatDialog} from '@angular/material/dialog';
import { EditSubscriberComponent } from '../subscribers/edit-subscriber-template/edit-subscriber.component';

enum FormFields {
  subscriber = 'subscriber',
  begin = 'begin',
  end = 'end',
  remark = 'remark',
  price = 'price',
  individualLicenseDurationMonths = 'individualLicenseDurationMonths',
}

export enum SubscriptionMode {
  regular = 'regular',
  individual = 'individual',
}

export class ItemNode {
  children?: ItemNode[];
  title: string;
  uid?: string;
  selected?: boolean;
  isSelectEntireCollection?: boolean;
  rowNode?: ItemNode;
  belongsToCollection?: string;
  price?: any;
}

export class ChecklistDatabase {
  dataChange = new BehaviorSubject<ItemNode[]>([]);

  get data(): ItemNode[] {
    return this.dataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize(): void {
  }
}

@Component({
  selector: 'app-subscription-editor',
  templateUrl: './subscription-editor.component.html',
  styleUrls: ['./subscription-editor.component.scss'],
  providers: [ChecklistDatabase]
})

export class SubscriptionEditorComponent implements OnInit, OnDestroy, IDialogContent {
  public readonly CustomIcons = CustomIcons;
  @Input() public closeDialog: any;

  public readonly FormFields = FormFields;
  public readonly SubscriptionMode = SubscriptionMode;

  public form: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(false);

  treeControl = new NestedTreeControl<ItemNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ItemNode>();
  currentSubscriberId: string;
  currentSubscription: any;
  public treeRows: any;
  private subs: Subscription[] = [];
  constructor(
    public formBuilder: FormBuilder,
    public state: ApplicationStateService,
    public api: ApplicationApiService,
    public utils: ApplicationUtilsService,
    public router: Router,
    private route: ActivatedRoute,
    private _database: ChecklistDatabase,
    private dialog: MatDialog,
  ) {
    this.subs.push(
      this.route.paramMap.subscribe(params => {
        this.state.routeParamSubscriptionId$.next(params?.get('subscriptionId'));
      })
    );
    this.state.getSubscribers();
    this.state.currentSubscriber$.subscribe(subscriber => {
      this.currentSubscriberId = subscriber?.uid;
    });
    this.state.currentSubscription$.subscribe(subscription => {
      if (subscription?.licenseType === 'Regular') {
        this.state.subscriptionMode$.next('regular');
      } else if (subscription?.licenseType === 'Individual') {
        this.state.subscriptionMode$.next('individual');
      }
      this.currentSubscription = subscription;
    });
    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  hasChild = (_: number, node: ItemNode) => !!node.children && node.children.length > 0;

  descendantsAllSelected(node: ItemNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.slice(1).every(child => {
        return child.rowNode.selected;
      });
    if (!descAllSelected) {
      node.isSelectEntireCollection = false;
    }
    return descAllSelected;
  }

  descendantsPartiallySelected(node: ItemNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.slice(1).some(child => child.rowNode.selected);
    return result && !this.descendantsAllSelected(node);
  }

  itemSelectionToggle(node: ItemNode): void {
    let allBooksSelected = node.children[0].rowNode.selected;
    if (!allBooksSelected && this.descendantsAllSelected(node)) {
      allBooksSelected = true;
    }

    if (allBooksSelected) {
      node.selected = false;
    } else {
      node.selected = true;
    }
    if (node.isSelectEntireCollection) {
      node.selected = true;
    }
    const descendants = this.treeControl.getDescendants(node);
    node.selected
      ? descendants.map(desc => {
        if (this.isBookAlreadySelected(desc)) {
          return;
        }
        desc.rowNode.selected = true;
      })
      : descendants.map(desc => {
        if (this.isBookAlreadySelected(desc)) {
          return;
        }
        desc.rowNode.selected = false;
      });

    descendants.forEach(child => child.rowNode.selected);
    this.checkAllParentsSelection(node);
  }

  itemEntireCollectionSelectionToggle(node: ItemNode): void {
    if (node.isSelectEntireCollection) {
      node.isSelectEntireCollection = false;
    } else {
      node.isSelectEntireCollection = true;
    }
    const descendants = this.treeControl.getDescendants(node);
    if (node.isSelectEntireCollection) {
      descendants.map(desc => desc.rowNode.selected = true);
    }
    descendants.forEach(child => child.rowNode.selected);
    this.checkAllParentsSelection(node);
  }

  leafItemSelectionToggle(node: ItemNode): void {
    if (node.rowNode.selected) {
      node.rowNode.selected = false;
    } else {
      node.rowNode.selected = true;
    }
    this.checkAllParentsSelection(node);
  }

  checkAllParentsSelection(node: ItemNode): void {
    let parent: ItemNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: ItemNode): void {
    const nodeSelected = node.children[0].rowNode.selected;
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.slice(1).every(child => {
        return child.rowNode.selected;
      });
    if (nodeSelected && !descAllSelected) {
      node.children[0].rowNode.selected = false;
    } else if (!nodeSelected && descAllSelected) {
      node.children[0].rowNode.selected = true;
    }
  }

  getParentNode(node: ItemNode): ItemNode | null {
    if (node.rowNode) {
      return this.treeRows.find(collection => collection.uid === node.belongsToCollection);
    }
    return null;
  }

  isBookAlreadySelected(node: ItemNode): boolean {
    return this?.getCollectionsAndBooksIds('booksInCollections').find(book => book.uid === node.rowNode.uid) !== undefined;
  }


  public ngOnInit(): void {
    combineLatest([this.state.ownBooks$, this.state.collections$, this.state.currentSubscription$])
      .pipe()
      .subscribe(res => {
        const [books, collections, currentSubscription] = res;
        const filteredBooks = books.filter(book => book?.isCreated);
        const booksOutOfCollection = filteredBooks.filter(book => book.collections.length === 0).map(book => {
          return {...book, belongsToCollection: 'outOfCollection'};
        });
        this.treeRows = collections?.filter(collection => collection?.books?.length > 0)?.map((collection, index) => {
          const booksInCollection = filteredBooks.filter(book => collection.books.find(collectionBook => collectionBook.uid === book.uid));
          const parentNode = <ItemNode>{
            children: booksInCollection.map(rowBook => {
              return <ItemNode>{...rowBook, rowNode: rowBook, belongsToCollection: collection.uid, price: rowBook?.price};
            }),
            title: collection.title,
            uid: collection.uid,
            isSelectEntireCollection: currentSubscription?.collections?.find(c => c.uid === collection.uid) !== undefined
          };
          parentNode.children.map(child => currentSubscription?.books?.find(book => book.uid === child.uid ?
            child.rowNode.selected = true : child.rowNode.selected));

          if (parentNode.isSelectEntireCollection) {
            parentNode.children.map(child => child.rowNode.selected = true);
          }

          parentNode.children.unshift(<ItemNode>{
            title: 'subscription_editor_all_books',
            uid: collection.uid,
            selected: parentNode.isSelectEntireCollection ||
              parentNode.children?.every(child => child.rowNode?.selected)
          });

          parentNode.children[0] = {
            ...parentNode.children[0],
            rowNode: parentNode.children[0],
            belongsToCollection: collection.uid
          };
          return parentNode;
        });
        const outOfCollection = <ItemNode>{
          children: booksOutOfCollection.map(rowBook => {
            return <ItemNode>{...rowBook, rowNode: rowBook, belongsToCollection: ''};
          }),
          title: 'out_of_collection',
          uid: 'outOfCollection',
          isSelectEntireCollection: false
        };

        outOfCollection.children.map(child => currentSubscription?.books?.find(book => book.uid === child.rowNode.uid ?
          child.rowNode.selected = true : child.rowNode.selected = false));
        outOfCollection.children.unshift(<ItemNode>{
          title: 'subscription_editor_all_books',
          uid: outOfCollection.uid,
          selected: outOfCollection?.children?.every(child => child.rowNode.selected) || outOfCollection.isSelectEntireCollection
        });
        outOfCollection.children[0] = {
          ...outOfCollection.children[0],
          rowNode: outOfCollection.children[0],
          belongsToCollection: outOfCollection.uid
        };

        if (outOfCollection.children.length > 1) {
          this.treeRows.push(outOfCollection);
        }
        this._database.dataChange.next(this.treeRows);
      });

    combineLatest([this.state.subscriptionMode$, this.state.currentSubscription$, this.state.currentSubscriber$])
      .subscribe(([subscriptionMode, currentSubscription, currentSubscriber]) => {
        if (subscriptionMode === 'regular') {
          this.buildRegularSubscriptionForm();
        } else {
          this.buildIndividualSubscriptionForm();
        }
      });
  }

  public ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  public getFormControl(field: FormFields): FormControl {
    return this.form.get(field) as FormControl;
  }

  public getCollectionsAndBooksIds(type: string): Array<{ uid: string }> {
    const books = [];
    const collections = [];
    const selectedCollectionsChildrens = [];
    for (let parentNode of this.treeRows) {
      if (parentNode.isSelectEntireCollection) {
        selectedCollectionsChildrens.push(...parentNode.children.map(child => child.rowNode).slice(1));
        collections.push({uid: parentNode.uid});
      }
    }
    if (type === 'booksInCollections') {
      return [...new Set(selectedCollectionsChildrens)];
    }
    if (type === 'collections') {
      return collections;
    }
    if (type === 'books') {
      for (let parentNode of this.treeRows.filter(collection => !collection.isSelectEntireCollection)) {
        for (let node of parentNode.children.slice(1)) {
          if (node.rowNode.selected && !books.find(book => book.uid === node.rowNode.uid)
            && !selectedCollectionsChildrens.find(child => child.uid === node.rowNode.uid)) {
            books.push({uid: node.rowNode.uid});
          }
        }
      }
      return books;
    }
    return;
  }

  private buildRegularSubscriptionForm(): void {
    this.form = this.formBuilder.group({
      [FormFields.subscriber]: [this.state.currentSubscription$.value?.subscriber?.uid || this.currentSubscriberId || '', Validators.required],
      [FormFields.begin]: [this.currentSubscription?.begin || '', Validators.required],
      [FormFields.end]: [this.currentSubscription?.end || '', Validators.required],
      [FormFields.price]: [this.currentSubscription?.price ?? '', Validators.required],
      [FormFields.remark]: [this.currentSubscription?.remark || '']
    });
  }

  private buildIndividualSubscriptionForm(): void {
    this.form = this.formBuilder.group({
      [FormFields.subscriber]: [this.currentSubscription?.subscriber?.uid || this.currentSubscriberId || '', Validators.required],
      [FormFields.individualLicenseDurationMonths]: [this.currentSubscription?.individualLicenseDurationMonths ?? '', Validators.required],
      [FormFields.remark]: [this.currentSubscription?.remark || '']
    });
  }

  createSubscriber(): any {
    this.state.currentSubscriber$.next(null);
    return this.dialog
      .open(EditSubscriberComponent, {
        maxWidth: '1000px',
        width: '100%',
        minHeight: '555px',
        disableClose: true,
        autoFocus: false,
      });
  }

  public editSubscriber(uid: string): any {
    event.preventDefault();
    event.stopPropagation();
    this.state.getSubscriber(uid);
    return this.dialog
      .open(EditSubscriberComponent, {
        maxWidth: '1000px',
        width: '100%',
        minHeight: '555px',
        disableClose: true,
        autoFocus: false,
        data: {subscriberId: uid}
      })
      .afterClosed().subscribe((res) => {
        if (res) {
          this.utils.showSnackbar('edit_subscription_success_msg');
        }
      });
  }

  changeCurrentSubscriber(uid: string): void {
    this.state.currentSubscriber$.next(this.state.subscribers$.value.find(subscriber => subscriber.uid === uid));
  }

  cancel(): void {
    this.router.navigate(['admin/manage-subscriptions']);
  }

  previous(): void {
    this.state.createSubscriptionStep$.next(0);
  }
  next(): void {
    this.state.createSubscriptionStep$.next(1);
  }

  toggleSubscriptionMode(subscriptionMode: string): void {
    this.state.subscriptionMode$.next(subscriptionMode);
  }

  save(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.loading$.next(true);
    let formPayload;
    if (this.state.subscriptionMode$.value === 'regular') {
      formPayload = {
        ...this.form.value,
        subscriber: {uid: this.form.value.subscriber},
        books: this.getCollectionsAndBooksIds('books') ?? [],
        collections: this.getCollectionsAndBooksIds('collections') ?? [],
        end: new Date(new Date(this.form.value.end).setHours(23, 59, 59, 999)),
        deleted: false,
        licenseType: 'Regular',
        begin: new Date(this.form.value.begin)
      };
    } else {
      formPayload = {
        ...this.form.value,
        subscriber: {uid: this.form.value.subscriber},
        books: this.getCollectionsAndBooksIds('books') ?? [],
        collections: this.getCollectionsAndBooksIds('collections') ?? [],
        deleted: false,
        licenseType: 'Individual',
        individualLicenseDurationMonths: this.form.value.individualLicenseDurationMonths
      };
    }
    this.api.postSubscription$(
      formPayload
      , this.state.currentSubscription$.value?.uid ?? undefined)
      .pipe(
        catchError((): any => {
          this.utils.showSnackbar('save_subscription_error_msg');
        }),
        finalize(() => {
          this.state.getSubscriptions();
          this.loading$.next(false);
        })).subscribe(() => {
      this.utils.showSnackbar('save_subscription_success_msg');
      this.router.navigate(['admin/manage-subscriptions']);
    });
  }
}
