import { Component } from '@angular/core';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';

@Component({
  selector: 'app-publishers',
  templateUrl: './publishers.component.html',
  styleUrls: ['./publishers.component.scss']
})
export class PublishersComponent {
  
  constructor(
    public state: ApplicationStateService,
  ) {}

}
