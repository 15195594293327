import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {BehaviorSubject, catchError, finalize, first, map, Observable, switchMap, timer} from 'rxjs';
import { ApplicationApiService } from '../../shared/services/application-api.service';
import { ApplicationAuthService, AuthorizationFormFields } from '../../shared/services/application-auth.service';
import { ApplicationUtilsService } from '../../shared/services/application-utils.service';
import {CustomIcons} from '../../shared/services/application-utils.service';
import {MatDialog} from '@angular/material/dialog';
import { RestorePasswordComponent } from './restore-password-template/restore-password.component';

export enum AuthorizationComponentMode {
    Registration = 1,
    Registered = 2,
    Authorization = 3,
    ConfirmRegistration = 4,
}

@Component({
    selector: 'app-authorization',
    templateUrl: './authorization.component.html',
    styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent implements OnInit, OnDestroy {

    public readonly ComponentMode = AuthorizationComponentMode;
    public readonly FormFields = AuthorizationFormFields;
    public readonly CustomIcons = CustomIcons;
    public formGroup: FormGroup;
    public loading$ = new BehaviorSubject(false);

    constructor(
        public authService: ApplicationAuthService,
        public formBuilder: FormBuilder,
        public api: ApplicationApiService,
        public route: ActivatedRoute,
        public router: Router,
        public utils: ApplicationUtilsService,
        public dialog: MatDialog,
    ) {
      this.route.data.subscribe((params) => {
        if (params.registration) {
          this.authService.authorizationComponentMode$.next(
            AuthorizationComponentMode.Registration
          );
          this.buildCommonRegistrationForm();
        } else {
          this.authService.authorizationComponentMode$.next(
            AuthorizationComponentMode.Authorization
          );
          this.buildLoginForm();
        }
      });
    }
  token: string;
  uid: string;
  ngOnInit(): void {
    this.parseUrlAndSetProperFormParams();
  }
  ngOnDestroy(): void {
    this.authService.loginOrRegistrationErrorMode$.next(false);
  }

  private parseUrlAndSetProperFormParams(): void {
    this.route.queryParamMap.subscribe((paramsMap) => {
      this.token = paramsMap.get('token');
      this.uid = paramsMap.get('uid');
      if (!!this.token) {
        this.authService.authorizationComponentMode$.next(
          AuthorizationComponentMode.ConfirmRegistration
        );

        console.log('Display Admin registration confirmation form');
        this.buildConfirmAdminRegistrationForm(paramsMap.get('email'));
      }
    });
  }

  private buildConfirmAdminRegistrationForm(email: string): void {
    this.formGroup = this.formBuilder.group(
      {
        [AuthorizationFormFields.name]: new FormControl(''),
        [AuthorizationFormFields.userName]: new FormControl({ value: email, disabled: true }),
        [AuthorizationFormFields.password]: [
          '',
          [Validators.minLength(6), Validators.required],
        ],
        [AuthorizationFormFields.repeatPassword]: [
          '',
          [Validators.minLength(6), Validators.required],
        ]
      },
      //////////////////////////  Match password validation  ///////////////////////////
      {
        validator: (control: AbstractControl) => {
          const password = control.get(AuthorizationFormFields.password).value;
          const confirmPassword = control.get(
            AuthorizationFormFields.repeatPassword
          ).value;
          if (password !== confirmPassword) {
            control
              .get(AuthorizationFormFields.repeatPassword)
              .setErrors({ MatchPassword: true });
          } else if (password === confirmPassword && control.get(['repeatPassword']).getError( 'MatchPassword')) {
            return control
              .get(['repeatPassword'])
              .setErrors(null);
          }
        },
      }
    );
  }

  private buildCommonRegistrationForm(): void {
    this.formGroup = this.formBuilder.group(
      {
        [AuthorizationFormFields.name]: new FormControl(''),
        [AuthorizationFormFields.userName]: new FormControl(
          '',
          [Validators.required, Validators.email],
          this.validateEmailNotTakenAsync.bind(this)
        ),
        [AuthorizationFormFields.password]: [
          '',
          [Validators.minLength(6), Validators.required],
        ],
        [AuthorizationFormFields.repeatPassword]: [
          '',
          [Validators.minLength(6), Validators.required],
        ],
        [AuthorizationFormFields.code]: [
          '',
          [Validators.minLength(8)],
        ],
      },
      //////////////////////////  Match password validation  ///////////////////////////
      {
        validator: (control: AbstractControl) => {
          const password = control.get(AuthorizationFormFields.password).value;
          const confirmPassword = control.get(
            AuthorizationFormFields.repeatPassword
          ).value;
          if (password !== confirmPassword) {
            control
              .get(AuthorizationFormFields.repeatPassword)
              .setErrors({ MatchPassword: true });
          } else if (password === confirmPassword && control.get(['repeatPassword']).getError( 'MatchPassword')) {
            return control
              .get(['repeatPassword'])
              .setErrors(null);
          }
        },
      }
    );
  }

  private buildLoginForm(): void {
    this.formGroup = this.formBuilder.group({
      [AuthorizationFormFields.userName]: [
        '',
        [
          Validators.required,
          // , Validators.email
        ],
      ],
      [AuthorizationFormFields.password]: [
        '',
        [
          Validators.minLength(6),
          Validators.required
        ],
      ],
      [AuthorizationFormFields.rememberMe]: [false],
    });
  }

  public submitRegistration(): void {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      this.authService.loginOrRegistrationErrorMode$.next(true);
      return;
    }

    const mode = this.authService.authorizationComponentMode$.value;
    const { name, userName, password, code } = this.formGroup.getRawValue();
    switch (mode) {
      case this.ComponentMode.Registration:
        // Common registration (as a reader);
        this.authService.registerReader({
          name,
          userName,
          password,
          code: code.trim()
        });
        break;

      case this.ComponentMode.ConfirmRegistration:
        // Confirm admin registration;
        this.authService.confirmAdminRegistration({
          name,
          uid: this.uid,
          token: this.token,
          newPassword: password
        });
        break;

      default:
        break;
    }
  }

  validateEmailNotTakenAsync(control: AbstractControl): Observable<any> {
    return timer(500).pipe(
      switchMap(() => {
        return this.api.checkEmailNotTaken(control.value).pipe(
          map(res => {
            return res ? null : { emailTaken: true };
          })
        );
      })
    );
  }

  resetPassword(email: string): void {
    this.dialog
      .open(RestorePasswordComponent, {
        maxWidth: '500px',
        width: '100%',
        autoFocus: false,
        data: {email: email}
      });
  }

  openConsent(): void {
    event.stopPropagation();
    window.open(`consent`);
  }
}
