
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ApplicationTranslateService {

  public currentLanguage$ = new BehaviorSubject(environment.language || 'en');

  private languageDictionary = {

    en: {
      button_text_send: 'Send',
      label_email: 'E-mail',
      label_custom_name: 'Your name',
      input_placeholder_email: 'example@mail.com',
      input_placeholder_publisher_name: 'Publisher title',
      publisher_registration_title: 'Registration for publisher',
      publisher_registration_subtitle: 'Upload books, create collections and sell subscriptions',
      publisher_registration_elibrica_description: 'Elibrio is the ideal solution for publishers who need transparency in direct contracting with their customers. Sell and distribute your books electronically to individuals or other organizations like libraries. We offer flexible tools for licensing that help publishers satisfy all needs and expectations of their clients.',
      publisher_registration_free_trial_description: 'We offer three months of free access for publishers so that you have time to get familiar with our platform and make sure that it works for you. We are sure that you will find it efficient, and we will continue cooperation on a mutually beneficial basis.',
      publisher_registration_free_trial_link_text: 'Request free access',
      publisher_registration_price_and_partners_title: 'Terms of cooperation after the trial period',
      publisher_registration_elibrica_percentage: ' to Elibrio',
      publisher_registration_publisher_percentage: ' to the publisher',
      publisher_registration_abilities_title: 'Flexibility of contracting',
      publisher_registration_abilities_description: 'We offer our partners ability to sell not only individual books but also complex collections of books.',
      publisher_registration_create_website_title: 'Create your site',
      publisher_registration_create_website_description: 'Your own site quickly created using Elibrio Site Builder will help you communicate with your customers efficiently! You can host your site on your own domain also.',
      publisher_registration_how_to_use_title: 'How to use the platform',
      publisher_registration_how_to_use_description: 'To publish your electronic books you need to register on our platform. You will then get access to the admin account where you can upload and sell your books, create collections and monitor subscriptions.',
      publisher_registration_how_to_use_step_one: 'REGISTRATION',
      publisher_registration_how_to_use_step_two: 'UPLOAD BOOKS',
      publisher_registration_how_to_use_step_three: 'MANAGE ACCESS',
      registration_title: 'Registration',
      authorization_title: 'Authentication',
      registration_agreements_agree_to_conditions_of: 'Agree with conditions',
      registration_agreements_user_agreement: 'user agreement',
      registration_user_agreements: 'user agreement',
      registration_user_send_button: 'Send',
      publishers_banner_title: 'Publishers e-books',
      publishers_banner_description: 'Elibrio platform is a new educational solution. It allows publishers create books collections and manage subscriptions. If your publisher is not here, you can become a partner and register your publisher.',
      publishers_banner_button_text: 'how to become a partner',
      publishers_list_title: 'Publishers catalogue',
      publishers_list_price_link_text: 'Prices of books and collections',
      publishers_list_number_of_books_text: 'number of downloaded (uploaded) books - ',
      publishers_list_phone: 'Phone: ',
      publishers_list_email: 'Email: ',
      publishers_question_form_title: 'Any questions?',
      publishers_question_form_description: 'Write them in the feedback form or contact us by Email',
      authorization_password_or_access_code_placeholder: 'Password',
      authorization_password_placeholder: 'Password',
      authorization_custom_name_placeholder: 'Username',
      authorization_access_code_placeholder: 'Access code',
      authorization_repeat_password_placeholder: 'Repeat password',
      authorization_remember_me: 'Remember me',
      authorization_data_error_msg: 'You have entered incorrect data',
      authorization_email_error_msg: 'Please enter a valid (correct) email',
      authorization_password_error_msg: 'Password must be at least 6 characters or numbers',
      authorization_password_matching_error_msg: 'Passwords do not match',
      authorization_login_btn: 'Log in',
      authorization_register_btn: 'Register',
      registration_register_link: 'Register',
      registration_succeed_msg: 'You have successfully registered and can log in with your account',
      registration_error_msg: 'User registration error',
      registration_error_code_msg: 'No matching licenses found for this access code',
      registration_hint: 'Use the email address or data provided for your educational institution for registration',
      user_name_is_already_taken_msg: 'This email already exists',
      menu_collections_button: 'Collections',
      menu_books_button: 'Books',
      menu_publisher_button: 'Publisher',
      menu_about_book_button: 'About the book',
      menu_back_button: 'Back',
      menu_authorization_button: 'Authorisation',
      menu_registration_button: 'Registration',
      menu_consent_button: 'Terms of use',
      menu_readers_button: 'Readers',
      menu_admins_button: 'Admins',
      menu_catalogue_button: 'Catalogue',
      menu_subscriptions_button: 'Subscriptions',
      my_profile_menu_item: 'My profile',
      my_subscriptions_menu_item: 'My subscriptions',
      my_profile_log_out: 'Exit',
      profile_edit_name_label: 'Name',
      profile_edit_email_label: 'Email',
      profile_new_password_label: 'New password (If you want to change it)',
      profile_old_password_label: 'Current password',
      consent_title: 'Terms of use',
      book_editor_addition_title: 'Add book',
      book_editor_editing_title: 'Edit book',
      book_editor_add_photo_label: 'Upload cover',
      book_editor_upload_label: 'Upload book',
      book_editor_upload_update_label: 'Update Book',
      book_editor_upload_another_label: 'Replace the book',
      book_editor_delete_file: 'Delete file',
      book_editor_collections_label: 'Collections',
      book_editor_upload_file_label: 'Uploaded file',
      book_editor_author_label: 'Author',
      book_editor_author_placeholder: 'Add author',
      book_editor_collections_placeholder: 'Select collections',
      book_editor_bibliography_label: 'Bibliographic record',
      book_editor_bibliography_placeholder: 'Add bibliographic title',
      book_editor_bibliography_hint: 'Example: Freeman, Daniel E. (2021). Mozart in Prague. Minneapolis: Calumet Editions. ISBN 978-1-950743-50-6.',
      book_editor_title_label: 'Title',
      book_editor_title_placeholder: 'Add title',
      book_editor_edition_label: 'Edition',
      book_editor_edition_placeholder: 'Add edition',
      book_editor_ISBN_placeholder: 'Add ISBN',
      book_editor_edition_year_label: 'Year published',
      book_editor_edition_year_placeholder: 'Add Year published',
      book_editor_format_label: 'Format',
      book_editor_format_placeholder: 'Add format',
      book_editor_binding_label: 'Binding',
      book_editor_binding_placeholder: 'Add Binding',
      book_editor_pages_label: 'Pages',
      book_editor_pages_placeholder: 'Add number of pages',
      book_editor_price_label: 'Price',
      book_editor_squeeze_label: 'Compress the book (recommended if the size is more than 100MB)',
      book_editor_price_placeholder: 'Add price',
      book_editor_description_label: 'Description',
      book_editor_description_placeholder: 'Add description',
      book_editor_content_label: 'Contents',
      book_editor_load_or_drag_hint: 'Upload or drag the cover file to the window',
      book_editor_book_has_loaded_successfully_hint: 'The book has been successfully uploaded',
      book_editor_loaded_file_name_hint: 'File uploaded',
      book_editor_format_hint: 'File format .jpg, .jpeg, .png',
      book_editor_loading_status: 'Loading in progress',
      cancel_button: 'Cancel',
      save_button: 'Save',
      previous_button: 'Previous',
      next_button: 'Next',
      admin_books_search_placeholder: 'Enter the title of the book or the author',
      admin_books_add_book_button: 'Add book',
      admin_books_edit_button: 'Edit',
      admin_books_delete_button: 'Delete',
      edit_collection_title: 'Title',
      edit_collection_title_placeholder: 'Enter collection title',
      edit_collection_books: 'Books',
      edit_collection_books_placeholder: 'Select books included in the collection',
      edit_collection_price: 'Price',
      edit_collection_price_placeholder: 'Specify the price of the collection',
      admin_collections_create_collection_button: 'Create collection',
      admin_collections_edit_button: 'Edit',
      admin_collections_delete_button: 'Delete',
      subscription_editor_add_subscription_title: 'Add subscription',
      subscription_editor_edit_subscription_title: 'Edit subscription',
      subscription_editor_add_individual_subscription_title: 'Edit subscription',
      subscription_editor_biblio_select_placeholder: 'Select subscriber',
      subscription_editor_biblio_select_new: 'New subscriber',
      subscription_editor_issuedTo_label: 'To whom the subscription was issued',
      subscription_editor_price_label: 'Subscription price',
      subscription_editor_price_placeholder: 'Add subscription price',
      subscription_editor_individual_duration_placeholder: 'Specify the duration of the subscription in months',
      subscription_editor_books_label: 'Books',
      subscription_editor_all_books: 'All books',
      subscription_editor_collection_label: 'Entire collections',
      subscription_editor_collection_unique_books: 'books: ',
      subscription_editor_collection_different_books: 'Individual books: ',
      subscription_editor_collection_entire_collection_selected: '(entire collection included)',
      subscription_editor_individual_collection_label: 'Select a book',
      subscription_editor_collection_and_books_label: 'Collections and books *',
      subscription_editor_created_label: 'Effective date',
      subscription_editor_end_label: 'Expiration date',
      subscription_editor_code_label: 'Key',
      subscription_editor_address_label: 'Address',
      subscription_editor_phone_label: 'Phone',
      subscription_editor_generate_new_code: 'Generate new key',
      subscription_editor_copy_code: 'Copy to clipboard',
      subscription_editor_remark_label: 'Notes',
      subscription_editor_remark_placeholder: 'Add notes',
      create_subscriber_dialog_title: 'Create subscriber',
      edit_subscriber_dialog_title: 'Edit subscriber',
      edit_subscriber_issuedTo_label: 'To whom the subscription was issued',
      edit_subscriber_issuedTo_placeholder: 'Enter the name of the organization',
      edit_subscriber_contact_person_label: 'Contact person',
      edit_subscriber_contact_person_placeholder: 'Subscriber name',
      edit_subscriber_address_label: 'Address',
      edit_subscriber_address_placeholder: 'Enter the address of the organization',
      edit_subscriber_phone_label: 'Phone',
      edit_subscriber_code_label: 'Key',
      edit_subscriber_generate_new_code: 'Generate new key',
      edit_subscriber_copy_code: 'Copy to clipboard',
      edit_subscriber_generate_code_placeholder: 'Click on key icon to generate code',
      edit_subscriber_ip_label: 'Organization IP address',
      edit_subscriber_ip_placeholder: 'Enter IP address',
      edit_subscriber_add_ip_button: 'Add IP address',
      subscription_price_info: 'The amount of the agreement with the subscriber for the duration of the subscription',
      subscriber_ip_info: 'Readers working from these IP addresses will have access to subscriber\'s books without registration',
      admin_subscribers_search_placeholder: 'Issued to',
      admin_subscribers_create_subscription_button: 'Create subscription',
      admin_subscribers_create_buyout_subscription_button: 'Create individual subscription',
      admin_subscribers_edit_button: 'Edit',
      admin_subscribers_delete_button: 'Delete',
      admin_subscribers_copy_code_button: 'Copy the key',
      admin_subscribers_history_button: 'History',
      admin_subscribers_days_left_label: 'Days left:',
      admin_subscribers_collections_label: 'Collections:',
      admin_subscribers_books_label: 'Books:',
      admin_subscribers_regular_tab_label: 'Regular subscription - fixed price, unlimited number of readers',
      admin_subscribers_regular_description: 'Subscriber gets access to the listed collections and individual books on the fixed period of time. Compensation to the publisher dpeneds on the subscription duration (for example 1 year) and is calculated on a contractual basis. The amount of readers is not limited in this case and the amount of the agreement does not depend on the number of users who actually read the books.',
      admin_subscribers_individual_tab_label: 'Individual subscription - get payment for the each book reader',
      admin_subscribers_individual_description: 'Subscriber gets ability to distribute books to the readers individually and pay only for those who actually read books. For example every 6 months, publisher can calculate the amount of readers who used the subscription (book readers) and can invoice the subscriber. Registration of the readers can be done using our public API (in case of integration) or using a special web form for registration.',
      admin_subscribers_individual_status_label: 'Status',
      admin_subscribers_individual_duration_label: 'Limit',
      admin_subscribers_individual_duration_months_text: ' months',
      admin_subscribers_individual_duration_unlimited_text: 'Unlimited',
      admin_subscribers_individual_price_label: 'Cost ',
      admin_subscribers_individual_total_price_label: 'Final cost ',
      admin_subscribers_individual_book_label: 'Book',
      edit_user_name_label: 'User Name',
      edit_user_password_label: 'Password',
      edit_user_code_label: 'Code',
      edit_password_old_password_label: 'Old password',
      edit_password_new_password_label: 'New password',
      edit_password_repeat_password_label: 'Repeat new password',
      edit_password_error_description: 'Passwords do not match',
      admin_users_create_user_button: 'Add admin',
      admin_users_edit_button: 'Edit',
      admin_users_delete_button: 'Delete',
      admin_users_change_password_button: 'Change password',
      admin_users_invitation_button: 'Re-send invitation',
      admin_main_first_step_title: 'Information for the catalogue',
      admin_main_first_step: 'Step 1.',
      admin_main_first_step_description: 'Fill in a brief information about your publisher.',
      admin_main_second_step_title: 'Books. Collections. Subscriptions',
      admin_main_second_step: 'Step 2.',
      admin_main_second_step_description: 'Upload your own books and build your collection.',
      admin_main_status: 'Status in the catalogue:',
      admin_main_published: 'published',
      admin_main_unpublished: 'not published',
      admin_main_publishers_catalogue_link: 'See in the catalogue',
      admin_main_publisher_name_label: 'Publisher title',
      admin_main_publisher_profile_label: 'Publisher profile',
      admin_main_publisher_description_label: 'Description',
      admin_main_publisher_description_placeholder: 'Briefly introduce yourself and describe the company in 5 to 10 lines. What does the publishing house specialize in, since what year have you been working. What is your team proud of, what are its values and motivation.',
      admin_main_publisher_email_label: 'Contact Email',
      admin_main_publisher_phone_label: 'Contact phone',
      admin_main_publisher_catalog_path_label: 'Path to the publisher\'s book catalogue',
      admin_main_publisher_catalog_path_placeholder: 'Path to the book catalogue',
      admin_main_publisher_catalog_path_pattern_hint: 'The path to the catalogue can only include English letters and numbers, and should not contain more than 50 characters',
      admin_main_publisher_form_checkbox: 'Publish in the publisher\'s catalogue',
      admin_main_publisher_form_submit_button: 'Save',
      admin_main_uploaded_books: 'Uploaded books',
      admin_main_empty_cell_placeholder: 'no',
      admin_main_collections: 'Collections',
      admin_main_subscriptions: 'Your subscriptions',
      admin_main_create_website_title: 'Your site',
      admin_main_create_website_description_first_part: 'Use a professional platform to upload books and build collections. With built-in modules and controls, your get great opportunities for e-book sales.',
      admin_main_create_website_description_second_part: 'On our platform, everyone can handle the creation of a website. You can create a site by choosing one of three templates to choose from with ready-made design and content options, in the editor it is possible to change the style of the site to your corporate colours. It is a convenient and efficient way to stay in touch with your customers. Our comprehensive website building platform includes 3 design templates as well as technical support.',
      admin_main_create_website_description_third_part: 'Sell books and get paid by customers online. You can use a free trial period for 1 month.',
      //admin_main_create_website_try_it_text: 'Try. It is free.',
      admin_main_create_website_button_text: 'Order a site',
      admin_users_admins_label: 'Admins',
      admin_users_readers_label: 'Readers',
      admin_phone_example_placeholder: '+47 12 34 56 78',
      create_admin_dialog_title: 'Enter the new user\'s E-mail. Instructions will be sent to the specified address to complete the registration.',
      book_contents_title: 'Content',
      book_description_publication_description_label: 'Edition:',
      book_description_publication_year_label: 'Year of issue:',
      book_description_format_label: 'Format:',
      book_description_binding_label: 'Binding:',
      book_description_pages_label: 'Pages:',
      book_bibliography_record_label: 'Bibliographic record',
      book_bibliography_publication_label: 'Minneapolis: Calumet Editions',
      book_bibliography_date: 'date of the application: ',
      book_bibliography_text_format: 'Text: electronic',
      book_bibliography_library_label: 'Electronic Library System Elibrio',
      book_bibliography_access_label: 'Access mode: for authorized users',
      book_bibliography_copy_label: 'copy bibliographic record',
      book_bibliography_copy_to_clipboard_successfully: 'Bibliographic record copied to clipboard',
      book_editor_loaded_pages_label: 'Loaded',
      book_reader_search_placeholder: 'Enter text to search',
      book_price_label: 'price:',
      currency_rub_label_short: 'NOK',
      books_catalogue_by_title_label: 'Alphabetically',
      books_catalogue_by_author_label: 'By author',
      books_catalogue_by_year_label: 'By publication date',
      books_catalogue_by_category_label: 'By category',
      books_catalogue_search_placeholder: 'Enter book title or author',
      books_catalogue_filter_placeholder: 'Filter by publisher',
      books_catalogue_filter_books_by_ip_option: 'Books in your library',
      header_login_button: 'Login',
      header_exit_button: 'Exit',
      header_books_button: 'Books',
      header_publishers_button: 'For publishers',
      footer_elibrica: 'ELIBRIO',
      footer_left_text: 'Text 1',
      footer_right_text: 'Text 2',
      read_book: 'Read',
      ISBN_is_already_taken_error_message: 'This ISBN already belongs to another book',
      save_book_error_msg: 'Error saving workbook',
      save_book_success_msg: 'The book successfully saved',
      delete_book_error_msg: 'Error while deleting the book',
      delete_book_success_msg: 'Book deleted successfully',
      loading_book_error_msg: 'Error loading the book',
      delete_book_confirm_title: 'Delete book',
      delete_book_confirm_text: 'Are you sure you want to delete the book?',
      save_collection_error_msg: 'Error saving the collection',
      save_collection_success_msg: 'Collection successfully saved',
      delete_collection_error_msg: 'Error deleting the collection',
      delete_collection_success_msg: 'Collection successfully deleted',
      delete_collection_confirm_title: 'Delete collection',
      delete_collection_confirm_text: 'Are you sure you want to delete the collection?',
      save_subscription_error_msg: 'Error saving the subscription',
      save_subscription_success_msg: 'Subscription successfully saved',
      delete_subscription_error_msg: 'Error deleting the subscription',
      delete_subscription_success_msg: 'Subscription successfully deleted',
      delete_subscription_confirm_title: 'Delete subscription',
      delete_subscription_confirm_text: 'Are you sure you want to delete the subscription?',
      delete_subscriber_confirm_title: 'Delete subscriber',
      delete_subscriber_confirm_text: 'Are you sure you want to delete the subscriber?',
      delete_subscriber_success_msg: 'Subscriber successfully deleted',
      delete_subscriber_error_msg: 'Error deleting the subscriber',
      save_user_error_msg: 'Error saving the user',
      save_user_success_msg: 'User successfully saved',
      delete_user_error_msg: 'Error deleting the user',
      delete_user_success_msg: 'User successfully deleted',
      delete_user_confirm_title: 'Delete user',
      delete_user_confirm_text: 'Are you sure you want to delete the user?',
      user_with_such_email_is_already_exist: 'User with this email already exists',
      change_password_error_msg: 'Error changing password',
      change_password_success_msg: 'Password successfully changed',
      resend_invitation_error_msg: 'Error sending invitation',
      resend_invitation_success_msg: 'Invitation re-sent',
      bind_accounts_success_msg: 'Accounts successfully linked',
      bind_accounts_wrong_password_error: 'Wrong password',
      history_loading_error_msg: 'Error loading history',
      data_loading_error_msg: 'Error loading data',
      using_storage_token_error_msg: 'Error when trying to use saved token',
      verification_of_token_error_msg: 'Token verification error. Sign in',
      authorization_error_msg: 'Error when trying to login',
      generation_code_error_msg: 'Error when trying to generate license code',
      generation_code_success_msg: 'New license code successfully generated',
      logout_error_msg: 'Error when trying to log out',
      book_read_forbidden_warn: 'This book is not included in your subscription',
      book_has_not_loaded_warn: 'Book not loaded',
      book_read_user_not_authorized_warn: 'You are not authorized',
      upload_file_error_msg: 'File upload error',
      license_copy_code_to_clipboard_successfully: 'Code copied to clipboard',
      empty_table_message: 'Empty',
      column_header_date_changes: 'Last changed',
      column_header_date_creation: 'Start date',
      column_header_list_of_changes: 'History of changed',
      column_header_list_of_books: 'List of books',
      column_header_price: 'Price',
      column_header_books_and_collections: 'Books and collections',
      column_header_key: 'Key',
      table_info_books_quantity_0: 'books',
      table_info_books_quantity_1: 'book',
      table_info_books_quantity_2: 'books',
      table_info_books_quantity_3: 'books',
      table_info_books_quantity_4: 'books',
      table_info_books_quantity_5_or_more: 'books',
      column_header_collections: 'Collections',
      column_header_who_added: 'Created by',
      column_header_when: 'Added',
      column_header_title: 'Title',
      column_header_remark: 'Notes',
      column_header_field: 'Field',
      column_header_was: 'Was',
      column_header_became: 'Became',
      column_header_authors: 'Author',
      column_header_user: 'User',
      column_header_role: 'Role',
      column_header_date: 'Date',
      column_header_code: 'Keys',
      column_header_publishers: 'Publishers',
      column_header_finish_date: 'End date',
      column_header_left: 'Left',
      column_header_whom_subscription_was_provided: 'Subscriber',
      pagination_on_page: 'on page',
      dialog_options_save: 'Save',
      dialog_options_cancel: 'Cancel',
      dialog_confirm_no: 'No',
      dialog_confirm_yes: 'Yes',
      reader_open_contents_button_tooltip: 'Open table of contents',
      reader_close_contents_button_tooltip: 'Close table of contents',
      reader_next_page_button_tooltip: 'Next page',
      reader_previous_page_button_tooltip: 'Previous page',
      reader_zoom_in_button_tooltip: 'Zoom in',
      reader_zoom_out_button_tooltip: 'Zoom out',
      reader_index_mode_button_tooltip: 'Pointer mode',
      reader_swipe_mode_button_tooltip: 'Manual mode',
      reader_full_screen_mode_button_tooltip: 'Fix to width',
      reader_unset_mode_button_tooltip: 'Fix to page',
      add_subscription: 'Add subscription',
      license_edit_code_label: 'License key',
      Handle_change_user_data_error_msg: 'An error occurred while updating. Try later.',
      Handle_change_user_data_success_msg: 'Update was successful!',
      my_subscriptions_dialog_title: 'My subscriptions',
      my_subscriptions_table_column_title_library: 'Subscriber',
      my_subscriptions_table_column_title_address: 'Address',
      my_subscriptions_table_column_title_phone: 'Phone',
      my_subscriptions_table_column_title_code: 'Key',
      my_subscriptions_table_column_title_status: 'Status',
      my_subscriptions_table_status_value_active: 'active',
      my_subscriptions_table_status_value_expired: 'expired',
      authorization_select_account_question: 'What publisher account you would like to open?',
      my_profile_super_admin: 'Super admin',
      tenants_management_block_button: 'Block',
      tenants_management_unblock_button: 'Unblock',
      tenants_management_delete_button: 'Delete',
      menu_manage_tenants_button: 'Tenants management',
      book_reader_header_prev_button_label: 'Previous chapter',
      book_reader_header_next_button_label: 'Next chapter',
      create_subscriber_success_msg: 'Subscriber successfully created',
      edit_subscriber_success_msg: 'Subscriber details successfully saved',
      edit_subscriber_error_msg: 'Error on trying to save the subscriber. Please try again or contact administrator!',
      edit_subscription_success_msg: 'Subscribiption successfully saved',
      edit_user_form_title: 'User settings',
      edit_password_dialog_title: 'Password change',
      edit_password_resend_mail_link_text: 'Password change',
      edit_password_resend_mail_text: 'using e-mail',
      publisher_registration_form_error_msg_first_part_text: 'E-mail already used! Try to ',
      publisher_registration_form_error_msg_link_text: 'login here',
      publisher_registration_form_error_msg_second_part_text: 'or use another email for registration.',
      column_header_status: 'Status',
      column_header_author_of_change: 'Author',
      column_header_published: 'Published',
      column_header_mail: 'E-mail',
      column_header_created: 'Created',
      book_bibliography_text_text_site: ': [web-site].',
      publisher_question_form_name_placeholder: 'Your name',
      publisher_question_form_question_placeholder: 'Ask question',
      tenants_management_status_blocked: 'Blocked',
      tenants_management_status_active: 'Active',
      publishers_become_partner_link_text: 'how to become a partner',
      edit_user_data_name_placeholder: 'Name',
      edit_user_password_placeholder: 'Enter user password',
      authorization_custom_name_label: 'User name',
      authorization_password_label: 'Password',
      authorization_repeat_password_label: 'Repeat password',
      authorization_access_code_label: 'Access code',
      subscription_editor_subscription_type_select_label: 'Select subscription type',
      admin_subscribers_months_left_label: 'Months:',
      book_editor_first_step: 'Step 1.',
      book_editor_first_step_description: 'Start with uploading the book. Supported formats are EPUB or PDF. We need to know the format to continue.',
      book_editor_second_step: 'Step 2.',
      book_editor_second_step_description: 'Fill in the form.',
      column_header_duration: 'Duration',
      admin_subscribers_individual_duration_months_left_text: 'months',
      out_of_collection: 'Out of collection',
      subscription_editor_duration_label: 'Duration from the payment',
      book_reader_prev_button: '« PREVIOUS',
      book_reader_next_button: 'NEXT »',
      book_reader_load_book_error_msg: "This book cannot be opened. Please contact the published or support services.",
      admin_main_mailto_order_website: "mailto:contact@elibrio.com?subject=Interested%20to%20order%20a%20website",
      restore_password_dialog_title: 'Password restore',
      restore_password_label: 'Enter your Email',
      restore_password_success_msg: 'Message to restore password has been sent',
      book_editor_publish_book_toggle: 'Publish in the books catalogue',
      book_editor_free_book_toggle: 'Free online use',
      book_editor_physical_book_toggle: 'Available as physical book',
      book_editor_unpublished_save_confirm_text: 'You are saving the book that is not published and will be visible only for administrators and integrated users. Continue?',
      book_editor_unpublished_save_confirm_title: 'Save unpublished book',
      book_editor_unpublished_save_success_msg: 'Saved book will not be displayed in the books catalogue',
      admin_users_table_column_title_first_time_opened: 'First time opened',
      admin_users_table_column_title_last_time_opened: 'Last time opened',
      admin_users_table_column_title_times_opened: 'Times opened',
      admin_users_table_column_title_last_page: 'Last opened page or chapter',
      forgot_password: 'Forgot password?',
      bind_accounts_dialog_description: 'For linking with existing account please confirm that this is your account entering the password',
      bind_accounts_dialog_title: 'Linking to the existing account',
      select_account_dialog_title: 'Select the workspace you would like to open',
      subscription_history_dialog_title: 'History of changes in subscription',
      create_collection_dialog_title: 'New collection',
      edit_collection_dialog_title: 'Edit collection',
      edit_user_profile_dialog_title: 'Edit user profile',
      dialog_button_accept: 'Accept',
      withdraw_button_text: 'Withdraw (delete account)',
      consent_dialog_description: 'We value your privacy! 🍪 This site uses third-party website technologies to provide and continually improve our services. Because we value your privacy, we are transparent about the technologies we use.',
      consent_dialog_read_more_link: 'You can read more here.',
      consent_dialog_title: 'User agreement',
      withdraw_warning_confirm_dialog_msg: 'Your user will be completely deleted from the system. Access to all available books will be lost.',
      withdraw_warning_confirm_dialog_title: 'Are you sure that your would like to withdraw?',
      column_header_subscriber_title: 'Title',
      column_header_subscriber_address: 'Address',
      column_header_subscriber_phone: 'Phone',
      column_header_subscriber_code: 'Code',
      column_header_subscriber_status: 'Status',
      consent_first_section_title_part_one: 'Why our site',
      consent_first_section_title_part_two: 'need your data?',
      consent_first_section_description: 'For the correct operation of the application as well as documentation and analysis in order to improve the process of using and increasing the effectiveness of our service.',
      consent_second_section_title: 'How to withdraw from the system?',
      consent_second_section_description_part_one: 'Your agreement allow our site',
      consent_second_section_description_part_two: 'receive your data in accordance with the Privacy policy. Refusal to accept an agreement leads to the impossibility of using our site. After accept an agreement you can change your mind and withdraw from our system at any time. If you decided to withdraw from the system, then you need to go into your profile and then click at button',
      consent_accept_question_text: 'Do you agree to the processing and transfer of your data?',
      // New texts

      switch_tenant_dialog_title: 'Select the workspace you would like to open',
      my_profile_switch_tenant: 'My publishers',
      admin_readers_users_search_string_label: 'Enter reader name',
      admin_readers_books_search_string_label: 'Enter book name',
      admin_readers_subscriber_select_placeholder: 'Select subscriber',
      add_user_to_subscriber_input_placeholder: 'Enter subscriber access code',
      add_user_to_subscriber_dialog_title: 'Add subscription',
      edit_user_add_user_to_subscriber_button: 'Add subscription',
      column_header_subscriber_book_count: 'Available books',
      admin_readers_option_all_text: 'All readers',
      admin_readers_option_regular_text: 'Regular subscriptions',
      admin_readers_option_individual_text: 'Individual subscriptions',
      admin_readers_date_picker_label: 'Enter a date range',
      admin_readers_date_picker_start_date_placeholder: 'From',
      admin_readers_date_picker_end_date_placeholder: 'To',
      admin_users_search_placeholder: 'Enter user email',
      ISBN_is_too_long_error_message: 'ISBN cannot be longer than 17 characters',
      book_edit_six_months_price_label: 'Price for six months',
      book_edit_six_months_price_placeholder: 'Add price for six months',
      book_edit_physical_price_label: 'Physical price',
      book_edit_physical_price_placeholder: 'Price for a real book',
      delete_tenant_confirm_title: 'Delete publisher',
      delete_tenant_confirm_text: 'Are you sure that you want to delete this publisher ? All books, collections, subscribers and subscriptions of this publisher will be deleted from the system.',
      book_description_read_info_integration: 'You can read this book with integration. To get instructions you can contact publisher or subscriber:',
      book_description_read_info_regular: 'You can read this by join one of this subscribers:',
      book_description_read_info_publisher: 'You can get access to this book directly from publisher:',
      book_description_read_info_publisher_phone: 'Publisher contact phone number:',
      book_description_read_info_publisher_email: 'Publisher contact email:',
      book_description_read_info_subscriber_phone: 'Contact phone:',
      my_subscriptions_empty_subscriptions_table_message: 'You have not any subscriptions yet',
      my_subscriptions_empty_purchases_table_message: 'You have not any purchases yet',
      my_subscriptions_withdraw_from_subscriber: 'Withdraw from subscriber',
      column_header_purchase_status: 'Status',
      column_header_purchase_title: 'Title',
      column_header_purchase_start: 'Purchase date',
      column_header_purchase_end: 'Access end',
      my_subscriptions_purchases_unlimited_duration: 'Unlimited',
      my_subscriptions_purchases_book_has_been_deleted: 'Book has been deleted',

      admin_main_publisher_currency_placeholder: 'Currency',
      admin_main_publisher_currency_label: 'Currency',
      admin_main_publisher_currency__pattern_hint: 'Currency must be in the list',

      readers_count_found: 'Readers found: ',
      books_count_found:'Books found: ',
      collections_count_found:'Collections found: ',
      subscribtions_count_found: 'Subscribtions found: ',
      admins_count_found: 'Admins found: ',

      publisher_currency_update_save_confirm_text: 'Are you sure you want to update your currency? The set prices are associated with the same values, but the currency changes.',
      publisher_update_save_confirm_title: 'Update сonfirmation',
      publisher_catalog_path_update_save_confirm_text: 'Are you sure you want to update the path? If you do this, then all links containing your path will stop working!',
      
      delete_tenant_error_msg: 'An error occurred while deleting the tenant completely, please try again later.',
      delete_tenant_success_msg: 'The tenant has been completely removed from the system',

      picture_successfully_uploaded_msg: 'Image updated successfully',
      picture_failed_upload_msg: 'The image is not updated, please try again later',

      book_editor_bibliography_toggle: 'Is there a bibliographic title',

      column_header_books_count: 'Books',
      column_header_collections_count: 'Collections',
      column_header_subscribtions_count: 'Subscribtions',
      column_header_active_subscribtions_count: 'Active subscribtions',

      admin_books_empty_book: 'Book uploaded without details',

      dialog_options_close: 'Close',
      already_subscribed_to_subscriber: 'You already subscribed to this subscriber',
      connection_with_subcriber: 'Connections with subscriber was established',

      date_format_example: 'DD/MM/YYYY – DD/MM/YYYY',
      registration_invalid_token_msg: 'Invalid token, recover your password again',
      column_header_last_login: 'Last login',
      last_login_undefined: '-',
      admin_readers_option_wait_request_text: 'Requested access',
      
      readers_request_header: 'Request for access to',
      readers_request_message_title: 'Attached message',
      readers_request_message_placeholder: 'I\'m John Smith with library card #2.',

      something_went_wrong_msg: 'Something went wrong',
      already_have_this_sub_error_msg: 'You already have this subscription',
      already_exist_request_error_msg: 'You have already submitted a request for this subscription',
      request_sended_msg: 'Request is successfully sent',

      request_accepted_msg: 'Request accepted',
      request_rejected_msg: 'Request rejected',
      accept_request_btn:'Accept',
      reject_request_btn: 'Reject',

      requests_count_found: 'Requests founded: ',
      phone_value_not_valid: 'Phone number has wrong format',
      admin_books_open_book_in_catalog: 'Open in catalogue',
      admin_books_published: 'Published',
      admin_books_unpublished: 'Unpublished',

      tenants_management_select_button: 'Enter to Tenant',
      tenants_management_tenant_selected: 'Tenant selected',
      footer_company_info: 'NRG Norge AS, Bergen'
    },

    ru: {
      button_text_send: 'Отправить',
      label_email: 'E-mail',
      label_custom_name: 'Ваше имя',
      input_placeholder_email: 'example@mail.ru',
      input_placeholder_publisher_name: 'Издательство',
      publisher_registration_title: 'Регистрация для издательств',
      publisher_registration_subtitle: 'Закачивайте книги, создавайте коллекции и продавайте подписки',
      publisher_registration_elibrica_description: 'Elibrica - идеальное решение для издательств, которым важны прозрачность и заключение прямых контрактов со своими клиентами. Мы предлагаем гибкие и индивидуальные условия, которые помогают издательствам добиться максимального удовлетворения потребностей и желаний их клиентов.',
      publisher_registration_free_trial_description: 'Мы также предлагаем трехмесячный бесплатный доступ для издательств, чтобы вы могли ознакомиться с нашей системой и убедиться в ее удобстве и эффективности. Мы уверены, что вы останетесь довольны нашими услугами и мы продолжим сотрудничество на взаимовыгодной основе.',
      publisher_registration_free_trial_link_text: 'Заявка на бесплатный доступ',
      publisher_registration_price_and_partners_title: 'Условия работы после окончания бесплатного доступа',
      publisher_registration_elibrica_percentage: ' получает Elibrica',
      publisher_registration_publisher_percentage: ' получает издательство',
      publisher_registration_abilities_title: 'Гибкость комплектования',
      publisher_registration_abilities_description: 'Мы предоставляем нашим партнерам возможность продавать и выставлять цены не только на отдельные книги, но и на сформированные коллекции.',
      publisher_registration_create_website_title: 'Создайте свой сайт',
      publisher_registration_create_website_description: 'Собственный сайт на базе панели управления Elibrica позволит настроить продажи эффективнее! Предоставляем возможность разместить его на вашем домене.',
      publisher_registration_how_to_use_title: 'Как пользоваться платформой',
      publisher_registration_how_to_use_description: 'Для размещения своих электронных изданий необходимо зарегистрироваться на нашей платформе. У вас откроется доступ в административный кабинет. Там вы можете загружать и продавать свои книги и формировать коллекции, отслеживать количество подписок.',
      publisher_registration_how_to_use_step_one: 'РЕГИСТРАЦИЯ',
      publisher_registration_how_to_use_step_two: 'ЗАГРУЗКА КНИГ',
      publisher_registration_how_to_use_step_three: 'ФОРМИРОВАНИЕ КОЛЛЕКЦИЙ',
      registration_title: 'Регистрация',
      authorization_title: 'Авторизация',
      registration_agreements_agree_to_conditions_of: 'Согласен с условиями',
      registration_agreements_user_agreement: 'пользовательского соглашения',
      registration_user_agreements: 'пользовательского соглашения',
      registration_user_send_button: 'Отправить',
      publishers_banner_title: 'Электронные версии книг издательств',
      publishers_banner_description: 'Платформа Elibrica — новый продукт на рынке электронных образовательных ресурсов. Платформа позволяет издательствам создавать коллекции книг и управлять подписками. Если вашего издательства здесь нет, вы можете стать партнером и зарегистрировать свое издательство.',
      publishers_banner_button_text: 'как стать парнером',
      publishers_list_title: 'Каталог издательств',
      publishers_list_price_link_text: 'Стоимость книг и коллекций',
      publishers_list_number_of_books_text: 'количество загруженных книг - ',
      publishers_list_phone: 'Телефон: ',
      publishers_list_email: 'Эл. почта: ',
      publishers_question_form_title: 'Возникли вопросы?',
      publishers_question_form_description: 'Задайте их в форме обратной связи или свяжитесь с нами по E-mail',
      authorization_password_or_access_code_placeholder: 'Пароль',
      authorization_password_placeholder: 'Пароль',
      authorization_custom_name_placeholder: 'Имя пользователя',
      authorization_access_code_placeholder: 'Код доступа',
      authorization_repeat_password_placeholder: 'Повторите пароль',
      authorization_remember_me: 'Запомнить меня',
      authorization_data_error_msg: 'Вы ввели неверные данные',
      authorization_email_error_msg: 'Введите корректный email',
      authorization_password_error_msg: 'Пароль должен быть от 6 символов или цифр',
      authorization_password_matching_error_msg: 'Пароли не совпадают',
      authorization_login_btn: 'Вход',
      authorization_register_btn: 'Зарегистрироваться',
      registration_register_link: 'Зарегистрироваться',
      registration_succeed_msg: 'Вы успешно зарегистрировались и можете войти в систему под своей учетной записью',
      registration_error_msg: 'Ошибка при регистрации пользователя',
      registration_error_code_msg: 'По данному коду доступа не найдено соответствующих лицензий',
      registration_hint: 'Используйте email-адрес или данные, предоставленные вашему учебному заведению для регистрации',
      user_name_is_already_taken_msg: 'Данный email уже существует',
      menu_collections_button: 'Коллекции',
      menu_books_button: 'Книги',
      menu_publisher_button: 'Издательство',
      menu_about_book_button: 'О книге',
      menu_back_button: 'Назад',
      menu_authorization_button: 'Авторизация',
      menu_registration_button: 'Регистрация',
      menu_consent_button: 'Пользовательское соглашение',
      menu_readers_button: 'Читатели',
      menu_admins_button: 'Админы',
      menu_catalogue_button: 'Каталог',
      menu_subscriptions_button: 'Подписки',
      my_profile_menu_item: 'Мой профиль',
      my_subscriptions_menu_item: 'Мои подписки',
      my_profile_log_out: 'Выйти',
      profile_edit_name_label: 'ФИО',
      profile_edit_email_label: 'Почта',
      profile_new_password_label: 'Новый пароль (Если хотите поменять)',
      profile_old_password_label: 'Текущий пароль',
      consent_title: 'Пользовательское соглашение',
      book_editor_addition_title: 'Добавление книги',
      book_editor_editing_title: 'Редактирование книги',
      book_editor_add_photo_label: 'Загрузить обложку',
      book_editor_upload_label: 'Загрузить книгу',
      book_editor_upload_update_label: 'Обновить книгу',
      book_editor_upload_another_label: 'Заменить книгу',
      book_editor_delete_file: 'Удалить файл',
      book_editor_collections_label: 'Коллекции',
      book_editor_upload_file_label: 'Загруженный файл',
      book_editor_author_label: 'Автор',
      book_editor_author_placeholder: 'Введите автора',
      book_editor_collections_placeholder: 'Выберите коллекции',
      book_editor_bibliography_label: 'Библиографическое название',
      book_editor_bibliography_placeholder: 'Заполните библиографическое название',
      book_editor_bibliography_hint: 'Пример: Амосов, П. Н. Биология животных: учебное пособие / П. Н. Амосов, Е. И. Чумасов',
      book_editor_title_label: 'Название',
      book_editor_title_placeholder: 'Введите название',
      book_editor_edition_label: 'Издание',
      book_editor_edition_placeholder: 'Введите издание',
      book_editor_ISBN_placeholder: 'Введите ISBN',
      book_editor_edition_year_label: 'Год издания',
      book_editor_edition_year_placeholder: 'Введите год издания',
      book_editor_format_label: 'Формат',
      book_editor_format_placeholder: 'Введите формат',
      book_editor_binding_label: 'Переплет',
      book_editor_binding_placeholder: 'Введите переплет',
      book_editor_pages_label: 'Страниц',
      book_editor_pages_placeholder: 'Введите количество страниц',
      book_editor_price_label: 'Цена ',
      book_editor_squeeze_label: 'Сжать книгу (рекомендуется при размере более 100Мб)',
      book_editor_price_placeholder: 'Введите цену',
      book_editor_description_label: 'Описание',
      book_editor_description_placeholder: 'Введите описание',
      book_editor_content_label: 'Содержание',
      book_editor_load_or_drag_hint: 'Загрузите или перетащите файл обложки в ячейку',
      book_editor_book_has_loaded_successfully_hint: 'Книга была успешно загружена',
      book_editor_loaded_file_name_hint: 'Загружен файл',
      book_editor_format_hint: 'Формат файла .jpg, .jpeg, .png',
      book_editor_loading_status: 'Выполняется загрузка',
      cancel_button: 'Отмена',
      save_button: 'Сохранить',
      previous_button: 'Назад',
      next_button: 'Продолжить',
      admin_books_search_placeholder: 'Введите название книги или автора',
      admin_books_add_book_button: 'Добавить книгу',
      admin_books_edit_button: 'Редактировать',
      admin_books_delete_button: 'Удалить',
      edit_collection_title: 'Название',
      edit_collection_title_placeholder: 'Введите название коллекции',
      edit_collection_books: 'Книги',
      edit_collection_books_placeholder: 'Выберите книги входящие в коллекцию',
      edit_collection_price: 'Цена ',
      edit_collection_price_placeholder: 'Укажите стоимость коллекции',
      admin_collections_create_collection_button: 'Создать коллекцию',
      admin_collections_edit_button: 'Редактировать',
      admin_collections_delete_button: 'Удалить',
      subscription_editor_add_subscription_title: 'Добавление подписки',
      subscription_editor_edit_subscription_title: 'Редактирование подписки',
      subscription_editor_add_individual_subscription_title: 'Редактирование подписки',
      subscription_editor_biblio_select_placeholder: 'Выберите подписчика',
      subscription_editor_biblio_select_new: 'Новый подписчик',
      subscription_editor_issuedTo_label: 'Кому выдана подписка',
      subscription_editor_price_label: 'Стоимость подписки',
      subscription_editor_price_placeholder: 'Введите цену подписки',
      subscription_editor_individual_duration_placeholder: 'Укажите длительность действия подписки в месяцах',
      subscription_editor_books_label: 'Книги',
      subscription_editor_all_books: 'Все книги',
      subscription_editor_collection_label: 'Коллекций целиком',
      subscription_editor_collection_unique_books: 'книг: ',
      subscription_editor_collection_different_books: 'Отдельных книг: ',
      subscription_editor_collection_entire_collection_selected: '(Коллекция полностью включена)',
      subscription_editor_individual_collection_label: 'Выберите книгу',
      subscription_editor_collection_and_books_label: 'Коллекции и книги *',
      subscription_editor_created_label: 'Дата начала действия',
      subscription_editor_end_label: 'Дата окончания действия',
      subscription_editor_code_label: 'Ключ',
      subscription_editor_address_label: 'Адрес',
      subscription_editor_phone_label: 'Телефон',
      subscription_editor_generate_new_code: 'Сгенерировать новый ключ',
      subscription_editor_copy_code: 'Копировать в буфер обмена',
      subscription_editor_remark_label: 'Примечание',
      subscription_editor_remark_placeholder: 'Введите примечание',
      create_subscriber_dialog_title: 'Создание подписчика',
      edit_subscriber_dialog_title: 'Редактирование подписчика',
      edit_subscriber_issuedTo_label: 'Кому выдана подписка',
      edit_subscriber_issuedTo_placeholder: 'Введите название организации',
      edit_subscriber_contact_person_label: 'Контактное лицо',
      edit_subscriber_contact_person_placeholder: 'Имя подписчика',
      edit_subscriber_address_label: 'Адрес',
      edit_subscriber_address_placeholder: 'Введите адрес организации',
      edit_subscriber_phone_label: 'Телефон',
      edit_subscriber_code_label: 'Ключ',
      edit_subscriber_generate_new_code: 'Сгенерировать новый ключ',
      edit_subscriber_copy_code: 'Копировать в буфер обмена',
      edit_subscriber_generate_code_placeholder: 'Для генерации кода нажмите на иконку ключа',
      edit_subscriber_ip_label: 'IP адреса организации',
      edit_subscriber_ip_placeholder: 'Введите IP адрес',
      edit_subscriber_add_ip_button: 'Добавить IP адрес',
      subscription_price_info: 'Сумма соглашения с подписчиком, на срок действия подписки',
      subscriber_ip_info: 'Читатели работающие с этих IP адресов будут иметь доступ к книгам подписчика без регистрации',
      admin_subscribers_search_placeholder: 'Кому выдана',
      admin_subscribers_create_subscription_button: 'Создать подписку',
      admin_subscribers_create_buyout_subscription_button: 'Создать индивидуальную подписку',
      admin_subscribers_edit_button: 'Редактировать',
      admin_subscribers_delete_button: 'Удалить',
      admin_subscribers_copy_code_button: 'Скопировать ключ',
      admin_subscribers_history_button: 'История',
      admin_subscribers_days_left_label: 'Дней:',
      admin_subscribers_collections_label: 'Коллекции:',
      admin_subscribers_books_label: 'Книги:',
      admin_subscribers_regular_tab_label: 'Обычная подписка - неограниченное количество читателей',
      admin_subscribers_regular_description: 'Подписчик получает доступ к указанным в подписке коллекциям и отдельным книгам на фиксированный срок и расчитывается с издательством за время активности подписки (например год) на договорной основе. Количество читателей при этом не ограничивается и сумма соглашения не зависит от того сколько пользователей читали книги.',
      admin_subscribers_individual_tab_label: 'Индивидуальная подписка - каждый читатель оплачивается',
      admin_subscribers_individual_description: 'Подписчик получает возможность выдавать книги своим читателям индивидуально и расчитываться по подписке только за тех кто читает книги. Например раз в 6 месяцев можно подсчитать сколько читателей воспользовались подпиской и оплатить услугу. Регистрация читателей может производится используя наш API (при необходимости интеграции книги) или используя специальную веб форму.',
      admin_subscribers_individual_status_label: 'Статус',
      admin_subscribers_individual_duration_label: 'Лимит',
      admin_subscribers_individual_duration_months_text: ' мес.',
      admin_subscribers_individual_duration_unlimited_text: 'Безлимитная',
      admin_subscribers_individual_price_label: 'Стоимость',
      admin_subscribers_individual_total_price_label: 'Итоговая стоимость',
      admin_subscribers_individual_book_label: 'Книга',
      edit_user_name_label: 'Имя пользователя',
      edit_user_password_label: 'Пароль',
      edit_user_code_label: 'Код',
      edit_password_old_password_label: 'Старый пароль',
      edit_password_new_password_label: 'Новый пароль',
      edit_password_repeat_password_label: 'Повторите новый пароль',
      edit_password_error_description: 'Пароли не совпадают',
      admin_users_create_user_button: 'Добавить администратора',
      admin_users_edit_button: 'Редактировать',
      admin_users_delete_button: 'Удалить',
      admin_users_change_password_button: 'Сменить пароль',
      admin_users_invitation_button: 'Отправить приглашение повторно',
      admin_main_first_step_title: 'Информация для каталога',
      admin_main_first_step: 'Шаг 1.',
      admin_main_first_step_description: 'Заполните краткую информацию о своем издательстве.',
      admin_main_second_step_title: 'Книги. Коллекции. Подписки',
      admin_main_second_step: 'Шаг 2.',
      admin_main_second_step_description: 'Загрузите собственные книги и сформируйте коллекцию.',
      admin_main_status: 'Стасус в каталоге:',
      admin_main_published: 'опубликован',
      admin_main_unpublished: 'неопубликован',
      admin_main_publishers_catalogue_link: 'Посмотреть в каталоге',
      admin_main_publisher_name_label: 'Название издательства',
      admin_main_publisher_profile_label: 'На чем профилируется издательство',
      admin_main_publisher_description_label: 'Описание',
      admin_main_publisher_description_placeholder: 'Коротко представьтесь и расскажите о компании в 5 - 10 строках. На чем специализируется издательство, с какого года вы работаете. Чем гордится ваша команда, какие у нее ценности и мотивация.',
      admin_main_publisher_email_label: 'Контактный E-mail',
      admin_main_publisher_phone_label: 'Контактный телефон',
      admin_main_publisher_catalog_path_label: 'Путь к каталогу книг издательства',
      admin_main_publisher_catalog_path_placeholder: 'Путь к каталогу книг',
      admin_main_publisher_catalog_path_pattern_hint: 'Путь к каталогу может включать в себя только английские буквы и цифры, и не должен содержать более 50 символов',
      admin_main_publisher_form_checkbox: 'Опубликовать в каталоге издательств',
      admin_main_publisher_form_submit_button: 'Сохранить',
      admin_main_uploaded_books: 'Загруженных книг',
      admin_main_empty_cell_placeholder: 'нет',
      admin_main_collections: 'Коллекций',
      admin_main_subscriptions: 'Ваши подписки',
      admin_main_create_website_title: 'Ваш сайт',
      admin_main_create_website_description_first_part: 'Используйте профессиональную платформу для загрузки книг и формирования коллекций. Благодаря встроенным модулям и управлению, ваши возможности в развитии продажи электронных книг становятся обширными.',
      admin_main_create_website_description_second_part: 'На нашей платформе c созданием сайта справится каждый. Создать сайт можно выбрав один из трех шаблонов на выбор с готовыми вариантами дизайна и содержания, в редакторе возможно поменять стиль сайта на ваши фирменные цвета. Это удобный и эффективный способ оставаться на связи со своими клиентами. Наша комплексная платформа для создания сайтов включает 3 дизайнерских шаблона, а также техническую поддержку.',
      admin_main_create_website_description_third_part: 'Продавайте книги и получайте оплату от клиентов онлайн. Вы можете воспользоваться бесплатным ознакомительным периодом на 1 месяц.',
      //admin_main_create_website_try_it_text: 'Попробуйте. Это бесплатно.',
      admin_main_create_website_button_text: 'Заказать сайт',
      admin_users_admins_label: 'Администраторы',
      admin_users_readers_label: 'Читатели',
      admin_phone_example_placeholder: '+7(499)-999-99-99',
      create_admin_dialog_title: 'Введите E-mail нового пользователя. На указанный адрес будет выслана инструкция для завершения регистрации.',
      book_contents_title: 'Содержание',
      book_description_publication_description_label: 'Издание:',
      book_description_publication_year_label: 'Год выпуска:',
      book_description_format_label: 'Формат:',
      book_description_binding_label: 'Переплет:',
      book_description_pages_label: 'Страниц:',
      book_bibliography_record_label: 'Библиографическая запись',
      book_bibliography_publication_label: 'Санкт-Петербург: Квадро',
      book_bibliography_date: 'дата обращения: ',
      book_bibliography_text_format: 'Текст: электронный',
      book_bibliography_library_label: 'Электронная библиотечная система Elibrica',
      book_bibliography_access_label: 'Режим доступа: для авторизир. пользователей',
      book_bibliography_copy_label: 'Скопировать библиографическую запись',
      book_bibliography_copy_to_clipboard_successfully: 'Библиографическая запись скопирована в буфер обмена',
      book_editor_loaded_pages_label: 'Загружено',
      book_reader_search_placeholder: 'Введите текст для поиска',
      book_price_label: 'цена:',
      currency_rub_label_short: 'руб',
      books_catalogue_by_title_label: 'По алфавиту',
      books_catalogue_by_author_label: 'По автору',
      books_catalogue_by_year_label: 'По дате публикации',
      books_catalogue_by_category_label: 'По категории',
      books_catalogue_search_placeholder: 'Введите название книги или автора',
      books_catalogue_filter_placeholder: 'Фильтр по издательствам',
      books_catalogue_filter_books_by_ip_option: 'Книги вашей библиотеки',
      header_login_button: 'Вход',
      header_exit_button: 'Выход',
      header_books_button: 'Книги',
      header_publishers_button: 'Издательствам',
      footer_elibrica: 'ЭЛИБРИКА',
      footer_left_text: 'Текст 1',
      footer_right_text: 'Текст 2',
      read_book: 'Читать',
      ISBN_is_already_taken_error_message: 'Данный ISBN уже принадлежит другой книге',
      save_book_error_msg: 'Ошибка при сохранении книги',
      save_book_success_msg: 'Книга успешно сохранена',
      delete_book_error_msg: 'Ошибка при удалении книги',
      delete_book_success_msg: 'Книга успешно удалена',
      loading_book_error_msg: 'Ошибка при загрузке книги',
      delete_book_confirm_title: 'Удаление книги',
      delete_book_confirm_text: 'Вы уверены что хотите удалить данную книгу?',
      save_collection_error_msg: 'Ошибка при сохранении коллекции',
      save_collection_success_msg: 'Коллекция успешно сохранена',
      delete_collection_error_msg: 'Ошибка при удалении коллекции',
      delete_collection_success_msg: 'Коллекция успешно удалена',
      delete_collection_confirm_title: 'Удаление коллекции',
      delete_collection_confirm_text: 'Вы уверены что хотите удалить данную коллекцию?',
      save_subscription_error_msg: 'Ошибка при сохранении подписки',
      save_subscription_success_msg: 'Подписка успешно сохранена',
      delete_subscription_error_msg: 'Ошибка при удалении подписки',
      delete_subscription_success_msg: 'Подписка успешно удалена',
      delete_subscription_confirm_title: 'Удаление подписки',
      delete_subscription_confirm_text: 'Вы уверены что хотите удалить данную подписку?',
      delete_subscriber_confirm_title: 'Удаление подписчик',
      delete_subscriber_confirm_text: 'Вы уверены что хотите удалить данного подписчика?',
      delete_subscriber_success_msg: 'Подписчик успешно удален',
      delete_subscriber_error_msg: 'Ошибка при удалении подписчика',
      save_user_error_msg: 'Ошибка при сохранении пользователя',
      save_user_success_msg: 'Пользователь успешно сохранен',
      delete_user_error_msg: 'Ошибка при удалении пользователя',
      delete_user_success_msg: 'Пользователь успешно удален',
      delete_user_confirm_title: 'Удаление пользователя',
      delete_user_confirm_text: 'Вы уверены что хотите удалить данного пользователя?',
      user_with_such_email_is_already_exist: 'Пользователь с такой почтой уже существует',
      change_password_error_msg: 'Ошибка при попытке изменить пароль',
      change_password_success_msg: 'Пароль успешно изменен',
      resend_invitation_error_msg: 'Ошибка при попытке отправить приглашение',
      resend_invitation_success_msg: 'Приглашение повторно отправлено',
      bind_accounts_success_msg: 'Аккаунты успешно связаны',
      bind_accounts_wrong_password_error: 'Неверный пароль',
      history_loading_error_msg: 'Ошибка при попытке загрузить историю',
      data_loading_error_msg: 'Ошибка при загрузке данных',
      using_storage_token_error_msg: 'Ошибка при попытке использовать сохраненный токен',
      verification_of_token_error_msg: 'Ошибка верификации токена. Войдите в систему',
      authorization_error_msg: 'Ошибка при попытке авторизации',
      generation_code_error_msg: 'Ошибка при попытке сгенерировать код лиценции',
      generation_code_success_msg: 'Новый код лиценции успешно сгенерирован',
      logout_error_msg: 'Ошибка при попытке выйти из системы',
      book_read_forbidden_warn: 'Данная книга не включена в вашу подписку',
      book_has_not_loaded_warn: 'Книга не загружена',
      book_read_user_not_authorized_warn: 'Вы не авторизованы',
      upload_file_error_msg: 'Ошибка загрузки файла',
      license_copy_code_to_clipboard_successfully: 'Код скопирован в буфер обмена',
      empty_table_message: 'Пусто',
      column_header_date_changes: 'Дата изменений',
      column_header_date_creation: 'Дата начала',
      column_header_list_of_changes: 'История изменений',
      column_header_list_of_books: 'Список книг',
      column_header_price: 'Цена',
      column_header_books_and_collections: 'Состав подписки',
      column_header_key: 'Ключ',
      table_info_books_quantity_0: 'книг',
      table_info_books_quantity_1: 'книга',
      table_info_books_quantity_2: 'книги',
      table_info_books_quantity_3: 'книги',
      table_info_books_quantity_4: 'книги',
      table_info_books_quantity_5_or_more: 'книг',
      column_header_collections: 'Коллекции',
      column_header_who_added: 'Кем создана',
      column_header_when: 'Дата добавления',
      column_header_title: 'Название',
      column_header_remark: 'Примечание',
      column_header_field: 'Поле',
      column_header_was: 'Было',
      column_header_became: 'Стало',
      column_header_authors: 'Автор',
      column_header_user: 'Пользователь',
      column_header_role: 'Роль',
      column_header_date: 'Дата',
      column_header_code: 'Коды',
      column_header_publishers: 'Издательства',
      column_header_finish_date: 'Окончание',
      column_header_left: 'Осталось',
      column_header_whom_subscription_was_provided: 'Кому выдана подписка',
      pagination_on_page: 'на странице',
      dialog_options_save: 'Сохранить',
      dialog_options_cancel: 'Отмена',
      dialog_confirm_no: 'Нет',
      dialog_confirm_yes: 'Да',
      reader_open_contents_button_tooltip: 'Открыть содержание',
      reader_close_contents_button_tooltip: 'Закрыть содержание',
      reader_next_page_button_tooltip: 'Следующая страница',
      reader_previous_page_button_tooltip: 'Предыдущая страница',
      reader_zoom_in_button_tooltip: 'Увеличить масштаб',
      reader_zoom_out_button_tooltip: 'Уменьшить масштаб',
      reader_index_mode_button_tooltip: 'Режим указателя',
      reader_swipe_mode_button_tooltip: 'Ручной режим',
      reader_full_screen_mode_button_tooltip: 'Выровнять по ширине окна',
      reader_unset_mode_button_tooltip: 'По размеру страницы',
      add_subscription: 'Добавить подписку',
      license_edit_code_label: 'Код лицензии',
      Handle_change_user_data_error_msg: 'Произошла ошибка при обновлении. Попробуйте позже.',
      Handle_change_user_data_success_msg: 'Обновление прошло успешно!',
      my_subscriptions_dialog_title: 'Мои подписки',
      my_subscriptions_table_column_title_library: 'Библиотека',
      my_subscriptions_table_column_title_address: 'Адрес',
      my_subscriptions_table_column_title_phone: 'Телефон',
      my_subscriptions_table_column_title_code: 'Ключ',
      my_subscriptions_table_column_title_status: 'Статус',
      my_subscriptions_table_status_value_active: 'активен',
      my_subscriptions_table_status_value_expired: 'истек',
      authorization_select_account_question: 'В какое издательство вы хотите войти?',
      my_profile_super_admin: 'Супер админ',
      tenants_management_block_button: 'Заблокировать',
      tenants_management_unblock_button: 'Разблокировать',
      tenants_management_delete_button: 'Удалить',
      menu_manage_tenants_button: 'Управление тенантами',
      book_reader_header_prev_button_label: 'Предыдущая глава',
      book_reader_header_next_button_label: 'Следующая глава',
      create_subscriber_success_msg: 'Подписчик успешно создан',
      edit_subscriber_success_msg: 'Редактирование подписчика прошло успешно',
      edit_subscriber_error_msg: 'Во время редактирования подписки произошла ошибка',
      edit_subscription_success_msg: 'Редактирование подписки прошло успешно',
      edit_user_form_title: 'Редактирование пользователя',
      edit_password_dialog_title: 'Смена пароля',
      edit_password_resend_mail_link_text: 'Сброс пароля',
      edit_password_resend_mail_text: 'через электронную почту',
      publisher_registration_form_error_msg_first_part_text: 'E-mail занят! Попробуйте',
      publisher_registration_form_error_msg_link_text: 'войти',
      publisher_registration_form_error_msg_second_part_text: 'или используйте другой email для регистрации.',
      column_header_status: 'Статус',
      column_header_author_of_change: 'Автор',
      column_header_published: 'Опубликован',
      column_header_mail: 'Почта',
      column_header_created: 'Создан',
      book_bibliography_text_text_site: ': [сайт].',
      publisher_question_form_name_placeholder: 'ФИО',
      publisher_question_form_question_placeholder: 'Задать вопрос',
      tenants_management_status_blocked: 'Заблокирован',
      tenants_management_status_active: 'Активен',
      publishers_become_partner_link_text: 'как стать парнером',
      edit_user_data_name_placeholder: 'Имя',
      edit_user_password_placeholder: 'Введите пароль пользователя',
      authorization_custom_name_label: 'Имя пользователя',
      authorization_password_label: 'Пароль',
      authorization_repeat_password_label: 'Повторите пароль',
      authorization_access_code_label: 'Код доступа',
      subscription_editor_subscription_type_select_label: 'Выберите тип подписки',
      admin_subscribers_months_left_label: 'Месяцев:',
      book_editor_first_step: 'Шаг 1.',
      book_editor_first_step_description: 'Начните с закачки книги. Поддерживаемые форматы EPUB и PDF. Нам надо знать формат книги чтобы продолжить работу.',
      book_editor_second_step: 'Шаг 2.',
      book_editor_second_step_description: 'Заполните форму.',
      column_header_duration: 'Длительность',
      admin_subscribers_individual_duration_months_left_text: 'месяцев',
      out_of_collection: 'Вне коллекции',
      subscription_editor_duration_label: 'Длительность с момента оплаты',
      book_reader_prev_button: '« Предыдущая',
      book_reader_next_button: 'Следующая »',
      book_reader_load_book_error_msg: 'Книга не загружается. Пожалуйста свяжитесь с издательством или службой поддержки.',
      admin_main_mailto_order_website: 'mailto:contact@elibrica.com?subject=Заказ%20веб%20сайта%20для%20издательства',
      restore_password_dialog_title: 'Восстановление пароля',
      restore_password_label: 'Введите свой Email',
      restore_password_success_msg: 'Письмо для восстановления пароля отправлено',
      book_editor_publish_book_toggle: 'Опубликовать в каталоге книг',
      book_editor_free_book_toggle: 'Бесплатное онлайн использование',
      book_editor_physical_book_toggle: 'Книга доступна в физическом формате',
      book_editor_unpublished_save_confirm_text: 'Вы сохраняете неопубликованную книгу к которой будет иметь доступ только администраторы и пользователи имеющие прямой доступ к книге. Продолжить?',
      book_editor_unpublished_save_confirm_title: 'Сохранить книгу как неопубликованную.',
      book_editor_unpublished_save_success_msg: 'Сохраненная книга не будет показана в катологе',
      admin_users_table_column_title_first_time_opened: 'Дата первого открытия',
      admin_users_table_column_title_last_time_opened: 'Дата последнего открытия',
      admin_users_table_column_title_times_opened: 'Прочтений книги',
      admin_users_table_column_title_last_page: 'Последняя открытая страница',
      forgot_password: 'Забыли пароль?',
      bind_accounts_dialog_description: 'Для связки с существующим аккаунтом подтвердите что этот аккаунт ваш введя пароль от него',
      bind_accounts_dialog_title: 'Привязка к существующему аккаунту',
      select_account_dialog_title: 'Выберите рабочее пространство в которое вы хотите зайти',
      subscription_history_dialog_title: 'История изменений подписки',
      create_collection_dialog_title: 'Создание коллекции',
      edit_collection_dialog_title: 'Редактирование коллекции',
      edit_user_profile_dialog_title: 'Редактирование профиля пользователя',
      dialog_button_accept: 'Подтвердить',
      withdraw_button_text: 'Уйти из системы',
      consent_dialog_description: 'Наш сайт использует сторонние веб-технологии для предоставления и постоянного улучшения наших услуг. Мы ценим вашу приватность, поэтому мы открыто говорим о технологиях которые используем.',
      consent_dialog_read_more_link: 'Прочтите подробнее здесь.',
      consent_dialog_title: 'Пользовательское соглашение',
      withdraw_warning_confirm_dialog_msg: 'Пользователь будет полностью удален из системы, все купленные книги будут недоступны',
      withdraw_warning_confirm_dialog_title: 'Вы уверены что хотите покинуть систему?',
      column_header_subscriber_title: 'Название',
      column_header_subscriber_address: 'Адрес',
      column_header_subscriber_phone: 'Телефон',
      column_header_subscriber_code: 'Код',
      column_header_subscriber_status: 'Статус',
      consent_first_section_title_part_one: 'Для чего нашему сайту',
      consent_first_section_title_part_two: 'нужны ваши данные?',
      consent_first_section_description: 'Для корректной работы приложения, а так же документирования и анализа с целью улучшения процесса использования и повышения эффективности наших услуг.',
      consent_second_section_title: 'Как отозвать согласие?',
      consent_second_section_description_part_one: 'Ваше согласие позволит сайту',
      consent_second_section_description_part_two: 'получать Ваши данные в соответствии с политике о конфиденциальности. Отказ предоставить согласие приведет к невозможности использовать наш сайт. Приняв пользовательское соглашение вы сможете передумать и потребовать вашего удаления из системы в любое время. Чтобы отозвать согласие, зайдите в профиль, а затем нажмите на кнопку',
      consent_accept_question_text: 'Принимаете ли вы согласие на обработку и передачу ваших данных?',

      /* ... */
      switch_tenant_dialog_title: 'Выберите рабочее пространство в которое вы хотите зайти',
      my_profile_switch_tenant: 'Мои издательства',
      admin_readers_users_search_string_label: 'Введите имя читателя',
      admin_readers_books_search_string_label: 'Введите название книги',
      admin_readers_subscriber_select_placeholder: 'Выберите подписчика',
      add_user_to_subscriber_input_placeholder: 'Введите код подписчика',
      add_user_to_subscriber_dialog_title: 'Добавить подписку',
      edit_user_add_user_to_subscriber_button: 'Добавить подписку',
      column_header_subscriber_book_count: 'Доступных книг',
      admin_readers_option_all_text: 'Все читатели',
      admin_readers_option_regular_text: 'С обычными подписками',
      admin_readers_option_individual_text: 'С индивидуальными подписками',
      admin_readers_date_picker_label: 'Выберите диапазон дат',
      admin_readers_date_picker_start_date_placeholder: 'От',
      admin_readers_date_picker_end_date_placeholder: 'До',
      admin_users_search_placeholder: 'Введите почту пользователя',
      ISBN_is_too_long_error_message: 'ISBN не может быть длиннее 17 символов',
      book_edit_six_months_price_label: 'Цена за 6 месяцев',
      book_edit_six_months_price_placeholder: 'Введите цену за 6 месяцев',
      book_edit_physical_price_label: 'Физическая цена',
      book_edit_physical_price_placeholder: 'Цена за реальную книгу',
      delete_tenant_confirm_title: 'Удаление издательства',
      delete_tenant_confirm_text: 'Вы уверены что хотите удалить это издательство ? Все книги, коллекции, подписчики и подписки этого издательства будут удалены из системы.',
      book_description_read_info_integration: 'Эта книга доступна к прочтению при интеграции. Для получения инструкций вы можете связаться с издательством или подписчиком:',
      book_description_read_info_regular: 'Вы можете прочитать эту книгу присоединившись к одному из подписчиков:',
      book_description_read_info_publisher: 'Вы можете получить доступ к этой книге напрямую от издательства:',
      book_description_read_info_publisher_phone: 'Контактный номер издательства:',
      book_description_read_info_publisher_email: 'Контактная почта издательства:',
      book_description_read_info_subscriber_phone: 'Контактный номер:',
      my_subscriptions_empty_subscriptions_table_message: 'У вас нет ни одной подписки',
      my_subscriptions_empty_purchases_table_message: 'У вас нет ни одной покупки',
      my_subscriptions_withdraw_from_subscriber: 'Отписаться',
      column_header_purchase_status: 'Статус',
      column_header_purchase_title: 'Название',
      column_header_purchase_start: 'Дата приобритения',
      column_header_purchase_end: 'Окончание доступа',
      my_subscriptions_purchases_unlimited_duration: 'Безлимитная',
      my_subscriptions_purchases_book_has_been_deleted: 'Книга была удалена',

      admin_main_publisher_currency_placeholder: 'Валюта',
      admin_main_publisher_currency_label: 'Валюта',
      admin_main_publisher_currency__pattern_hint: 'Валюта должна быть в списке',

      readers_count_found: 'Читателей найдено: ',
      books_count_found:'Книг найдено: ',
      collections_count_found:'Коллекций найдено: ',
      subscribtions_count_found: 'Подписок найдено: ',
      admins_count_found: 'Админов найдено: ',

      publisher_currency_update_save_confirm_text: 'Вы уверены что хотите обновить валюту? Установленные цены останутся с теми же значениями, но валюта изменится.',
      publisher_update_save_confirm_title: 'Подтверждение обновления',
      publisher_catalog_path_update_save_confirm_text: 'Вы уверены что хотите обновить путь? Если вы это сделаете то все ссылки содержащие в себе ваш путь перестанут работать!',
    
      delete_tenant_error_msg: 'Возникла ошибка при полном удалении тенанта, повторите позже',
      delete_tenant_success_msg: 'Тенант полностью удалён из системы',

      picture_successfully_uploaded_msg: 'Изображение успешно обновлено',
      picture_failed_upload_msg: 'Изображение не обновлено, повторите попытку позже',

      book_editor_bibliography_toggle: 'Имеется ли библиографическое название',

      column_header_books_count: 'Книг',
      column_header_collections_count: 'Коллекций',
      column_header_subscribtions_count: 'Подписок',
      column_header_active_subscribtions_count: 'Активных подписок',

      admin_books_empty_book: 'Книга загружена без описания',
      dialog_options_close: 'Закрыть',
      already_subscribed_to_subscriber: 'Вы уже подписаны на этого подписчика',
      connection_with_subcriber: 'Вы успешно подписались',
      date_format_example: 'MM/DD/YYYY – MM/DD/YYYY',
      registration_invalid_token_msg: 'Неверный токен, восстановите пароль ещё раз',
      column_header_last_login: 'Дата последнего входа',
      last_login_undefined: '-',
      admin_readers_option_wait_request_text: 'Запросившие доступ',

      readers_request_header: 'Запрос на получение доступа для',
      readers_request_message_title: 'Прикреплённое сообщение',
      readers_request_message_placeholder: 'Я Иван Иванович с читательским билетом №2.',

      something_went_wrong_msg: 'Что-то пошло не так',
      already_have_this_sub_error_msg: 'У вас уже есть данная подписка',
      already_exist_request_error_msg: 'Вы уже подали запрос для данной подписки',
      request_sended_msg: 'Запрос успешно отправлен',

      request_accepted_msg: 'Запрос принят',
      request_rejected_msg: 'Запрос отклонён',

      accept_request_btn:'Принять',
      reject_request_btn: 'Отклонить',

      requests_count_found: 'Запросов всего: ',
      phone_value_not_valid: 'Телефон должен быть в корректном формате',
      admin_books_open_book_in_catalog: 'Отрыть в каталоге',
      admin_books_published: 'Опубликована',
      admin_books_unpublished: 'Не опубликована',

      tenants_management_select_button: 'Зайти в тенант',
      tenants_management_tenant_selected: 'Тенант выбран',
      footer_company_info: 'ООО "Энерджи Софт", Санкт-Петербург'
    }
  };
  constructor() {}

  public getTranslation(key: string, langKey?: string): string {
    return this.languageDictionary[langKey ?? this.currentLanguage$.value][key] ?? key;
  }
}