import { Pipe, PipeTransform } from '@angular/core';
import {ApplicationTranslateService} from '../services/application-translate.service';

export interface TranslationParams {
  lang: string;
}

@Pipe({
  name: 'translate',
  pure: true
})

export class TranslatePipe implements PipeTransform {
  constructor(private translationService: ApplicationTranslateService) {}
  transform(value: any, args?: TranslationParams): any {
    return this.translationService.getTranslation(value, args?.lang ?? undefined);
  }
}
