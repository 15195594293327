import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {ApplicationStateService} from '../../shared/services/application-state.service';
import {Page, PageLimitOptions} from '../../shared/app-global-variables';
import {Book} from '../../shared/app-interfaces';
import {BehaviorSubject, combineLatest, debounceTime, finalize, Subscription} from 'rxjs';
import { CustomIcons } from 'src/app/shared/services/application-utils.service';
import {Router} from '@angular/router';

export enum BooksSortingMode {
  ByTitle = 'title',
  ByAuthor = 'authors',
  ByPublicationYear = 'publicationYear'
}
export type PageViewType = 'row' | 'grid';

@Component({
  selector: 'app-books-catalogue',
  templateUrl: './books-catalogue.component.html',
  styleUrls: ['./books-catalogue.component.scss']
})

export class BooksCatalogueComponent implements OnDestroy {
  public readonly CustomIcons = CustomIcons;
  public page = new Page();
  public publishers: any;
  public temp = [];
  public rows: Book[];
  public pageLimitOptions = PageLimitOptions;
  public loading$ = new BehaviorSubject(false);
  public BooksSortingMode = BooksSortingMode;
  public oldSearchValue: string;
  public pageViewType: PageViewType = 'row';
  defaultFilter: string;
  private subs: Subscription[] = [];
  constructor(
    public cd: ChangeDetectorRef,
    public state: ApplicationStateService,
    public router: Router,
  ) {
    this.page.offset = 0;
    this.page.limit = 25;
    this.page.currentPage = 1;
    this.state.publisherFilter$.next('');
    this.state.getPublishers();

    this.subs.push(combineLatest([this.state.publishers$, this.state.routerUrl$])
      .pipe(debounceTime(400))
      .subscribe(([publishers, routerUrl]) => {
        this.publishers = publishers?.filter(publisher => publisher?.catalogPath && publisher.published);
        this.defaultFilter = publishers?.find(publisher => routerUrl.includes(publisher?.catalogPath))?.catalogPath;
      }));

    this.subs.push(combineLatest([this.state.bookSearchString$, this.state.bookSortingMode$,
      this.state.publisherFilter$, this.state.booksByIpFilter$])
      .pipe(debounceTime(400))
      .subscribe((res) => this.setPage({offset: 0})));
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  setPage(pageInfo): any {
    this.loading$.next(true);
    this.page.offset = pageInfo.offset;
    if (this.state.booksByIpFilter$.value) {
      this.state.getBooksByIp(this.page.limit, this.page.offset * this.page.limit, {
        sort: this.getSort(),
        filter: this.getFilter()
      }).pipe(finalize(() => this.loading$.next(false)))
        .subscribe((res) => {
          const {data, page} = res;
          this.temp = data;
          this.page.totalElements = page.totalElements;
          this.page.totalPages = page.totalElements / this.page.limit >= 1 ? page.totalElements / this.page.limit : 1;
          this.rows = this.temp;
          this.cd.markForCheck();
        });
      return;
    }
    this.state.getBooks(true, this.page.limit, this.page.offset * this.page.limit, {
      sort: this.getSort(),
      filter: this.getFilter()
    })
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe((res) => {
        const {data, page} = res;
        this.temp = data;
        this.page.totalElements = page.totalElements;
        this.page.totalPages = page.totalElements / this.page.limit >= 1 ? page.totalElements / this.page.limit : 1;
        this.rows = this.temp;
        this.cd.markForCheck();
      });
  }
  setPageViewType(val: PageViewType): void {
    this.pageViewType = val;
  }

  setPublisherFilter(val: string): void {
    if (val === 'subscriberByIp') {
      this.state.booksByIpFilter$.next(true);
      this.state.publisherFilter$.next(null);
      return;
    }

    this.state.booksByIpFilter$.next(false);
    this.state.publisherFilter$.next(val);
    if (val) {
      this.router.navigate([val]);
    }
  }
  executeSearch(searchString: string): void {
    if (searchString !== this.oldSearchValue) {
      this.oldSearchValue = searchString;
      this.state.bookSearchString$.next(searchString);
    }
  }

  changePage(page: any): void {
    this.page.currentPage = page;
    this.setPage({offset: page - 1});
  }

  changeLimit(limit: number): void {
    this.page.limit = limit;
    this.page.currentPage = 1;
    this.page.offset = 0;
    this.setPage({offset: this.page.offset});
  }

  getSort(): any {
    return {
      column: this.state.bookSortingMode$?.value,
      order: 'asc'
    };
  }

  getFilter(): any {
    const filter = [];
    if (this.state.publisherFilter$?.value) {
      filter.push({
        column: 'CatalogPath',
        method: '=',
        data: this.state.publisherFilter$?.value
      });
    }

    if (this.state.bookSearchString$?.value) {
      filter.push(
        {
          column: 'title',
          method: 'contains',
          data: this.state.bookSearchString$?.value
        },
        {
          column: 'authors',
          method: 'contains',
          data: this.state.bookSearchString$?.value
        },
        {
          column: 'annotation',
          method: 'contains',
          data: this.state.bookSearchString$?.value
        }
      );
    }
    return filter;
  }

  clearFilters(): void {
    this.state.booksByIpFilter$.next(false);
    this.state.publisherFilter$.next(null);
    this.router.navigate(['']);
  }
}
