import {ChangeDetectionStrategy, Component, Input, OnInit, Inject} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, catchError, finalize, first} from 'rxjs';
import {ApplicationStateService} from 'src/app/shared/services/application-state.service';
import {ApplicationUtilsService, CustomIcons} from 'src/app/shared/services/application-utils.service';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/component';
import { ApplicationAuthService } from 'src/app/shared/services/application-auth.service';

enum FormFields {
  name = 'name',
  userName = 'userName',

  // #note Temporary disabled fields;

  // password = 'password',
  // newPassword = 'newPassword'
  // name = 'name',
  // email = 'email',
  // password = 'password',
  // newPassword = 'newPassword'
}

@Component({
  selector: 'app-edit-user-profile-template',
  templateUrl: './edit-user-data.component.html',
  styleUrls: ['./edit-user-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class EditUserProfileComponent implements OnInit {
  public readonly CustomIcons = CustomIcons;
  public readonly FormFields = FormFields;
  public form: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(false);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: any },
    public formBuilder: FormBuilder,
    public state: ApplicationStateService,
    public api: ApplicationApiService,
    public utils: ApplicationUtilsService,
    public dialog: MatDialog,
    private auth: ApplicationAuthService,
    private dialogRef: MatDialogRef<EditUserProfileComponent>
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder?.group({
        [FormFields.name]: [this.data.user.name ?? ''],
        [FormFields.userName]: [this.data.user.userName ?? '', Validators.required], // E-mail

        // #note Temporary disabled fields;

        // [FormFields.email]: [this.user.userName ?? '', Validators.required],
        // [FormFields.password]: ['', Validators.required],
        // [FormFields.newPassword]: ['', Validators.required]
      },
      {
        validator: (control: AbstractControl) => {
          // #note Temporary disabled validation;

          // const password = control.get(FormFields.password).value;
          // const confirmPassword = control.get(FormFields.newPassword).value;
          // if (password !== confirmPassword) {
          //   control.get(FormFields.newPassword).setErrors({MatchPassword: true});
          // } else {
          //   return null;
          // }
        }
      });
  }

  public getFormControl(field: FormFields): FormControl {
    return this.form.get(field) as FormControl;
  }

  save(): void {

    if (this.form.invalid) {
      return;
    }
    this.loading$.next(true);
    this.api.updateSelf(
      {
        uid: this.data.user.user_id,
        name: this.form.value[FormFields.name]
      }
    )
    .pipe(first())
    .pipe(
      catchError((e: HttpErrorResponse): any => {
      this.utils.showSnackbar(this.utils.translate('Handle_change_user_data_error_msg'), 10000);
    }),
      finalize(() => this.loading$.next(false))
    ).subscribe(res => {
      this.utils.showSnackbar(this.utils.translate('Handle_change_user_data_success_msg'));
      this.dialogRef.close(this.form.value[FormFields.name]);
    });
  }

  withdraw(): any {
    this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: {text: 'withdraw_warning_confirm_dialog_msg', title: 'withdraw_warning_confirm_dialog_title'}
    }).afterClosed().subscribe(response => {
      if (response) {
        this.api.withdraw$().subscribe(res => {
          this.auth.logout();
          this.utils.showSnackbar('User was removed from the system');
          this.cancel();
        });
      }
    })
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
