import { ChangeDetectionStrategy, Component, ChangeDetectorRef, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { compareAsc, differenceInDays, format } from 'date-fns';
import {BehaviorSubject, catchError, combineLatest, debounceTime, finalize, first, Subscription, switchMap} from 'rxjs';
import { Page } from 'src/app/shared/app-global-variables';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { ApplicationUtilsService } from 'src/app/shared/services/application-utils.service';
import { EditUserComponent } from '../../../users/edit-form-template/edit-user.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/component';
import { ApplicationApiService } from 'src/app/shared/services/application-api.service';
import { EditPasswordComponent } from '../../../users/edit-user-password-template/edit-password.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';

enum FormFields {
  start = 'start',
  end = 'end'
}

@Component({
    selector: 'individual-mode-readers',
    templateUrl: 'individual-mode.component.html',
    styleUrls: ['individual-mode.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })
  export class IndividualModeReadersComponent implements OnInit, OnDestroy{
    @ViewChild('bookInput') bookInput: ElementRef<HTMLInputElement>;
    @ViewChild('groupTable') groupTable: DatatableComponent;
    public isLoading$ = new BehaviorSubject<boolean>(false);
    private subs: Subscription[] = [];
    public oldBookSearchValue: string;
    rows: any;
    filteredSubscribers: any;
    filteredSubscriptions: any;
    filteredCollections: any;
    selectedBooks: { uid, title }[] = [];
    public form: FormGroup;

    page = new Page();
    constructor(
      public formBuilder: FormBuilder,
      public state: ApplicationStateService,
      public utils: ApplicationUtilsService,
      public cd: ChangeDetectorRef,
      private dialog: MatDialog,
      public api: ApplicationApiService
    ) {
      this.page.offset = 0;
      this.page.limit = 10000;
      this.state.getSubscribers();
      this.state.selectedIndividualSubscriber$.next(null);
      this.subs.push(
       combineLatest([this.state.readersBookAutocompleteString$, this.state.ownBooks$, this.state.collections$,
         this.state.selectedIndividualSubscriber$, this.state.subscriptions$, this.state.subscribers$])
         .pipe(debounceTime(500))
         .subscribe(([searchString, books, collections, selectedSubscriber, subscriptions, subscribers]) => {
           this.filteredSubscribers = subscribers.filter(subscriber => subscriptions.find(subscription => {
               return subscription.subscriber.uid === subscriber.uid &&  subscription.licenseType === 'Individual';
             }
           )).sort(x => { 
            return this.state.subscriptions$.value.filter(sub => sub.subscriber.uid == x.uid && this.isExpired(sub)).length > 1 ? 1 : -1;
          });

           if (selectedSubscriber?.uid && !this.filteredSubscribers.find(subscriber => subscriber.uid === selectedSubscriber?.uid)) {
             this.state.selectedSubscriber$.next(null);
           }

           const integrationSubscriptions = subscriptions.filter(subscription => subscription.licenseType === 'Individual'
             && (selectedSubscriber ? subscription.subscriber.uid === selectedSubscriber.uid : true)
             && (subscription.collections.length !== 0 || subscription.books.length !== 0));

           this.filteredSubscriptions = integrationSubscriptions;

           this.getFilteredCollections(searchString, books, collections, integrationSubscriptions);
           this.setPage({offset: this.page.offset});
         })
     );
    }

    ngOnInit(): void {
      this.form = this.formBuilder?.group({
        [FormFields.start]: [null],
        [FormFields.end]: [null]
      });
    }

    
    ngOnDestroy(): void {
      this.subs.forEach(x => x.unsubscribe());
    }

    setPage(pageInfo): any {
      this.isLoading$.next(true);
      this.page.offset = pageInfo.offset;
      this.getFilteredPaginatedReaders();
      this.cd.markForCheck();
    }

    getFilteredPaginatedReaders(): void {
      let body = {};
      const filter = [];
      if (this.state.selectedIndividualSubscriber$?.value?.uid) {
        filter.push({
          column: 'Subscriber',
          method: '=',
          data: this.state.selectedIndividualSubscriber$.value.uid
        });
        body = {
          sort: {
            column: 'name',
            order: 'asc'
          },
          filter
        };
      }
      
      if (this.selectedBooks.length > 0) {
        filter.push({
          column: 'Title',
          method: '=',
          data: JSON.stringify(this.selectedBooks.map(book => book.uid))
        });
      }

      if (this.form.value[FormFields.start] && this.form.value[FormFields.end]) {
        filter.push({
          column: 'BookUsage',
          method: 'contains',
          data: JSON.stringify([new Date(this.form.value[FormFields.start]), new Date(this.form.value[FormFields.end])])
        });
      } else if (this.form.value[FormFields.start]) {
        filter.push({
          column: 'BookUsage',
          method: 'contains',
          data: JSON.stringify([new Date(this.form.value[FormFields.start])])
        });
      }

      this.state.getPaginatedReaders(this.page.limit, this.page.offset * this.page.limit, body, 'individual').subscribe((res) => {
        const {data, page} = res;
        this.rows = data;
  
        this.page.totalElements = page.totalElements;
        this.page.totalPages = page.totalElements / this.page.limit >= 1 ? page.totalElements / this.page.limit : 1;
        this.isLoading$.next(false);
        this.cd.markForCheck();
      });
    }

    getFilteredCollections(searchString, books, collections, integrationSubscriptions): void {
      const integrationSubscriptionWithBooks = collections.filter(collection => {
        return integrationSubscriptions.find(subscription => subscription.collections
          .find(subscriptionCollection => subscriptionCollection.uid === collection.uid));
      });
  
      this.filteredCollections = integrationSubscriptionWithBooks.map(collection => {
        return {
          ...collection,
          books: collection.books.filter(book => book.title.toLowerCase().includes(searchString.toLowerCase())
            && !this.selectedBooks.find(selectedBook => selectedBook.uid === book.uid))
        };
      }).filter(collection => collection.books.length !== 0);
  
      const outOfCollection = {
        title: 'outOfCollection',
        books: books?.filter(book =>
          integrationSubscriptions?.map(sub => [...sub.books.map(item => item.uid)])
            .find(subscription => subscription?.includes(book.uid) &&
              book.title.toLowerCase().includes(searchString.toLowerCase()) &&
              !this.selectedBooks.find(selectedBook => selectedBook.uid === book.uid)))
      };
  
      if (outOfCollection.books.length > 0) {
        this.filteredCollections.push(outOfCollection);
      }
    }

    addBook(book: any): void {
      const value = {
        uid: book?.uid,
        title: (book?.title || '').trim()
      };
      if (value) {
        this.selectedBooks.push(value);
      }
      this.state.readersBookAutocompleteString$.next('');
      this.bookInput.nativeElement.value = '';
      this.cd.markForCheck();
    }

    remove(uid: string): void {
      const index = this.selectedBooks.findIndex(book => book.uid === uid);
  
      if (index >= 0) {
        this.selectedBooks.splice(index, 1);
      }
      this.setPage({offset: this.page.offset});
      this.getFilteredCollections(this.state.readersBookAutocompleteString$.value, this.state.books$.value, this.state.collections$.value, this.filteredSubscriptions);
      this.cd.markForCheck();
    }

    public dateChanged(): void {
      this.getFilteredPaginatedReaders();
    }

    toggleExpandGroup(group): void {
      this.groupTable.groupHeader.toggleExpandGroup(group);
    }

    changeSelectedSubscriber(uid: string): void {
      this.state.selectedIndividualSubscriber$.next(this.state.subscribers$.value.find(subscriber => subscriber.uid === uid));
    }

    executeBookSearch(searchString: string): void {
      if (searchString !== this.oldBookSearchValue) {
        this.oldBookSearchValue = searchString;
        this.state.readersBookAutocompleteString$.next(searchString);
      }
    }

    formattedDate(date): any {
      return date ? format(new Date(date), 'dd.MM.yyyy') : "";
    }

    isExpired(row: any): boolean {
      if (row.licenseType === 'Individual') {
        return !row?.enabled;
      }
      return this.getLeftDays(row) === 0;
    }

    getLeftDays(row: any): any {
      if (compareAsc(new Date(), new Date(row?.end)) !== -1) {
        return 0;
      } else {
        return differenceInDays(
          new Date(row?.end),
          new Date()
        );
      }
    }
  }