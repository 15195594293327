import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import { Book } from '../app-interfaces';
import { ApplicationApiService } from './application-api.service';
import {ApplicationStateService} from './application-state.service';

const A4_HEIGHT = 877; // px
const A4_WIDTH = 620; // px

export enum ReaderMode {
    Unset,
    FullScreen
}

export enum CursorMode {
    Mouse,
    HandScroll
}

@Injectable({ providedIn: 'root' })
export class PdfReaderService {

    public readonly readerMode$ = new BehaviorSubject(ReaderMode.Unset);
    public readonly cursorMode$ = new BehaviorSubject(CursorMode.Mouse);

    public readonly pageSizeHeight$ = new BehaviorSubject(A4_HEIGHT);
    public readonly pageSizeWidth$ = new BehaviorSubject(A4_WIDTH);
    private timer = null;
    public bookId: string;
    public book: Book;
    public currentPage: number = 1;
    public zoom: number = 1;
    public bookPagesList: Array<any>;
    public countLoadedPages: number;
    public previousRange: Array<number> = [];
    public virtualScrollViewport: CdkVirtualScrollViewport;

    constructor(
        public applicationApiService: ApplicationApiService,
        private zone: NgZone,
        public state: ApplicationStateService
    ) {
    }

    public load(countLoadedPages: number): Observable<any> {
        for (let i = 0; i < countLoadedPages; i++) {
            this.bookPagesList.push({ id: i + 1, content: null });
        }

        this.loadRangeOfPages(this.currentPage);

        setTimeout(() => {
            const element = document.getElementsByClassName('reader-viewport')[0];

            element.addEventListener('scroll', (e: any) => {
                if (this.timer !== null) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(() => {
                    const page = Math.trunc(Math.round(Math.round(e.target.scrollTop) / this.getPageHeight())) + 1;
                    if (this.currentPage !== page) {
                        this.currentPage = page;
                        this.loadRangeOfPages(this.currentPage);
                    }
                }, 500);
            });
        });

        return of('');
    }

    public getResults(pageNumber): any {
        return this.applicationApiService.getPage(this.state?.routeParamBookId$?.value, pageNumber);
    }

    public removePreviousRangePages(range: Array<number>): void {
        const difference = this.previousRange.filter((i) => !range.includes(i));

        difference.forEach((i) => {
            this.bookPagesList[i - 1].content = null;
        });
    }

    public getNextPage(pageNumber: number): void {
        this.getResults(pageNumber).subscribe((pagedResults) => {
            this.zone.run(() => {
                setTimeout(() => {
                    if (pagedResults) {
                        this.bookPagesList[pageNumber - 1].content = URL.createObjectURL(pagedResults);
                    }
                }, 500);
            });
        }, (err) => console.log(err));
    }

    public isPageLoaded(pageId: number): boolean {
        return !!this.bookPagesList[pageId - 1].content;
    }

    public createRange(middleNum: number): Array<number> {
        const arr: Array<number> = [];
        for (let i = middleNum; i < middleNum + 5; i++) {
            if (i <= this.countLoadedPages) {
                arr.push(i);
            }
        }

        for (let i = middleNum; i > middleNum - 5; i--) {
            if (i > 0) {
                arr.push(i);
            }
        }

        return arr.sort().filter((item, pos, ary) => {
            return !pos || item !== ary[pos - 1];
        });
    }

    public loadRangeOfPages(pageId: number): void {
        const range = this.createRange(pageId);

        for (const page of range) {
            if (!this.isPageLoaded(page)) {
                this.getNextPage(page);
            }
        }

        this.removePreviousRangePages(range);

        this.previousRange = range;
    }

    public changePage(): void {
        this.virtualScrollViewport?.scrollToIndex(this.currentPage - 1, 'auto');
        this.loadRangeOfPages(this.currentPage);
    }

    public getZoomString(): string {
        return Math.round(this.zoom * 100) + '%';
    }

    public getPageHeight(): number {
        return this.pageSizeHeight$.value * this.zoom;
    }

    public getPageWidth(): any {
        return this.pageSizeWidth$.value * this.zoom;
    }

    public dragEventListener(evt): void {
        const element: any = document.getElementsByClassName('reader-viewport')[0];

        let pos = { top: 0, left: 0, x: 0, y: 0 };

        const mouseDownHandler = (e) => {
            pos = {
                left: element.scrollLeft,
                top: element.scrollTop,

                // Get the current mouse position
                x: e.clientX,
                y: e.clientY
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = (e) => {
            // How far the mouse has been moved
            const dx = e.clientX - pos.x;
            const dy = e.clientY - pos.y;

            // Scroll the element
            element.scrollTop = pos.top - dy;
            element.scrollLeft = pos.left - dx;
        };

        const mouseUpHandler = () => {
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };

        mouseDownHandler(evt);
    }

    public dragToScroll(): void {
        this.cursorMode$.next(CursorMode.HandScroll);
        const element: any = document.getElementsByClassName('reader-viewport')[0];

        element.classList.add('grabbable');
        element.addEventListener('mousedown', this.dragEventListener);
    }

    public removeDragToScroll(): void {
        this.cursorMode$.next(CursorMode.Mouse);
        const element: any = document.getElementsByClassName('reader-viewport')[0];

        element.classList.remove('grabbable');
        element.removeEventListener('mousedown', this.dragEventListener);
    }

    public switchFullScreenMode(): void {
        if (this.readerMode$.value === ReaderMode.FullScreen) {
            this.readerMode$.next(ReaderMode.Unset);
            this.pageSizeHeight$.next(A4_HEIGHT);
            this.pageSizeWidth$.next(A4_WIDTH);
        } else {
            this.readerMode$.next(ReaderMode.FullScreen);
            this.setHeightOnFullScreenMode();
        }
        this.zoom = 1;
        setTimeout(() => {
            this.changePage();
        }, 1000);
    }

    public setHeightOnFullScreenMode(): any {
        setTimeout(() => {
            const canvasWrapper: any = document.querySelector('.canvasWrapper');
            if (canvasWrapper && canvasWrapper.offsetHeight && canvasWrapper.offsetWidth) {
                this.pageSizeHeight$.next(canvasWrapper.offsetHeight);
                this.pageSizeWidth$.next(canvasWrapper.offsetWidth);
            }
        }, 500);
    }

    public next() {
        this.currentPage++;
        this.changePage();
    }

    public prev() {
        if (this.currentPage !== 1) {
            this.currentPage--;
            this.changePage();
        }
    }

    public zoomIn(step: number): any {
        if (this.zoom >= 5) {
            return;
        }
        this.zoom = this.zoom + step;

        setTimeout(() => {
            this.changePage();
        }, 500);
    }

    public zoomOut(step: number): any {
        if (this.zoom <= 0.25) {
            return;
        }
        this.zoom = this.zoom - step;

        setTimeout(() => {
            this.changePage();
        }, 500);
    }
}
