<div
  class="book-cnt"
  *ngIf="{
     adminMode: state.adminMode$ | async,
     searchString: (state.bookSearchString$ | async).trim()
      } as data">
  <div class="global-flex-row-space-between-start full-height book-container">
    <div class="book-picture-cnt">
      <div class="book-picture" style="cursor: pointer" routerLink="{{ '/book/' + book.uid }}" [ngClass]="{ 'without-image': !book.hasImage }">
        <img *ngIf="book.hasImage" src="{{ utils.getBookTitleImage(book.uid) }}" alt="img">
        <img *ngIf="!book.hasImage" src="assets/book_without_title.png" alt="img">
      </div>
    </div>
    <div class="book-info-cnt global-flex-column">
      <div class="book-desc-cnt global-flex-column-space-between-start">
      <span
        class="global-authors"
        [innerHtml]="book.authors | highlight: (data.searchString)"
      ></span>
        <a
          routerLink="{{ '/book/' + book.uid }}"
          class="global-title global-link"
          [innerHtml]="book.title | highlight: (data.searchString)"
        >
        </a>
        <span
          class="description"
          [innerHtml]="book.annotation | highlight: (data.searchString)"
        ></span>
      </div>
      <!--<span class="price">{{ ('book_price_label' | translate) + ' ' + book.price + ' ' + ('currency_rub_label_short' | translate)}}</span>-->
    </div>
  </div>
</div>
