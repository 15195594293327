<mat-dialog-content>
<h3 class="dialog-title">{{ data?.title | translate }}</h3>
  <div
    *ngIf="{
      loading: loading$ | async
    } as data"
    class="subscribers-edit-form-template"
    style="position: relative">
    <div *ngIf="data.loading" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>
    <form [formGroup]="form" class="global-flex-column-space-between-start">
      <div class="form-field-cnt global-flex-column-space-between-start">
        <span>{{'edit_collection_title' | translate}}</span>
        <mat-form-field
          [floatLabel]="'never'"
          class="global-mat-form-field">
          <input [formControlName]="FormFields.title"
                matInput type="text"
                [placeholder]="'edit_collection_title_placeholder' | translate">
        </mat-form-field>
      </div>

      <div class="form-field-cnt global-flex-column-space-between-start">
        <span>{{'edit_collection_books' | translate}}</span>
        <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
          <mat-select [formControlName]="FormFields.books"
                      multiple
                      [placeholder]="'edit_collection_books_placeholder' | translate">
            <mat-option *ngFor="let book of filteredBooks"
                        [value]="book.uid">
              {{ book.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-field-cnt global-flex-column-space-between-start">
        <span>{{'edit_collection_price' | translate | currency:state.tenantCurrency$.value}}</span>
        <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
          <input [formControlName]="FormFields.price"
                matInput
                type="number"
                [placeholder]="'edit_collection_price_placeholder' | translate">
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="buttons">
    <button class="global-button default" (click)="close()">
      {{'dialog_options_cancel' | translate }}
    </button>
    <button class="global-button" (click)="save()">
      {{'dialog_options_save' | translate }}
    </button>
  </div>
</mat-dialog-actions>
