import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '../app-interfaces';
import { environment } from 'src/environments/environment';

@Pipe({
  name:"currency"
})
// {{a| currency:currency.currentCurrency$.value}}
export class CurrencyPipe implements PipeTransform {

  constructor() {}

    transform(value: string, currency: Currency): string {
      return value + (currency == null? '':`, ${currency.symbol} (${(currency.title == 'RUB' && environment.language == 'ru') ? 'руб':currency.title})`);
    }
}