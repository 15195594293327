import {Component, Inject, Input, OnInit } from '@angular/core';
import {BehaviorSubject, catchError, finalize, switchMap} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../../shared/app-interfaces';
import {ApplicationStateService} from '../../../../shared/services/application-state.service';
import {ApplicationUtilsService} from '../../../../shared/services/application-utils.service';
import {ApplicationApiService} from '../../../../shared/services/application-api.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/component';
import { ApplicationAuthService } from 'src/app/shared/services/application-auth.service';

enum FormFields {
  email = 'email',
  name = 'name'
}

@Component({
  selector: 'app-edit-user-template',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})

export class EditUserComponent implements OnInit {

  public readonly roles = [
    'Admin'
  ];

  public readonly FormFields = FormFields;
  public form: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {user: User, title: string},
    public formBuilder: FormBuilder,
    public state: ApplicationStateService,
    public utils: ApplicationUtilsService,
    private api: ApplicationApiService,
    private dialogRef: MatDialogRef<EditUserComponent>
  ) {
  }

  public ngOnInit(): void {
    this.form = this.formBuilder?.group({
      [FormFields.name]: [this.data.user?.name || ''],
      [FormFields.email]: [this.data.user.email || this.data.user?.userName, Validators.required]
    });
  }

  public getFormControl(field: FormFields): FormControl {
    return this.form.get(field) as FormControl;
  }

  save(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.loading$.next(true);
    this.api.postUser$(this.form.value, this.data.user?.id ?? undefined).pipe(
      catchError((): any => {
        this.utils.showSnackbar('save_user_error_msg');
      }),
      finalize(() => this.loading$.next(false)),
    ).subscribe((res) => {
      this.loading$.next(false);
      this.utils.showSnackbar('save_user_success_msg');
      
      this.dialogRef.close(res);
    });
  }

  cancel():void{
    this.dialogRef.close(false);
  }
}
