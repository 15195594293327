<mat-dialog-content>
  <h3 class="dialog-title">{{ 'restore_password_dialog_title' | translate }}</h3>
  <div class="restore-password-template">
    <div *ngIf="loading$ | async" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>
      <form [formGroup]="form">
        <div class="global-flex-column-center-start full-width">
          <span class="global-label">{{'restore_password_label' | translate}}</span>
          <input class="global-input" id="email" name="email" type="email"
                [formControlName]="FormFields.email"
                [placeholder]="'input_placeholder_email' | translate"/>
        </div>
      </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="buttons">
    <button class="global-button default" (click)="cancel()">
      {{ 'dialog_options_cancel' | translate }}
    </button>
    <button class="global-button" (click)="save()">
      {{ 'button_text_send' | translate }}
    </button>
  </div>
</mat-dialog-actions>