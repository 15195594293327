<section class="publishers-question-form">
  <h2 class="title">{{'publishers_question_form_title' | translate}}</h2>
  <div class="form-container">
    <img
      src="assets/publishers-question-form-illustration.svg"
      alt="publishers-question-form-illustration">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <p class="form-info-text">{{'publishers_question_form_description' | translate}}</p>
      <div class="form-fields">
        <input
          id="fullName"
          name="fullName"
          formControlName="fullName"
          type="text"
          [placeholder]="'publisher_question_form_name_placeholder' | translate"
        />
        <input
          id="email"
          name="email"
          formControlName="email"
          type="email"
          placeholder="E-mail"
        />
        <textarea
          id="question"
          name="question"
          formControlName="question"
          [placeholder]="'publisher_question_form_question_placeholder' | translate"
        ></textarea>
      </div>
      <button
        type="submit"
        class="submit-button"
        [disabled]="!isAgreed"
      >{{'registration_user_send_button' | translate}}</button>
    </form>
  </div>
</section>
