<mat-dialog-content [ngClass]="{ 'tooltip-mode': data.toolTipMode }">
  <h3>{{ data.title | translate }}</h3>
  <ng-container *ngIf="data.text">
    <br />
    <div *ngIf="isMultipleText()">
      <p *ngFor="let str of data.text">
        {{str | translate}}
      </p>
    </div>
    <p *ngIf="!isMultipleText()">{{ data.text | translate }}</p>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!data.hideButtons">
  <div class="buttons">
    <button class="global-button default" *ngIf="!data.hideCancel" (click)="decline()">
      {{ (data.noButtonTitle || 'dialog_confirm_no') | translate }}
    </button>

    <button class="global-button" (click)="confirm()">
      {{ (data.yesButtonTitle || 'dialog_confirm_yes') | translate }}
    </button>
  </div>
</mat-dialog-actions>

<span *ngIf="data.toolTipMode" class="tooltip-mode-anchor">
</span>
