import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BooksCatalogueComponent} from './components/books-catalogue/books-catalogue.component';
import {PublisherRegistrationComponent} from './components/publisher-registration/publisher-registration.component';
import {AuthorizationComponent} from './components/authorization/authorization.component';
import {AdminUsersComponent} from './components/admin/users/admin-users.component';
import {AdminBooksComponent} from './components/admin/books/admin-books.component';
import {BookEditorComponent} from './components/admin/book-editor/book-editor.component';
import {AdminCollectionsComponent} from './components/admin/collections/admin-collections.component';
import {AdminSubscribersComponent} from './components/admin/subscribers/admin-subscribers.component';
import {AdminMainComponent} from './components/admin/main/admin-main.component';
import {AuthenticationAdminGuardInterceptor} from './shared/services/authorization-guard.interceptor';
import {ConsentComponent} from './components/consent/consent.component';
import {SubscriptionEditorComponent} from './components/admin/subscription-editor/subscription-editor.component';
import {TenantsManagementComponent} from './components/super-admin/tenants-management/tenants-management.component';
import {AdminReadersComponent} from './components/admin/readers/admin-readers.component';

const routes: Routes = [
  {
    path: '',
    component: BooksCatalogueComponent
  },

  // {
  //   path: 'admin',
  //   //canActivate: [AuthenticationAdminGuardInterceptor],
  //   loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  // },

  {
    path: 'book',
    loadChildren: () => import('./modules/book/book.module').then(m => m.BookModule)
  },

  {
    path: 'publishers',
    component: PublisherRegistrationComponent
  },
  {
    path: 'authorization',
    component: AuthorizationComponent,
    data: {
      registration: false
    }
  },
  {
    path: 'registration',
    component: AuthorizationComponent,
    data: {
      registration: true
    }
  },
  {
    path: 'consent',
    component: ConsentComponent
  },

  {
    path: ':catalogPath',
    component: BooksCatalogueComponent,
  },

  {
    path: 'admin',
    canActivate: [AuthenticationAdminGuardInterceptor],
    children: [
      {
        path: 'manage-main',
        component: AdminMainComponent
      },
      {
        path: 'manage-subscriptions',
        component: AdminSubscribersComponent
      },
      {
        path: 'manage-subscriptions/new',
        component: SubscriptionEditorComponent
      },
      {
        path: 'manage-subscriptions/edit/:subscriptionId',
        component: SubscriptionEditorComponent
      },
      {
        path: 'manage-readers/:mode',
        component: AdminReadersComponent
      },
      {
        path: 'manage-users',
        component: AdminUsersComponent
      },
      {
        path: 'manage-collections',
        component: AdminCollectionsComponent
      },
      {
        path: 'manage-books',
        component: AdminBooksComponent
      },
      {
        path: 'book/edit/:bookId',
        component: BookEditorComponent
      },
      {
        path: 'book/new',
        component: BookEditorComponent
      }
    ]
  },
  {
    path: 'superAdmin',
    canActivate: [AuthenticationAdminGuardInterceptor],
    children: [
      {
        path: 'manage-tenants',
        component: TenantsManagementComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
