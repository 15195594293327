import {ChangeDetectionStrategy, Component, Input, OnInit, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, catchError, empty, throwError} from 'rxjs';
import {ApplicationUtilsService} from '../../../shared/services/application-utils.service';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ErrorResponseCodes} from "../../../shared/app-interfaces";
import {HttpErrorResponse} from "@angular/common/http";
import { RestorePasswordComponent } from '../restore-password-template/restore-password.component';

enum FormFields {
  'password' = 'password'
}
@Component({
  selector: 'app-google-bind',
  templateUrl: './google-bind.component.html',
  styleUrls: ['./google-bind.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GoogleBindComponent implements OnInit {
  public form: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(false);
  public readonly FormFields = FormFields;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {googleToken: string, email: string},
    public formBuilder: FormBuilder,
    public utils: ApplicationUtilsService,
    public api: ApplicationApiService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<GoogleBindComponent>
    ) {
  }

  public ngOnInit(): void {
    this.buildBindForm();
  }

  public buildBindForm(): void {
    this.form = this.formBuilder.group({
      [FormFields.password]: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.api.googleBind(this.form.controls[FormFields.password].value, this.data.googleToken).pipe(
      catchError((err: HttpErrorResponse): any => {
      if (err?.error === ErrorResponseCodes.WRONG_PASSWORD) {
        this.utils.showSnackbar('bind_accounts_wrong_password_error');
      }
      return throwError(() => err);
    })).subscribe(res => {
        this.utils.showSnackbar('bind_accounts_success_msg');
        this.dialogRef.close();
      });
  }

  resetPassword(): void {
    this.dialog
      .open(RestorePasswordComponent, {
        maxWidth: '500px',
        width: '100%',
        autoFocus: false,
        data: {email: this.data.email}
      });
  }

  cancel(){
    this.dialogRef.close();
  }
}
