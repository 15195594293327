import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject, catchError, finalize, first, skip, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { Book } from 'src/app/shared/app-interfaces';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { ApplicationUtilsService, CustomIcons } from 'src/app/shared/services/application-utils.service';
import { ApplicationAuthService } from 'src/app/shared/services/application-auth.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';

const BACKDROP_THRESHOLD = 1100; // px

export enum BookType {
  PDF = 'pdf',
  EPUB = 'epub'
}

@Component({
  selector: 'app-book-reader',
  templateUrl: './book-reader.component.html',
  styleUrls: ['./book-reader.component.scss']
})
export class BookReaderComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') public sidenav: MatSidenav;

  public readonly BookType = BookType;
  public readonly currentBook$: BehaviorSubject<Book>;
  public loading$ = new BehaviorSubject<boolean>(false);

  public readonly customIcons = CustomIcons;
  public bookType: BookType;
  public sidenavOverMode = window.innerWidth < BACKDROP_THRESHOLD;

  private subs: Subscription[] = [];

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    public state: ApplicationStateService,
    private cookieService: CookieService,
    private applicationAuthService: ApplicationAuthService, // Not in use but probably important for authentication directly on book reader
    public router: Router,
    public utils: ApplicationUtilsService,
  ) {
    this.currentBook$ = state.currentBook$;

    this.subs.push(this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.cookieService.set('token', params['token'], { path: '/', domain: `.${environment.domain}.com` });
        this.cookieService.set('_net.core.pub', params['token'], { path: '/', domain: `.${environment.domain}.com` });

        const credentials = {token: params['token']};
        sessionStorage.setItem('credentials', JSON.stringify(credentials));

        this.router.navigate([], {
          queryParams: {
            ['token']: null
          },
          queryParamsHandling: 'merge'
        })
      }
    }));
  }

  ngOnInit(): void {
    this.loading$.next(true);
    this.subs.push(this.currentBook$
      .pipe(
        catchError((err: HttpErrorResponse): any => {
          this.utils.showSnackbar('book_reader_load_book_error_msg');
        }),
        finalize(() => {
          this.loading$.next(false);
        }))
      .subscribe((book: Book) => {
        if (book && !this.bookType) {
          this.title.setTitle(book.title);
          this.bookType = book?.extension === '.epub' ? BookType.EPUB : BookType.PDF;
        }
        if (book) {
          this.loading$.next(false);
        }
      })
    );

    if (!this.state.consentCheck$.value && !this.state.consentDialogOpened$.value) {
      this.state.checkConsent();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  // public openPage(pageId: number): any {
  //   switch (this.bookType) {
  //     case BookType.PDF: {
  //       this.pdfReaderService.currentPage = pageId;
  //       this.pdfReaderService.changePage();
  //       this.pdfReaderService.loadRangeOfPages(pageId);

  //       break;
  //     }
  //     case BookType.EPUB: {

  //       break;
  //     }
  //   }

  //   this.hideSidenav();
  // }

  public switchSidenavModeOnResize(event): void {
    const prevState = this.sidenavOverMode;
    this.sidenavOverMode = event?.target?.innerWidth < BACKDROP_THRESHOLD;

    if (prevState !== this.sidenavOverMode && this.sidenavOverMode) {
      this.hideSidenav();
    } else if (!this.sidenavOverMode && !this.sidenav.opened) {
      this.sidenav.open();
    }
  }

  public hideSidenav(): void {
    this.sidenav?.close();
  }
}
