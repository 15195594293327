import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';


/**
 * Prefixes all requests with `environment.serverUrl` or `environment.nodeServerUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('assets/') === -1) {
      if (window.location.hostname.indexOf('localhost') === -1) {
        request = request.clone({ url: environment.apiUrl + request.url });
      }
    }
    request = request.clone({ withCredentials: true });
    return next.handle(request);
  }
}
