import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageLimitOptions } from '../../app-global-variables';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Input() public page: any;
  @Input() public size: any;
  @Input() public count: any;
  @Output() changePage = new EventEmitter<string>();
  @Output() changeLimit = new EventEmitter<number>();

  public pageLimitOptions = PageLimitOptions;
  public pageSize = 25;
  private countValue = 1;
  private pageValue = 1;
  pages: any;

  constructor() {
  }

  ngOnInit(): void {
    this.pageValue = this.page;
    this.pageSize = this.size;
    this.countValue = this.count;
    this.calcPages();
  }

  get totalPages(): number {
    const count = this.size < 1 ? 1 : Math.ceil(this.count / this.pageSize);
    return Math.max(count || 0, 1);
  }

  canPrevious(): boolean {
    return this.page > 1;
  }

  canNext(): boolean {
    return this.page < this.totalPages;
  }

  prevPage(): void {
    this.selectPage(this.page - 1);
  }

  nextPage(): void {
    this.selectPage(this.page + 1);
  }

  selectPage(page): void {
    if (page > 0 && page <= this.totalPages && page !== this.page) {
      this.page = page;
      this.changePage.emit(page);
    }
    this.calcPages();
  }

  calcPages(page?): any {
    const pages = [];
    let startPage = 1;
    let endPage = this.totalPages;
    const maxSize = 5;
    const isMaxSized = maxSize < this.totalPages;
    page = page || this.page;
    if (isMaxSized) {
      startPage = page - Math.floor(maxSize / 2);
      endPage = page + Math.floor(maxSize / 2);
      if (startPage < 1) {
        startPage = 1;
        endPage = Math.min(startPage + maxSize - 1, this.totalPages);
      } else if (endPage > this.totalPages) {
        startPage = Math.max(this.totalPages - maxSize + 1, 1);
        endPage = this.totalPages;
      }
    }
    for (let num = startPage; num <= endPage; num++) {
      pages.push({
        number: num,
        text: num
      });
    }
    return this.pages = pages;
  }

  onLimitChange(limit: any): any {
    this.pageSize = Number(limit);
    this.changeLimit.emit(limit);
    this.totalPages;
    this.calcPages()
  }
}
