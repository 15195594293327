<div class="datatable-pager">
  <div *ngIf="count > 0" class="page-limit-selection">
    <mat-select
      [value]="pageSize"
      style="width: 200px;"
      class="global-select"
      (valueChange)="onLimitChange($event)">
      <mat-option
        *ngFor="let option of pageLimitOptions"
        [value]="option.value"
      >
        {{ option.value }} {{ 'pagination_on_page' | translate }}
      </mat-option>
    </mat-select>
  </div>

  <ul *ngIf="count > 0 && (count / pageSize) > 1" class="pager">
    <li [class.disabled]="!canPrevious()">
      <a role="button" aria-label="go to first page" href="javascript:void(0)" (click)="selectPage(1)">
        <i class="datatable-icon-prev"></i>
      </a>
    </li>
    <li [class.disabled]="!canPrevious()">
      <a role="button" aria-label="go to previous page" href="javascript:void(0)" (click)="prevPage()">
        <i class="datatable-icon-left"></i>
      </a>
    </li>
    <li
      role="button"
      [attr.aria-label]="'page ' + pg.number"
      class="pages"
      *ngFor="let pg of pages"
      [class.active]="pg.number === page"
    >
      <a href="javascript:void(0)" (click)="selectPage(pg.number)">
        {{ pg.text }}
      </a>
    </li>
    <li [class.disabled]="!canNext()">
      <a role="button" aria-label="go to next page" href="javascript:void(0)" (click)="nextPage()">
        <i class="datatable-icon-right"></i>
      </a>
    </li>
    <li [class.disabled]="!canNext()">
      <a role="button" aria-label="go to last page" href="javascript:void(0)" (click)="selectPage(totalPages)">
        <i class="datatable-icon-skip"></i>
      </a>
    </li>
  </ul>
</div>
