<mat-dialog-content>
  <h3 class="dialog-title">{{ data?.title | translate }}</h3>
</mat-dialog-content>

<mat-dialog-actions>
  
  <div *ngIf="data.mode === 'subscriber' && data.isEdit">
    <button mat-icon-button (click)="delete()">
      <mat-icon style="color: #BDBDBD;">delete</mat-icon>
    </button>
  </div>

  <div *ngIf="data.mode === 'editUser'" style="transform: translateX(-10px)">
    <button mat-button (click)="withdraw()">
      <span style="color: #BDBDBD;">{{'withdraw_button_text' | translate}}</span>
    </button>
  </div>

  <div *ngIf="!data?.hideButtons" class="buttons">
    <button *ngIf="!data?.hideCancel" class="global-button default" (click)="cancel()">
      {{ (data?.noButtonTitle || 'dialog_options_cancel') | translate }}
    </button>
    <button type="submit" *ngIf="data.mode !== 'history' && !data?.hideSave" class="global-button" (click)="save()">
      {{ (data?.yesButtonTitle || 'dialog_options_save') | translate }}
    </button>
  </div>
</mat-dialog-actions>
