import {Component, Input, OnInit} from '@angular/core'

@Component({
  selector: 'app-book-contents',
  templateUrl: './book-contents.component.html',
  styleUrls: ['./book-contents.component.scss']
})
export class BookContentsComponent implements OnInit {
  @Input() bookContents: any;

  constructor() { }

  ngOnInit(): void {
  }

}
