import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {format} from 'date-fns';
import {BehaviorSubject, catchError, finalize, first, switchMap, combineLatest} from 'rxjs';
import {ConfirmDialogComponent} from 'src/app/shared/components/confirm-dialog/component';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {ApplicationUtilsService, CustomIcons} from '../../../shared/services/application-utils.service';
import {PageLimitOptions} from '../../../shared/app-global-variables';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import { EditUserComponent } from './edit-form-template/edit-user.component';
import { CreateAdminFormComponent } from './create-admin-form-template/create-admin-form.component';
import { EditPasswordComponent } from './edit-user-password-template/edit-password.component';

class Page {
  limit = 0;
  totalElements = 0;
  totalPages = 0;
  offset = 0;
}

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {
  @ViewChild('table') table: DatatableComponent;

  CustomIcons = CustomIcons;
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public pageLimitOptions = PageLimitOptions;
  temp = [];
  rows: any;
  columns = [];
  page = new Page();
  sort: any = [{field: 'email', order: 'asc'}];
  role = 'Admin';

  public oldSearchValue: string;

  constructor(
    private dialog: MatDialog,
    public state: ApplicationStateService,
    public api: ApplicationApiService,
    public utils: ApplicationUtilsService,
    public router: Router,
    public cd: ChangeDetectorRef
  ) {
    this.page.offset = 0;
    this.page.limit = 25;
    this.state.adminsSearchString$.subscribe(searchString => {
      this.setPage({offset: 0});
    });
  }

  ngOnInit(): void {
  }

  refresh(): any {
    this.setPage({offset: 0});
  }

  setPage(pageInfo): any {
    this.isLoading$.next(true);
    this.page.offset = pageInfo.offset;
    if (this.state.adminsSearchString$.value.length === 0) {
    this.state.getPaginatedUsers(this.page.limit, this.page.offset * this.page.limit, this.role).subscribe((res) => {
      const {data, page} = res;
      this.rows = [...data?.map(user => {
          return {...user, tenantShortDTOs: user.tenantShortDTOs.map(tenant => tenant.name)};
      })];
      this.page.totalElements = page.totalElements;
      this.page.totalPages = page.totalElements / this.page.limit >= 1 ? page.totalElements / this.page.limit : 1;
      this.isLoading$.next(false);
      this.cd.markForCheck();
      });
    } else {
      this.getFilteredPaginatedUsers();
    }
  }

  onLimitChange(limit: any): any {
    this.page.limit = Number(limit);
    this.refresh();
  }

  formattedDate(date): any {
    return date ? format(new Date(date), 'dd.MM.yyyy') : "";
  }

  createUser(): any {
    return this.dialog
      .open(CreateAdminFormComponent, {
        maxWidth: '500px',
        width: '100%',
        autoFocus: false
      })
      .afterClosed().subscribe((res) => {
        if(res == null){
          this.setPage({offset: this.page.offset});
        }
      });
  }

  editUser(row: any): any {
    return this.dialog
      .open(EditUserComponent, {
        maxWidth: '500px',
        width: '100%',
        data: {user: row, title: 'edit_user_form_title'}
      })
      .afterClosed().subscribe((res) => {
        if (res) {
          row.email = res.email;
          row.name = res.name;
        }
      });
  }

  deleteUser(id: string): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        maxWidth: '500px',
        width: '100%',
        autoFocus: false,
        data: {text: 'delete_user_confirm_text', title: 'delete_user_confirm_title'}
      })
      .afterClosed()
      .pipe(first()).subscribe(yes => {
      if (!yes) {
        return;
      }
      this.isLoading$.next(true);
      this.api.deleteUser$(id)
        .pipe(
          first(),
          catchError((): any => {
            this.utils.showSnackbar('delete_user_error_msg');
          }),
          finalize(() => this.isLoading$.next(false)),
          switchMap(() => {
            return this.state.getPaginatedUsers(this.page.limit, this.page.offset * this.page.limit, this.role);
          })
        ).subscribe(() => {
        this.isLoading$.next(false);
        this.setPage({offset: this.page.offset});
        this.utils.showSnackbar('delete_user_success_msg');
      });
    });
  }

  changePassword(row: any): any {
    return this.dialog
      .open(EditPasswordComponent, {
        maxWidth: '500px',
        width: '100%',
        disableClose: true,
        autoFocus: false,
        data: {user: row}
      });
  }

  resendInvitation(id: string): any {
    this.isLoading$.next(true);
    this.api.resendInvitation(id)
      .pipe(
        first(),
        catchError((): any => {
          this.utils.showSnackbar('resend_invitation_error_msg');
        }),
        finalize(() => this.isLoading$.next(false))
      ).subscribe(() => {
      this.isLoading$.next(false);
      this.utils.showSnackbar('resend_invitation_success_msg');
    });
  }

  executeSearch(searchString: string): void {
    if (searchString !== this.oldSearchValue) {
      this.oldSearchValue = searchString;
      this.state.adminsSearchString$.next(searchString);
    }
  }

  getFilteredPaginatedUsers(): void {
    const body = {
      sort: {
        column: 'name',
        order: 'asc'
      },
      filter: [
        {
          column: 'Email',
          method: 'contains',
          data: this.state.adminsSearchString$.value
        }
      ]
    };
    this.state.getPaginatedUsers(this.page.limit, this.page.offset * this.page.limit, this.role, body).subscribe((res) => {
      const {data, page} = res;
      this.rows = [...data?.map(user => {
        return {...user, tenantShortDTOs: user.tenantShortDTOs.map(tenant => tenant.name)};
      })];
      this.page.totalElements = page.totalElements;
      this.page.totalPages = page.totalElements / this.page.limit >= 1 ? page.totalElements / this.page.limit : 1;
      this.isLoading$.next(false);
      this.cd.markForCheck();
    });
  }
}
