import {Component, Input, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {BehaviorSubject, catchError, finalize, first} from 'rxjs';
import { User } from 'src/app/shared/app-interfaces';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { ApplicationUtilsService } from 'src/app/shared/services/application-utils.service';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {HttpErrorResponse} from '@angular/common/http';

enum FormFields {
  code = 'code'
}

@Component({
  selector: 'app-edit-user-license-template',
  templateUrl: 'edit-user-license-template.component.html',
  styleUrls: ['edit-user-license-template.component.scss']
})

export class EditUserLicenseComponent implements OnInit {
  @Input() public user: User;
  @Input() public closeDialog: any;

  public readonly FormFields = FormFields;
  public form: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    public formBuilder: FormBuilder,
    public state: ApplicationStateService,
    public api: ApplicationApiService,
    public utils: ApplicationUtilsService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder?.group({
        [FormFields.code]: ['', Validators.required]
      });
  }

  public getFormControl(field: FormFields): FormControl {
    return this.form.get(field) as FormControl;
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }
    this.loading$.next(true);
    this.api.addUserLicense(
      this.user,
      this.form.value[FormFields.code]).pipe(first()
    ).pipe(
      catchError((e: HttpErrorResponse): any => {
        this.utils.showSnackbar(this.utils.translate('Handle_change_user_data_error_msg'), 10000);
      }),
      finalize(() => this.loading$.next(false))
    ).subscribe(res => {
      this.utils.showSnackbar(this.utils.translate('Handle_change_user_data_success_msg'));
    });
  }
}
