import {Component, Input, OnDestroy} from '@angular/core';
import {BehaviorSubject, catchError, finalize, Subscription, combineLatest} from 'rxjs';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import {ApplicationUtilsService, CustomIcons} from '../../../shared/services/application-utils.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {ApplicationAuthService} from '../../../shared/services/application-auth.service';
import {compareAsc} from "date-fns";
import { AddUserToSubscriberDialogComponent } from '../edit-user-data-template/add-user-to-subscriber-dialog-template/add-user-to-subscriber-dialog.component';

@Component({
  selector: 'app-my-subscriptions-dialog-template',
  templateUrl: 'my-subscriptions-dialog-template.component.html',
  styleUrls: ['my-subscriptions-dialog-template.component.scss']
})

export class MySubscriptionsDialogComponent implements OnDestroy {
  public loading$ = new BehaviorSubject<boolean>(false);
  public readonly CustomIcons = CustomIcons;
  rows: any;
  public tabSelectedIndex$ = new BehaviorSubject(0);
  private subs: Subscription[] = [];
  constructor(
    public state: ApplicationStateService,
    public utils: ApplicationUtilsService,
    public dialog: MatDialog,
    public api: ApplicationApiService,
    public auth: ApplicationAuthService,
    private dialogRef: MatDialogRef<MySubscriptionsDialogComponent>
  ) {

    this.subs.push(this.tabSelectedIndex$.subscribe(selectedIndex => {
      if (selectedIndex) {
        this.state.getUserPurchases(this.auth.credentials.user_id);
      } else {
        this.state.getUserSubscribers(this.auth.credentials.user_id);
      }
    }));

    this.subs.push(this.state.userProfileSubscribers$.subscribe((res: any) => {
      if (!this.tabSelectedIndex$.value) {
        const {data, page} = res;
        this.rows = data;
      }
    }));

    this.subs.push(this.state.userProfilePurchases$.subscribe((res: any) => {
      if (this.tabSelectedIndex$.value) {
        const {data, page} = res;
        this.rows = data;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }
  addSubscriber(): any {
    return this.dialog
      .open(AddUserToSubscriberDialogComponent, {
        maxWidth: '500px',
        width: '100%',
        autoFocus: false,
        data: { userUid: this.auth.credentials.user_id }
      });
  }

  removeUserFromSubscriber(subscriberUid: string): any {
    this.api.removeUserFromSubscriber$(this.auth.credentials.user_id, subscriberUid).pipe(
      catchError((err): any => {
        return;
      }),
      finalize(() => {})
    ).subscribe(res => {
      this.state.getUserSubscribers(this.auth.credentials.user_id);
      this.utils.showSnackbar('Connections with subscriber was severed');
    });
  }

  bookAvailable(enabled, end): boolean {
    if (!enabled) {
      return false;
    } else if (!end) {
      return true;
    }
    return compareAsc(new Date(), new Date(end)) !== -1;
  }

  close(): void{
    this.dialogRef.close();
  }
}
