import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, catchError, finalize, first, Subscription, switchMap} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Book} from '../../../shared/app-interfaces';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {ApplicationUtilsService, CustomIcons} from '../../../shared/services/application-utils.service';
import {ConfirmDialogComponent} from '../../../shared/components/confirm-dialog/component';

enum FormFields {
  uid = 'uid',
  title = 'title',
  authors = 'authors',
  collections = 'collections',
  bibliographicTitle = 'bibliographicTitle',
  isbn = 'isbn',
  annotation = 'annotation',
  recommended = 'recommended',
  publicationTitle = 'publicationTitle',
  tableContents = 'tableContents',
  countPages = 'countPages',
  publicationYear = 'publicationYear',
  price = 'price',
  bookBinding = 'bookBinding',
  format = 'format',
  imageTmp = 'imageTmp',
  isFree = 'isFree',
  hasPaperBack = 'hasPaperBack',
  digital = 'digital',
  digital6m = 'digital6m',
  paperBack = 'paperBack',
  isPublished = 'isPublished'
}

@Component({
  selector: 'app-book-editor',
  templateUrl: './book-editor.component.html',
  styleUrls: ['./book-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BookEditorComponent implements OnInit, OnDestroy {
  public readonly FormFields = FormFields;
  public readonly InputImageStringUrl = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%23979797FF' stroke-width='2' stroke-dasharray='5%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`;
  CustomIcons = CustomIcons;
  public form: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(false);
  public loadingFile$ = new BehaviorSubject<boolean>(false);
  public coverBook: string;
  public uploadedFileName;
  public bookHasLoadedSuccessfully$ = new BehaviorSubject(false);
  file: any;

  private subs: Subscription[] = [];
  public getBookImageStyleForBackgroundImageByUid(uid: string): string {
    return `url("${environment.apiUrl}/api/v1/book/image/?uid=${uid}"`;
  }

  constructor(
    public state: ApplicationStateService,
    public formBuilder: FormBuilder,
    public api: ApplicationApiService,
    public utils: ApplicationUtilsService,
    public router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
  //  this.state.getClassification();
    this.uploadedFileName = this.state.currentBook$?.value?.loadedFileName || '';
    this.subs.push(
      this.route.paramMap.subscribe(params => {
        this.state.routeParamBookId$.next(params?.get('bookId'));
      })
    );
    this.state.currentBook$.subscribe((book: Book) => {
      if (book?.uid) {
        this.state.bookEditorStep$.next(1);
      } else {
        this.state.bookEditorStep$.next(0);
      }
      this.form = this.formBuilder.group({
        [FormFields.uid]: [book?.uid || ''],
        [FormFields.title]: [book?.title || '', Validators.required],
        [FormFields.authors]: [book?.authors || '', Validators.required],
        [FormFields.collections]: [book?.collections?.map(c => c.uid) || []],
        [FormFields.bibliographicTitle]: [book?.bibliographicTitle || ''],
        [FormFields.isbn]: [book?.isbn || ''],
        [FormFields.publicationYear]: [book?.publicationYear || ''],
        [FormFields.publicationTitle]: [book?.publicationTitle || ''],
        [FormFields.format]: [book?.format || ''],
        [FormFields.bookBinding]: [book?.bookBinding || ''],
        [FormFields.countPages]: [book?.countPages || ''],
        [FormFields.annotation]: [book?.annotation || ''],
        [FormFields.tableContents]: [book?.tableContents || ''],
        [FormFields.recommended]: [book?.recommendation || ''],
        [FormFields.imageTmp]: [book?.imageTmp || ''],
        [FormFields.isFree]: [book?.isFree],
        [FormFields.hasPaperBack]: [book?.hasPaperBack],
        [FormFields.digital]: [{value: book?.price?.digital || 0, disabled: book?.isFree}],
        [FormFields.digital6m]: [{value: book?.price?.digital6m || 0, disabled: book?.isFree}],
        [FormFields.paperBack]: [{value: book?.price?.paperBack || 0, disabled: !book?.hasPaperBack}],
        [FormFields.isPublished]: [book?.isPublished ?? true]
      });
      if(this.getFormControl(FormFields.bibliographicTitle).value == ''){
        this.getFormControl(FormFields.bibliographicTitle).disable();
      } else {
        this.getFormControl(FormFields.bibliographicTitle).enable();
      }
    });
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  public getFormControl(field: FormFields): FormControl {
    return this.form.get(field) as FormControl;
  }

  get currentBook(): Book {
    return this.form.value;
  }

  coverBrowseHandler(files): void {
    this.loadingFile$.next(true);
    if (files.length === 0) {
      this.loadingFile$.next(false);
      return;
    }
    this.uploadCover(files);
  }

  uploadCover(files): any {
    const reader = new FileReader();

    if (files && files.length) {
      const [file] = files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.coverBook = reader.result as string;
        const uid = this.getFormControl(FormFields.uid).value;
        this.api.uploadBookCover(files[0], uid)
          .pipe(
            catchError((err: HttpErrorResponse): any => {
              this.utils.showSnackbar('upload_file_error_msg');
            }),
            finalize(() => {
              this.loadingFile$.next(false);
            }))
          .subscribe((res) => {
            if (!uid) {
              const imageUIDArray = res;
              this.form.patchValue({
                imageTmp: imageUIDArray
              });
            }
          });
      };
    }
  }

  /*  fileBrowseHandler(files): void {
      this.loadingFile$.next(true);
      if (files.length === 0) {
        this.loadingFile$.next(false)
        return
      }
      this.uploadFile(files);
    }

    public uploadFile(files): any {
      const uid = this.getFormControl(FormFields.uid).value;
      this.api.uploadBookFile(files[0], uid)
        .pipe(
          catchError((err: HttpErrorResponse): any => {
            this.utils.showSnackbar('upload_file_error_msg');
          }),
          finalize(() => {
            this.loadingFile$.next(false);
          }))
        .subscribe((res) => {
          if (!uid) {
            this.form.patchValue({
              sourceTmp: res
            });
          }
          this.uploadedFileName = files[0].name;
          this.bookHasLoadedSuccessfully$.next(true);
        });
    }*/

  save(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    if (!this.form.controls[FormFields.isPublished].value) {
      this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        autoFocus: false,
        data: {text: 'book_editor_unpublished_save_confirm_text', title: 'book_editor_unpublished_save_confirm_title'}
      })
        .afterClosed()
        .pipe(first()).subscribe(yes => {
        if (!yes) {
          return;
        } else {
          this.utils.showSnackbar('book_editor_unpublished_save_success_msg');
          this.saveBook();
          }
      });
    } else {
        this.saveBook();
    }
  }

  saveBook(): void {
    this.loading$.next(true);
    
    this.api.patchBook$(
      {
        ...this.form.value, collections: this.form.controls[FormFields.collections]?.value?.map(uid => {
          return {uid};
        }),
        bibliographicTitle: this.form.value.bibliographicTitle ? this.form.value.bibliographicTitle : '',
        price: this.compilePrice()
      }, this.form.value?.uid
    )
      .pipe(
        first(),
        catchError((err): any => {
          if (err.error === 'ISBN_is_already_taken') {
            this.form.controls[FormFields.isbn].setErrors({notUnique: true});
            this.utils.showSnackbar('ISBN_is_already_taken_error_message');
          } else if (err.error.errors['model?.ISBN'][0] === 'too_long_string') {
              this.form.controls[FormFields.isbn].setErrors({tooLong: true});
              this.utils.showSnackbar('ISBN_is_too_long_error_message');
          } else {
              this.utils.showSnackbar('save_book_error_msg');
            }
        }),
        finalize(() => this.loading$.next(false)),
        switchMap(() => {
          return this.state.getBooks(true);
        })
      )
      .subscribe(() => {
        this.state.getCollections();
        this.utils.showSnackbar('save_book_success_msg');
        this.router.navigate(['admin/manage-books']);
      });
  }
  cancel(): void {
    this.router.navigate(['admin/manage-books']);
  }

  next(): void {
    this.state.bookEditorStep$.next(1);
  }

  changeBookEditorStep(): void {
    this.state.bookEditorStep$.next(0);
  }

  public fileBrowseHandler(files): void {
    this.loading$.next(true);
    if (files) {
      this.uploadFile(files);
    } else {
      this.uploadFile([undefined]);
    }
  }

  public uploadFile(files?): any {
    if (!files && !this.form.value?.uid) {
      return;
    }
    if (!files) {
      this.api.uploadBookFile(undefined).pipe(
        catchError((err: HttpErrorResponse): any => {
          this.utils.showSnackbar('upload_file_error_msg');
        }),
        finalize(() => {
          this.loading$.next(false);
        }));
    } else if (files && !this.form.value?.uid) {
      this.api.uploadBookFile(files[0])
        .pipe(
          catchError((err: HttpErrorResponse): any => {
            this.utils.showSnackbar('upload_file_error_msg');
          }),
          finalize(() => {
            this.loading$.next(false);
          }))
        .subscribe((sourceTmp) => {
          const loadedFileName = sourceTmp.loadedFileName;
          this.uploadedFileName = sourceTmp.loadedFileName;
          this.form.patchValue({loadedFileName});
          this.form.patchValue({uid: sourceTmp.uid});
          if (sourceTmp.extention === '.epub') {
            if (!this.state.currentBook$?.value?.uid) {
              this.state.currentBook$.next(sourceTmp);
            } else {
              this.state.currentBook$.next({
                ...this.state.currentBook$.value,
                hasImage: sourceTmp.hasImage,
                authors: sourceTmp.authors,
                title: sourceTmp.title, annotation: sourceTmp.annotation
              });
            }
          }
          this.bookHasLoadedSuccessfully$.next(true);
        });
    } else if (files && this.form.value?.uid) {
      this.api.uploadBookFile(files[0], this.form.value?.uid)
        .pipe(
          catchError((err: HttpErrorResponse): any => {
            this.utils.showSnackbar('upload_file_error_msg');
          }),
          finalize(() => {
            this.loading$.next(false);
          }))
        .subscribe((sourceTmp) => {
          const loadedFileName = sourceTmp.loadedFileName;
          this.uploadedFileName = sourceTmp.loadedFileName;
          this.form.patchValue({loadedFileName});
          if (sourceTmp.extention === '.epub') {
            this.state.currentBook$.next({
              ...this.state.currentBook$.value,
              hasImage: sourceTmp.hasImage,
              authors: sourceTmp.authors,
              title: sourceTmp.title, annotation: sourceTmp.annotation
            });
          }
          this.bookHasLoadedSuccessfully$.next(true);
        });
    }
  }

  openBook(book: Book): void {
    if (book.hasAccessRead) {
      window.open(`/book/${book.uid}/read`);
    }
  }

  toggleControl(fields: FormFields[]): void {
    for (const field of fields) {
      if (this.getFormControl(field)?.status === 'DISABLED') {
        this.getFormControl(field).enable();
      } else {
        this.getFormControl(field).disable();
      }
    }
  }

  compilePrice(): any {
    const digital = this.getFormControl(FormFields.digital)?.value || 0;
    const digital6m = this.getFormControl(FormFields.digital6m)?.value || 0;
    const paperBack = this.getFormControl(FormFields.paperBack)?.value || 0;
    return {digital, digital6m, paperBack};
  }
}
