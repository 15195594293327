<mat-dialog-content>
  <h3 class="dialog-title">{{ data?.title | translate }}</h3>
  <div class="edit-user-form-template" style="position: relative">
    <div *ngIf="loading$ | async" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>

    <form [formGroup]="form">
      <div>
        <label class="global-label">{{ 'label_custom_name' | translate }}</label>
        <input class="global-input" [formControlName]="FormFields.name" type="text"
          placeholder="{{'label_custom_name' | translate}}">
      </div>

      <div>
        <label class="global-label">{{ 'label_email' | translate }}</label>
        <input class="global-input" [formControlName]="FormFields.email" type="email"
          placeholder="{{'input_placeholder_email' | translate}}">
      </div>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="buttons">
    <button class="global-button default" (click)="cancel()">
      {{ 'dialog_options_cancel' | translate }}
    </button>
    <button class="global-button" (click)="save()">
      {{ 'dialog_options_save' | translate }}
    </button>
  </div>
</mat-dialog-actions>