import {Component, Input, OnInit} from '@angular/core';
import {Book} from '../../../shared/app-interfaces';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {ApplicationUtilsService, CustomIcons} from '../../../shared/services/application-utils.service';


@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {
  CustomIcons = CustomIcons;

  @Input() book: Partial<Book>;
  image: any;

  constructor(
    public state: ApplicationStateService,
    public api: ApplicationApiService,
    public utils: ApplicationUtilsService
  ) {
  }

  ngOnInit(): void {}
}
