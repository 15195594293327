import { Component } from '@angular/core';

@Component({
  selector: 'app-publishers-banner',
  templateUrl: './publishers-banner.component.html',
  styleUrls: ['./publishers-banner.component.scss']
})
export class PublishersBannerComponent {

  constructor() {}

}
