<div *ngIf="{
               books: state.ownBooks$ | async,
               collections: state.collections$ | async,
               subscribers: state.subscribers$ | async,
               currentSubscriber: state.currentSubscriber$ | async,
               currentSubscription: state.currentSubscription$ | async,
               subscriptionMode: state.subscriptionMode$ | async,
               createSubscriptionStep: state.createSubscriptionStep$ | async } as data"
     class="global-main-cnt global-flex-column-start-end">
  <div class="edit-form-cnt" style="position: relative;">
    <div class="global-title">
      {{(data.currentSubscription ? 'subscription_editor_edit_subscription_title' : 'subscription_editor_add_subscription_title') | translate}}
    </div>
    <div class="first-step" *ngIf="!data.currentSubscription && data.createSubscriptionStep === 0">
      <mat-radio-group [value]="data.subscriptionMode" class="radio-group">
        <mat-radio-button [value]="'regular'"
                          [color]="'primary'"
                          [ngClass]="{'selected': data.subscriptionMode === 'regular'}"
                          class="subscription-mode-container"
                          (click)="toggleSubscriptionMode('regular')">
          <span>
            {{'admin_subscribers_regular_tab_label' | translate}}
          </span>
          <span style="max-width: 500px; white-space: normal">{{'admin_subscribers_regular_description' | translate}}</span>
        </mat-radio-button>
        <mat-radio-button [value]="'individual'"
                          [color]="'primary'"
                          [ngClass]="{'selected': data.subscriptionMode === 'individual'}"
                          class="subscription-mode-container"
                          (click)="toggleSubscriptionMode('individual')">
          <span>
            {{'admin_subscribers_individual_tab_label' | translate}}
          </span>
          <span style="max-width: 500px; white-space: normal">{{'admin_subscribers_individual_description' | translate}}</span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="second-step" *ngIf="data.currentSubscription && data.subscriptionMode || data.createSubscriptionStep === 1">
      <div class="subscribers-edit-form-template"
         style="position: relative; padding-top: 16px;">
      <div *ngIf="loading$ | async" class="global-spinner-cnt">
        <mat-spinner color="primary" diameter="50"></mat-spinner>
      </div>
      <form [formGroup]="form" class="global-flex-column-space-between-start">
        <div class="form-field-cnt global-flex-column-space-between-start">
          <span class="global-label">{{'subscription_editor_issuedTo_label' | translate}}</span>
          <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
            <mat-select [formControlName]="FormFields.subscriber" [placeholder]="'subscription_editor_biblio_select_placeholder' | translate">
              <mat-select-trigger>
                <div>{{data.currentSubscriber?.title || data.currentSubscription?.subscriber?.title}}</div>
              </mat-select-trigger>
              <mat-option (click)="createSubscriber()">
                <div style="display: flex; align-items: center">
                  <mat-icon style="margin-right: 10px">add</mat-icon>
                  <span>{{'subscription_editor_biblio_select_new' | translate}}</span>
                </div>
              </mat-option>
              <mat-option *ngFor="let subscriber of data.subscribers" [value]="subscriber.uid">
                <div class="global-flex-row-space-between-center">
                    <span (click)="changeCurrentSubscriber(subscriber.uid)" style="flex: 1">
                      {{subscriber.title}}
                    </span>
                  <button mat-icon-button class="edit-subscriber-button" (click)="editSubscriber(subscriber.uid)">
                    <mat-icon style="margin-right: 0; color: #BDBDBD">edit</mat-icon>
                  </button>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="data.subscriptionMode === 'regular'" class="secondary-fields-cnt global-flex-row-space-between-start">
          <div class="form-field-cnt global-flex-column-space-between-start">
            <span class="global-label">{{'subscription_editor_created_label' | translate}}</span>
            <mat-form-field [floatLabel]="'never'" class="global-mat-form-field datepicker">
              <input matInput [formControlName]="FormFields.begin" [matDatepicker]="pickerCreated" placeholder="DD/MM/YYYY">
              <mat-datepicker-toggle matSuffix [for]="pickerCreated"></mat-datepicker-toggle>
              <mat-datepicker #pickerCreated></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="form-field-cnt global-flex-column-space-between-start" style="margin-right: 0">
            <span class="global-label">{{'subscription_editor_end_label' | translate}}</span>
            <mat-form-field [floatLabel]="'never'" class="global-mat-form-field datepicker">
              <input matInput [formControlName]="FormFields.end" [matDatepicker]="pickerEnd" placeholder="DD/MM/YYYY">
              <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
              <mat-datepicker #pickerEnd></mat-datepicker>
            </mat-form-field>
          </div>

        </div>

        <div *ngIf="data.subscriptionMode === 'individual'" class="form-field-cnt global-flex-row-space-between-start">
          <div class="form-field-cnt global-flex-column-space-between-start">
            <span class="global-label">{{'subscription_editor_duration_label' | translate}}</span>
            <mat-form-field [floatLabel]="'never'" class="global-mat-form-field datepicker">
              <mat-select [formControlName]="FormFields.individualLicenseDurationMonths"
                          [placeholder]="'subscription_editor_individual_duration_placeholder' | translate">
                <mat-option [value]="0">
                  {{'admin_subscribers_individual_duration_unlimited_text' | translate}}
                </mat-option>
                <mat-option [value]="1">
                  {{'Six months'}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="data.subscriptionMode === 'regular'" class="form-field-cnt" style="align-items: center; flex-direction: row">
          <div class="global-flex-column-space-between-start" style="flex: 1">
            <span class="global-label">{{'subscription_editor_price_label' | translate | currency:state.tenantCurrency$.value}}</span>
            <mat-form-field [floatLabel]="'never'"
                            class="global-mat-form-field">
              <input [formControlName]="FormFields.price"
                     matInput type="number"
                     [placeholder]="'subscription_editor_price_placeholder' | translate">
            </mat-form-field>
          </div>
          <mat-icon [matTooltip]="'subscription_price_info' | translate" class="price-help-icon">help</mat-icon>
        </div>


        <div *ngIf="data.subscriptionMode === 'regular' || data.subscriptionMode === 'individual'" class="form-field-cnt global-flex-column-space-between-start">
          <span class="global-label">{{'subscription_editor_remark_label' | translate}}</span>
          <textarea
            matInput cdkTextareaAutosize
            [formControlName]="FormFields.remark"
            [placeholder]="'subscription_editor_remark_placeholder' | translate"
          ></textarea>
        </div>
      </form>
    </div>
      <div *ngIf="data.subscriptionMode === 'regular' || data.subscriptionMode === 'individual'">
      <div style="margin-top: 20px">
        {{('subscription_editor_collection_label' | translate) + ': ' +
      getCollectionsAndBooksIds('collections')?.length + ' (' +
      ('subscription_editor_collection_unique_books' | translate) +
      getCollectionsAndBooksIds('booksInCollections')?.length + ')'}}
        <br>
        {{('subscription_editor_collection_different_books' | translate) +
      getCollectionsAndBooksIds('books')?.length}}
      </div>

      <div class="form-field-cnt global-flex-column-space-between-start" style="padding-top: 6px;">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
          <mat-tree-node *matTreeNodeDef="let node;" matTreeNodeToggle>
            <button mat-icon-button disabled></button>
            <mat-checkbox *ngIf="node.title !== 'subscription_editor_all_books'"
                          class="checklist-leaf-node"
                          [checked]="node?.rowNode?.selected"
                          [color]="'primary'"
                          [disabled]="getParentNode(node)?.isSelectEntireCollection || isBookAlreadySelected(node)"
                          (change)="leafItemSelectionToggle(node)">{{node.title}}</mat-checkbox>

            <mat-checkbox *ngIf="node.title === 'subscription_editor_all_books'"
                          class="checklist-leaf-node"
                          style="font-weight: 500"
                          [checked]="node?.rowNode?.selected || descendantsAllSelected(getParentNode(node))"
                          [color]="'primary'"
                          [disabled]="getParentNode(node)?.isSelectEntireCollection"
                          (change)="itemSelectionToggle(getParentNode(node))">{{node.title | translate}}</mat-checkbox>
          </mat-tree-node>

          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div class="mat-tree-node">
              <button mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'Toggle ' + node.title">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <div class="global-flex-row-space-between-center">
                <mat-checkbox *ngIf="node?.title !== 'out_of_collection'"
                              [checked]="node.isSelectEntireCollection"
                              [color]="'primary'"
                              [indeterminate]="descendantsPartiallySelected(node) || (descendantsAllSelected(node) && !node.isSelectEntireCollection)"
                              (change)="itemEntireCollectionSelectionToggle(node)"
                              style="margin-right: 10px">
                </mat-checkbox>
                <div style="display: flex;">
                  <span>{{node.title | translate}}</span>
                  <span *ngIf="data.subscriptionMode === 'individual'" style="color: red">{{node?.rowNode?.price}}</span>
                  <p *ngIf="node.isSelectEntireCollection" style="margin:0 0 0 10px">
                    {{'subscription_editor_collection_entire_collection_selected' | translate}}</p>
                </div>
              </div>
            </div>
            <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                 role="group">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </mat-nested-tree-node>
        </mat-tree>
      </div>
    </div>
    </div>

  </div>
  <div class="buttons-cnt global-flex-row-end-center">
    <button class="global-button grey default" mat-flat-button type="button" (click)="cancel()" *ngIf="data.currentSubscription || data.createSubscriptionStep === 0">
      <span>{{'cancel_button' | translate}}</span></button>
    <button class="global-button grey default" mat-flat-button type="button" (click)="previous()" *ngIf="!data.currentSubscription && data.createSubscriptionStep === 1">
      <span>{{'previous_button' | translate}}</span></button>
    <button class="global-button green" mat-flat-button type="submit" (click)="next()" *ngIf="!data.currentSubscription && data.createSubscriptionStep === 0" [disabled]="!data.subscriptionMode">
      <span>{{'next_button' | translate}}</span></button>
    <button class="global-button green" mat-flat-button type="submit" (click)="save()" *ngIf="data.currentSubscription || data.createSubscriptionStep === 1">
      <span>{{'save_button' | translate}}</span></button>
  </div>

  <div class="buttons-cnt global-flex-row-end-center" *ngIf="data.currentSubscription">
  </div>
</div>
