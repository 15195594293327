import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, catchError, finalize, first, map, Observable, of} from 'rxjs';
import {Credentials, UserRoles} from '../app-interfaces';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ApplicationApiService} from './application-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApplicationUtilsService} from './application-utils.service';
import {FormGroup} from '@angular/forms';
import {AuthorizationComponentMode} from '../../components/authorization/authorization.component';
import { CookieService } from 'ngx-cookie-service';
import {MatDialog} from '@angular/material/dialog';

export enum AuthorizationFormFields {
  name= 'name',
  userName = 'userName',
  password = 'password',
  repeatPassword = 'repeatPassword',
  code = 'code',
  consent = 'consent',
  rememberMe = 'remember_me'
}

@Injectable({
  providedIn: 'root'
})

export class ApplicationAuthService {

  public readonly credentialsSubject = new BehaviorSubject<Credentials>(null);
  public adminMode$ = new BehaviorSubject<boolean>(undefined);
  public superAdminMode$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = new BehaviorSubject<boolean>(undefined);
  public user_id$ = new BehaviorSubject<string>(undefined);
  public haveBooksByIp$ = new BehaviorSubject<boolean>(false);
  public globalLoading$ = new BehaviorSubject<boolean>(false);
  public loginOrRegistrationErrorMode$ = new BehaviorSubject<boolean>(false);
  public authorizationComponentMode$ = new BehaviorSubject(AuthorizationComponentMode.Authorization);
  public consentCheck$ = new BehaviorSubject<boolean>(false);

  public set credentials(credentials: Credentials) {
    this.credentialsSubject.next(credentials);
    this.isAuthenticated$.next(!!credentials);
    this.adminMode$.next(credentials?.role === UserRoles.Admin || credentials?.role.split(',').includes(UserRoles.Admin));
    this.superAdminMode$.next(credentials?.role.split(',').includes(UserRoles.SuperAdmin));
    this.user_id$.next(credentials?.user_id);
  }
  get credentials(): Credentials {
    return this.credentialsSubject.value;
  }

  constructor(
    private router: Router,
    public api: ApplicationApiService,
    public utils: ApplicationUtilsService,
    private cookieService: CookieService,
    public dialog: MatDialog,
    public zone: NgZone
  ) {

      this.api.checkBooksByIp$().subscribe(res => {
        this.haveBooksByIp$.next(res);
      });

/*      this.api.getMyIpBooks$().subscribe(res => {
        console.log(res);
      });*/

      this.getCredentials$().subscribe(credentials => {
        this.credentials = credentials;
      });
  }
  // public checkEmailNotTaken(newEmail: string): Observable<boolean> {
  //   if (!newEmail) {
  //     return of(true);
  //   }
  //   newEmail = newEmail.toLowerCase().trim();
  //   return this.httpClient.get(`/check-email?email=${encodeURIComponent(newEmail)}`).pipe(
  //     map(res => res),
  //     map(res => !res)
  //   );
  // }

  // resetPassword(model: { email: string }): Observable<any> {
  //   return this.httpClient.post('/send-reset-password-info', model);
  // }
  //
  // changePassword(model: Partial<ChangePasswordData>): Observable<any> {
  //   return this.httpClient.post('/reset-password', model);
  // }
  //
  // changePasswordByCode(model: Partial<ChangePasswordData>): Observable<any> {
  //   return this.httpClient.post('/reset-password-by-code', model);
  // }

  public getCredentials$(): Observable<Credentials> {
    const savedCredentialsParsed = this.tryToGetParsedCredentialsFromStorage();
    if (savedCredentialsParsed && (new Date(savedCredentialsParsed.expires) > new Date())) {
      return this.api.verifyToken$().pipe(first(), map((credentialsFromServer) => {
        return this.normalizeCredentials(credentialsFromServer);
      }));
    } else {
      return of(null);
    }
  }


  public tryToGetParsedCredentialsFromStorage(): Credentials {
    let savedCredentialsParsed: Credentials = null;
    const savedCredentials =
      sessionStorage.getItem('credentials') || localStorage.getItem('credentials');
    if (savedCredentials) {
      try {
        savedCredentialsParsed = JSON.parse(savedCredentials);
      } catch (err) {
        this.utils.showSnackbar('using_storage_token_error_msg', 10000);
      }
    }
    return savedCredentialsParsed;
  }

  registerReader(payload: {
    name: string;
    userName: string;
    password: string;
    code: string;
  }): void {
    this.globalLoading$.next(true);
    this.api.registerUser(payload).pipe(
      first(),
      catchError((error: HttpErrorResponse): any => {
        if (error.error[0]?.code === 'CodeNotFound') {
          this.utils.showSnackbar('registration_error_code_msg', 5000);
        } else {
          this.utils.showSnackbar('registration_error_msg', 5000);
        }
      }),
      finalize(() => {
        this.globalLoading$.next(false);
      })
    ).subscribe(credentials => {
      this.setCredentialsToStorage(this.normalizeCredentials(credentials));
      this.credentials = this.normalizeCredentials(credentials);
      window.document.body.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
      this.router.navigate(['']);
    });
  }

  public confirmAdminRegistration(payload: {
    name: string
    uid: string;
    token: string;
    newPassword: string;
  }): any {
    this.globalLoading$.next(true);
    this.api.confirmAdminRegistration$(payload)
      .pipe(
        catchError((error) => {
          if (error.error[0]?.code === 'CodeNotFound') {
            this.utils.showSnackbar('registration_error_code_msg', 5000);
          } else if(error.error[0]?.code == 'InvalidToken'){
            this.utils.showSnackbar('registration_invalid_token_msg', 5000);
          } else {
            this.utils.showSnackbar('registration_error_msg', 5000);
          }
          return of(error);
        }),
        finalize(() => this.globalLoading$.next(false))
      )
      .subscribe((credentials) => {
        this.setCredentialsToStorage(this.normalizeCredentials(credentials));
        this.credentials = this.normalizeCredentials(credentials);
        window.document.body.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        this.router.navigate(['/admin/manage-books']);
      });
  }
/*  public setSelectedTenant = (uid: string): void => {
    this.cookieService.set('active_tenant_id', uid, {path: '/'});
  }*/

  public authorizeUser(formData: FormGroup): void {
    if (formData.invalid) { return; }
    this.globalLoading$.next(true);
    const rememberMe = formData.controls[AuthorizationFormFields.rememberMe].value;
    this.api.authorizeUser(formData.value).pipe(
      catchError((error: any): any => {
        this.utils.showSnackbar('authorization_error_msg', 10000);
        this.loginOrRegistrationErrorMode$.next(true);
      }),
      finalize(() => this.globalLoading$.next(false))
    ).subscribe(credentialsData => {
      this.login(credentialsData, rememberMe);
    });
  }

  public login(credentialsData: any, rememberMe: boolean): void {
    // const activeTenantId = this.cookieService.get('active_tenant_id');
    const tenants = credentialsData.tenants;
    const setCredentials = () => {
      const credentials: Credentials = this.normalizeCredentials(credentialsData);
      this.credentials = credentials ?? null;
      if (credentials) {
        const storage = rememberMe ? localStorage : sessionStorage;
        storage.setItem('credentials', JSON.stringify(credentials));
      } else {
        sessionStorage.removeItem('credentials');
        localStorage.removeItem('credentials');
      }
      this.credentials = credentials;
      this.globalLoading$.next(false);
      this.zone.run(() => {
        this.router.navigate((this.superAdminMode$.value ? ['..', 'superAdmin', 'manage-tenants'] : this.adminMode$.value ? ['..', 'admin', 'manage-main'] : ['']));
      });
    };

/*    if (credentialsData.role === 'Admin') {
      if (!activeTenantId && tenants.length > 1) {
        this.zone.run(() => {
          this.dialog
            .open(FormDialogComponent, {
              maxWidth: '500px',
              width: '100%',
              autoFocus: false,
              data: {tenants, mode: 'selectAccount', title: 'select_account_dialog_title', hideButtons: true}
            }).afterClosed().subscribe(() => {
              if (this.cookieService.get('active_tenant_id')) {
                setCredentials();
              }
          });
        });
        return;
      }
      if (!activeTenantId) {
        this.setSelectedTenant(tenants[0].uid);
      }
    }*/
    setCredentials();
  }

  public logout(): any {
    this.globalLoading$.next(true);
    this.api.logOut$().pipe(
      catchError((err: any): any => {
        this.utils.showSnackbar('logout_error_msg', 10000);
        // this.state.globalSpinner$.next(false);
      }),
      finalize(() => {
        this.globalLoading$.next(false);
      })
    ).subscribe(() => {
        this.cookieService.deleteAll();
        this.setCredentialsToStorage();
        this.router.navigate(['']);
        this.consentCheck$.next(false);
    });
  }

  public verifyToken(): any {
    this.api.verifyToken$();
  }

  public isAuthenticated(): boolean {
    return !!this.credentials;
  }

  public setCredentialsToStorage(credentials?: Credentials, remember?: boolean): void {
    this.credentials = credentials || null;
    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem('credentials', JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem('credentials');
      localStorage.removeItem('credentials');
    }
  }

  normalizeCredentials(credentialsData: any): Credentials {
    return {
      userName: credentialsData.userName,
      name: credentialsData.custom_name,
      token: credentialsData.access_token,
      issued: credentialsData['.issued'],
      expires: credentialsData['.expires'],
      role: credentialsData.role,
      user_id: credentialsData.user_id,
      tenants: credentialsData.tenants
    };
  }
}
