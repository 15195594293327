<main class="global-main-cnt" *ngIf="{
      isLoading$: isLoading$ | async
   } as data">
  <section class="publisher-info">
    <h2>{{'admin_main_first_step_title' | translate}}</h2>
    <h3><b>{{'admin_main_first_step' | translate}}</b> {{'admin_main_first_step_description' | translate}}</h3>
<!--    <div class="publisher-status">
      <span>
        {{'admin_main_status' | translate}}
        <span class="publisher-status-value" [ngClass]="{
        'published': form.controls['published'].value === true,
        'unpublished': form.controls['published'].value === false}">
          {{ (form.controls['published'].value ? 'admin_main_published' : 'admin_main_unpublished') | translate}}
        </span>
      </span>

      #todo Uncomment when the publishers page is ready (the list is displayed)
      <a routerLink="/publishers">{{'admin_main_publishers_catalogue_link' | translate}}</a>
    </div>-->
    <form id="form" [formGroup]="form" (ngSubmit)="submit()" class="edit-publisher-form">
      <div class="col col-0">
        <div class="img-container"
          [ngStyle]="{'height': !!logoSrc ? 'auto' : '97px', 'background' : logoSrc ? 'transparent' : '#F6F7F9'}">
          <img [src]="logoSrc" alt=""/>
          <mat-icon *ngIf="!logoSrc" class="material-icons add-img-ico">add_a_photo</mat-icon>
          <input type="file" accept=".png,.jpeg,.jpg,.svg" (change)="handleLogoChange($event.target.files)" />
        </div>
        <div class="img-input-hints">
          <span>{{'book_editor_load_or_drag_hint' | translate}}</span>
          <span>{{'book_editor_format_hint' | translate}}</span>
        </div>
      </div>
      


      <div class="col col-1">
        <div style="display: flex; flex-direction: row; vertical-align: top;">
          <div class="input-container" style="width: 70%; height: min-content;">
            <label [ngClass]="{'ng-invalid ng-touched': nameField.invalid && nameField.touched}">
              {{'admin_main_publisher_name_label' | translate}}
            </label>
            <input id="name" class="global-input" name="name" formControlName="name" type="text">
          </div>

          <div class="input-container catalog-path-input-container" style="width: 30%; margin-left: 10px;">
            <label
             [ngClass]="{'ng-invalid ng-touched': catalogPathField.invalid && catalogPathField.touched}">
              <a [href]="'/' + currentPublisher?.catalogPath" target="catalog">{{'admin_main_publisher_catalog_path_label'| translate}}</a>
            </label>
            <input id="catalogPath" class="global-input" formControlName="catalogPath" name="catalogPath"
             type="text" placeholder="{{'admin_main_publisher_catalog_path_placeholder' | translate}}" pattern="[a-zA-Z0-9]{1,50}">
            <span style="color: #E86F00" *ngIf="catalogPathField.invalid">
              {{'admin_main_publisher_catalog_path_pattern_hint' | translate}}
            </span>
         </div>

        </div>
        <div class="input-container">
          <label>{{'admin_main_publisher_profile_label' | translate}}</label>
          <input id="category" class="global-input" formControlName="category" type="text">
        </div>

        <div class="input-container">
          <label>{{'admin_main_publisher_description_label' | translate}}</label>
          <quill-editor bounds="self" #editor [styles]="{
              'font-size': '14px',
              'border-radius': '3px',
              'font-family': 'Open Sans, Roboto, Helvetica, Arial, sans-serif'
            }" [modules]="{ toolbar: [
            [{ 'header': [1, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'align': [] }],
            ['link', 'image']
            ]}" id="description" name="description" formControlName="description"
            placeholder="{{'admin_main_publisher_description_placeholder' | translate}}"
            [debounceTime]="700"></quill-editor>
        </div>

        <div style="display: flex; flex-direction: row; flex-wrap: nowrap;">
          <div class="input-container" style="width: 60%;">
            <label
              [ngClass]="{'ng-invalid ng-touched': emailField.invalid && emailField.touched}">{{'admin_main_publisher_email_label'
              |
              translate}}</label>
            <input id="email" class="global-input" name="email" formControlName="email" type="email"
              placeholder="example@mail.com">
          </div>

          <div class="input-container" style="width: 40%; margin-left: 10px;">
            <label
              [ngClass]="{'ng-invalid ng-touched': phoneField.invalid && phoneField.touched}">{{'admin_main_publisher_phone_label'
              |
              translate}}</label>
            <input id="phone" class="global-input" name="phone" formControlName="phone" type="text"
              placeholder="{{'admin_phone_example_placeholder' | translate}}">
          </div>
        </div>

        <div class="input-container currency-input-container">
          <label
           [ngClass]="{'ng-invalid ng-touched': currencyField.invalid && currencyField.touched}">{{'admin_main_publisher_currency_label'
           | translate}}</label>
              <input id="currency" class="global-input" formControlName="currency" name="currency"
                type="text" placeholder="{{'admin_main_publisher_currency_placeholder' | translate}}" maxlength="3"
                [matAutocomplete]="auto" (input)="filter()" (focus)="filter()"/>
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredCurrencies" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
          <span style="color: #E86F00;" *ngIf="currencyField.invalid">
            {{'admin_main_publisher_currency__pattern_hint' | translate}}
          </span>
       </div>

<!--        <div class="publish-status-checkbox">
          <mat-checkbox id="published" name="published" formControlName="published" color="primary"
            disableRipple="true">{{'admin_main_publisher_form_checkbox' | translate}}</mat-checkbox>
        </div>-->

        <button [disabled]="isSubmitDisabled" class="custom-button"
          type="submit">{{'admin_main_publisher_form_submit_button' | translate}}</button>
      </div>
    </form>
  </section>

  <section class="books-collections-subscriptions">
    <h2>{{'admin_main_second_step_title' | translate}}</h2>
    <h3><b>{{'admin_main_second_step' | translate}}</b> {{'admin_main_second_step_description' | translate}}</h3>
    <table>
      <tr>
        <td>{{'admin_main_uploaded_books' | translate}}</td>
        <td>{{ totalBooksAmount || ('admin_main_empty_cell_placeholder' | translate) }}</td>
        <td>
          <button mat-icon-button class="edit-ico-button" routerLink="/admin/manage-books">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </tr>
      <tr>
        <td>{{'admin_main_collections' | translate}}</td>
        <td>{{ totalCollectionsAmount || ('admin_main_empty_cell_placeholder' | translate) }}</td>
        <td>
          <button mat-icon-button class="edit-ico-button" routerLink="/admin/manage-collections">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </tr>
      <tr>
        <td>{{'admin_main_subscriptions' | translate}}</td>
        <td>{{ totalSubscriptionsAmount || ('admin_main_empty_cell_placeholder' | translate) }}</td>
        <td>
          <button mat-icon-button class="edit-ico-button" routerLink="/admin/manage-collections">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </tr>
    </table>
  </section>

  <section class="create-website">
    <h2>{{'admin_main_create_website_title' | translate}}</h2>
    <p>{{'admin_main_create_website_description_first_part' | translate}}</p>
    <p>{{'admin_main_create_website_description_second_part' | translate}}</p>
    <p>{{'admin_main_create_website_description_third_part' | translate}}</p>

    <!-- <span>{{'admin_main_create_website_try_it_text' | translate}}</span> -->
    <a href="{{ 'admin_main_mailto_order_website' | translate}}" class="custom-button">{{'admin_main_create_website_button_text' | translate}}</a>

  </section>
</main>
