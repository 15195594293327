<div class="global-main-cnt global-flex-column-space-between-center"
     *ngIf="{ mode: authService.authorizationComponentMode$ | async,
      loading: authService.globalLoading$ | async,
      loginOrRegistrationErrorMode: authService.loginOrRegistrationErrorMode$ | async
       } as data">
  <ng-container  *ngIf="data.mode !== ComponentMode.Registered; else registeredTemplate">
    <div class="authorization-cnt global-flex-row-space-between-center">
      <div class="logo-img-cnt">
        <img src="assets/login-picture.png" alt="logo">
      </div>
      <div class="form-cnt global-flex-column-space-between-center">
        <span class="form-title">{{ (((data.mode === ComponentMode.Registration ? 'registration_title' : 'authorization_title') | translate)) | uppercase }}</span>
<!--        <img src="assets/logo.png" alt="logo">-->
        <!-- <mat-icon [svgIcon]="CustomIcons.logo" style="width: 100%;height: 100px;"></mat-icon> -->
        <form
          [formGroup]="formGroup"
          class="global-flex-column-space-between-start"
          [ngClass]="{ 'login-error': data.loginOrRegistrationErrorMode }"
          style="gap: 5px;">
          <div *ngIf="data.mode === ComponentMode.Registration || data.mode === ComponentMode.ConfirmRegistration" class="global-flex-column-center-start full-width">
            <span class="global-label">{{'authorization_custom_name_label' | translate}}</span>
            <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
              <input matInput [formControlName]="FormFields.name" type="text" [placeholder]="'authorization_custom_name_placeholder' | translate">
            </mat-form-field>
          </div>

          <div class="global-flex-column-center-start full-width">
            <span class="global-label">{{'label_email' | translate}}</span>
            <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
              <input matInput [formControlName]="FormFields.userName" type="email"
                     name="userName"
                     [autocomplete]="formGroup.controls[FormFields.rememberMe]?.value || data.mode !== ComponentMode.Authorization ? 'userName' : null"
                     [placeholder]="'input_placeholder_email' | translate">
            </mat-form-field>
          </div>

          <div class="global-flex-column-center-start full-width">
            <span class="global-label">{{'authorization_password_label' | translate}}</span>
            <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
              <input matInput type="password" name="current-password" [autocomplete]="'current-password'" [formControlName]="FormFields.password" [placeholder]="'authorization_password_placeholder' | translate">
            </mat-form-field>
          </div>
          
          <div class="global-flex-column-center-start full-width">
            <mat-checkbox *ngIf="data.mode === ComponentMode.Authorization" [formControlName]="FormFields.rememberMe" [color]="'primary'">
              <span>{{ 'authorization_remember_me' | translate }}</span>
            </mat-checkbox>
          </div>

          <div *ngIf="data.mode !== ComponentMode.Authorization" class="global-flex-column-center-start full-width">
            <span class="global-label">{{'authorization_repeat_password_label' | translate}}</span>
            <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
              <input matInput type="password" [formControlName]="FormFields.repeatPassword" [placeholder]="'authorization_repeat_password_placeholder' | translate">
            </mat-form-field>
          </div>

          <div *ngIf="data.mode === ComponentMode.Registration && data.mode !== ComponentMode.ConfirmRegistration" class="global-flex-column-center-start full-width">
            <span class="global-label">{{'authorization_access_code_label' | translate}}</span>
            <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
              <input matInput [formControlName]="FormFields.code" [placeholder]="'authorization_access_code_placeholder' | translate">
            </mat-form-field>
          </div>

          <div class="global-flex-row-end-center full-width" *ngIf="data.loginOrRegistrationErrorMode && data.mode === ComponentMode.Authorization">
            <span class="error-message">{{ 'authorization_error_msg' | translate }}</span>
          </div>

          <div class="global-flex-row-end-center full-width"
            *ngIf="formGroup.controls[FormFields.userName]?.hasError('email')
             && formGroup.controls[FormFields.userName]?.value
             && formGroup.controls[FormFields.userName]?.touched;
            ">
            <span class="error-message">{{ 'authorization_email_error_msg' | translate }}</span>
          </div>
          <div class="global-flex-row-end-center full-width" *ngIf="formGroup.controls[FormFields.userName]?.hasError('emailTaken')">
            <span class="error-message">{{ 'user_name_is_already_taken_msg' | translate }}</span>
          </div>

          <div class="global-flex-row-end-center full-width"
            *ngIf="data.mode === ComponentMode.Registration &&
               !formGroup.controls[FormFields.userName]?.hasError('email') &&
               ((formGroup.controls[FormFields.password]?.hasError('minlength') && formGroup.controls[FormFields.password].touched)
               || (formGroup.controls[FormFields.repeatPassword]?.hasError('minlength') && formGroup.controls[FormFields.repeatPassword].touched))
            " >
            <span class="error-message">{{ 'authorization_password_error_msg' | translate }}</span>
          </div>

          <div class="global-flex-row-end-center full-width"
            *ngIf="data.mode === ComponentMode.Registration
               && !formGroup.controls[FormFields.userName]?.hasError('email')
               && formGroup.controls[FormFields.repeatPassword]?.hasError('MatchPassword')
               && formGroup.controls[FormFields.repeatPassword]?.value
               && formGroup.controls[FormFields.repeatPassword]?.touched
            ">
            <span class="error-message">{{ 'authorization_password_matching_error_msg' | translate }}</span>
          </div>

          <div class="global-flex-row-space-between-center" style="width: 100%">
            <button type="submit" *ngIf="data.mode !== ComponentMode.Authorization"
              [disabled]="data.loading" class="global-button green"
              (click)="submitRegistration()">
              <mat-icon>person</mat-icon>
              <span>{{ ('authorization_register_btn' | translate) | uppercase}}</span>
            </button>
          </div>

          <button
            type="submit"
            *ngIf="data.mode === ComponentMode.Authorization"
            [disabled]="data.loading"
            class="global-button green"
            (click)="authService.authorizeUser(formGroup)">
            <mat-icon>person</mat-icon>
            <span>{{ ('authorization_login_btn' | translate) | uppercase }}</span>
          </button>

          <!-- class="global-flex-row-space-between-center" -->
          <div
          class="forgot-register-block"
           *ngIf="data.mode === ComponentMode.Authorization"
           style="width: 100%">

           <!-- class="reset-password" -->
            <span  (click)="resetPassword(formGroup.controls[FormFields.userName]?.value)">
              {{'forgot_password' | translate}}
            </span>
            
            <!-- class="global-link-green" -->
            <span [routerLink]="'/registration'"
                style="width: 100%; text-decoration: none;">
                {{ 'registration_register_link' | translate }}
            </span>
          </div>
        
          <app-google-auth style="align-self: center"></app-google-auth>
        </form>
      </div>
    </div>
    <!-- <span style="text-align: center" *ngIf="data.mode !== ComponentMode.ConfirmRegistration">
      {{ 'registration_hint' | translate }}
    </span>
    <a *ngIf="data.mode === ComponentMode.Authorization" class="global-link-green" href="/registration">{{ 'registration_register_link' | translate }}</a> -->
  </ng-container>
  <ng-template #registeredTemplate>
    <a class="global-link-green" [routerLink]="
    ['..','authorization', { data: { email: formGroup.controls[FormFields.userName].value}}]"
    >
     {{ 'registration_succeed_msg' | translate }}
    </a>
  </ng-template>
</div>