import { Clipboard } from '@angular/cdk/clipboard';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit , Inject} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {BehaviorSubject, catchError, finalize, first} from 'rxjs';
import { IDialogContent } from 'src/app/shared/components/form-dialog/form-dialog.component';
import { ApplicationApiService } from '../../../../shared/services/application-api.service';
import { ApplicationStateService } from '../../../../shared/services/application-state.service';
import { ApplicationUtilsService, CustomIcons } from '../../../../shared/services/application-utils.service';
import {ConfirmDialogComponent} from '../../../../shared/components/confirm-dialog/component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Subscriber} from '../../../../shared/app-interfaces';

enum FormFields {
  title = 'title',
  code = 'code',
  contactPerson = 'contactPerson',
  address = 'address',
  phone = 'phone',
  ipAddresses = 'ipAddresses',
}


@Component({
  selector: 'app-edit-subscriber-template',
  templateUrl: './edit-subscriber.component.html',
  styleUrls: ['./edit-subscriber.component.scss'],
})

export class EditSubscriberComponent implements OnInit {
  public readonly CustomIcons = CustomIcons;
  public readonly FormFields = FormFields;
  public form: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {subscriberId: string},
    public formBuilder: FormBuilder,
    public state: ApplicationStateService,
    public api: ApplicationApiService,
    public utils: ApplicationUtilsService,
    private clipboard: Clipboard,
    public router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EditSubscriberComponent>
  ) {
  }

  public ngOnInit(): void {
    this.state.currentSubscriber$.subscribe((subscriber: Subscriber) => {
      this.form = this.formBuilder?.group({
        [FormFields.title]: [subscriber?.title || '', Validators.required],
        [FormFields.contactPerson]: [subscriber?.contactPerson || ''],
        [FormFields.address]: [subscriber?.address || ''],
        [FormFields.phone]: [subscriber?.phone || ''],
        [FormFields.code]: [subscriber?.code || '', Validators.required],
        [FormFields.ipAddresses]: this.formBuilder?.array(subscriber?.ipAddresses?.length > 0 ? subscriber.ipAddresses : ['']),
      });
    });
  }

  get ipAddresses(): any {
    return this.form.controls[FormFields.ipAddresses] as FormArray;
  }
  public getFormControl(field: FormFields): FormControl {
    return this.form.get(field) as FormControl;
  }

  save(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.loading$.next(true);
    this.api.postSubscriber$({
      ...
        this.form.value,
      ipAddresses: this.form.value.ipAddresses.filter(ip => ip.trim().length > 0)
    }, this.data?.subscriberId ?? undefined)
      .pipe(
        catchError((error: HttpErrorResponse): any => {
          if(error.error.errors.phone){
            this.utils.showSnackbar(error.error.errors.phone);
          } else {
            this.utils.showSnackbar('edit_subscriber_error_msg');
          }
        }),
        finalize(() => {
          this.loading$.next(false);
        })).subscribe((res) => {
          this.api.checkBooksByIp$().subscribe((result: boolean) => {
            this.state.haveBooksByIp$.next(result);
          });
          /*
          // if (res) {
          //   if (!this.subscriberId) {
          //     this.state.getSubscriber(res);
          //     this.utils.showSnackbar('create_subscriber_success_msg');
          //   } else {
          //     this.utils.showSnackbar('edit_subscriber_success_msg');
          //   }
          // }
          // if (!res && this.subscriberId) {
          //   this.utils.showSnackbar('edit_subscriber_success_msg');
          // }
          */

          if (res &&!this.data?.subscriberId) {
            this.state.getSubscriber(res);
            this.utils.showSnackbar('create_subscriber_success_msg');
          } else if (res && this.data?.subscriberId) {
            this.utils.showSnackbar('edit_subscriber_success_msg');
          }
          
          this.state.getSubscribers();
          this.dialogRef.close(res);
    });
  }

  delete(): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        disableClose: true,
        autoFocus: false,
        data: {text: 'delete_subscriber_confirm_text', title: 'delete_subscriber_confirm_title'}
      })
      .afterClosed()
      .pipe(first()).subscribe(yes => {
      if (!yes) {
        return;
      }
      this.loading$.next(true);
      this.api.deleteSubscriber$(this.state.currentSubscriber$.value.uid)
        .pipe(
          first(),
          catchError((): any => {
            this.utils.showSnackbar('delete_subscriber_error_msg');
          }),
          finalize(() => this.loading$.next(false)),
        ).subscribe((res) => {
          this.api.checkBooksByIp$().subscribe((result: boolean) => {
            this.state.haveBooksByIp$.next(result);
          });
          this.state.getSubscribers();
          this.state.getSubscriptions();
          this.utils.showSnackbar('delete_subscriber_success_msg');
          this.dialogRef.close();
      });
    });
  }

  copyCodeToClipboard(code: string): void {
    this.clipboard.copy(code);
    this.utils.showSnackbar('license_copy_code_to_clipboard_successfully');
  }

  generateCode(): void {
    this.api.generateKey().pipe(
      first(),
      catchError((err: HttpErrorResponse): any => {
        this.utils.showSnackbar('generation_code_error_msg', 5000);
      })).subscribe(result => {
      this.utils.showSnackbar('generation_code_success_msg');
      this.form.controls[FormFields.code]?.setValue(result);
    });
  }

  addIpAddress(): void {
    const newIp = this.formBuilder.control( '');
    this.ipAddresses.push(newIp);
  }

  deleteIp(index: number): void {
    this.ipAddresses.removeAt(index);
  }

  cancel():void{
    this.dialogRef.close(false);
  }
}