<main class="global-main-cnt">

  <section class="cooperation-options">
    <div class="col col-0">
      <img src="assets/publisher_registration_options.png" alt="">
    </div>

    <div class="col col-1">
      <p class="cooperation-options-list-item-text">{{'publisher_registration_elibrica_description' | translate}}
      </p>
      <p class="cooperation-options-list-item-text">
        {{'publisher_registration_free_trial_description' | translate}}
      </p>

      <a class="link-button" routerLink="/publishers" (click)="goToRegistrationForm()">
        {{'publisher_registration_free_trial_link_text' | translate}}
      </a>
    </div>
  </section>

  <section class="services-prices-and-partners">
    <div class="columns">
      <div class="col col-0 prices">
        <div class="col-ico-container">
          <img src="assets/svg-icons/users_ico.svg" alt="">
        </div>
        <div class="col-content">
          <h3 class="col-title">{{'publisher_registration_price_and_partners_title' | translate}}</h3>
          <p class="col-text"><b>80%</b>{{'publisher_registration_publisher_percentage' | translate}}</p>
          <p class="col-text"><b>-</b></p>
          <p class="col-text"><b>20%</b>{{'publisher_registration_elibrica_percentage' | translate}}</p>
        </div>
      </div>

      <div class="col col-1 abilities">
        <div class="col-ico-container">
          <img src="assets/svg-icons/opened_book_ico.svg" alt="">
        </div>
        <div class="col-content">
          <h3 class="col-title">{{'publisher_registration_abilities_title' | translate}}</h3>
          <p class="col-text">{{'publisher_registration_abilities_description' | translate}}</p>
        </div>
      </div>

      <div class="col col-3 create-website">
        <div class="col-ico-container">
          <img src="assets/svg-icons/display_ico.svg" alt="">
        </div>
        <div class="col-content">
          <h3 class="col-title">{{'publisher_registration_create_website_title' | translate}}</h3>
          <p class="col-text">{{'publisher_registration_create_website_description' | translate}}</p>
        </div>
      </div>
    </div>

    <!-- <div class="partners">
      <div class="partner-0">
        <img src="assets/partners_slack_logo.svg" alt="slack logo">
      </div>
      <div class="partner-1">
        <img src="assets/partners_kvadro_logo.png" alt="kvadro logo">
      </div>
      <div class="partner-2">
        <img src="assets/partners_lan_logo.svg" alt="lan logo">
      </div>
      <div class="partner-3">
        <img src="assets/partners_zoover_logo.svg" alt="zoover logo">
      </div>
    </div> -->
  </section>

  <section class="how-to-use">
    <h2>{{'publisher_registration_how_to_use_title' | translate}}</h2>
    <p>{{'publisher_registration_how_to_use_description' | translate}}</p>
    <div class="steps">
      <div class="step step-0">
        <span class="step-number">1</span>
        <span class="step-name">{{'publisher_registration_how_to_use_step_one' | translate}}</span>
      </div>

      <div class="step step-1">
        <span class="step-number">2</span>
        <span class="step-name">{{'publisher_registration_how_to_use_step_two' | translate}}</span>
      </div>

      <div class="step step-2">
        <span class="step-number">3</span>
        <span class="step-name">{{'publisher_registration_how_to_use_step_three' | translate}}</span>
      </div>

      <img class="arrow-img" src="assets/curly_arrow.svg" alt="">
    </div>
  </section>

  <app-publisher-registration-form></app-publisher-registration-form>
</main>
