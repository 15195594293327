<div class="global-main-cnt global-flex-column-space-between-center"
     *ngIf="{
      adminMode: state.adminMode$ | async,
      isLoading$: isLoading$ | async
   } as data">
  <div class="options">
    <div class="search">
      <mat-form-field
        [floatLabel]="'never'"
        class="global-mat-form-field"
      >
        <input
          #searchInput
          type="search"
          matInput
          [value]="searchString"
          [placeholder]="'admin_subscribers_search_placeholder' | translate"
          autocomplete="off"
          (keyup)="executeSearch(searchInput.value?.trim())"
        >
        <mat-icon matSuffix class="global-icon-inactive">search</mat-icon>
      </mat-form-field>
    </div>
    <div class="btn-section">
      <button mat-button
              (click)="createSubscription()">
        <mat-icon [svgIcon]="CustomIcons.plus"></mat-icon>
        <span>{{'admin_subscribers_create_subscription_button' | translate}}</span>
      </button>
    </div>
  </div>
  <div class="table" style="position: relative">
    <div style="text-align: right;width: 100%; margin-right: 0px;">
      {{'subscribtions_count_found' | translate}}{{page.totalElements}}
    </div>
    <ngx-datatable
      #table
      class="material expandable"
      [rows]="rows"
      [groupRowsBy]="'subscriberId'"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [virtualization]="false"
      [externalPaging]="true"
      [count]="table?.groupedRows?.length"
      [offset]="page.offset"
      [limit]="page.limit"
      [sorts]="[{prop: 'created', dir: 'asc'}]"
      (page)="setPage($event)"
      [groupExpansionDefault]="true"
      [messages]="{emptyMessage: this.utils.getTableMessage('empty_table_message' | translate)}"
    >
      <!-- Group Header Template -->
      <ngx-datatable-group-header [rowHeight]="50" #myGroupHeader>
        <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
          <div class="group-header">
            <span class="group-title">{{ group.value[0].subscriber.title }}</span>
            <div style="display: flex; position: sticky; right: 0">
              <button (click)="addSubscription(group.value[0].subscriberId)">
                <mat-icon>add</mat-icon>
              </button>
              <button (click)="editSubscriber(group.value[0].subscriberId)">
                <mat-icon style="margin: 0 6px 0 4px; color: #BDBDBD">edit</mat-icon>
              </button>
              <button class="copy-key" (click)="copyCode(group.value[0].subscriber.code)">
                <mat-icon>vpn_key</mat-icon>
              </button>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-group-header>

      <!-- Row Column Template -->
      <ngx-datatable-column [flexGrow]="1" [sortable]="false">
        <ng-template ngx-datatable-cell-template
                     let-rowIndex="rowIndex"
                     let-value="value"
                     let-row="row"
                     let-group="group">
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="1"  [name]="'column_header_status' | translate" prop="code" [sortable]="false">
        <ng-template ngx-datatable-cell-template
                     let-rowIndex="rowIndex"
                     let-value="value"
                     let-row="row"
                     let-group="group">
          <div *ngIf="row?.end" style="display: flex; flex-direction: row; align-items: center;">
            <mat-icon
              style="width: 20px; height: 20px; margin-right: 10px;"
              [svgIcon]="isExpired(row)
                ? CustomIcons.checkbox_failed_circle_outline
                : CustomIcons.checkbox_marked_circle_outline
              ">
            </mat-icon>
          </div>
          <span *ngIf="row?.emptyMessage" class="mobile-hidden empty-subscriber-message">{{ row?.emptyMessage }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="3" [name]="'column_header_duration' | translate" prop="duration" [sortable]="false">
        <ng-template ngx-datatable-cell-template
                     let-rowIndex="rowIndex"
                     let-value="value"
                     let-row="row"
                     let-group="group">
          <span *ngIf="row?.end && row.licenseType === 'Regular'" class="mobile-hidden">
            {{ utils.getformattedDate(row.begin) + '—' + utils.getformattedDate(row.end) + ' ' + ('admin_subscribers_days_left_label' | translate) + ' ' + getLeftDays(row)}}
          </span>
          <span *ngIf="row.licenseType === 'Individual'" class="mobile-hidden">
            {{ row?.individualLicenseDurationMonths === 0 ?  ('admin_subscribers_individual_duration_unlimited_text' | translate) :
               6 + ' ' + ('admin_subscribers_individual_duration_months_left_text' | translate) }}
          </span>
        </ng-template>
      </ngx-datatable-column>


      <ngx-datatable-column [flexGrow]="1" [name]="'column_header_price' | translate | currency:state.tenantCurrency$.value" prop="price" [sortable]="false">
        <ng-template ngx-datatable-cell-template
                     let-rowIndex="rowIndex"
                     let-value="value"
                     let-row="row"
                     let-group="group">
          <span class="mobile-hidden">{{ row?.price }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="1" [name]="'column_header_books_and_collections' | translate" prop="subscriptionComposition" [sortable]="false">
        <ng-template ngx-datatable-cell-template
                     let-rowIndex="rowIndex"
                     let-value="value"
                     let-row="row"
                     let-group="group">
          <div style="display: flex; flex-direction: column;">
            <span *ngIf="row?.collections"
                  class="mobile-hidden">{{ ('admin_subscribers_collections_label' | translate) + ' '+ row?.collections?.length }}</span>
            <span *ngIf="row?.books"
                  class="mobile-hidden">{{ ('admin_subscribers_books_label' | translate) + ' ' + row?.books?.length }}</span>
          </div>

        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="1" [name]="'column_header_remark' | translate" prop="remark" [sortable]="false">
        <ng-template ngx-datatable-cell-template
                     let-rowIndex="rowIndex"
                     let-value="value"
                     let-row="row"
                     let-group="group">
          <span *ngIf="row?.remark" class="mobile-hidden remark-container">{{ row?.remark }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [flexGrow]="1"
        [width]="50"
        [resizeable]="false"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="false"
      >
        <ng-template let-row="row" let-group="group" let-expanded="expanded" ngx-datatable-cell-template>
          <button *ngIf="!row?.emptyMessage" mat-icon-button [matMenuTriggerFor]="menu" class="table-edit-button">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editSubscription(row)">
              <mat-icon>edit</mat-icon>
              <span>{{'admin_subscribers_edit_button' | translate}}</span>
            </button>
            <button *ngIf="row.licenseType === 'Individual'" mat-menu-item (click)="toggleSubscriptionStatus(row)">
              <mat-icon>{{ row?.enabled ? 'lock' : 'lock_open' }}</mat-icon>
              <span>{{ (row?.enabled ? 'Disable' : 'Activate') | translate}}</span>

            </button>
            <button mat-menu-item (click)="deleteSubscription(row.uid)">
              <mat-icon>close</mat-icon>
              <span>{{'admin_subscribers_delete_button' | translate}}</span>
            </button>
            <button mat-menu-item (click)="openHistory(row)">
              <mat-icon>history</mat-icon>
              <span>{{'admin_subscribers_history_button' | translate}}</span>
            </button>
          </mat-menu>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer>
        <ng-template  ngx-datatable-footer-template>
          <div>Total: {{rows?.length}}</div>
        </ng-template>
      </ngx-datatable-footer>

    </ngx-datatable>
  </div>
</div>
