import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

import { Book } from 'src/app/shared/app-interfaces';
import { CustomIcons } from 'src/app/shared/services/application-utils.service';
import { CursorMode, PdfReaderService, ReaderMode } from 'src/app/shared/services/pdf-reader.service';
import { BookType } from '../book-reader.component';
import { EpubReaderService } from 'src/app/shared/services/epub-reader.service';

@Component({
    selector: 'app-book-reader-header',
    templateUrl: './book-reader-header.component.html',
    styleUrls: ['./book-reader-header.component.scss']
})
export class BookReaderHeaderComponent implements OnInit, OnDestroy {
    @Input() public type: BookType;
    @Input() public sidenav: MatSidenav;
    @Input() public book: Book;

    public readonly readerMode$ = this.pdfReaderService.readerMode$;
    public readonly cursorMode$ = this.pdfReaderService.cursorMode$;

    public readonly CustomIcons = CustomIcons;
    public readonly ReaderMode = ReaderMode;
    public readonly CursorMode = CursorMode;
    public readonly BookType = BookType;

    private subs: Subscription[] = [];

    constructor(
        public pdfReaderService: PdfReaderService,
        public epubReaderService: EpubReaderService,
        private cd: ChangeDetectorRef
    ) {
        this.readerMode$ = this.pdfReaderService.readerMode$;
        this.cursorMode$ = this.pdfReaderService.cursorMode$;
    }

    ngOnInit(): void {
      this.subs.push(this.epubReaderService.chapterRendered$.subscribe(() => {
        this.cd.markForCheck();
      }));
    }

    ngOnDestroy(): void {
        this.subs.forEach(x => x.unsubscribe());
    }

    public get currentPage() {
        switch (this.type) {
            case BookType.PDF: {
                return this.pdfReaderService.currentPage;
            }
            case BookType.EPUB: {
                return null;//this.epubReaderService.currentPage;
            }
        }
    }

    public get totalPages() {
        switch (this.type) {
            case BookType.PDF: {
                return this.book?.countLoadedPages;
            }
            case BookType.EPUB: {
                return null;//this.epubReaderService.totalPages;
            }
        }
    }

    public getZoomString() {
        switch (this.type) {
            case BookType.PDF: {
                return this.pdfReaderService.getZoomString();
            }
            case BookType.EPUB: {
                return this.epubReaderService.getZoomString();
                break;
            }
        }
    }

    public switchFullScreenMode() {
        switch (this.type) {
            case BookType.PDF: {
                this.pdfReaderService.switchFullScreenMode();
                break;
            }
            case BookType.EPUB: {

                break;
            }
        }
    }

    public dragToScroll() {
        switch (this.type) {
            case BookType.PDF: {
                this.pdfReaderService.dragToScroll();
                break;
            }
            case BookType.EPUB: {

                break;
            }
        }
    }

    public removeDragToScroll() {
        switch (this.type) {
            case BookType.PDF: {
                this.pdfReaderService.removeDragToScroll();
                break;
            }
            case BookType.EPUB: {

                break;
            }
        }
    }

    public zoomIn() {
        switch (this.type) {
            case BookType.PDF: {
                this.pdfReaderService.zoomIn(0.25);
                break;
            }
            case BookType.EPUB: {

                break;
            }
        }
    }

    public zoomOut() {
        switch (this.type) {
            case BookType.PDF: {
                this.pdfReaderService.zoomOut(0.25);
                break;
            }
            case BookType.EPUB: {

                break;
            }
        }
    }

    public prev() {
        switch (this.type) {
            case BookType.PDF: {
                this.pdfReaderService.prev();
                break;
            }
            case BookType.EPUB: {
                this.epubReaderService.prev();
                break;
            }
        }
    }

    public next() {
        switch (this.type) {
            case BookType.PDF: {
                this.pdfReaderService.next();
                break;
            }
            case BookType.EPUB: {
                this.epubReaderService.next();
                break;
            }
        }
    }
}
