import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {ApplicationAuthService} from './application-auth.service';
import {BehaviorSubject, catchError, map, Observable, of, skip, startWith, switchMap, tap} from 'rxjs';
import {ApplicationUtilsService} from './application-utils.service';
import {Credentials, UserRoles} from '../app-interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationAdminGuardInterceptor implements CanActivate {
  constructor(
    private router: Router,
    private authService: ApplicationAuthService,
    private utils: ApplicationUtilsService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (this.authService.isAuthenticated$.value && this.authService.adminMode$.value) {
      return of(true);
    }
    const credentials = this.authService.tryToGetParsedCredentialsFromStorage();
    if (credentials?.token && credentials?.role) {
      return of(credentials.role === UserRoles.Admin ||
        credentials?.role.split(',').includes(UserRoles.SuperAdmin) ||
        credentials?.role.split(',').includes(UserRoles.Admin));
    } else {
      return this.authService.verifyToken()?.pipe(map((credentialsFromServer: Credentials) => {
        this.authService.credentials = this.authService.normalizeCredentials(credentialsFromServer);
        return of(credentialsFromServer?.role === UserRoles.Admin);
      }),
      catchError((err: any): any => {
        this.utils.showSnackbar('verification_of_token_error_msg', 10000);
        this.router.navigate(['..', 'authorization']);
        return of(false);
      }));
    }
  }
}
