<mat-dialog-content>
  <h3 class="dialog-title">{{ 'edit_subscriber_dialog_title' | translate }}</h3>
  <div
    *ngIf="{books: state.books$ | async,
            collections: state.collections$ | async,
            currentSubscriber: state.currentSubscriber$ | async} as data"
    class="global-main-cnt global-flex-column-start-center"
  >
    <div class="edit-form-cnt" style="position: relative;">
      <div class="subscribers-edit-form-template"
          style="position: relative; padding-top: 16px;">
        <div *ngIf="loading$ | async" class="global-spinner-cnt">
          <mat-spinner color="primary" diameter="50"></mat-spinner>
        </div>
        <form [formGroup]="form" class="global-flex-column-space-between-start">
          <div class="form-field-cnt global-flex-column-space-between-start">
            <span class="global-label">{{'edit_subscriber_issuedTo_label' | translate}}</span>
            <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
              <input [formControlName]="FormFields.title" matInput type="text" [placeholder]="'edit_subscriber_issuedTo_placeholder' | translate">
            </mat-form-field>
          </div>


          <div class="form-field-cnt global-flex-column-space-between-start">
            <span class="global-label">{{'edit_subscriber_contact_person_label' | translate}}</span>
            <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
              <input [formControlName]="FormFields.contactPerson" matInput type="text" [placeholder]="'edit_subscriber_contact_person_placeholder' | translate">
            </mat-form-field>
          </div>

          <div class="form-field-cnt global-flex-column-space-between-start">
            <span class="global-label">{{'edit_subscriber_address_label' | translate}}</span>
            <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
              <input [formControlName]="FormFields.address" matInput type="text" [placeholder]="'edit_subscriber_address_placeholder' | translate">
            </mat-form-field>
          </div>

          <div class="form-field-cnt global-flex-column-space-between-start">
            <span class="global-label">{{'edit_subscriber_phone_label' | translate}}</span>
            <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
              <input [formControlName]="FormFields.phone" matInput type="text"  placeholder="+7(499)-999-99-99">
            </mat-form-field>
          </div>

          <div class="secondary-fields-cnt global-flex-row-space-between-start">
            <div class="form-field-cnt global-flex-column-space-between-start">
              <span class="global-label">{{'edit_subscriber_code_label' | translate}}</span>
              <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
                <input #code readonly [formControlName]="FormFields.code" matInput type="text"
                      [placeholder]="'edit_subscriber_generate_code_placeholder' | translate">
              </mat-form-field>
            </div>
            <div class="functions-btn-cnt global-flex-column-center-center" [ngClass]="{'key-hidden': isEdit}">
              <mat-icon
                class="generate-btn"
                [matTooltip]="'edit_subscriber_generate_new_code' | translate"
                (click)="generateCode()">
                vpn_key
              </mat-icon>
            </div>
            <div class="functions-btn-cnt global-flex-column-center-center">
              <mat-icon
                class="copy-btn"
                [matTooltip]="'edit_subscriber_copy_code' | translate"
                (click)="copyCodeToClipboard(code.value)">
                content_copy
              </mat-icon>
            </div>
          </div>

          <div class="form-field-cnt">
            <div class="global-flex-row-space-between-center">
              <span class="global-label">{{'edit_subscriber_ip_label' | translate}}</span>
              <mat-icon class="ip-help-icon" [matTooltip]="'subscriber_ip_info' | translate">
                help
              </mat-icon>
            </div>
            <div style="flex-wrap: wrap; gap: 10px" class="global-flex-row-start-center" id="subscriber-ips-container" [formArrayName]="FormFields.ipAddresses">
              <div *ngFor="let ipControl of ipAddresses.controls; let i = index" style="max-width: 217px">
                <mat-form-field [floatLabel]="'never'" class="global-mat-form-field" >
                  <input matInput type="text" [placeholder]="'edit_subscriber_ip_placeholder' | translate" [formControlName]="i">
                  <mat-icon matSuffix (click)="deleteIp(i)" class="ip-close-icon">close</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>

        <button mat-button (click)="addIpAddress()" class="add-ip-button green-color" id="add-ip-button">
          <mat-icon>add</mat-icon>
          <span>{{'edit_subscriber_add_ip_button' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="buttons">
    <button mat-icon-button (click)="delete()">
      <mat-icon style="color: #BDBDBD;">delete</mat-icon>
    </button>

    <button class="global-button default" (click)="cancel()">
      {{ 'dialog_options_cancel' | translate }}
    </button>
    <button type="submit" class="global-button" (click)="save()">
      {{ 'dialog_options_save' | translate }}
    </button>
  </div>
</mat-dialog-actions>