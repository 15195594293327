<div class="global-main-cnt">
  <h2 class="consent-title">
    {{'consent_title' | translate}}
  </h2>

  <div class="consent-container">

    <section class="consent-section">

      <h3 class="consent-section-title">{{ 'consent_first_section_title_part_one' | translate }} «{{domainName}}» {{ 'consent_first_section_title_part_two' | translate}}</h3>

      <p class="consent-section-description">{{ 'consent_first_section_description' | translate }}</p>

    </section>


    <section class="consent-section">

      <h3 class="consent-section-title">{{ 'consent_second_section_title' | translate }}</h3>

      <p class="consent-section-description">{{ 'consent_second_section_description_part_one' | translate }} «{{domainName}}» {{ 'consent_second_section_description_part_two' | translate }}<span style="font-weight: 700"> «{{ 'withdraw_button_text' | translate}}»</span>.</p>

    </section>

    <div class="global-flex-row-space-between-center">
      <p>{{ 'consent_accept_question_text' | translate }}</p>
      <button class="global-button" (click)="acceptConsent()">{{ 'dialog_button_accept' | translate }}</button>
    </div>
  </div>
</div>
