<section class="banner">
  <article class="col col-0">
    <h1 class="banner-title">{{'publishers_banner_title' | translate}}</h1>
    <p class="banner-text">{{'publishers_banner_description' | translate}}</p>
    <a routerLink="/publisher-registration">
      <button class="banner-button">{{'publishers_banner_button_text' | translate}}</button>
    </a>
  </article>
  <div class="col col-1">
    <div class="banner-illustration-container">
      <img src="assets/publishers-banner-ilustration.svg" alt="" class="banner-illustration">
    </div>
  </div>
</section>

