import {Component, OnDestroy, OnInit, NgZone} from '@angular/core';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {catchError, finalize} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ApplicationAuthService} from '../../../shared/services/application-auth.service';
import {ApplicationUtilsService} from '../../../shared/services/application-utils.service';
import {ErrorResponseCodes} from '../../../shared/app-interfaces';
import {CookieService} from 'ngx-cookie-service';
import { GoogleBindComponent } from '../../authorization/google-bind-template/google-bind.component';

@Component({
  selector: 'app-google-registration',
  templateUrl: './google-publisher-registration.component.html',
  styleUrls: ['./google-publisher-registration.component.scss']
})

export class GooglePublisherRegistrationComponent implements OnInit, OnDestroy {

  constructor(
    public api: ApplicationApiService,
    public state: ApplicationStateService,
    public dialog: MatDialog,
    public zone: NgZone,
    public auth: ApplicationAuthService,
    public utils: ApplicationUtilsService,
    public cookieService: CookieService,
  ) {
  }

  ngOnInit(): void {
    window.handleCredentialResponse = (googleResponse) => {
        this.cookieService.deleteAll();
        return this.api.googleAuth(JSON.stringify(googleResponse.credential), 'generateTenant').pipe(
          catchError((err: any): any => {
            if (err.error === 'entity_not_found') {
              this.utils.showSnackbar(ErrorResponseCodes.ENTITY_NOT_FOUND);
            }

            if (err.error === 'already_exist') {
              this.utils.showSnackbar(ErrorResponseCodes.TENANT_ALREADY_EXISTS);
            }

            if (err?.error[0] === 'can_bind_accounts') {
              this.openDialog(err?.error[1], googleResponse?.credential);
            }

            return err?.error;
          }),
          finalize(() => {})
        ).subscribe((credentialsData: any) => {
          if (credentialsData?.access_token) {
            this.auth.login(credentialsData, true);
          }
        });
    };
  }

  ngOnDestroy(): void {
  }

  public openDialog(email, googleToken): void {
    this.zone.run(() => {
      this.dialog
        .open(GoogleBindComponent, {
          maxWidth: '500px',
          width: '100%',
          autoFocus: false,
          data: {
            googleToken: googleToken,
            email: email
          }
        });
    });
  }
}
