<div class="global-main-cnt global-flex-column-space-between-center"
     *ngIf="{
      collections: state.collections$ | async,
      adminMode: state.adminMode$ | async,
      searchString: state.bookSearchString$ | async,
      isLoading$: isLoading$ | async
   } as data">
  <div class="options full-width">
    <div class="search">
    </div>
    <div class="btn-section">
      <button mat-button
              (click)="createCollection()">
        <mat-icon [svgIcon]="CustomIcons.plus"></mat-icon>
        <span>{{'admin_collections_create_collection_button' | translate}}</span>
      </button>
    </div>
  </div>
  <div class="table full-width" style="position: relative">
    <div *ngIf="data.isLoading$" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>
    <div style="text-align: right;width: 100%; margin-right: 0px;">
      {{'collections_count_found' | translate}}{{page.totalElements}}
    </div>
    <ngx-datatable
      #table
      [headerHeight]="60"
      [footerHeight]="0"
      [rowHeight]="87"
      [columnMode]="'force'"
      [rows]="rows"
      [columns]="columns"
      [virtualization]="false"
      [externalPaging]="false"
      [count]="page.totalElements"
      [offset]="page.offset"
      [limit]="page.limit"
      (page)="setPage($event)"
      [messages]="{emptyMessage: this.utils.getTableMessage('empty_table_message' | translate)}"
    >
      <ngx-datatable-column [name]="'column_header_title' | translate" prop="title" [sortable]="true">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="mobile-hidden">{{ column.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ row.title }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [name]="'column_header_price' | translate | currency:state.tenantCurrency$.value" prop="price" [sortable]="true">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="mobile-hidden">{{ column.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div style="display: flex; flex-direction: row; align-items: center;">
            <span>{{ row.price }}</span>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [name]="'column_header_list_of_books' | translate" prop="books" [sortable]="true">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="mobile-hidden">{{ column.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div style="display: flex; flex-direction: row; align-items: center;">
            <span>{{ (row?.books?.length ?? 0) + ' ' + (((row?.books?.length ?? 0) < 5 ? ('table_info_books_quantity_' + (row?.books?.length ?? 0)) : 'table_info_books_quantity_5_or_more') | translate ) }}</span>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [name]="'column_header_who_added' | translate" prop="createdBy" [sortable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="mobile-hidden">{{ column.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ row.createdUser?.email }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [name]="'column_header_when' | translate" prop="subscriptionComposition" [sortable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="mobile-hidden">{{ column.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div style="display: flex; flex-direction: column;">
            <span class="mobile-hidden">{{ formattedDate(row.createdDate) }}</span>
          </div>

        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        [width]="50"
        [resizeable]="false"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="false"
      >
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <button mat-icon-button [matMenuTriggerFor]="menu" class="table-edit-button">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editCollection(row)">
              <mat-icon>edit</mat-icon>
              <span>{{'admin_collections_edit_button' | translate}}</span>
            </button>
            <button mat-menu-item (click)="deleteCollection(row.uid)">
              <mat-icon>close</mat-icon>
              <span>{{'admin_collections_delete_button' | translate}}</span>
            </button>
          </mat-menu>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
