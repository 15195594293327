<mat-dialog-content>
  <h3 class="dialog-title">{{ 'consent_dialog_title' | translate }}</h3>
  <div class="consent-dialog-description-container">
    <span>
      {{ 'consent_dialog_description' | translate }}
    </span>
    <a class="read-more-link" [routerLink]="['..', 'consent']" (click)="close()">
      {{ 'consent_dialog_read_more_link' | translate }}
    </a>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="buttons">
    <button class="global-button" (click)="save()">
      {{ 'dialog_button_accept' | translate }}
    </button>
  </div>
</mat-dialog-actions>