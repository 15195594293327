import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import { PdfReaderService, ReaderMode } from 'src/app/shared/services/pdf-reader.service';
import { Book } from 'src/app/shared/app-interfaces';

@Component({
    selector: 'app-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit, OnDestroy {
    @Input() book: Book;
    @Output() switchSidenavModeOnResize = new EventEmitter<any>();

    @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

    public readonly ReaderMode = ReaderMode;
    public readonly readerMode$ = this.pdfReaderService.readerMode$;
    private subs: Subscription[] = [];

    @HostListener('mousewheel', ['$event'])
    public onMouseWheel(event: any): void {
        if (event.ctrlKey && this.pdfReaderService.readerMode$.value !== ReaderMode.FullScreen) {
            event.preventDefault();
            if (event.deltaY > 0) {
                this.pdfReaderService.zoomOut(0.05);
            } else {
                this.pdfReaderService.zoomIn(0.05);
            }
        }
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event: any): void {
        if (this.pdfReaderService.readerMode$.value === ReaderMode.FullScreen) {
            this.pdfReaderService.setHeightOnFullScreenMode();
        }
        this.switchSidenavModeOnResize.emit(event);
    }

    constructor(
        private route: ActivatedRoute,
        public pdfReaderService: PdfReaderService
    ) {
        this.pdfReaderService.bookPagesList = [];

        this.subs.push(
            this.route.paramMap.subscribe(paramMap => {
                this.pdfReaderService.bookId = paramMap.get('bookId');
            })
        );
    }

    ngOnInit(): void {
        if (this.book?.countLoadedPages && this.pdfReaderService.bookPagesList.length === 0) {
            this.pdfReaderService.countLoadedPages = this.book?.countLoadedPages;
            this.pdfReaderService.load(this.book.countLoadedPages).subscribe(() => {
                setTimeout(() => {
                    this.pdfReaderService.virtualScrollViewport = this.virtualScroll;
                });
            });
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach(x => x.unsubscribe());
    }

    public getPageHeight(): number {
        return this.pdfReaderService.getPageHeight();
    }

    public getPageWidth(): any {
        return this.pdfReaderService.getPageWidth();
    }
}
