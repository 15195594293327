import { ChangeDetectionStrategy, Component, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { compareAsc, differenceInDays, format } from 'date-fns';
import {BehaviorSubject, catchError, combineLatest, debounceTime, finalize, first, Subscription, switchMap} from 'rxjs';
import { Page } from 'src/app/shared/app-global-variables';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { ApplicationUtilsService } from 'src/app/shared/services/application-utils.service';
import { EditUserComponent } from '../../../users/edit-form-template/edit-user.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/component';
import { ApplicationApiService } from 'src/app/shared/services/application-api.service';
import { EditPasswordComponent } from '../../../users/edit-user-password-template/edit-password.component';

@Component({
    selector: 'regular-mode-readers',
    templateUrl: 'regular-mode.component.html',
    styleUrls: ['regular-mode.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })
  export class RegularModeReadersComponent{
    public isLoading$ = new BehaviorSubject<boolean>(false);
    private subs: Subscription[] = [];
    rows: any;
    filteredSubscribers: any;

    page = new Page();
    constructor(
      public state: ApplicationStateService,
      public utils: ApplicationUtilsService,
      public cd: ChangeDetectorRef,
      private dialog: MatDialog,
      public api: ApplicationApiService
    ) {
      this.page.offset = 0;
      this.page.limit = 10000;
      this.state.getSubscribers();
      
      this.subs.push(
        combineLatest([this.state.selectedSubscriber$, this.state.subscriptions$, this.state.subscribers$])
        .pipe(debounceTime(500))
            .subscribe(([selectedSubscriber, subscriptions, subscribers]) => {
            
            this.filteredSubscribers = subscribers.filter(subscriber => subscriptions.find(subscription => {
                return subscription.subscriber.uid === subscriber.uid && subscription.licenseType === 'Regular';
              }
            )).sort(x => { 
              return this.state.subscriptions$.value.filter(sub => sub.subscriber.uid == x.uid && this.isExpired(sub)).length > 1 ? 1 : -1;
            });;
            
            
            if (selectedSubscriber?.uid && !this.filteredSubscribers.find(subscriber => subscriber.uid === selectedSubscriber?.uid)) {
              this.state.selectedSubscriber$.next(null);
            }

            this.setPage({offset: this.page.offset});
          })
      );
    }

    setPage(pageInfo): any {
      this.isLoading$.next(true);
      this.page.offset = pageInfo.offset;
      this.getFilteredPaginatedReaders();
      this.cd.markForCheck();
    }

    getFilteredPaginatedReaders(): void {
      let body = {};
      const filter = [];
      
      if (this.state.selectedSubscriber$?.value?.uid) {
        filter.push({
          column: 'Subscriber',
          method: '=',
          data: this.state.selectedSubscriber$.value.uid
        });
        body = {
          sort: {
            column: 'name',
            order: 'asc'
          },
          filter
        };
      }
  
      this.state.getPaginatedReaders(this.page.limit, this.page.offset * this.page.limit, body, 'regular').subscribe((res) => {
        const {data, page} = res;
        this.rows = data;
  
        this.page.totalElements = page.totalElements;
        this.page.totalPages = page.totalElements / this.page.limit >= 1 ? page.totalElements / this.page.limit : 1;
        this.isLoading$.next(false);
        this.cd.markForCheck();
      });
    }

    changeSelectedSubscriber(uid: string): void {
      this.state.selectedSubscriber$.next(this.state.subscribers$.value.find(subscriber => subscriber.uid === uid));
    }

    formattedDate(date): any {
      return date ? format(new Date(date), 'dd.MM.yyyy') : "";
    }

    isExpired(row: any): boolean {
      if (row.licenseType === 'Individual') {
        return !row?.enabled;
      }
      return this.getLeftDays(row) === 0;
    }

    getLeftDays(row: any): any {
      if (compareAsc(new Date(), new Date(row?.end)) !== -1) {
        return 0;
      } else {
        return differenceInDays(
          new Date(row?.end),
          new Date()
        );
      }
    }
  }