import { Component } from '@angular/core';

// #note Temporary interface. Will be corrected according DB fields;
interface Publisher {
  uid: string;
  logo?: string;
  expertise: string;
  pricesLink?: string;
  name: string;
  description: string;
  uploadedBooksAmount: number;
  phone?: string;
  email?: string;
}

const mockPublishers: Publisher[] = [
  {
    uid: '1',
    logo: undefined,
    expertise: 'Профилируются на технической литературе',
    pricesLink: undefined,
    name: 'Лань',
    description:
      'Издательство сотрудничает с ЭБС Элибрика с 2022 г. — с самого зарождения ЭБС. Поскольку мы специализируемся в основном на выпуске серьезной технической литературы, то нам важно иметь дело с профессионалами, и именно таковыми являются сотрудники «Лани». «Лань» для нас — ключевой игрок на рынке учебной литературы, более половины всех продаж в ЭБС у нас проходят через «Лань». Книги размещаются быстро и их легко найти по каталогу, проверить и проконтролировать. Отчеты по продажам присылаются ежемесячно, оплата поступает всегда в срок.',
    uploadedBooksAmount: 75,
    phone: undefined,
    email: undefined,
  },
  {
    uid: '2',
    logo: undefined,
    expertise: 'Профилируются на животноводстве',
    pricesLink: '/#',
    name: 'Квадро',
    description:
      'Издательство «Квадро» - специализированное издательство, выпускающее учебно-методическую и справочную литературу для студентов аграрных и сельскохозяйственных учебных заведений. Книги разделены и представлены по разделам (коллекциям) физико-математических наук, что очень упрощает работу вузов.',
    uploadedBooksAmount: 33,
    phone: '+7-921-946-23-27',
    email: '9462327@mail.ru',
  },
  {
    uid: '3',
    logo: undefined,
    expertise: 'Профилируются на детской литературе',
    pricesLink: undefined,
    name: 'Clever',
    description:
      'Издательство «Квадро» - специализированное издательство, выпускающее учебно-методическую и справочную литературу для студентов аграрных и сельскохозяйственных учебных заведений.  Книги разделены и представлены по разделам (коллекциям) физико-математических наук, что очень упрощает работу вузов.',
    uploadedBooksAmount: 33,
    phone: undefined,
    email: undefined,
  },
];

@Component({
  selector: 'app-publishers-list',
  templateUrl: './publishers-list.component.html',
  styleUrls: ['./publishers-list.component.scss'],
})
export class PublishersListComponent {

  public publishers = mockPublishers; // #todo Replace with actual fetched data;

  constructor() {}
}
