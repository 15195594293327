import {Clipboard} from '@angular/cdk/clipboard';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {compareAsc, differenceInDays} from 'date-fns';
import {
  BehaviorSubject,
  catchError,
  finalize,
  first,
  Observable,
  Subscription,
  switchMap,
  combineLatest,
  debounceTime
} from 'rxjs';
import {ConfirmDialogComponent} from 'src/app/shared/components/confirm-dialog/component';
import {PageLimitOptions} from '../../../shared/app-global-variables';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {ApplicationUtilsService, CustomIcons} from '../../../shared/services/application-utils.service';
import { ViewHistoryComponent } from './history-form-template/history-form.template';
import { EditSubscriberComponent } from './edit-subscriber-template/edit-subscriber.component';

class Page {
  limit = 0;
  totalElements = 0;
  totalPages = 0;
  offset = 0;
}

@Component({
  selector: 'app-admin-subscribers',
  templateUrl: './admin-subscribers.component.html',
  styleUrls: ['./admin-subscribers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminSubscribersComponent implements OnInit, OnDestroy {
  @ViewChild('myIndividualTable') individualTable: any;
  @ViewChild('table') table: any;
  public readonly CustomIcons = CustomIcons;

  public isLoading$ = new BehaviorSubject<boolean>(false);
  public pageLimitOptions = PageLimitOptions;
  public oldSearchValue: string;

  searchString = '';
  temp = [];
  rows: any;
  page = new Page();
  sort: any = [{field: 'id', order: 'asc'}];
  groups = [];

  private subs: Subscription[] = [];
  editing = {};
  constructor(
    private dialog: MatDialog,
    private clipboard: Clipboard,
    public state: ApplicationStateService,
    public api: ApplicationApiService,
    public utils: ApplicationUtilsService,
    public cd: ChangeDetectorRef,
    public router: Router,
  ) {
    this.page.offset = 0;
    this.page.limit = 25;

    this.state.getBooks();
    this.state.getCollections();
    this.state.getSubscribers();

    this.subs.push(combineLatest([this.state.subscribersSearchString$, this.state.collections$, this.state.books$,
    this.state.subscribers$]).pipe(debounceTime(400))
      .subscribe(([searchString, collections, books, subscribers]) => {
        this.setPage({offset: 0});
      }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  refresh(): any {
    this.setPage({offset: 0});
  }

  setPage(pageInfo): any {
    this.isLoading$.next(true);
    this.page.offset = pageInfo.offset;
    this.state.getSubscriptions(true, this.page.limit, this.page.offset * this.page.limit, {
      sort: {
        column: 'title',
        order: 'asc'
      },
      filter: this.getFilter()
    }).subscribe((res) => {
      const {data, page} = res;
      const subscribers = this.state.subscribers$.value.map(subscriber => {
        return {...subscriber, collections: data.filter(collection => collection.subscriber.uid === subscriber.uid)};
      });
      this.temp = data.map(collection => {
        return {...collection, subscriberId: collection.subscriber.uid};
      }).sort(x => this.isExpired(x) ? 1 : -1);
      subscribers.map(subscriber => {
        if (subscriber.collections.length === 0 && this.state.subscribersSearchString$.value.length === 0) {
          this.temp.push({subscriber, subscriberId: subscriber.uid, emptyMessage: 'No subscriptions yet'});
        }
      });
      this.page.totalElements = page.totalElements;
      this.page.totalPages = page.totalElements / this.page.limit >= 1 ? page.totalElements / this.page.limit : 1;
      this.rows = this.temp;
      this.isLoading$.next(false);
      this.cd.markForCheck();
    });
  }

  public confirmOperation = (confirmationText: string, translate?: boolean, translateOptions?: object): Observable<any> => {
    return;
  }

  onLimitChange(limit: any): any {
    this.page.limit = Number(limit);
    this.refresh();
  }

  executeSearch(searchString: string): void {
    if (searchString !== this.oldSearchValue) {
      this.oldSearchValue = searchString;
      this.state.subscribersSearchString$.next(searchString);
    }
  }

  public getFilteredSubscriptions$(searchString): void {
    this.state.subscribersSearchString$.next(searchString.toString().trim().toLowerCase());
  }

  getLeftDays(row: any): any {
    if (compareAsc(new Date(), new Date(row?.end)) !== -1) {
      return 0;
    } else {
      return differenceInDays(
        new Date(row?.end),
        new Date()
      );
    }
  }

  isExpired(row: any): boolean {
    if (row.licenseType === 'Individual') {
      return !row?.enabled;
    }
    return this.getLeftDays(row) === 0;
  }

  copyCode(code): void {
    this.clipboard.copy(code);
    this.utils.showSnackbar('license_copy_code_to_clipboard_successfully');
  }


  createSubscription(): void {
    this.state.currentSubscriber$.next(null);
    this.state.subscriptionMode$.next(null);
    this.state.currentSubscription$.next(null);
    this.state.createSubscriptionStep$.next(0);
    this.router.navigate(['admin/manage-subscriptions/new']);
    if (this.state.subscribers$.value.length === 0) {
      this.dialog
        .open(EditSubscriberComponent, {
          maxWidth: '1000px',
          width: '100%',
          minHeight: '555px',
          disableClose: true,
          autoFocus: false,
          data: {}
        });
    }
  }
  addSubscription(id: string): any {
    this.state.getSubscriber(id);
    this.state.subscriptionMode$.next(null);
    this.state.createSubscriptionStep$.next(0);
    this.router.navigate(['/admin/manage-subscriptions/new']);
  }

  editSubscription(row: any): any {
    if (row.licenseType === 'Regular') {
      this.state.subscriptionMode$.next('regular');
    } else {
      this.state.subscriptionMode$.next('individual');
    }
    this.router.navigate(['admin/manage-subscriptions', 'edit', row.uid]);
  }

  editSubscriber(uid: string): any {
    this.state.getSubscriber(uid);
    return this.dialog
      .open(EditSubscriberComponent, {
        maxWidth: '1000px',
        width: '100%',
        minHeight: '555px',
        disableClose: true,
        autoFocus: false,
        data: {subscriberId: uid}
      })
      .afterClosed().subscribe((res) => {
        if(res){
          this.setPage({offset: this.page.offset});
        }
      });
  }

  openHistory(row: any): any {
    return this.dialog
      .open(ViewHistoryComponent, {
        maxWidth: '1000px',
        width: '100%',
        minHeight: '200px',
        disableClose: true,
        autoFocus: false,
        data: {subscriptionId: row.uid}
      });
  }

  deleteSubscription(id: string): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        disableClose: true,
        autoFocus: false,
        data: {text: 'delete_subscription_confirm_text', title: 'delete_subscription_confirm_title'}
      })
      .afterClosed()
      .pipe(first()).subscribe(yes => {
      if (!yes) {
        return;
      }
      this.isLoading$.next(true);
      this.api.deleteSubscription$(id).pipe(
        first(),
        catchError((): any => {
          this.utils.showSnackbar('delete_subscription_error_msg');
        }),
        finalize(() => this.isLoading$.next(false)),
        switchMap(() => {
          return this.state.getSubscriptions(true);
        })
      ).subscribe(() => {
        this.isLoading$.next(false);
        this.setPage({offset: this.page.offset});
        this.utils.showSnackbar('delete_subscription_success_msg');
      });
    });
  }

  getFilter(): any {
    if (this.state.subscribersSearchString$?.value) {
      return [
        {
          column: 'title',
          method: 'contains',
          data: this.state.subscribersSearchString$?.value
        }
      ];
    } else {
      return [];
    }
  }

  public toggleSubscriptionStatus(row: any): void {
    const payload = {...row, enabled: !row.enabled};
    this.api.postSubscription$(
      payload
      , row?.uid)
      .pipe(
        catchError((): any => {
          this.utils.showSnackbar('save_subscription_error_msg');
        }),
        finalize(() => {
          this.setPage({offset: 0});
        })).subscribe(() => {
      this.utils.showSnackbar('save_subscription_success_msg');
    });
  }
}
