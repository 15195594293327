<div *ngIf="{
    users: this.state.users$ | async,
    isLoading$: isLoading$ | async,
    subscribers: state.subscribers$ | async,
    subscriber: subscriber$ | async,
    searchString: (state.usersSearchString$ | async).trim()
  } as data">

    <div class="options" style="gap: 20px; margin-top: 20px;">
        <div class="search" style="margin-bottom: 0">
          <mat-form-field
              [floatLabel]="'never'"
              class="global-mat-form-field">
              <mat-select [placeholder]="'admin_readers_subscriber_select_placeholder' | translate">
                  <mat-select-trigger>
                      <div>{{data.subscriber?.title}}</div>
                  </mat-select-trigger>
                  <mat-option (click)="selectSubscriber(null)">
                  --
                  </mat-option>
                  <mat-option *ngFor="let subscriber of filteredSubscribers" [value]="subscriber.uid">
                      <div class="global-flex-row-space-between-center">
                          <span (click)="selectSubscriber(subscriber)" style="flex: 1">
                          {{subscriber.title}}
                          </span>
                      </div>
                  </mat-option>
              </mat-select>
          </mat-form-field>
        </div>
    </div>
    <div style="text-align: right;width: 100%; margin-right: 0px;">
        {{'requests_count_found' | translate}}{{page.totalElements}}
    </div>
    <div class="table" style="position: relative">
        <div *ngIf="data.isLoading$" class="global-spinner-cnt">
          <mat-spinner color="primary" diameter="50"></mat-spinner>
        </div>
        <!-- [columnMode]="'force'" -->
        <ngx-datatable #table [headerHeight]="60" [footerHeight]="50" [rowHeight]="'auto'" style="width: 100%;"
            [columnMode]="'flex'" [rows]="rows" [columns]="columns" [virtualization]="false" [externalPaging]="true"
            [count]="(page.totalElements + rows?.length)" [offset]="page.offset" [limit]="page.limit"
            (page)="setPage($event)" [messages]="{emptyMessage: this.utils.getTableMessage('empty_table_message' | translate)}">
        
            <ngx-datatable-column  [flexGrow]="1" [resizeable]="false" [name]="'column_header_who_added' | translate" prop="email" [sortable]="true">
              <ng-template let-column="column" ngx-datatable-header-template>
                <span class="mobile-hidden">{{ column.name }}</span>
              </ng-template>
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span class="mobile-hidden">{{ row.createdUser.email }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column  [flexGrow]="1" [resizeable]="false" [name]="'column_header_date' | translate" prop="date" [sortable]="true">
              <ng-template let-column="column" ngx-datatable-header-template>
                <span class="mobile-hidden">{{ column.name }}</span>
              </ng-template>
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span class="mobile-hidden">{{ row.createDate | date:'short' }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column  [flexGrow]="1" [resizeable]="false" [name]="'column_header_whom_subscription_was_provided' | translate" prop="subs" [sortable]="true">
              <ng-template let-column="column" ngx-datatable-header-template>
                <span class="mobile-hidden">{{ column.name }}</span>
              </ng-template>
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span class="mobile-hidden">{{ row.subscriber.title }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column  [flexGrow]="2" [resizeable]="false" [name]="'readers_request_message_title' | translate" prop="message" [sortable]="true">
              <ng-template let-column="column" ngx-datatable-header-template>
                <span class="mobile-hidden">{{ column.name }}</span>
              </ng-template>
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span class="mobile-hidden" style="display: table-cell; text-wrap: balance;">{{ row.message }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [width]="150" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                <div style="padding: 5px;">
                  <button mat-raised-button (click)="accept(row.id)" style="width: 100%; color: green;">
                    <mat-icon>check_circle</mat-icon>
                    {{'accept_request_btn' | translate}}
                  </button>
                  <button mat-raised-button color="warn" (click)="reject(row.id)" style="margin-top: 2.5px; width: 100%;">
                    <mat-icon>delete</mat-icon>
                    <span>{{'reject_request_btn' | translate}}</span>
                  </button>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible">
                <div *ngIf="rows?.length > 0" class="page-limit-selection">
                  <mat-select
                    [value]="page.limit"
                    style="width: 200px"
                    class="global-select"
                    (valueChange)="onLimitChange($event)">
                    <mat-option
                      *ngFor="let option of pageLimitOptions"
                      [value]="option.value"
                    >
                      {{ option.value }} {{ 'pagination_on_page' | translate }}
                    </mat-option>
                  </mat-select>
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!((page.totalElements / pageSize) > 1)"
                  (change)="table.onFooterPage($event)">
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
      </ngx-datatable>
    </div>   
</div>