<header class="global-main-cnt" *ngIf="{ adminMode: state.adminMode$ | async,
           superAdminMode: state.superAdminMode$ | async,
           isAuthenticated: state.isAuthenticated$ | async,
           routerUrl: state.routerUrl$ | async } as data">

  <a [routerLink]="['']">
    <img class="main-logo" src="assets/svg-icons/{{ environmentDomain == 'elibrio' ? 'elibrio_logo.svg' : 'elibrica_logo.svg'}}" alt="logo">
  </a>

  <div *ngIf="!data.adminMode" class="header-links">
    <a class="header-link" [routerLink]="['/']" [routerLinkActive]="'header-link-active'"
    [routerLinkActiveOptions]="{ exact: true }">{{'header_books_button' | translate}}</a>

    <a class="header-link" href="/publishers"
       [class.header-link-active]="(data.routerUrl?.includes('publishers'))">{{'header_publishers_button' | translate}}</a>
  </div>

  <div class="login-cnt global-flex-row-space-between-center">
    <span class="user-name" *ngIf="data.isAuthenticated">
      <!-- #todo Replace with E-mail -->
      {{ authService.credentials?.userName}}</span>
    <a *ngIf="!data.isAuthenticated" class="global-button green"
      [ngClass]="{ disabled: data.routerUrl?.includes('authorization') }" href="/authorization">
      <mat-icon [svgIcon]="CustomIcons.arrow_right_bold_box"></mat-icon><span>{{'header_login_button' |
        translate}}</span>
    </a>


    <mat-icon *ngIf="data.isAuthenticated" [ngClass]="data.adminMode ? 'active' : null"
      class="global-icon profile-icon" [mat-menu-trigger-for]="profileMenu">person</mat-icon>

<!--    <button *ngIf="data.isAuthenticated" class="global-button grey exit-icon" (click)="authService.logout()">
      <mat-icon>exit_to_app_icon</mat-icon>
      <span>{{'header_exit_button' | translate}}</span>
    </button>-->

    <mat-menu #profileMenu>

      <button *ngIf="data.superAdminMode" mat-menu-item (click)="openTenantsManagingDialog()">{{ 'my_profile_super_admin' | translate}}</button>
      <button *ngIf="authService.credentials?.tenants?.length > 1" mat-menu-item (click)="openSwitchTenantDialog()">{{ 'my_profile_switch_tenant' | translate}}</button>
      <button mat-menu-item (click)="openProfileDialog()">{{ 'my_profile_menu_item' | translate}}</button>
      <button mat-menu-item (click)="openMySubscriptionsDialog()">{{ 'my_subscriptions_menu_item' | translate}}</button>
      <button mat-menu-item (click)="authService.logout()">{{ 'my_profile_log_out' | translate }}</button>

    </mat-menu>
  </div>
</header>
