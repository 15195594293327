<div
  class="global-main-cnt global-flex-column-start-center"
  *ngIf="{
    currentBook: state.currentBook$ | async,
    collections: state.collections$ | async,
    adminMode: state.adminMode$ | async,
    loading: loading$ | async,
    loadingFile: loadingFile$ | async,
    isAuthenticated: state.isAuthenticated$ | async,
    bookHasLoaded: bookHasLoadedSuccessfully$ | async,
    bookEditorStep: state.bookEditorStep$ | async
  } as data"
>
  <div *ngIf="data.loading" class="global-spinner-cnt">
    <mat-spinner color="primary" diameter="50"></mat-spinner>
  </div>
  <span
    class="global-title">{{ (data.currentBook ? 'book_editor_editing_title' : 'book_editor_addition_title') | translate }}</span>
  <div *ngIf="data.bookEditorStep === 0 && !data.currentBook" class="first-step">
    <span class="first-step-description">
      <b>{{ 'book_editor_first_step' | translate }}</b> {{ 'book_editor_first_step_description' | translate }}
    </span>
    <div class="file-load-input-container">
      <input style="display: none" type="file" #fileLoadRef id="fileLoadRef" accept=".pdf, .epub"
             (change)="fileBrowseHandler($event.target.files)" />
      <label class="global-button green" style="cursor: pointer" for="fileLoadRef">{{
        (data.currentBook?.hasBook || data.bookHasLoaded
            ? 'book_editor_upload_update_label'
            : 'book_editor_upload_label'
        ) | translate
        }}</label>
    </div>

    <div
      *ngIf="data.currentBook?.loadedFileName || data.bookHasLoaded"
      class="global-flex-column-center-center" style="width: 100%; margin: 14px 0;">
      <div class="global-flex-row-center-center" style="margin-bottom: 10px; word-break: break-word">
        <mat-icon class="material-icons" [svgIcon]="CustomIcons.pdf" style="margin: 10px;"></mat-icon>
        <span
          style="font-weight: 600;font-size: 13px;line-height: 16px;color: #979797;">{{ uploadedFileName || data.currentBook?.loadedFileName }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="data.bookEditorStep === 1" class="second-step">
    <span *ngIf="!data.currentBook" class="second-step-description">
      <b>{{ 'book_editor_second_step' | translate }}</b> {{ 'book_editor_second_step_description' | translate }}
    </span>
    <div class="edit-form-cnt global-flex-row-start-start" style="position: relative;">
      <div class="load-book-cnt global-flex-column-start-start" [ngClass]="{'disabledSection': data.loadingFile}">
      <div class="book-picture" style="position: relative">
        <div style="position: absolute; top: 0; bottom: 0; right: 0; left: 0; z-index: 5"
             [ngStyle]="{
          'background-image': data.currentBook?.hasImage
              ? getBookImageStyleForBackgroundImageByUid(data.currentBook?.uid)
              : InputImageStringUrl,
          'background-repeat': data.currentBook?.hasImage ? 'no-repeat' : null,
          'background-position': data.currentBook?.hasImage ? 'center' : null,
          'background-size': data.currentBook?.hasImage ? 'cover' : null
        }"
        ></div>
        <div
          class="book-picture-load-cnt"
          style="position: absolute; top: 0; bottom: 0; right: 0; left: 0;"
          appDnd (fileDropped)="coverBrowseHandler($event)">
          <span>{{'book_editor_add_photo_label' | translate}}</span>
          <mat-icon class="material-icons">
            add_a_photo
          </mat-icon>
          <input type="file" #coverDropRef id="coverDropRef" accept=".png,.jpeg,.jpg"
                 (change)="coverBrowseHandler($event.target.files)"/>
          <label for="coverDropRef"></label>
        </div>
        <div *ngIf="coverBook" style="z-index: 7">
          <img style="width: 100%; height: 100%; object-fit: cover" src="{{coverBook}}" alt="coverBook">
        </div>
      </div>
      <div class="file-load-hint" style="max-width: 160px; padding-bottom: 5px;">
        {{'book_editor_load_or_drag_hint' | translate}}
      </div>
      <div class="file-load-hint" style="max-width: 160px; padding-bottom: 20px;">
        {{'book_editor_format_hint' | translate}}
      </div>

      <div
        *ngIf=" data.currentBook?.loadedFileName || data.bookHasLoaded"
        class="global-flex-column-center-center" style="width: 100%; margin: 14px 0;">
        <div class="global-flex-row-center-center" style="margin-bottom: 10px; word-break: break-word">
          <mat-icon class="material-icons" [svgIcon]="CustomIcons.pdf" style="margin: 10px;"></mat-icon>
          <span
            style="font-weight: 600;font-size: 13px;line-height: 16px;color: #979797;">{{ uploadedFileName || data.currentBook?.loadedFileName }}</span>
        </div>
        <!--        <span class="file-load-hint">{{ 'book_editor_upload_file_label' | translate }}</span>-->
      </div>

      <button
        mat-flat-button
        class="global-button green full-width"
        style="position: relative; margin-bottom: 14px; cursor: pointer">
        <input style="display: none" type="file" #fileUpdateRef id="fileUpdateRef" accept=".pdf, .epub"
               (change)="fileBrowseHandler($event.target.files)"/>
        <label
          style="cursor: pointer"
          for="fileUpdateRef">{{
            (data.currentBook?.hasBook || data.bookHasLoaded
              ? 'book_editor_upload_update_label'
              : 'book_editor_upload_label'
            ) | translate
          }}</label>
      </button>

      <button mat-flat-button
              [ngClass]="{ disabled: !data.currentBook?.hasBook || !data.isAuthenticated || !data.currentBook?.hasAccessRead }"
              class="global-button green full-width"
              (click)="openBook(data.currentBook)"
      ><span>{{ 'read_book' | translate}}</span>
      </button>

    </div>
      <form [formGroup]="form" class="global-flex-column-space-between-start">
      <div class="form-field-cnt global-flex-column-space-between-start">
        <span>{{'book_editor_author_label' | translate}}</span>
        <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
          <input [formControlName]="FormFields.authors" matInput type="text"
                 [placeholder]="'book_editor_author_placeholder' | translate">
        </mat-form-field>
      </div>

      <div class="form-field-cnt global-flex-column-space-between-start">
        <span>{{'book_editor_title_label' | translate}}</span>
        <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
          <input [formControlName]="FormFields.title" matInput type="text"
                 [placeholder]="'book_editor_title_placeholder' | translate">
        </mat-form-field>
      </div>

      <div class="form-field-cnt global-flex-column-space-between-start">
        <span>{{'book_editor_collections_label' | translate}}</span>
        <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
          <mat-select [placeholder]="'book_editor_collections_placeholder' | translate"
                      [formControl]="getFormControl(FormFields.collections)" multiple>
            <mat-option *ngFor="let collection of data.collections"
                        [value]="collection.uid">
              {{ collection.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="global-flex-row-space-between-end bibliography-container">
        <div style="width: 100%"  class="global-flex-column-space-between-start">
          <div style="width: 100%" class="global-flex-row-space-between-center">
            <span>{{'book_editor_bibliography_label' | translate}}</span>
            <mat-icon [matTooltip]="'book_editor_bibliography_hint' | translate" class="bibliography-help-icon">help</mat-icon>
          </div>
          <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
            <input class="bibliography-input"
                   [formControlName]="FormFields.bibliographicTitle" matInput type="text"
                   [placeholder]="'book_editor_bibliography_placeholder' | translate">
          </mat-form-field>
        </div>
        <!-- [ngClass]="{'active-toggle': !getFormControl(FormFields.bibliographicTitle).value}" -->
        <!-- [checked]="getFormControl(FormFields.bibliographicTitle).value != ''"-->
        <!-- [disabled]="getFormControl(FormFields.bibliographicTitle).value == ''" -->
        <mat-slide-toggle disableRipple
                          style="margin-bottom: 10px"
                          [color]="'primary'"
                          [checked]="!getFormControl(FormFields.bibliographicTitle).disabled"
                          (change)="toggleControl([FormFields.bibliographicTitle])">
          {{ 'book_editor_bibliography_toggle' | translate }}
        </mat-slide-toggle>
      </div>

      <div class="secondary-fields-cnt global-flex-row-space-between-start">
        <div class="form-field-cnt global-flex-column-space-between-start">
          <span>ISBN</span>
          <mat-form-field [ngClass]="{'ng-invalid ng-touched': form.controls[FormFields.isbn].hasError('notUnique') ||
          form.controls[FormFields.isbn].hasError('tooLong')}"
                          [floatLabel]="'never'" class="global-mat-form-field">
            <input [formControlName]="FormFields.isbn" matInput type="text"
                   [placeholder]="'book_editor_ISBN_placeholder' | translate">
          </mat-form-field>
        </div>

        <div class="form-field-cnt global-flex-column-space-between-start">
          <span>{{'book_editor_edition_label' | translate}}</span>
          <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
            <input [formControlName]="FormFields.publicationTitle" matInput type="text"
                   [placeholder]="'book_editor_edition_placeholder' | translate">
          </mat-form-field>
        </div>

        <div class="form-field-cnt global-flex-column-space-between-start">
          <span>{{'book_editor_edition_year_label' | translate}}</span>
          <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
            <input [formControlName]="FormFields.publicationYear" matInput type="number"
                   [placeholder]="'book_editor_edition_year_placeholder' | translate">
          </mat-form-field>
        </div>

        <div class="form-field-cnt global-flex-column-space-between-start">
          <span>{{'book_editor_format_label' | translate}}</span>
          <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
            <input [formControlName]="FormFields.format" matInput type="text"
                   [placeholder]="'book_editor_format_placeholder' | translate">
          </mat-form-field>
        </div>

        <div class="form-field-cnt global-flex-column-space-between-start">
          <span>{{'book_editor_binding_label' | translate}}</span>
          <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
            <input [formControlName]="FormFields.bookBinding" matInput type="text"
                   [placeholder]="'book_editor_binding_placeholder' | translate">
          </mat-form-field>
        </div>

        <div class="form-field-cnt global-flex-column-space-between-start">
          <span>{{ ('book_editor_pages_label' | translate) +
          (data.currentBook?.countLoadedPages
            ? (' (' + ('book_editor_loaded_pages_label' | translate)
              + ' ' + data.currentBook?.countLoadedPages + ')')
            : '')}}
          </span>
          <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
            <input [formControlName]="FormFields.countPages" matInput type="number"
                   [placeholder]="'book_editor_pages_placeholder' | translate">
          </mat-form-field>
        </div>
      </div>
        <div class="global-flex-column-space-between-start" style="gap: 20px">

          <div class="global-flex-row-space-between-end" style="gap: 20px; flex-direction: row-reverse">
            <mat-slide-toggle disableRipple
                              [formControlName]="FormFields.isFree"
                              style="margin-bottom: 10px"
                              [color]="'primary'"
                              [ngClass]="{'active-toggle': !getFormControl(FormFields.isFree).value}"
                              (toggleChange)="toggleControl([FormFields.digital, FormFields.digital6m])">
              {{'book_editor_free_book_toggle' | translate}}
            </mat-slide-toggle>

              <div class="form-field-cnt global-flex-column-space-between-start">
                <span style="font-size: 16px">{{'book_editor_price_label' | translate | currency:state.tenantCurrency$.value}}</span>
                <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
                  <input [formControlName]="FormFields.digital" matInput type="number"
                         [placeholder]="'book_editor_price_placeholder' | translate">
                </mat-form-field>
              </div>
          </div>

          <div class="global-flex-row-space-between-end" style="gap: 20px; flex-direction: row-reverse">

              <div class="form-field-cnt global-flex-column-space-between-end">
                <span style="font-size: 16px">{{'book_edit_six_months_price_label' | translate | currency:state.tenantCurrency$.value}}</span>
                <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
                  <input [formControlName]="FormFields.digital6m" matInput type="number"
                         [placeholder]="'book_edit_six_months_price_placeholder' | translate">
                </mat-form-field>
              </div>
          </div>
          <div class="global-flex-row-space-between-end" style="gap: 20px; flex-direction: row-reverse">
            <mat-slide-toggle disableRipple
                              style="margin-bottom: 10px"
                              [color]="'primary'"
                              [ngClass]="{'active-toggle': !getFormControl(FormFields.hasPaperBack).value}"
                              [formControlName]="FormFields.hasPaperBack"
                              (toggleChange)="toggleControl([FormFields.paperBack])">
              {{'book_editor_physical_book_toggle' | translate}}
            </mat-slide-toggle>
            <div class="form-field-cnt global-flex-column-space-between-start">
              <span style="font-size: 16px">{{'book_edit_physical_price_label' | translate | currency:state.tenantCurrency$.value}}</span>
              <mat-form-field [floatLabel]="'never'" class="global-mat-form-field">
                <input [formControlName]="FormFields.paperBack" matInput type="number"
                       [placeholder]="'book_edit_physical_price_placeholder' | translate">
              </mat-form-field>
            </div>
          </div>
        </div>

      <div class="form-field-cnt global-flex-column-space-between-start">
        <span>{{'book_editor_description_label' | translate}}</span>
        <textarea
          matInput cdkTextareaAutosize
          [placeholder]="'book_editor_description_placeholder' | translate"
          [formControlName]="FormFields.annotation"
        ></textarea>
      </div>


      <div class="global-flex-row-space-between-end" style="gap: 20px; flex-direction: row-reverse">
        <mat-slide-toggle disableRipple
                          style="margin-bottom: 10px"
                          [color]="'primary'"
                          [ngClass]="{'active-toggle': !getFormControl(FormFields.isPublished).value}"
                          [formControlName]="FormFields.isPublished">
          {{ 'book_editor_publish_book_toggle' | translate }}
        </mat-slide-toggle>
      </div>

      <div class="form-field-cnt global-flex-column-space-between-start" *ngIf="data?.currentBook?.extension !== '.epub'">
        <span>{{'book_editor_content_label' | translate}}</span>
        <quill-editor
          style="width: 100%; border-color: #DBDBE9"
          bounds="self"
          #editor
          [styles]="{
          'height': 'auto',
          'font-size': '14px',
           'border-radius': '0 0 3px 3px',
          'font-family': 'Open Sans, Roboto, Helvetica, Arial, sans-serif'
        }"
          [modules]="{ toolbar: [
         [{ 'header': [1, false] }],
         ['bold', 'italic', 'underline', 'strike'],
         [{ 'list': 'ordered'}, { 'list': 'bullet' }],
         [{ 'indent': '-1'}, { 'indent': '+1' }],
         [{ 'align': [] }],
         ['link', 'image']
        ]}"
          [debounceTime]="700"
          [formControlName]="FormFields.tableContents"
        ></quill-editor>
      </div>
    </form>
    </div>
  </div>

  <div class="buttons-cnt global-flex-row-end-center" [ngClass]="{'disabledSection': data.loadingFile}">
    <div *ngIf="data.loadingFile" class="process-info global-flex-row-start-center" style="margin-right: 20px;">
      <mat-spinner color="primary" diameter="25"></mat-spinner>
      <span
        style="font-size: 14px;color: #C4C4C4; margin-left: 10px;">{{'book_editor_loading_status' | translate}}</span>
    </div>
    <button class="global-button grey default" mat-flat-button type="button" (click)="cancel()">
      <span>{{'cancel_button' | translate}}</span></button>
    <button class="global-button green" mat-flat-button type="submit" (click)="next()" *ngIf="data.bookEditorStep === 0" [disabled]="!data.currentBook?.hasBook && !bookHasLoadedSuccessfully$.value">
      <span>{{'next_button' | translate}}</span></button>
    <button class="global-button green" mat-flat-button type="submit" (click)="save()" *ngIf="data.bookEditorStep === 1">
      <span>{{'save_button' | translate}}</span></button>
  </div>
</div>


<!--<div class="main-test">-->
<!--  <div class="big-cnt"></div>-->
<!--  <div class="action-div"></div>-->
<!--</div>-->
