import {Component, Inject, Input, OnInit } from '@angular/core';
import {BehaviorSubject, catchError, finalize, switchMap} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Collection} from '../../../../shared/app-interfaces';
import {ApplicationStateService} from '../../../../shared/services/application-state.service';
import {ApplicationUtilsService} from '../../../../shared/services/application-utils.service';
import {ApplicationApiService} from '../../../../shared/services/application-api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

enum FormFields {
  title = 'title',
  price = 'price',
  books = 'books'
}

@Component({
  selector: 'app-edit-collection-template',
  templateUrl: './edit-collection-component.html',
  styleUrls: ['./edit-collection.component.scss']
})

export class EditCollectionComponent implements OnInit {
  public readonly FormFields = FormFields;
  public form: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(false);
  private filteredBooks = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {collection: Collection, title: string},
    public formBuilder: FormBuilder,
    public state: ApplicationStateService,
    public utils: ApplicationUtilsService,
    private api: ApplicationApiService,
    private dialogRef: MatDialogRef<EditCollectionComponent>
  ) {
    this.state.ownBooks$.subscribe(books => {
      this.filteredBooks = books?.filter(book => book.isCreated);
    });
  }

  public ngOnInit(): void {
    this.form = this.formBuilder?.group({
      [FormFields.title]: [this.data.collection?.title || '', Validators.required],
      [FormFields.books]: [this.data.collection?.books?.map(book => book.uid) ?? [], Validators.required],
      [FormFields.price]: [this.data.collection?.price ?? '', Validators.required]
    });
  }

  save(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.dialogRef.close(this.form.value);
    this.loading$.next(true);
    this.api.postCollection$({...
        this.form.value,
        books: this.form.controls[FormFields.books]?.value?.map((uid: string) => {
          return { uid };
        }) ?? []
      }, this.data.collection?.uid ?? undefined)
      .pipe(
        catchError((): any => {
          this.utils.showSnackbar('save_collection_error_msg');
        }),
        finalize(() => this.loading$.next(false))
      ).subscribe((res) => {
      this.loading$.next(false);
      this.state.getBooks();
      this.utils.showSnackbar('save_collection_success_msg');
      
      this.dialogRef.close(res);
    });
  }

  close(): void{
    this.dialogRef.close(false);
  }
}
