import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BookComponent } from 'src/app/modules/book/book.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { BOOK_ROUTES } from './book.routes';
import { BookDescriptionComponent } from './book-description/book-description.component';
import { BookContentsComponent } from './book-description/book-contents/book-contents.component';
import { BookReaderComponent } from './book-reader/book-reader.component';
import { BookReaderHeaderComponent } from './book-reader/book-reader-header/book-reader-header.component';
import { EpubViewerComponent } from './book-reader/epub-viewer/epub-viewer.component';
import { PdfViewerComponent } from './book-reader/pdf-viewer/pdf-viewer.component';
import { ContentTableComponent } from './book-reader/content-table/content-table.component';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { PdfReaderService } from 'src/app/shared/services/pdf-reader.service';
import { EpubReaderService } from 'src/app/shared/services/epub-reader.service';
import { MaterialModule } from 'src/app/shared/material.module';
import {OverlayModule} from '@angular/cdk/overlay';

@NgModule({
  declarations: [
    BookComponent,
    BookDescriptionComponent,
    BookContentsComponent,
    BookReaderComponent,
    BookReaderHeaderComponent,
    EpubViewerComponent,
    PdfViewerComponent,
    ContentTableComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    RouterModule.forChild(BOOK_ROUTES),
    QuillModule.forRoot(),
    PdfViewerModule,
    MaterialModule,
    OverlayModule
  ],
  providers: [
    PdfReaderService,
    EpubReaderService
  ],
  exports: []
})

export class BookModule {
}
