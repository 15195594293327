<main class="global-main-cnt" *ngIf="{
  isAuthenticated: state.isAuthenticated$ | async
} as data">
  <app-publishers-banner *ngIf="!data.isAuthenticated"></app-publishers-banner>

  <!-- #todo Uncomment when is ready -->
  <!-- <app-publishers-list></app-publishers-list> -->
  <!-- <a *ngIf="!data.isAuthenticated" routerLink="/publisher-registration">
    <button class="become-partner-button">{{'publishers_become_partner_link_text' | translate}}</button>
  </a> -->

  <app-publishers-question-form *ngIf="!data.isAuthenticated"></app-publishers-question-form>
</main>

