import { Component, Input, Inject} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {BehaviorSubject, catchError, finalize, first} from 'rxjs';
import { User } from 'src/app/shared/app-interfaces';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { ApplicationUtilsService } from 'src/app/shared/services/application-utils.service';
import {ApplicationApiService} from '../../../../shared/services/application-api.service';
import {HttpErrorResponse} from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

enum FormFields {
  oldPassword = 'oldPassword',
  password = 'password',
  repeatPassword = 'repeatPassword'
}

@Component({
  selector: 'app-edit-user-password-template',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})

export class EditPasswordComponent {
  public readonly FormFields = FormFields;
  public form: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {user: User},
    public formBuilder: FormBuilder,
    public state: ApplicationStateService,
    public api: ApplicationApiService,
    public utils: ApplicationUtilsService,
    private dialogRef: MatDialogRef<EditPasswordComponent>
  ) {
    this.form = this.formBuilder?.group({
        [FormFields.oldPassword]: ['', Validators.required],
        [FormFields.password]: ['', Validators.required],
        [FormFields.repeatPassword]: ['', Validators.required]
      },
      {
        validator: (control: AbstractControl) => {
          const password = control.get(FormFields.password).value;
          const confirmPassword = control.get(FormFields.repeatPassword).value;
          if (password !== confirmPassword) {
            control.get(FormFields.repeatPassword).setErrors({MatchPassword: true});
          } else {
            return null;
          }
        }
      });
  }

  public getFormControl(field: FormFields): FormControl {
    return this.form.get(field) as FormControl;
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }
    this.loading$.next(true);
    this.api.changePassword(
      this.data.user.id,
      this.form.value[FormFields.oldPassword],
      this.form.value[FormFields.password]).pipe(first()
    ).pipe(
      catchError((e: HttpErrorResponse): any => {
      this.utils.showSnackbar(this.utils.translate('change_password_error_msg'), 10000);
    }),
      finalize(() => this.loading$.next(false))
    ).subscribe(res => {
      this.utils.showSnackbar(this.utils.translate('change_password_success_msg'));
      this.dialogRef.close();
    });
  }

  public sendResetPasswordMail() {
    console.log('Send request');
  }

  cancel():void {
    this.dialogRef.close();
  }
}
