import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {Book, Subscriber} from 'src/app/shared/app-interfaces';
import {ApplicationStateService} from 'src/app/shared/services/application-state.service';
import {ApplicationUtilsService, CustomIcons} from 'src/app/shared/services/application-utils.service';
import {ApplicationAuthService} from 'src/app/shared/services/application-auth.service';
import { AddUserToSubscriberDialogComponent } from 'src/app/components/header/edit-user-data-template/add-user-to-subscriber-dialog-template/add-user-to-subscriber-dialog.component';
import { ApplicationApiService } from 'src/app/shared/services/application-api.service';
import { ReadersRequestSendDialogComponent } from './readers-request-dialog-template/readers-request-send-dialog.component';

@Component({
  selector: 'app-book-description',
  templateUrl: './book-description.component.html',
  styleUrls: ['./book-description.component.scss']
})
export class BookDescriptionComponent implements OnInit {
  @ViewChild('bibliography') bibliography: ElementRef;
  public currentBook$: Observable<Book>;
  public CustomIcons = CustomIcons;
  public currentDate: Date;
  public accessToReadInfo: any;

  constructor(
    private dialog: MatDialog,
    public router: Router,
    public state: ApplicationStateService,
    private clipboard: Clipboard,
    public utils: ApplicationUtilsService,
    private authService: ApplicationAuthService,
    api: ApplicationApiService
  ) {
    this.currentBook$ = state.currentBook$;
    this.currentBook$.subscribe(book => {
      this.accessToReadInstruction(book);
      if(book?.extension == '.epub'){
        book.tableContents = '';
        api.getBookNavigation(book.uid).subscribe(result => {
          result.forEach(x => book.tableContents += (x.title + "<br/>"));
        }); 
      }
    });
  }

  ngOnInit(): void {
    this.currentDate = new Date();
  }

  openBook(book: Book): void {
    if (book.hasAccessRead) {
      window.open(`/book/${book.uid}/read`);
    }
  }

  checkDoubleDot(text: string): string {
    if (text === null) {
      return;
    }
    if (text[text.length - 1] === '.') {
      return text;
    }
    return text + '.';
  }

  copyCode(code): void {
    this.clipboard.copy(code);
    this.utils.showSnackbar('book_bibliography_copy_to_clipboard_successfully');
  }

  addLicense(): any {
    return this.dialog
      .open(AddUserToSubscriberDialogComponent, {
        maxWidth: '500px',
        width: '100%',
        autoFocus: false,
        data: { userUid: this.authService.credentials.user_id }
      })
      .afterClosed().subscribe((res) => {
        if (res) {
          document.location.reload();
        }
      });
  }

  accessToReadInstruction(book: Book): any {
    if (!book?.hasAccessRead) {
      const regularSubscriptions = book?.subscribers?.filter(subscription => subscription.licenseType !== 'Individual');
      const individualSubscriptions = book?.subscribers?.filter(subscription => subscription.licenseType === 'Individual');
      const onlyIntegration = regularSubscriptions?.length === 0 && individualSubscriptions?.length > 0;
      if (onlyIntegration) {
        return this.accessToReadInfo = {
          onlyIntegration: 'book_description_read_info_integration',
          individualSubscriptions
        };
      }

      if (regularSubscriptions?.length > 0 && individualSubscriptions?.length > 0) {
        return this.accessToReadInfo = {
          integration: 'book_description_read_info_integration',
          individualSubscriptions,
          regular: 'book_description_read_info_regular',
          regularSubscriptions
        };
      }

      if (regularSubscriptions?.length > 0) {
        return this.accessToReadInfo = {
          regular: 'book_description_read_info_regular',
          regularSubscriptions
        };
      }
    }
  }

  OpenReadersRequestDialog(subscriber: Subscriber){
    this.dialog
      .open(ReadersRequestSendDialogComponent, {
        maxWidth: '1000px',
        maxHeight: '400px',
        height: 'auto',
        width: '100%',
        autoFocus: true,
        data: { subscriber: subscriber}
      });
  }
}
