import { Component, OnInit } from '@angular/core';
import { ApplicationStateService } from '../../../shared/services/application-state.service';
import { BehaviorSubject, catchError, of } from 'rxjs';
import { ApplicationApiService } from '../../../shared/services/application-api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ApplicationUtilsService } from 'src/app/shared/services/application-utils.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/component';
import { Publisher } from '../../publisher-registration/publisher-registration-form/publisher-registration-form.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-admin-main',
  templateUrl: './admin-main.component.html',
  styleUrls: ['./admin-main.component.scss'],
})
export class AdminMainComponent implements OnInit {
  public form: FormGroup;
  public isLoading$ = new BehaviorSubject<boolean>(false);

  public totalBooksAmount: number = 0;
  public totalCollectionsAmount: number = 0;
  public totalSubscriptionsAmount: number = 0;
  private currentPublisher;

  private filteredCurrencies: string[];

  constructor(
    private formBuilder: FormBuilder,
    public state: ApplicationStateService,
    public api: ApplicationApiService,
    public router: Router,
    private cookieService: CookieService,
    private utils: ApplicationUtilsService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {}

  get isFormDirty() {
    return this.form.dirty
  }
  get nameField() {
    return this.form.get('name');
  }
  get emailField() {
    return this.form.get('email');
  }
  get phoneField() {
    return this.form.get('phone');
  }
  get catalogPathField() {
    return this.form.get('catalogPath');
  }
  get currencyField() {
    return this.form.get('currency');
  }
  get logoSrc() {
    return this.form.get('logoSrc').value;
  }
  get logoFile() {
    return this.form.get('logoFile').value;
  }
  get isSubmitDisabled() {
    const { invalid, dirty, valid } = this.form;
    const logoFileChanged = !!this.form.controls['logoFile'].value;
    return invalid || (valid && !dirty && !logoFileChanged);
  }

  ngOnInit() {
    this.buildForm();
    this.fetchAndSetInitialFormValues();
    this.setInitialLogoSrc();

    this.fetchAndSetTotalBooksAmount();
    this.fetchAndSetTotalCollectionsAmount();
    this.fetchAndSetTotalSubscriptionsAmount();
    this.state.currencies$.subscribe(result => {
      this.form.get('currency').setValue(this.form.get('currency').value);
      this.filter();
    });
  }

  filter(): void {
    this.filteredCurrencies = this.state.currencies$.value.filter(o => o.title.includes(this.form.value['currency'])).map(x => x.title);
  }

  submit(): void {

    // #todo Add Success request message;

    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    // 1. Send a request to upload new image (if needed);
    if (!!this.form.controls['logoFile'].value) {
      this.api
        .putPublisherLogo$({
          image: this.form.get('logoFile').value,
        }).pipe(catchError((err): any => {
            this.utils.showSnackbar('picture_failed_upload_msg', 2000);
          })
        ).subscribe((result) => {
          this.utils.showSnackbar('picture_successfully_uploaded_msg', 2000);
          this.form.patchValue({ 'logoFile': null });
        });
    }

    // 2. Compose the main payload (pick only "dirty" fields);
    const payload = {};
    const fieldsToExcept = ['logoSrc', 'logoFile'];
    for (let fieldName in this.form.value) {
      const isDirty = this.form.controls[fieldName].dirty;
      if (isDirty && !fieldsToExcept.includes(fieldName))
        payload[fieldName] = this.form.value[fieldName];
    }
    // Fix the problem with the empty description field;
    if (payload['description'] === null) payload['description'] = '';

    let texts: string[] = [];
    if(payload['currency'] && payload['currency']!= this.currentPublisher.currency){
      payload['currency'] = {
        title: payload['currency'],
        symbol: ''
      }
      texts.push('publisher_currency_update_save_confirm_text');
    };
    if(payload['catalogPath'] && payload['catalogPath']!= this.currentPublisher.catalogPath) {
      texts.push('publisher_catalog_path_update_save_confirm_text');
    }
    if(texts.length > 0){
      this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        autoFocus: true,
        data: {text: texts, title: 'publisher_update_save_confirm_title'}
       }).afterClosed().subscribe(result => {
        if(result){
          this.publisherChange(payload);
        }
      });
    } else {
      this.publisherChange(payload);
    }
  }

  private publisherChange(payload){
    // 3. Send an API request and handle the response (if there's fields to update);
    if (Object.entries(payload).length > 0) {
      if (payload['catalogPath'] === this.currentPublisher?.catalogPath) {
        delete payload['catalogPath']
      }

      this.api
        .patchPublisher$(payload).pipe(
          catchError((err): any => {
            this.utils.showSnackbar('Handle_change_user_data_error_msg', 2000);
          })
        ).subscribe((result) => {
          this.setFields(result);
          this.utils.showSnackbar('Handle_change_user_data_success_msg', 2000);
          this.form.markAsPristine();
        });
    }
  }

  public handleLogoChange(files: File[]): void {
    if (!!files && files.length != 0) {
      const [file] = files;
      const Extensions = ['svg','jpg','png', 'jpeg'];
      const Extension = file.name.split('.').pop().toLowerCase();
      if(Extensions.indexOf(Extension) == -1){
        return;
      }
      
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => { 
        let fileString :any = reader.result as string;
        if(Extension == 'svg'){
          fileString = this.sanitizer.bypassSecurityTrustResourceUrl(fileString);//UNSAFE 
        }
        
        this.form.patchValue({ logoSrc: fileString});
        this.form.patchValue({ logoFile: file });
      };
    }
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      //published: [false],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      description: [''],
      category: [''],
      catalogPath: [''],
      currency: ['',[Validators.required, this.currencyValidator.bind(this)]],
      logoSrc: [''], // @localField
      logoFile: [null], // @localField
    });
  }

  currencyValidator(control: FormControl): {[key: string]: any} | null {
    if (control.value) {
       return this.state.currencies$.value.filter(x => x.title == control.value).length == 1? null : { 'currency': true };
    } else {
       return null;
    }
  }
   
  private fetchAndSetInitialFormValues(): void {
      this.api.getPublisher$().subscribe((result) => {
        this.setFields(result);
      });
  }

  private setFields(result:any){
    this.currentPublisher = result;
        this.form.patchValue({
          name: result.name,
          email: result.email,
          phone: result.phone,
          description: result.description,
          category: result.category,
          catalogPath: result.catalogPath,
          currency: result.currency?.title
        });
        this.state.tenantCurrency$.next(result.currency);
  }

  private setInitialLogoSrc(): void {
    this.api
      .getPublisherLogo$()
      .pipe(catchError((error) => of(error)))
      .subscribe((result) => {
        if (result !== null && result.statusText === 'OK')
          this.form.patchValue({ logoSrc: result.url });
      });
  }

  // Fetch data for the table of statistics;
  private fetchAndSetTotalBooksAmount(): void {
    this.api
      .getOwnPaginatedBooks$({ size: 0, offset: 0, body: {} })
      .subscribe((result) => {
        this.totalBooksAmount = result.page.totalElements;
      });
  }
  private fetchAndSetTotalCollectionsAmount(): void {
    this.api
      .getPaginatedCollections$({
        size: 0,
        offset: 0,
        body: {},
      })
      .subscribe((result) => {
        this.totalCollectionsAmount = result.page.totalElements;
      });
  }
  private fetchAndSetTotalSubscriptionsAmount(): void {
    this.api
      .getPaginatedSubscriptions$({
        size: 0,
        offset: 0,
        body: {},
      })
      .subscribe((result) => {
        this.totalSubscriptionsAmount = result.page.totalElements;
      });
  }
}