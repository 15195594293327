import {Injectable} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest, debounceTime, distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  Observable,
  of,
  switchMap,
  tap
} from 'rxjs';
import {Book, Collection, Currency, MySubscription, Subscription, User} from '../app-interfaces';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BooksSortingMode} from '../../components/books-catalogue/books-catalogue.component';
import {ApplicationApiService} from './application-api.service';
import {ApplicationAuthService} from './application-auth.service';
import {ApplicationUtilsService} from './application-utils.service';
import {isBefore} from 'date-fns';
import {MatDialog} from '@angular/material/dialog';
import { ConsentDialogComponent } from 'src/app/components/consent-dialog/consent-dialog.component';

@Injectable({providedIn: 'root'})
export class ApplicationStateService {

  public globalLoading$ = new BehaviorSubject<boolean>(null);
  public books$ = new BehaviorSubject<Book[]>([]);
  public ownBooks$ = new BehaviorSubject<Book[]>([]);
  // public subscribersGroups$ = new BehaviorSubject<any>([]);
  public currentBook$ = new BehaviorSubject<Book>(null);
  public bookSearchString$ = new BehaviorSubject<string>('');
  public subscribersSearchString$ = new BehaviorSubject<string>('');
  public usersSearchString$ = new BehaviorSubject<string>('');
  public adminsSearchString$ = new BehaviorSubject<string>('');
  public readersBookAutocompleteString$ = new BehaviorSubject<string>('');
  public bookSortingMode$ = new BehaviorSubject(BooksSortingMode.ByTitle);
  public collections$ = new BehaviorSubject<Collection[]>([]);
  public subscriptions$ = new BehaviorSubject<Subscription[]>([]);
  public subscriptionMode$ = new BehaviorSubject<string>(null);
  public createSubscriptionStep$ = new BehaviorSubject<number>(0);
  public bookEditorStep$ = new BehaviorSubject<number>(0);
  public subscribers$ = new BehaviorSubject<any[]>([]);
  public publishers$ = new BehaviorSubject<any[]>([]);
  public currencies$ = new BehaviorSubject<Currency[]>([]);
  public currentSubscriber$ = new BehaviorSubject<any>({});
  public selectedSubscriber$ = new BehaviorSubject<any>({});
  public selectedIndividualSubscriber$ = new BehaviorSubject<any>({});
  public currentSubscription$ = new BehaviorSubject<any>(null);
  public users$ = new BehaviorSubject<User[]>([]);
  public readers$ = new BehaviorSubject<User[]>([]);
  public userProfileSubscribers$ = new BehaviorSubject<any[]>([]);
  public userProfilePurchases$ = new BehaviorSubject<any[]>([]);

  public tenantRearersRequests$ = new BehaviorSubject<any[]>([]);

  public routerUrl$ = new BehaviorSubject<string[]>(null);
  public routeParamBookId$ = new BehaviorSubject<string>(null);
  public routeParamSubscriptionId$ = new BehaviorSubject<string>(null);

  public mySubscriptions$ = new BehaviorSubject<MySubscription[]>(null);
  public publisherFilter$ = new BehaviorSubject<string>('');
  public booksByIpFilter$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$: BehaviorSubject<any> = this.authService.isAuthenticated$;
  public adminMode$: BehaviorSubject<boolean> = this.authService.adminMode$;
  public superAdminMode$: BehaviorSubject<boolean> = this.authService.superAdminMode$;
  public user_id$: BehaviorSubject<string> = this.authService.user_id$;
  public tenantCurrency$: BehaviorSubject<Currency> = new BehaviorSubject<Currency>(null);
  public haveBooksByIp$: BehaviorSubject<boolean> = this.authService.haveBooksByIp$;
  public consentCheck$: BehaviorSubject<boolean> = this.authService.consentCheck$;
  public consentDialogOpened$ = new BehaviorSubject<boolean>(null);
  public classification$ = new BehaviorSubject<any>(null);
  constructor(
    public authService: ApplicationAuthService,
    public utils: ApplicationUtilsService,
    public api: ApplicationApiService,
    public router: Router,
    public dialog: MatDialog,
  ) {

    this.globalLoading$.next(true);

    this.getAllAccordingToAccess().pipe(
      catchError((err: any): any => {
        this.handleLoadDataException();
      })
    ).subscribe(() => {
      this.globalLoading$.next(false);
    });

    this.routeParamBookId$.pipe(
      switchMap(bookId => {
        return bookId ? this.api.getBook$(bookId) : of(null);
      })
    ).subscribe((book: Book) => {
        this.currentBook$.next(book);
      }
    );

    this.routeParamSubscriptionId$.pipe(
      switchMap(subscriptionId => {
        return subscriptionId ? this.api.getSubscription$(subscriptionId) : of(null);
      })
    ).subscribe((subscription: Subscription) => {
        this.currentSubscription$.next(subscription);
      }
    );


    // this.route.paramMap.pipe(
    //   switchMap(params => { return params?.get('subscriptionId') })
    // ).subscribe(this.routeParamSubscriptionId$);

    // combineLatest([this.subscriptions$, this.routeParamSubscriptionId$]).pipe(
    //   filter(([subscriptions, subscriptionId]) => subscriptions?.length && !!subscriptionId))
    //   .subscribe(([subscriptions, subscriptionId]) => {
    //     this.currentSubscription$.next(subscriptions?.find(subscription => subscriptionId == subscription.uid));
    //   });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.routerUrl$.next(event.url.split('/'));
        if (this.isAuthenticated$.value && !this.consentCheck$.value && !this.consentDialogOpened$.value && !this.routerUrl$.value.includes('consent')) {
          this.checkConsent();
        }
      });

    // combineLatest([this.books$, this.routerUrl$]).pipe(
    //   filter(([books, routerUrl]) => books?.length && !!routerUrl))
    //   .subscribe(([books, routerUrl]) => {
    //     const currentBook = books?.find(book => routerUrl?.includes(book?.uid));
    //     if (currentBook) {
    //       this.api.getBook$(currentBook?.uid).subscribe(book => {
    //         this.currentBook$.next(book);
    //       });
    //       return;
    //     }
    //     this.currentBook$.next(null);
    //   });

    // combineLatest([this.subscriptions$, this.routerUrl$]).pipe(
    //   filter(([subscriptions, routerUrl]) => subscriptions?.length && !!routerUrl))
    //   .subscribe(([subscriptions, routerUrl]) => {
    //     this.currentSubscription$.next(subscriptions?.find(subscription => (routerUrl.includes(subscription.uid)) || null));
    //   });

    
    api.getCurrentCurrency()
      .subscribe((result) => {
        this.tenantCurrency$.next(result);
    });

    api.GetAllCurrencies()
      .subscribe((result) => {
        this.currencies$.next(result);
    });
  }


  // public setCurrentBook(bookId: string) {
  // }

  public getSortedAndFilteredBooks$(): Observable<Book[]> {
    return combineLatest([this.bookSearchString$, this.bookSortingMode$])
      .pipe(debounceTime(500), switchMap(([searchString, sortingMode]) => {
      const normalizedString = searchString.toLowerCase().trim();
      return this.books$.pipe(map((books: Book[]) => {
        return books.filter(book => {
          return book.title.toLowerCase().includes(normalizedString)
            || book.authors.toLowerCase().includes(normalizedString)
            || book.annotation.toLowerCase().includes(normalizedString);
        })
          .sort((book1, book2) => {
            if (sortingMode === BooksSortingMode.ByTitle) {
              if (book1.title > book2.title) {
                return 1;
              }
              if (book1.title < book2.title) {
                return -1;
              }
            }
            if (sortingMode === BooksSortingMode.ByAuthor) {
              if (book1.authors > book2.authors) {
                return 1;
              }
              if (book1.authors < book2.authors) {
                return -1;
              }
            }
            if (sortingMode === BooksSortingMode.ByPublicationYear) {
              if (book1.publicationYear > book2.publicationYear) {
                return 1;
              }
              if (book1.publicationYear < book2.publicationYear) {
                return -1;
              }
            }
            return 0;
          });
      }));
    }));
  }

  public getBooks(async = false, size = 10000, offset = 0, body = {}): any {
    if (async) {
      return this.api.getBooks$(size, offset, body).pipe(first(), tap(books => {
        this.books$.next(books.data ?? []);
      }));
    }
    this.api.getBooks$(size, offset, body).pipe(first()).subscribe(books => {
      this.books$.next(books.data ?? []);
    });
  }

  public getOwnPaginatedBooks(async = false, size = 10000, offset = 0, body = {}): any {
    if (async) {
      return this.api.getOwnPaginatedBooks$({size, offset, body}).pipe(first(), tap(books => {
        this.ownBooks$.next(books.data ?? []);
      }));
    }
    this.api.getOwnPaginatedBooks$({size, offset, body}).pipe(first()).subscribe(books => {
      this.ownBooks$.next(books.data ?? []);
    });
  }

  public getBooksByIp(size = 10000, page = 0, body = {}): any {
    return this.api.getBooksByIp$(size, page, body).pipe(first(), tap(books => {
      this.books$.next(books.data ?? []);
    }));
  }


  public getCollections(async = false, size = 10000, offset = 0): any {
    if (async) {
      return this.api.getCollections$(size, offset).pipe(first(), tap(collections => {
        this.collections$.next(collections.data ?? []);
      }));
    }
    this.api.getCollections$(size, offset).pipe(first()).subscribe(collections => {
      this.collections$.next(collections.data ?? []);
    });
  }

  public getSubscriptions(async = false, size = 10000, offset = 0, body = {}): any {
    if (async) {
      return this.api.getSubscriptions$(size, offset, body).pipe(first(), tap(subscriptions => {
        this.subscriptions$.next(subscriptions.data ?? []);
      }));
    }
    this.api.getSubscriptions$(size, offset, body).pipe(first()).subscribe(subscriptions => {
      this.subscriptions$.next(subscriptions.data ?? []);
    });
  }

  public getSubscribers(async = false, size = 10000, offset = 0): any {
    this.api.getPaginatedSubscribers$({size, offset}).pipe(first()).subscribe(subscribers => {
      this.subscribers$.next(subscribers.data ?? []);
    });
  }

  public getSubscriber(id: string): any {
    this.api.getSubscriber$(id).pipe(first()).subscribe(subscriber => {
      this.currentSubscriber$.next(subscriber);
    });
  }
  public getPublishers(async = false, size = 10000, offset = 0, userRole = ''): any {
    this.api.getPaginatedPublishers$({size, offset, filter: {}, userRole}).pipe(first()).subscribe(publishers => {
      this.publishers$.next(publishers?.data ?? []);
    });
  }


  public getPaginatedUsers(size, offset, parameter, body?): any {
    return this.api.getPaginatedUsers$({size, offset, parameter, body}).pipe(first(), tap(users => {
      this.users$.next(users.data ?? []);
    }));
  }

  public getPaginatedReaders(size, offset, body?, parameter?): any {
    return this.api.getPaginatedReaders$({size, offset, body, parameter}).pipe(first(), tap(readers => {
      this.readers$.next(readers.data ?? []);
    }));
  }

  public getUserSubscribers(uid: string): any {
    return this.api.getUserSubscribers$(uid).pipe(first()).subscribe((res: any) => {
      this.userProfileSubscribers$.next(res);
    });
  }

  public getUserPurchases(uid: string): any {
    return this.api.getUserPurchases$(uid).pipe(first()).subscribe(res => {
      this.userProfilePurchases$.next(res);
    });
  }

  public getMySubscriptions(callback?: () => void): void {
    const uid = this.user_id$.getValue();
    if (uid) {
      this.api
        .fetchSubscriptionsByUser$(uid)
        .pipe(
          map((list) =>
            list.map(({uid, issuedTo, address, code, phone, end}) => ({
              uid,
              subscriber: issuedTo,
              address,
              phone,
              code,
              isActive: isBefore(new Date(), new Date(end)),
            }))
          ),
          finalize(() => {
            callback?.();
          })
        )
        .subscribe((result) => {
          this.mySubscriptions$.next(result);
        });
    } else {
      this.mySubscriptions$.next([]);
      callback?.();
    }
  }

  public getAllAccordingToAccess(): Observable<any> {
    return this.authService.isAuthenticated$.pipe(distinctUntilChanged(), switchMap(isAuthenticated => {
      if (isAuthenticated) {
        if (!this.consentCheck$.value && !this.consentDialogOpened$.value && !this.routerUrl$.value.includes('consent')) {
          this.checkConsent();
        }
        return this.authService.adminMode$.pipe(distinctUntilChanged(), switchMap(adminMode => {
          if (adminMode) {
            return combineLatest([
              this.getBooks(true),
              this.getOwnPaginatedBooks(true),
              this.getCollections(true),
              this.getSubscriptions(true),
            ]).pipe(first());
          }
          return this.getBooks(true);
        }));
      } else {
        return this.getBooks(true);
      }
    }), distinctUntilChanged());
  }

  public handleLoadDataException(stopSpinner?: boolean): void {
    if (stopSpinner) {
      this.authService.globalLoading$.next(false);
    }
    this.utils.showSnackbar('data_loading_error_msg', 10000);
  }

  public checkConsent(): void {
    this.api.consentCheck$().subscribe(res => {
      if (res && !this.authService.consentCheck$.value) {
        this.authService.consentCheck$.next(res);
        if (this.routerUrl$.value.includes('read')) {
          this.api.startBookReading(this.routeParamBookId$.value).subscribe();
        }
      }

      if (!res && !this.consentDialogOpened$.value) {
        this.dialog.open(ConsentDialogComponent, {
          maxWidth: '500px',
          width: '100%',
          autoFocus: false,
          disableClose: true
        }).afterClosed().pipe().subscribe(result => {
          this.consentDialogOpened$.next(false);
        });
      }
    });
  }

  public getClassification(): any {
    return this.api.getClassification$().subscribe(res => {
      return this.classification$.next(res);
    });
  }

  public getTenantReadersRequests(size, offset, parameter): any {
    return this.api.GetTenantRequests({size, offset, parameter}).pipe(first(), tap(res => {
      this.tenantRearersRequests$.next(res.data);
    }));
  }
}
