<div *ngIf="{
    users: this.state.users$ | async,
    isLoading$: isLoading$ | async,
    subscribers: state.subscribers$ | async,
    selectedSubscriber: state.selectedIndividualSubscriber$ | async,
    bookSearchString: (state.readersBookAutocompleteString$ | async).trim(),
    searchString: (state.usersSearchString$ | async).trim()
  } as data">

    <div class="options" style="gap: 20px; margin-top: 20px;">
        <div class="search" style="margin-bottom: 0">
            <mat-form-field
                [floatLabel]="'never'"
                class="global-mat-form-field">
                <mat-select [placeholder]="'admin_readers_subscriber_select_placeholder' | translate">
                    <mat-select-trigger>
                        <div>{{data.selectedSubscriber?.title}}</div>
                    </mat-select-trigger>
                    <mat-option (click)="state.selectedIndividualSubscriber$.next(null)">
                    --
                    </mat-option>
                    <mat-option *ngFor="let subscriber of filteredSubscribers" [value]="subscriber.uid">
                        <div class="global-flex-row-space-between-center">
                            <span (click)="changeSelectedSubscriber(subscriber.uid)" style="flex: 1">
                            {{subscriber.title}}
                            </span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="options" *ngIf="data.selectedSubscriber" style="gap: 20px; margin-top: 20px;">
        <div class="search">
            <mat-form-field [floatLabel]="'never'" style="height: auto" class="filter-cnt global-mat-form-field">
            <mat-label>{{'admin_readers_books_search_string_label' | translate}}</mat-label>
            <input #bookInput type="search" matInput [value]="data.bookSearchString"
                (keyup)="executeBookSearch(bookInput.value?.trim())"
                [placeholder]="'admin_readers_books_search_string_placeholder' | translate" [matAutocomplete]="autoGroup"/>
            <mat-autocomplete #autoGroup="matAutocomplete">
                <mat-optgroup *ngFor="let collection of filteredCollections" [label]="collection.title == 'outOfCollection'? ('out_of_collection' | translate) : collection.title">
                <mat-option *ngFor="let book of collection?.books" [value]="book.title" (click)="addBook(book)">
                    {{ book.title }}
                </mat-option>
                </mat-optgroup>
            </mat-autocomplete>
            <mat-chip-list #chipGrid>
                <mat-chip *ngFor="let book of selectedBooks" [matTooltip]="book?.title">
                <span class="chip">{{ book?.title }}</span>
                <mat-icon (click)="remove(book?.uid)">close</mat-icon>
                </mat-chip>
            </mat-chip-list>
            <mat-icon matSuffix class="global-icon-inactive">search</mat-icon>
            </mat-form-field>
        </div>
        <div class="calendar">
            <mat-form-field>
            <mat-label>{{'admin_readers_date_picker_label' | translate}}</mat-label>
            <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
                <input matStartDate formControlName="start" [placeholder]="'admin_readers_date_picker_start_date_placeholder' | translate " (dateChange)="dateChanged()">
                <input matEndDate formControlName="end" [placeholder]="'admin_readers_date_picker_end_date_placeholder' | translate " (dateChange)="dateChanged()">
            </mat-date-range-input>
            <mat-hint>{{ 'date_format_example' | translate }}</mat-hint>
            <mat-datepicker-toggle style="font-size: 24px" matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="!data.selectedSubscriber" style="text-align: right;width: 100%; margin-right: 0px;">
        {{'readers_count_found' | translate}}{{page.totalElements}}
    </div>
    <div class="table" style="position: relative">
        <div *ngIf="data.isLoading$" class="global-spinner-cnt">
          <mat-spinner color="primary" diameter="50"></mat-spinner>
        </div>

        <ngx-datatable *ngIf="!data.selectedSubscriber"
            #table [headerHeight]="60" [footerHeight]="0" [rowHeight]="50"
                [columnMode]="'force'" [rows]="rows" [columns]="columns" [virtualization]="false" [externalPaging]="false"
                [count]="(page.totalElements + rows?.length)" [offset]="page.offset" [limit]="page.limit"
                (page)="setPage($event)" [messages]="{emptyMessage: this.utils.getTableMessage('empty_table_message' | translate)}">
            
            <ngx-datatable-column [name]="'column_header_user' | translate" prop="email" [sortable]="true">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <span class="mobile-hidden">{{ column.name }}</span>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span class="mobile-hidden">{{ row.email }}</span>
                </ng-template>
            </ngx-datatable-column>
    
            <ngx-datatable-column [name]="'column_header_date' | translate" prop="created" [sortable]="true">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <span class="mobile-hidden">{{ column.name }}</span>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span class="mobile-hidden">{{ formattedDate(row.createdDate) }}</span>
                </ng-template>
            </ngx-datatable-column>
    
            <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
                <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                    <button mat-icon-button [matMenuTriggerFor]="menu" class="table-edit-button">
                    <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="editUser(row)">
                        <mat-icon>edit</mat-icon>
                        <span>{{'admin_users_edit_button' | translate}}</span>
                    </button>
                    <button mat-menu-item (click)="deleteUser(row.id)">
                        <mat-icon>close</mat-icon>
                        <span>{{'admin_users_delete_button' | translate}}</span>
                    </button>
                    <button mat-menu-item (click)="changePassword(row)">
                        <mat-icon>lock</mat-icon>
                        <span>{{'admin_users_change_password_button' | translate}}</span>
                    </button>
                    <button mat-menu-item (click)="resendInvitation(row.id)">
                        <mat-icon>send</mat-icon>
                        <span>{{'admin_users_invitation_button' | translate}}</span>
                    </button>
                    </mat-menu>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>

        <ngx-datatable *ngIf="data.selectedSubscriber"
            #groupTable
            [headerHeight]="60"
            [footerHeight]="0"
            [rowHeight]="50"
            [columnMode]="'force'"
            [rows]="rows"
            [groupRowsBy]="'bookId'"
            [columns]="columns"
            [virtualization]="false"
            [externalPaging]="false"
            [count]="page.totalElements"
            [offset]="page.offset"
            [limit]="page.limit"
            (page)="setPage($event)"
            [messages]="{emptyMessage: this.utils.getTableMessage('empty_table_message' | translate)}"
            >

            <ngx-datatable-group-header [rowHeight]="50" #groupHeader>
                <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
                <div class="group-header" (click)="toggleExpandGroup(group)">
                    <span [class.datatable-icon-right]="!expanded"
                        [class.datatable-icon-down]="expanded">
                        <span class="group-title" style="width: 100%;">{{ group.value[0]?.title}}</span>
                    </span>
                    <span style="text-align: right;">
                        {{'readers_count_found' | translate}}{{group.value.length}}
                    </span>
                </div>
                </ng-template>
            </ngx-datatable-group-header>

            <ngx-datatable-column [width]="50" [sortable]="false"></ngx-datatable-column>

            <ngx-datatable-column [name]="'column_header_user' | translate" prop="email" [sortable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                <span class="mobile-hidden">{{ column.name }}</span>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span class="mobile-hidden">{{ row.email }}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [name]="'admin_users_table_column_title_first_time_opened' | translate" prop="firstTimeOpened" [sortable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                <span class="mobile-hidden">{{ column.name }}</span>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span class="mobile-hidden">{{ utils.getformattedDate(row.firstTimeOpened) }}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [name]="'admin_users_table_column_title_last_time_opened' | translate" prop="lastTimeOpened" [sortable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                <span class="mobile-hidden">{{ column.name }}</span>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span class="mobile-hidden">{{ utils.getformattedDate(row?.lastTimeOpened)}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [name]="'admin_users_table_column_title_times_opened' | translate" prop="timesOpened" [width]="50" [sortable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                <span class="mobile-hidden">{{ column.name }}</span>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span class="mobile-hidden">{{ row.timesOpened }}</span>
                </ng-template>
            </ngx-datatable-column>


            <!-- <ngx-datatable-column [name]="'admin_users_table_column_title_last_page' | translate" prop="lastPage" [sortable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                <span class="mobile-hidden">{{ column.name }}</span>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span class="mobile-hidden">{{ row.lastPage }}</span>
                </ng-template>
            </ngx-datatable-column> -->


            <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
                <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                    <user-menu [(page)]="page" [(isLoading$)]="isLoading$" [(reload)]="getFilteredPaginatedReaders" [row]="row"></user-menu>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>   
</div>