<div class="global-main-cnt"
     *ngIf="{
      adminMode: state.adminMode$ | async,
      searchString: state.bookSearchString$ | async,
      loading: loading$ | async,
      routerUrl: state.routerUrl$ | async,
      isAuthenticated: state.isAuthenticated$ | async,
      publishers: state.publishers$ | async,
      haveBooksByIp: state.haveBooksByIp$ | async
   } as data"

     [ngStyle]="data.isAuthenticated || data.routerUrl?.includes('authorization') ? {} : {'borderTop': '1px solid #BDBDBD'}">
  <div class="list-options-cnt global-flex-row-space-between-center">
    <div class="sorting-cnt global-flex-row-start-center">
      <mat-select class="global-select"
                  [value]="state.bookSortingMode$?.value ?? BooksSortingMode.ByTitle">
        <mat-option
          [ngStyle]="{'fontFamily': 'Open Sans', 'fontWeight' : 600}"
          [value]="BooksSortingMode.ByTitle"
          (click)="state.bookSortingMode$.next(BooksSortingMode.ByTitle)">{{('books_catalogue_by_title_label' | translate).toUpperCase()}}
        </mat-option>
        <mat-option
          [ngStyle]="{'fontFamily': 'Open Sans', 'fontWeight' : 600}"
          [value]="BooksSortingMode.ByAuthor"
          (click)="state.bookSortingMode$.next(BooksSortingMode.ByAuthor)">{{('books_catalogue_by_author_label' | translate).toUpperCase()}}
        </mat-option>
        <mat-option
          [ngStyle]="{'fontFamily': 'Open Sans', 'fontWeight' : 600}"
          [value]="BooksSortingMode.ByPublicationYear"
          (click)="state.bookSortingMode$.next(BooksSortingMode.ByPublicationYear)">{{('books_catalogue_by_year_label' | translate).toUpperCase()}}
        </mat-option>
        <!--        <mat-option-->
        <!--          [value]="BooksSortingMode.ByCategory"-->
        <!--          (click)="state.bookSortingMode$.next(BooksSortingMode.ByCategory)">{{'books_catalogue_by_category_label' | translate}}-->
        <!--        </mat-option>-->
      </mat-select>
    </div>
    <mat-form-field
      [floatLabel]="'never'"
      class="filter-cnt global-mat-form-field"
    >
      <input
        #searchInput
        type="search"
        class="filter-cnt-input"
        matInput
        [value]="data.searchString"
        [placeholder]="('books_catalogue_search_placeholder' | translate).toUpperCase()"
        autocomplete="off"
        (keyup)="executeSearch(searchInput.value?.trim())"
      >
      <mat-icon matSuffix class="global-icon-inactive">search</mat-icon>
    </mat-form-field>


    <div class="publisher-filter global-mat-form-field">
      <mat-select ngModel (ngModelChange)="setPublisherFilter($event)"
                  [(value)]="defaultFilter"
                  [placeholder]="'books_catalogue_filter_placeholder' | translate">
        <mat-option (click)="clearFilters()">--</mat-option>
        <mat-option *ngIf="data.haveBooksByIp" [value]="'subscriberByIp'">{{'books_catalogue_filter_books_by_ip_option' | translate}}</mat-option>
        <mat-option *ngFor="let publisher of publishers" [value]="publisher.catalogPath">{{publisher.name}}</mat-option>
      </mat-select>
    </div>

    <!-- <button mat-icon-button (click)="setPageViewType('row')" disableRipple>
      <mat-icon [svgIcon]="CustomIcons.view_sequential"
                [ngClass]="{
                'sequential-icon-active': pageViewType === 'row',
                'sequential-icon-disabled': pageViewType === 'grid'
                }"></mat-icon>
    </button>

    <button mat-icon-button (click)="setPageViewType('grid')" disableRipple>
      <mat-icon [svgIcon]="CustomIcons.view_grid"
                [ngClass]="{
                'grid-icon-active': pageViewType === 'grid',
                'grid-icon-disabled': pageViewType === 'row'
                }"></mat-icon>
    </button> -->
  </div>
  <div class="books-cnt">
    <div *ngIf="data.loading" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>
    <ng-container *ngFor="let book of rows">
      <app-book [book]="book"></app-book>
    </ng-container>
  </div>

  <div *ngIf="!data.loading" style="margin-top: 22px">
    <app-paginator
      [page]="page.currentPage"
      [size]="page.limit"
      [count]="page.totalElements"
      (changePage)="changePage($event)"
      (changeLimit)="changeLimit($event)"
    ></app-paginator>
  </div>
</div>

