import { ChangeDetectionStrategy, Component, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { format } from 'date-fns';
import {BehaviorSubject, debounceTime, Subscription } from 'rxjs';
import { Page, PageLimitOptions } from 'src/app/shared/app-global-variables';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { ApplicationUtilsService } from 'src/app/shared/services/application-utils.service';
import { ApplicationApiService } from 'src/app/shared/services/application-api.service';

@Component({
    selector: 'all-mode-readers',
    templateUrl: 'all-mode.component.html',
    styleUrls: ['all-mode.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })
  export class AllModeReadersComponent{
    public isLoading$ = new BehaviorSubject<boolean>(false);
    public oldSearchValue: string;
    private subs: Subscription[] = [];
    rows: any;
    public pageLimitOptions = PageLimitOptions;

    page = new Page();
    constructor(
      public state: ApplicationStateService,
      public utils: ApplicationUtilsService,
      public cd: ChangeDetectorRef,
      private dialog: MatDialog,
      public api: ApplicationApiService
    ) {
      this.page.offset = 0;
      this.page.limit = 25;//PageLimitOptions[0].value;

      this.subs.push(this.state.usersSearchString$.pipe(debounceTime(500)).subscribe(searchString => {
        this.setPage(this.page);
      }));
    }

    executeSearch(searchString: string): void {
      if (searchString !== this.oldSearchValue) {
        this.oldSearchValue = searchString;
        this.state.usersSearchString$.next(searchString);
      }
    }

    onLimitChange(limit: any): any {
      this.page.limit = Number(limit);
      this.setPage(this.page);
    }

    reload(){
      this.setPage(this.page);
    }

    setPage(pageInfo): any {
      this.isLoading$.next(true);
      this.page.offset = pageInfo.offset;
        let body = {};
        if (this.state.usersSearchString$.value.length > 0) {
          body = {
            filter: [
              {
                column: 'Email',
                method: 'contains',
                data: this.state.usersSearchString$.value
              }
            ]
          };
        }
        this.state.getPaginatedUsers(this.page.limit, this.page.offset * this.page.limit, 'User', body).subscribe((res) => {
          const {data, page} = res;
          this.rows = [...data?.map(user => {
            return {...user, tenantShortDTOs: user.tenantShortDTOs.map(tenant => tenant.uid)};
          })];
          this.page.totalElements = page.totalElements;
          this.page.totalPages = page.totalElements / this.page.limit >= 1 ? page.totalElements / this.page.limit : 1;
          this.isLoading$.next(false);
        });  
      this.cd.markForCheck();
    }

    formattedDate(date): any {
      return date ? format(new Date(date), 'dd.MM.yyyy') : "";
    }
  }