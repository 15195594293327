import { ChangeDetectionStrategy, Component, ChangeDetectorRef, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, catchError, finalize, first, switchMap } from 'rxjs';
import { Page } from 'src/app/shared/app-global-variables';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { ApplicationUtilsService } from 'src/app/shared/services/application-utils.service';
import { EditUserComponent } from '../../../users/edit-form-template/edit-user.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/component';
import { ApplicationApiService } from 'src/app/shared/services/application-api.service';
import { EditPasswordComponent } from '../../../users/edit-user-password-template/edit-password.component';

@Component({
    selector: 'user-menu',
    templateUrl: 'user-menu.component.html',
    styleUrls: ['user-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })
  export class UserMenuColumn {
    @Input() page: Page;
    @Input() isLoading$: BehaviorSubject<boolean>;
    @Input() reload: any;
    @Input() row: any;

    constructor(
        public state: ApplicationStateService,
        public utils: ApplicationUtilsService,
        public cd: ChangeDetectorRef,
        private dialog: MatDialog,
        public api: ApplicationApiService
      ) {
      }

    editUser(row: any): any {
        return this.dialog
          .open(EditUserComponent, {
            maxWidth: '500px',
            width: '100%',
            data: {user: row, title: 'edit_user_form_title'}
          })
          .afterClosed().subscribe((res) => {
            if (res) {
              row.email = res.email;
              row.name = res.name;
            }
          });
      }
  
      deleteUser(id: string): void {
        this.dialog
          .open(ConfirmDialogComponent, {
            maxWidth: '500px',
            width: '100%',
            autoFocus: false,
            data: {text: 'delete_user_confirm_text', title: 'delete_user_confirm_title'}
          })
          .afterClosed()
          .pipe(first()).subscribe(yes => {
          if (!yes) {
            return;
          }
          this.isLoading$.next(true);
          this.api.deleteUser$(id)
            .pipe(
              first(),
              catchError((): any => {
                this.utils.showSnackbar('delete_user_error_msg');
              }),
              finalize(() => this.isLoading$.next(false)),
              switchMap(() => {
                return this.state.getPaginatedReaders(this.page.limit, this.page.offset * this.page.limit);
              })
            ).subscribe(() => {
            this.isLoading$.next(false);
            this.reload();
            this.utils.showSnackbar('delete_user_success_msg');
          });
        });
      }
  
      changePassword(row: any): any {
        return this.dialog
          .open(EditPasswordComponent, {
            maxWidth: '500px',
            width: '100%',
            disableClose: true,
            autoFocus: false,
            data: {user: row}
          });
      }
  
      resendInvitation(id: string): any {
        this.isLoading$.next(true);
        this.api.resendInvitation(id)
          .pipe(
            first(),
            catchError((): any => {
              this.utils.showSnackbar('resend_invitation_error_msg');
            }),
            finalize(() => this.isLoading$.next(false))
          ).subscribe(() => {
          this.isLoading$.next(false);
          this.utils.showSnackbar('resend_invitation_success_msg');
        });
      }
  }