<div *ngIf="{
    users: this.state.users$ | async,
    isLoading$: isLoading$ | async,
    subscribers: state.subscribers$ | async,
    selectedSubscriber: state.selectedSubscriber$ | async,
    searchString: (state.usersSearchString$ | async).trim()
  } as data">

    <div class="options" style="gap: 20px; margin-top: 20px;">
        <div class="search" style="margin-bottom: 0">
            <mat-form-field [floatLabel]="'never'" style="height: auto" class="filter-cnt global-mat-form-field">
                <mat-label>{{'admin_readers_users_search_string_label' | translate}}</mat-label>
                    <input #readerInput
                        matInput
                        type="search"
                        [(ngModel)]="data.searchString"
                        autocomplete="off"
                        (keyup)="executeSearch(readerInput.value?.trim())"
                        [placeholder]="'admin_readers_users_search_string_placeholder' | translate"/>
                <mat-icon matSuffix class="global-icon-inactive">search</mat-icon>
            </mat-form-field>
        </div>
    </div>
    <div style="text-align: right;width: 100%; margin-right: 0px;">
        {{'readers_count_found' | translate}}{{page.totalElements}}
    </div>
    <div class="table" style="position: relative">
        <div *ngIf="data.isLoading$" class="global-spinner-cnt">
          <mat-spinner color="primary" diameter="50"></mat-spinner>
        </div>
        <!-- [columnMode]="'force'" -->
        <ngx-datatable #table [headerHeight]="60" [footerHeight]="50" [rowHeight]="50" style="width: 100%;"
            [columnMode]="'flex'" [rows]="rows" [columns]="columns" [virtualization]="false" [externalPaging]="true"
            [count]="(page.totalElements + rows?.length)" [offset]="page.offset" [limit]="page.limit"
            (page)="setPage($event)" [messages]="{emptyMessage: this.utils.getTableMessage('empty_table_message' | translate)}">
        
            <ngx-datatable-column  [flexGrow]="1" [name]="'column_header_user' | translate" prop="email" [sortable]="true">
              <ng-template let-column="column" ngx-datatable-header-template>
                <span class="mobile-hidden">{{ column.name }}</span>
              </ng-template>
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span class="mobile-hidden">{{ row.email }}</span>
              </ng-template>
            </ngx-datatable-column>
  
            <ngx-datatable-column  [flexGrow]="1" [name]="'column_header_date' | translate" prop="created" [sortable]="true">
              <ng-template let-column="column" ngx-datatable-header-template>
                <span class="mobile-hidden">{{ column.name }}</span>
              </ng-template>
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span class="mobile-hidden">{{ formattedDate(row.createdDate) }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column  [flexGrow]="1" [name]="'column_header_last_login' | translate" prop="loginned" [sortable]="true">
              <ng-template let-column="column" ngx-datatable-header-template>
                <span class="mobile-hidden">{{ column.name }}</span>
              </ng-template>
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span class="mobile-hidden">{{ row.lastLogin != null ? (row.lastLogin | date:'dd.MM.yyyy HH:mm'): ('last_login_undefined' | translate)}}</span>
              </ng-template>
            </ngx-datatable-column>
  
            <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                <user-menu [(page)]="page" [(isLoading$)]="isLoading$" [(reload)]="reload" [row]="row"></user-menu>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible">
                <div *ngIf="rows?.length > 0" class="page-limit-selection">
                  <mat-select
                    [value]="page.limit"
                    style="width: 200px"
                    class="global-select"
                    (valueChange)="onLimitChange($event)">
                    <mat-option
                      *ngFor="let option of pageLimitOptions"
                      [value]="option.value"
                    >
                      {{ option.value }} {{ 'pagination_on_page' | translate }}
                    </mat-option>
                  </mat-select>
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!((page.totalElements / pageSize) > 1)"
                  (change)="table.onFooterPage($event)">
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
      </ngx-datatable>
    </div>   
</div>