<div class="google-auth-container">
  <div id="g_id_onload"
       data-client_id="545708396310-ct2thmbi56cf4jk1con884sphrusrgkd.apps.googleusercontent.com"
       data-callback="handleCredentialResponse">
  </div>
  <div class="g_id_signin"
       data-type="standard"
       data-shape="rectangular"
       data-theme="outline"
       data-text="signin_with"
       data-size="large"
       data-logo_alignment="left">
  </div>
</div>

