<div class="admin-users global-main-cnt global-flex-column-space-between-center"
     *ngIf="{
     users: this.state.users$ | async,
     isLoading$: isLoading$ | async,
     searchString: (state.adminsSearchString$ | async).trim()
   } as data
">
  <div class="options">
    <div class="search">
      <mat-form-field
        [floatLabel]="'never'"
        class="global-mat-form-field"
      >
        <input
          #searchInput
          type="search"
          matInput
          [value]="data.searchString"
          [placeholder]="'admin_users_search_placeholder' | translate"
          autocomplete="off"
          (keyup)="executeSearch(searchInput.value)"
        >
        <mat-icon matSuffix class="global-icon-inactive">search</mat-icon>
      </mat-form-field>
    </div>
    <div class="btn-section">
      <button mat-button
              (click)="createUser()">
        <mat-icon [svgIcon]="CustomIcons.plus"></mat-icon>
        <span>{{'admin_users_create_user_button' | translate}}</span>
      </button>
    </div>
  </div>
  <div class="table" style="position: relative">
    <div *ngIf="data.isLoading$" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>
    <div style="text-align: right;width: 100%; margin-right: 0px;">
      {{'admins_count_found' | translate}}{{page.totalElements}}
    </div>
    <ngx-datatable
      *ngIf="role !== 'User'"
      #table
      [headerHeight]="60"
      [footerHeight]="50"
      [rowHeight]="50"
      [columnMode]="'force'"
      [rows]="rows"
      [columns]="columns"
      [virtualization]="false"
      [externalPaging]="true"
      [count]="page.totalElements"
      [offset]="page.offset"
      [limit]="page.limit"
      (page)="setPage($event)"
      [messages]="{emptyMessage: this.utils.getTableMessage('empty_table_message' | translate)}"
    >


      <ngx-datatable-column [name]="'column_header_user' | translate" prop="email" [sortable]="true">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="mobile-hidden">{{ column.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ row.email }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [name]="'column_header_date' | translate" prop="created" [sortable]="true">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="mobile-hidden">{{ column.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ formattedDate(row.createdDate) }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [name]="'column_header_publishers' | translate" prop="publishers" [sortable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="mobile-hidden">{{ column.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span style="white-space: pre-wrap" class="mobile-hidden">{{ row.tenantShortDTOs ? row.tenantShortDTOs.join(', ') : '' }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        [width]="50"
        [resizeable]="false"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="false"
      >
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <button mat-icon-button [matMenuTriggerFor]="menu" class="table-edit-button">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editUser(row)">
              <mat-icon>edit</mat-icon>
              <span>{{'admin_users_edit_button' | translate}}</span>
            </button>
            <button mat-menu-item (click)="deleteUser(row.id)">
              <mat-icon>close</mat-icon>
              <span>{{'admin_users_delete_button' | translate}}</span>
            </button>
            <button mat-menu-item (click)="changePassword(row)">
              <mat-icon>lock</mat-icon>
              <span>{{'admin_users_change_password_button' | translate}}</span>
            </button>
            <button mat-menu-item (click)="resendInvitation(row.id)">
              <mat-icon>send</mat-icon>
              <span>{{'admin_users_invitation_button' | translate}}</span>
            </button>
          </mat-menu>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer>
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-selectedCount="selectedCount"
          let-curPage="curPage"
          let-offset="offset"
          let-isVisible="isVisible">
          <div *ngIf="rows?.length > 0" class="page-limit-selection">
            <mat-select
              [value]="page.limit"
              style="width: 200px"
              class="global-select"
              (valueChange)="onLimitChange($event)">
              <mat-option
                *ngFor="let option of pageLimitOptions"
                [value]="option.value"
              >
                {{ option.value }} {{ 'pagination_on_page' | translate }}
              </mat-option>
            </mat-select>
          </div>
          <datatable-pager
            [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'"
            [page]="curPage"
            [size]="pageSize"
            [count]="rowCount"
            [hidden]="!((page.totalElements / pageSize) > 1)"
            (change)="table.onFooterPage($event)">
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>
</div>
