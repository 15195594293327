import {Component, OnInit} from '@angular/core';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {ApplicationUtilsService, CustomIcons} from '../../../shared/services/application-utils.service';
import {ConfirmDialogComponent} from '../../../shared/components/confirm-dialog/component';
import {BehaviorSubject, catchError, finalize, first, switchMap} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';

@Component({
  selector: 'app-edit-tenants-dialog-template',
  templateUrl: './tenants-management.component.html',
  styleUrls: ['./tenants-management.component.scss']
})
export class TenantsManagementComponent implements OnInit {
  readonly CustomIcons = CustomIcons;
  public isLoading$ = new BehaviorSubject<boolean>(false);

  rows: any;
  constructor(
    private dialog: MatDialog,
    public state: ApplicationStateService,
    public api: ApplicationApiService,
    public utils: ApplicationUtilsService,
    private translate: TranslatePipe
  ) {
    this.state.getPublishers(false, 10000, 0,  'SuperAdmin');
  }

  ngOnInit(): void {
    this.state.publishers$.subscribe(publishers => {
      this.rows = publishers;
    });
  }

  selectTenant(uid): void{
    this.api.selectActiveTenant(uid).pipe().subscribe(res => {
      window.location.reload();
      this.utils.showSnackbar('tenants_management_tenant_selected');
    });
  }

  blockTenant(uid, block): void {
    this.api.blockPublisher$(uid, !block).subscribe(res => {
      const changedRowIndex = this.rows.findIndex(publisher => publisher?.uid === uid);
      this.rows[changedRowIndex].blocked = !block;
      }
    );
  }

  deleteTenant(deletingPublisher: any): void {
      let info: string = `${this.translate.transform('books_count_found')}${deletingPublisher.booksCount}\r\n`;
      info += `${this.translate.transform('collections_count_found')}${deletingPublisher.collectionsCount}\r\n`;
      info += `${this.translate.transform('subscribtions_count_found')}${deletingPublisher.subscriptionsCount}\r\n`
      this.dialog
        .open(ConfirmDialogComponent, {
          disableClose: true,
          autoFocus: false,
          data: {text: ['delete_tenant_confirm_text', info], title: 'delete_tenant_confirm_title'}
        })
        .afterClosed()
        .pipe(first()).subscribe(yes => {
        if (!yes) {
          return;
        }
        this.isLoading$.next(true);
        this.api.deletePublisher$(deletingPublisher.uid, 'hard').pipe(
          first(),
          catchError((): any => {
            this.utils.showSnackbar('delete_tenant_error_msg');
          }),
          finalize(() => this.isLoading$.next(false)),
          switchMap(() => {
            return this.state.getSubscriptions(true);
          })
        ).subscribe(() => {
          this.isLoading$.next(false);
          this.state.getPublishers(false, 10000, 0, 'SuperAdmin');
          this.utils.showSnackbar('delete_tenant_success_msg');
        });
    });
  }

}
