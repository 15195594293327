import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription, switchMap } from "rxjs";
import { ApplicationStateService } from "src/app/shared/services/application-state.service";

@Component({
  selector: 'app-book-main',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private state: ApplicationStateService
  ) {
    this.subs.push(
      this.route.paramMap.subscribe(params => {
        this.state.routeParamBookId$.next(params?.get('bookId'))
      })
    );

  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

}
