import {Component, OnDestroy, OnInit, NgZone} from '@angular/core';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {ApplicationStateService} from '../../../shared/services/application-state.service';
import {catchError, finalize, throwError} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ApplicationAuthService} from '../../../shared/services/application-auth.service';
import {ApplicationUtilsService} from '../../../shared/services/application-utils.service';
import {ErrorResponseCodes} from '../../../shared/app-interfaces';
import {CookieService} from 'ngx-cookie-service';
import { GoogleBindComponent } from '../google-bind-template/google-bind.component';

@Component({
  selector: 'app-google-auth',
  templateUrl: './google-auth.component.html',
  styleUrls: ['./google-auth.component.scss']
})

export class GoogleAuthComponent implements OnInit, OnDestroy {

  constructor(
    public api: ApplicationApiService,
    public state: ApplicationStateService,
    public dialog: MatDialog,
    public zone: NgZone,
    public auth: ApplicationAuthService,
    public utils: ApplicationUtilsService,
    public cookieService: CookieService,
  ) {
  }

  ngOnInit(): void {
    window.handleCredentialResponse = (googleResponse) => {
      console.log(googleResponse);
      this.cookieService.deleteAll();
      if (this.state.routerUrl$.value.includes('authorization')) {
        return this.api.googleAuth(JSON.stringify(googleResponse.credential), 'authorization').pipe(
          catchError((err: any): any => {
            if (err.error === 'entity_not_found') {
              this.utils.showSnackbar(ErrorResponseCodes.ENTITY_NOT_FOUND);
            }
            if (err?.error[0] === 'can_bind_accounts') {
              this.openDialog(err?.error[1], googleResponse?.credential);
            }

            if (err?.error === ErrorResponseCodes.ENTITY_NOT_FOUND) {
              return this.googleSignUp(googleResponse);
            }
            return err?.error;
          }),
          finalize(() => {})
        ).subscribe((credentialsData: any) => {
          if (credentialsData?.access_token) {
            this.auth.login(credentialsData, true);
          }
        });
      } else if (this.state.routerUrl$.value.includes('registration')) {
          return this.googleSignUp(googleResponse);
      }
    };
  }

  ngOnDestroy(): void {
  }

  public openDialog(email, googleToken): void {
    this.zone.run(() => {
      this.dialog
      .open(GoogleBindComponent, {
        maxWidth: '500px',
        width: '100%',
        autoFocus: false,
        data: {
          googleToken: googleToken,
          email: email
        }
      });
    });
  }

  public googleSignUp(googleResponse: any): any {
    return this.api.googleAuth(JSON.stringify(googleResponse.credential), 'registration').pipe(
      catchError((err: any): any => {
      if (err?.error[0] === 'can_bind_accounts') {
        this.openDialog(err?.error[1], googleResponse?.credential);
      }
      return throwError(() => err);
    }),
    finalize(() => {})).subscribe(credentialsData => {
      if (credentialsData?.access_token) {
        this.auth.login(credentialsData, true);
      }
    });
  }
}
