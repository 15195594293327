import { Component } from '@angular/core';
import {ApplicationUtilsService} from './shared/services/application-utils.service';
import {ApplicationAuthService} from './shared/services/application-auth.service';
import {ApplicationStateService} from './shared/services/application-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'library';

  constructor(
    public customIconsService: ApplicationUtilsService,
    public state: ApplicationStateService,
    public authService: ApplicationAuthService) {
    this.customIconsService.registerIcons();
  }
}
