import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {ApplicationApiService} from '../../services/application-api.service';
import {ApplicationAuthService} from '../../services/application-auth.service';
import {ApplicationUtilsService} from '../../services/application-utils.service';
import {ConfirmDialogComponent} from "../confirm-dialog/component";

export interface IDialogContent {
  title?: string;
  hideButtons?: boolean;
  hideCancel?: boolean;
  hideSave?: boolean;
  noButtonTitle?: string;
  yesButtonTitle?: string;
  delete?(): void;
  save(): void;
}

@Component({
  selector: 'app-form-dialog',
  templateUrl: 'form-dialog.component.html',
  styleUrls: ['form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FormDialogComponent implements OnInit, OnDestroy {
  @ViewChild('dialogContent') private dialogContent: IDialogContent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FormDialogComponent>,
    public api: ApplicationApiService,
    public auth: ApplicationAuthService,
    public utils: ApplicationUtilsService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }


  withdraw(): any {
    this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: {text: 'withdraw_warning_confirm_dialog_msg', title: 'withdraw_warning_confirm_dialog_title'}
    }).afterClosed().subscribe(response => {
      if (response) {
        this.api.withdraw$().subscribe(res => {
          this.auth.logout();
          this.utils.showSnackbar('User was removed from the system');
          this.cancel();
        });
      }
    })
  }
  delete(): any {
    this.dialogContent.delete();
  }
  save(): any {
    this.dialogContent.save();
  }

  cancel = () => {
    this.dialogRef.close(false);
  }

  closeDialog = (result: boolean) => {
    this.dialogRef.close(result);
  }
}
