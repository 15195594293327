import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, catchError, debounceTime, finalize, first, map, Subscription} from 'rxjs';
import {ApplicationApiService} from '../../../../shared/services/application-api.service';
import {CookieService} from 'ngx-cookie-service';
import {ApplicationUtilsService} from '../../../../shared/services/application-utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscriber } from 'src/app/shared/app-interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

enum FormFields {
  message = 'message',
  
}
@Component({
  selector: 'readers-request-send-dialog',
  templateUrl: 'readers-request-send-dialog.component.html',
  styleUrls: ['readers-request-send-dialog.component..scss']
})

export class ReadersRequestSendDialogComponent implements OnInit, OnDestroy {
  public readonly FormFields = FormFields;
  public form: FormGroup;

  public subscriber: Subscriber;
  
  public loading$ = new BehaviorSubject<boolean>(false);

  private subs: Subscription[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {subscriber: Subscriber},
    public formBuilder: FormBuilder,
    public api: ApplicationApiService,
    public cookieService: CookieService,
    public utils: ApplicationUtilsService,
    private dialogRef: MatDialogRef<ReadersRequestSendDialogComponent>
  ) {
    this.subscriber = data.subscriber;
  }

  ngOnInit(): void {
    this.form = this.formBuilder?.group({
      [FormFields.message]: ['']
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  close(): void {
    this.dialogRef.close(false);
  }

  save():void{
    this.loading$.next(true)
    this.api.SendReadersRequest(this.subscriber.uid, this.form.controls[FormFields.message].value)
    .pipe(
      catchError((error): any => {
        switch(error.error){
          case 'already_exist':
             this.utils.showSnackbar('already_exist_request_error_msg');
             this.dialogRef.close(false);
            break;
          case 'already_have_this_sub':
             this.utils.showSnackbar('already_have_this_sub_error_msg');
             this.dialogRef.close(false);
             break;
          default: 
            this.utils.showSnackbar('something_went_wrong_msg');
            break;
        }
      }),
      finalize(() => this.loading$.next(false))
    ).subscribe(result => {
      if(!result){
        this.utils.showSnackbar('something_went_wrong_msg');
        return;
      }
      this.utils.showSnackbar('request_sended_msg');
      this.dialogRef.close(true);
    });
  }
}