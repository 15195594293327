import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, catchError, finalize, of } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationUtilsService } from '../../../../shared/services/application-utils.service';
import { ApplicationApiService } from '../../../../shared/services/application-api.service';
import {ErrorResponseCodes} from '../../../../shared/app-interfaces';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-admin-form-template',
  templateUrl: './create-admin-form.component.html',
  styleUrls: ['./create-admin-form.component.scss'],
})
export class CreateAdminFormComponent implements OnInit {
  public form: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(false);
  public displayEmailIsTakenError = false;
  constructor(
    public formBuilder: FormBuilder,
    public utils: ApplicationUtilsService,
    private api: ApplicationApiService,
    private dialogRef: MatDialogRef<CreateAdminFormComponent>
  ) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  public removeEmailIsTakenError(): void {
    if (this.displayEmailIsTakenError) {
      this.form.controls.email.setErrors({exists: false});
      this.displayEmailIsTakenError = false;
    }
  }

  save(): void {
    this.form.controls.email.setValue(this.form.controls.email.value.trim());
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    // Start submitting process;
    this.loading$.next(true);
    this.api
      .sendAdminRegistrationRequest$({userName: this.form.controls.email.value})
      .pipe(
        catchError((error) => {
          if (error.error === ErrorResponseCodes.USER_ALREADY_EXISTS) {
            this.displayEmailIsTakenError = true;
            this.form.controls.email.setErrors({exists: true});
            this.utils.showSnackbar('user_with_such_email_is_already_exist');
          } else {
            this.utils.showSnackbar('save_user_error_msg');
          }
          return of(error);
        })
      )
      .subscribe((res) => {
        this.loading$.next(true);
        if(res == null){
          this.utils.showSnackbar('save_user_success_msg');
          this.dialogRef.close(res);
        }
      });
  }

  cancel(): void{
    this.dialogRef.close(false);
  }
}
