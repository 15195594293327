export const PageLimitOptions = [
  // {value: 10},
  {value: 25},
  {value: 50},
  {value: 100},
  {value: 200},
  {value: 500}
];

export class Page {
  limit = 0;
  totalElements = 0;
  totalPages = 0;
  offset = 0;
  currentPage = 0;
}
