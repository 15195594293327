import { MatTreeModule } from '@angular/material/tree';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/component';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {AuthorizationComponent} from './components/authorization/authorization.component';
import {BooksCatalogueComponent} from './components/books-catalogue/books-catalogue.component';
import {MySubscriptionsDialogComponent} from './components/header/my-subscriptions-dialog-template/my-subscriptions-dialog-template.component';
import {PublishersBannerComponent} from './components/publishers/publishers-banner/publishers-banner.component';
import {PublishersListComponent} from './components/publishers/publishers-list/publishers-list.component';
import {PublishersComponent} from './components/publishers/publishers.component';
import {PublisherRegistrationComponent} from './components/publisher-registration/publisher-registration.component';
import {PublisherRegistrationFormComponent} from './components/publisher-registration/publisher-registration-form/publisher-registration-form.component';
import {PublishersQuestionFormComponent} from './components/publishers/publishers-question-form/publishers-question-form.component';
import {BookComponent} from './components/books-catalogue/book/book.component';
import {MaterialModule} from './shared/material.module';
import {FooterComponent} from './components/footer/footer.component';
import {NaviBarComponent} from './components/navi-bar/navi-bar.component';
import {AdminUsersComponent} from './components/admin/users/admin-users.component';
import {AdminSubscribersComponent} from './components/admin/subscribers/admin-subscribers.component';
import {AdminBooksComponent} from './components/admin/books/admin-books.component';
import {BookEditorComponent} from './components/admin/book-editor/book-editor.component';
import {AdminCollectionsComponent} from './components/admin/collections/admin-collections.component';
import {FormDialogComponent} from './shared/components/form-dialog/form-dialog.component';
import {PaginatorComponent} from './shared/components/paginator/paginator.component';
import {EditUserComponent} from './components/admin/users/edit-form-template/edit-user.component';
import {CreateAdminFormComponent} from './components/admin/users/create-admin-form-template/create-admin-form.component';
import {ViewHistoryComponent} from './components/admin/subscribers/history-form-template/history-form.template';
import {EditCollectionComponent} from './components/admin/collections/edit-collection-template/edit-collection.component';
import {EditPasswordComponent} from 'src/app/components/admin/users/edit-user-password-template/edit-password.component';
import {ApiPrefixInterceptor} from './shared/services/api-prefix.interceptor';
import {DndDirective} from './shared/directives/dnd.directive';
import {PipesModule} from './shared/pipes/pipes.module';
import {ConsentComponent} from './components/consent/consent.component';
import {ConsentDialogComponent} from './components/consent-dialog/consent-dialog.component';
import {EditUserProfileComponent} from './components/header/edit-user-data-template/edit-user-data.component';
import {EditUserLicenseComponent} from './components/header/edit-user-license-template/edit-user-license-template.component';
import {AdminMainComponent} from './components/admin/main/admin-main.component';
import { SelectAccountComponent } from './components/authorization/select-account-template/select-account.component';
import { RestorePasswordComponent } from './components/authorization/restore-password-template/restore-password.component';
import { SubscriptionEditorComponent } from './components/admin/subscription-editor/subscription-editor.component';
import { EditSubscriberComponent } from './components/admin/subscribers/edit-subscriber-template/edit-subscriber.component';
import { TenantsManagementComponent } from './components/super-admin/tenants-management/tenants-management.component';
import { BookModule } from './modules/book/book.module';
import { QuillModule } from 'ngx-quill';
import { GoogleAuthComponent } from './components/authorization/google-auth-template/google-auth.component';
import { GoogleBindComponent } from './components/authorization/google-bind-template/google-bind.component';
import {AuthTokenInterceptor} from './shared/services/auth-token.interceptor';
import {AdminReadersComponent} from './components/admin/readers/admin-readers.component';
import { AddUserToSubscriberDialogComponent } from './components/header/edit-user-data-template/add-user-to-subscriber-dialog-template/add-user-to-subscriber-dialog.component';
import { SwitchTenantDialogTemplateComponent } from './components/header/switch-tenant-dialog-template/switch-tenant-dialog-template.component';
import { GooglePublisherRegistrationComponent } from './components/publisher-registration/google-registration-template/google-publisher-registration.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AllModeReadersComponent } from './components/admin/readers/views-mode-templates/all-mode-template/all-mode.component';
import { IndividualModeReadersComponent } from './components/admin/readers/views-mode-templates/individual-mode-template/individual-mode.component';
import { RegularModeReadersComponent } from './components/admin/readers/views-mode-templates/regular-mode-template/regular-mode.component';
import { ReadersRequestSendDialogComponent } from './modules/book/book-description/readers-request-dialog-template/readers-request-send-dialog.component';
import { ReadersRequestComponent } from './components/admin/readers/views-mode-templates/readers-requests-mode-template/readers-requests.component';
import { UserMenuColumn } from './components/admin/readers/views-mode-templates/user-menu/user-menu.component';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    BookComponent,
    HeaderComponent,
    FooterComponent,
    NaviBarComponent,
    AdminBooksComponent,
    AdminUsersComponent,
    AdminReadersComponent,
    BookEditorComponent,
    AuthorizationComponent,
    ConsentComponent,
    ConsentDialogComponent,
    GoogleAuthComponent,
    GoogleBindComponent,
    GooglePublisherRegistrationComponent,
    BooksCatalogueComponent,
    MySubscriptionsDialogComponent,
    SwitchTenantDialogTemplateComponent,
    PublishersBannerComponent,
    PublishersComponent,
    PublishersQuestionFormComponent,
    PublisherRegistrationComponent,
    PublisherRegistrationFormComponent,
    PublishersListComponent,
    AdminCollectionsComponent,
    AdminSubscribersComponent,
    AdminMainComponent,
    EditCollectionComponent,
    ConfirmDialogComponent,
    FormDialogComponent,
    PaginatorComponent,
    SubscriptionEditorComponent,
    EditSubscriberComponent,
    EditUserComponent,
    CreateAdminFormComponent,
    AddUserToSubscriberDialogComponent,
    SelectAccountComponent,
    RestorePasswordComponent,
    EditUserProfileComponent,
    EditUserLicenseComponent,
    ViewHistoryComponent,
    EditPasswordComponent,
    TenantsManagementComponent,
    AllModeReadersComponent,
    IndividualModeReadersComponent,
    RegularModeReadersComponent,
    ReadersRequestSendDialogComponent,
    ReadersRequestComponent,
    UserMenuColumn,
    DndDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    CommonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    HttpClientModule,
    NgxDatatableModule,
    ScrollingModule,
    MatTreeModule,
    PipesModule,
    BookModule,
    QuillModule,
    //AdminModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: environment.language == 'ru'? 'ru-RU': 'en-GB' },
        CookieService
  ],  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
}
