import { Component, OnInit, Inject } from '@angular/core';
import { BehaviorSubject, catchError, finalize } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationUtilsService } from '../../../../shared/services/application-utils.service';
import { ApplicationApiService } from '../../../../shared/services/application-api.service';
import {ApplicationStateService} from '../../../../shared/services/application-state.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-user-to-subscriber-dialog-template',
  templateUrl: './add-user-to-subscriber-dialog.component.html',
  styleUrls: ['./add-user-to-subscriber-dialog.component.scss'],
})
export class AddUserToSubscriberDialogComponent implements OnInit {
  public form: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(false);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {userUid: string},
    public formBuilder: FormBuilder,
    public utils: ApplicationUtilsService,
    private api: ApplicationApiService,
    public state: ApplicationStateService,
    private dialogRef: MatDialogRef<AddUserToSubscriberDialogComponent>
  ) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      subscriberCode: ['', [Validators.required]],
    });
  }

  save(): void {
    this.form.controls.subscriberCode.setValue(this.form.controls.subscriberCode.value.trim());
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.loading$.next(true);
    this.api.addUserToSubscriber$(this.data.userUid, this.form.controls.subscriberCode.value).pipe(
      catchError((err): any => {
        if (err.error === 'already_exist') {
          this.utils.showSnackbar('already_subscribed_to_subscriber');
          this.form.controls.subscriberCode.setValue(null);
        }
        return;
      }),
      finalize(() => {
        this.loading$.next(false);
      })
    ).subscribe(res => {
      this.state.getUserSubscribers(this.data.userUid);
      if (this.state.routeParamBookId$?.value) {
        this.api.getBook$(this.state.routeParamBookId$.value).subscribe(book => {
          this.state.currentBook$.next(book);
        });
      }
      this.utils.showSnackbar('connection_with_subcriber');
      this.dialogRef.close(true);
    });
  }

  cancel():void{
    this.dialogRef.close(false);
  }
}
