<ng-container *ngIf="{
    adminMode: state.adminMode$ | async,
    superAdminMode: state.superAdminMode$ | async,
    loading: authService.globalLoading$ | async,
    routerUrl: state.routerUrl$ | async
} as data">
  <ng-container *ngIf="!(data.routerUrl?.includes('read') || data.routerUrl?.includes('consent'));
   else (data.routerUrl?.includes('consent') ? consent : reader)">
    <app-header></app-header>
    <app-navi-bar *ngIf="data.adminMode || data.routerUrl?.includes('superAdmin')"></app-navi-bar>
    <div class="router-content" style="position: relative">
      <div *ngIf="data.loading" class="global-spinner-cnt">
        <mat-spinner color="primary" diameter="50"></mat-spinner>
      </div>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </ng-container>
  <ng-template #consent>
    <router-outlet></router-outlet>
  </ng-template>
  <ng-template #reader>
    <router-outlet></router-outlet>
  </ng-template>
</ng-container>
