<mat-dialog-content>
  <h3 class="dialog-title">{{ 'my_subscriptions_dialog_title' | translate }}</h3>
  <div *ngIf="{
    subscribers: state.userProfileSubscribers$ | async,
    loading: loading$ | async
  } as data" class="my-subscriptions-dialog-template">
    <div *ngIf="data.loading" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>

    <div class="global-flex-row-space-between-center" style="flex-direction: row-reverse">

    <div class="btn-section">
      <button mat-button
              (click)="addSubscriber()">
        <mat-icon [svgIcon]="CustomIcons.plus"></mat-icon>
        <span>{{'edit_user_add_user_to_subscriber_button' | translate}}</span>
      </button>
    </div>

    <mat-tab-group #matTabGroup
                  (selectedIndexChange)="tabSelectedIndex$.next($event)"
                  class="remove-border-bottom"
                  color="transparent">
      <mat-tab>
        <ng-template matTabLabel>
          <div [ngClass]="{'selected-mat-tab-label': tabSelectedIndex$?.value === 0}">
            {{'Subscriptions' | translate}}
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab>
        <ng-template matTabLabel>
          <div [ngClass]="{'selected-mat-tab-label': tabSelectedIndex$?.value === 1}">
            {{'Purchases' | translate}}
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    </div>

    <div class="table" style="position: relative">
      <ngx-datatable
        *ngIf="tabSelectedIndex$?.value === 0"
        #table
        [headerHeight]="60"
        [rowHeight]="50"
        [columnMode]="'force'"
        [rows]="rows"
        [columns]="[]"
        [virtualization]="false"
        [messages]="{emptyMessage: this.utils.getTableMessage('my_subscriptions_empty_subscriptions_table_message' | translate)}"
      >


        <ngx-datatable-column [name]="'column_header_subscriber_status' | translate" prop="status" [sortable]="true" [width]="50">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span class="mobile-hidden">{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <mat-icon [svgIcon]="row?.status
              ? CustomIcons.checkbox_marked_circle_outline
              : CustomIcons.checkbox_failed_circle_outline">
            </mat-icon>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'column_header_subscriber_title' | translate" prop="title" [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span class="mobile-hidden">{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span class="mobile-hidden">{{ row.title }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'column_header_subscriber_address' | translate" prop="address" [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span class="mobile-hidden">{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span class="mobile-hidden datatable-body-cell-pre-wrap">{{ row.address }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'column_header_subscriber_phone' | translate" prop="phone" [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span class="mobile-hidden">{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span class="mobile-hidden">{{ row.phone }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'column_header_subscriber_book_count' | translate" prop="bookCount" [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span class="mobile-hidden">{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span class="mobile-hidden">{{ row.bookCount }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [width]="50"
          [resizeable]="false"
          [sortable]="false"
          [draggable]="false"
          [canAutoResize]="false"
        >
          <ng-template let-row="row" let-group="group" let-expanded="expanded" ngx-datatable-cell-template>
            <button *ngIf="!row?.emptyMessage" mat-icon-button [matMenuTriggerFor]="menu" class="table-edit-button">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="removeUserFromSubscriber(row.uid)">
                <mat-icon>delete</mat-icon>
                <span>{{'my_subscriptions_withdraw_from_subscriber' | translate}}</span>
              </button>
            </mat-menu>
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>

      <ngx-datatable
        *ngIf="tabSelectedIndex$?.value === 1"
        #table
        [headerHeight]="60"
        [rowHeight]="50"
        [columnMode]="'force'"
        [rows]="rows"
        [columns]="[]"
        [virtualization]="false"
        [messages]="{emptyMessage: this.utils.getTableMessage('my_subscriptions_empty_purchases_table_message' | translate)}"
      >

        <ngx-datatable-column [name]="'column_header_purchase_status' | translate" prop="status" [sortable]="true" [width]="50">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span class="mobile-hidden">{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <mat-icon [svgIcon]="bookAvailable(row.enabled, row.licenseEnd)
              ? CustomIcons.checkbox_marked_circle_outline
              : CustomIcons.checkbox_failed_circle_outline">
            </mat-icon>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'column_header_purchase_title' | translate" prop="title" [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span class="mobile-hidden">{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span class="mobile-hidden" *ngIf="row?.book?.title">{{ row?.book?.title }}</span>
            <span class="mobile-hidden" *ngIf="row?.book?.isbn === 'book_has_been_deleted'">{{ ('my_subscriptions_purchases_book_has_been_deleted' | translate)}}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'column_header_purchase_start' | translate" prop="licenseStart" [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span class="mobile-hidden">{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span class="mobile-hidden datatable-body-cell-pre-wrap">{{ utils.getformattedDate(row?.dateTimeAcquired) }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column class="duration" [name]="'column_header_purchase_end' | translate" prop="licenseEnd" [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span class="mobile-hidden">{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span>
              {{ row?.licenseEnd ? utils.getformattedDate(row?.licenseEnd) : ('my_subscriptions_purchases_unlimited_duration' | translate)}}
            </span>
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="buttons">
    <button class="global-button default" (click)="close()">
      {{ 'dialog_options_close' | translate }}
    </button>
  </div>
</mat-dialog-actions>