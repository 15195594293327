import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec} from '@angular/common/http';
import {
  Book,
  Collection,
  Credentials,
  PaginatedBooksResponse,
  PaginatedCollectionsResponse,
  PaginatedSubscriptionResponse,
  Subscriber,
  Subscription,
  User,
} from '../app-interfaces';
import {map, Observable, of} from 'rxjs';
import {ApplicationUtilsService} from './application-utils.service';

// #todo The types imported below will be moved to the interfaces file and imported from there. This is a temporary import.
import { CreatePublisherPayload, Publisher, PublisherBeforeDelete } from 'src/app/components/publisher-registration/publisher-registration-form/publisher-registration-form.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationApiService {

  constructor(private http: HttpClient, private utils: ApplicationUtilsService) {
  }


  /* Book */

  public getPaginatedBooks$({
    size,
    offset,
    body,
  }: {
    size: number;
    offset: number;
    body?: any; // #todo Set right type;
  }): Observable<PaginatedBooksResponse> {
    const payload = {...body, size, offset};
    return this.http.post<PaginatedBooksResponse>(
      `/v1/books`,
      payload || {}
    );
  }

  public getOwnPaginatedBooks$({
     size,
     offset,
     body,
   }: {
    size: number;
    offset: number;
    body?: any; // #todo Set right type;
  }): Observable<PaginatedBooksResponse> {
    const payload = {...body, size, offset};
    return this.http.post<PaginatedBooksResponse>(
      `/v1/books/own`,
      payload || {}
    );
  }


  public getBooks$(size, offset, body): Observable<any> {
    const payload = {...body, size, offset};
    return size && (offset || offset === 0)
      ? this.http.post<any>(`/v1/books`, payload)
      : this.http.get<Book[]>(`/v1/books`);
  }

  public getBook$(uid: string): Observable<any> {
    return this.http.get<Book>(`/v1/book?uid=${uid}`);
  }

  public patchBook$(payload: Partial<Book>, uid: string): Observable<Book> {
    if(payload.countPages == null) payload.countPages = 0;
    if(payload.publicationYear == null) payload.publicationYear = 0;
    return this.http.patch<Book>(`/v1/book`, {model: payload, uid});
  }
  public deleteBook$(uid: string): Observable<any> {
    return this.http.delete<Book>(`/v1/book/?uid=${uid}`);
  }

  public uploadBookFile(file?: any, uid?: string): any {
    const formData: FormData = new FormData();
    const param = uid ? ('/?uid=' + uid) : ``;
    if (!file) {
      return this.http.put<any>( `/v1/book/source`, formData);
    }
    formData.append('fileKey', file, file.name);

    if (file) {
      return this.http.put<any>( `/v1/book/source${param}`, formData);
    }
  }

/*  public uploadBookFile(file?: any, uid?: string): any {
    const formData: FormData = new FormData();
    if (!file) {
      return this.http.put<any>( `/v1/book/source`, formData);
    }
    formData.append('fileKey', file, file.name);

    if (file) {
      return this.http.put<any>( '/v1/book/source' + (uid ? ('/' + uid) : ``), formData);
    }
  }*/

  public uploadBookCover(image: string, uid?: string): any {
    const formData: FormData = new FormData();
    formData.append('fileKey', image);
    const param = uid ? ('/?uid=' + uid) : ``;
    return this.http.patch<any>(`/v1/book/image${param}`, formData);
  }

  public getImage(uid: string): any {
    return this.http.get<any>( `/v1/book/image/?uid=${uid}`);
  }

  public getBookLog$(uid: string): any {
    return this.http.get<any>(`/v1/book/log/?uid=${uid}`);
  }


  /* BookReader */

  public getPage(bookId: string, pageNumber: number): any {
    return this.http.get<any>( `/v1/reader/?book=${bookId}&page=${pageNumber}`, { responseType: 'blob' as 'json'});
  }

      /* old reader*/
  public getBookPage(bookId: string, pageId: number): Observable<any> {
    return this.http.get(`/v1/reader/?book=${bookId}&page=${pageId}`, { responseType: 'arraybuffer' });
  }

  getBookNavigation(bookId: string): any {
    return this.http.get<any>(`/v1/reader/navigation?book=${bookId}`);
  }

  getEpubBookPackage(bookId: string): any {
    return this.http.get(`/v1/reader/${bookId}/opf`);
  }

  public startBookReading(bookUid: string): any {
    return this.http.post<any>(`/v1/reader/start-book-reading/?bookUid=${bookUid}`, {});
  }


  /* BooksByIp */

  public getMyIpBooks$(): Observable<any> {
    return this.http.get<any>(`/v1/by-ip/my-ip`);
  }
  public checkBooksByIp$(): Observable<boolean> {
    return this.http.get<any>(`/v1/by-ip/check-books-by-ip`);
  }
  public getBooksByIp$(size, offset, body): Observable<any> {
    const payload = {...body, size, offset};
    return this.http.post<any>(`/v1/by-ip/books-by-ip`, payload);
  }


  /* BookUsage */

  public getBookUsageUsers$(bookId: string): any {
    return this.http.get<any>(`/v1/book-usage/users/?uid=${bookId}`);
  }

  public getBookUsage$(bookId: string): any {
    return this.http.get<any>(`/v1/book-usage/?uid=${bookId}`);
  }

  public postBookmark$(bookId: string, payload: string): any {
    return this.http.post<any>(`/v1/book-usage/bookmark/?uid=${bookId}`, payload);
  }


  /* Classification */

  public getClassification$(): any {
    return this.http.get<any>(`/v1/classification/default`);
  }


  /* Collection */

  // #todo Replace POST (paginated) requests wherever they are whit new "getPaginatedSubscriptions$" func;
  public getCollections$(size, offset): Observable<any> {
    const payload = {size: 10000, offset};
    return this.http.post<any>(`/v1/collections`, payload);
  }

  public getPaginatedCollections$({
                                    size,
                                    offset,
                                    body,
                                  }: {
    size: number;
    offset: number;
    body?: any; // #todo Set right type;
  }): Observable<PaginatedCollectionsResponse> {
    const payload = {...body, size, offset};
    return this.http.post<PaginatedCollectionsResponse>(
      `/v1/collections`,
      payload || {}
    );
  }

  public getCollection$(uid: string): Observable<any> {
    return this.http.get<any>(`/v1/collections/?uid=${uid}`);
  }

  public postCollection$(payload: Partial<Collection>, uid?: string): Observable<Collection> {
    return uid
      ? this.http.patch<Collection>(`/v1/collection`, {model: payload, uid})
      : this.http.post<Collection>(`/v1/collection`, payload);
  }

  public deleteCollection$(id: string): Observable<any> {
    return this.http.delete<Collection>(`/v1/collection/?uid=${id}`);
  }


  /* Consent */

  public consentAgree$(): any {
    return this.http.post<any>(`/v1/consent/agree`, {version: '0.0.0.0', language: 'en'});
  }

  public withdraw$(): any {
    return this.http.post<any>( `/v1/consent/withdraw`, {});
  }

  public consentCheck$(): any {
    return this.http.get<any>(`/v1/consent/check`);
  }


  /* GoogleAuth */

  public googleAuth(payload: any, mode: string): Observable<any> {
    if (mode === 'generateTenant') {
      return this.http.post<any>(`/v1/google/signup/?generateTenant=true&defaultCurrency=` + (environment.language == 'ru' ? 'RUB' : 'USD'), payload, {
        headers: {
          'Content-Type': 'application/json',
        }});
    }

    if (mode === 'authorization') {
      return this.http.post<any>(`/v1/google/login`, payload, {
        headers: {
          'Content-Type': 'application/json',
        }});

    }
    if (mode === 'registration') {
      return this.http.post<any>(`/v1/google/signup?defaultCurrency=` + (environment.language == 'ru' ? 'RUB' : 'USD'), payload, {
        headers: {
          'Content-Type': 'application/json',
        }});
    }
  }

  public googleBind(password: string, googleToken: string): Observable<any> {
    const payload = {password, googleToken};
    return this.http.post<any>(`/v1/google/bind`, payload);
  }


  /* License */

  // #todo Replace POST (paginated) requests wherever they are whit new "getPaginatedSubscriptions$" func;
  public getSubscriptions$(size, offset, body): Observable<any> {
    const payload = {...body, size, offset};
    return this.http.post<any>(`/v1/licenses`, payload);
  }

  public getPaginatedSubscriptions$({
    size,
    offset,
    body,
  }: {
    size: number;
    offset: number;
    body?: any; // #todo Set right type;
  }): Observable<PaginatedSubscriptionResponse> {
    const payload = {...body, size, offset};
    return this.http.post<PaginatedSubscriptionResponse>(
      `/v1/licenses`,
      payload || {}
    );
  }

  public getAllTenantsSubscriptions$(size, offset, body): Observable<any> {
    const payload = {...body, size, offset};
    return this.http.post<any>(`/v1/licenses/sa`, payload);
  }

  public getSubscription$(uid: string): Observable<any> {
    return this.http.get<Subscription>(`/v1/license/item/?uid=${uid}`);
  }

  public postSubscription$(payload: Partial<Subscription>, uid?: string): Observable<Subscription> {
    return uid
      ? this.http.patch<Subscription>(`/v1/license`, {model: payload, uid})
      : this.http.post<Subscription>(`/v1/license`, payload);
  }

  public deleteSubscription$(uid: string): Observable<any> {
    return this.http.delete<Subscription>(`/v1/license/?uid=${uid}`);
  }

  public getSubscriptionHistory$(uid: string): Observable<any[]> {
    return this.http.get<any[]>(`/v1/license/log/?uid=${uid}`);
  }

  public postSubscriptionHistory$(body: any, uid: string): Observable<any> {
    const payload = {body, parameter: uid};
    return this.http.post<any>(`/v1/license/log`, payload);
  }

  public generateKey(): Observable<any> {
    return this.http.get<any>(`/v1/license/generate-code`);
  }


  /* deprecated */
  public fetchSubscriptionsByUser$(uid: string): Observable<any> {
    return this.http.get(`/v1/license/by-user/${uid}`);
  }


  /* Log */

  public getLogs(payload: any): any {
    return this.http.post<any>(`/v1/logs`, payload);
  }


  /* Subscriber */

  public getSubscriber$(uid: string): Observable<any> {
    return this.http.get<any>(`/v1/subscriber/?uid=${uid}`);
  }

  public postSubscriber$(payload: any, uid?: string): any {
    return uid
      ? this.http.patch<any>(`/v1/subscriber/`, {model: payload, uid})
      : this.http.post<any>(`/v1/subscriber`, payload);
  }

  public deleteSubscriber$(uid: string): Observable<any> {
    return this.http.delete<Subscriber>(`/v1/subscriber/?uid=${uid}`);
  }

  public getPaginatedSubscribers$({
    size,
    offset,
    body,
  }: {
    size: number;
    offset: number;
    body?: any;
  }): any {
    const payload = {...body, size, offset};
    return this.http.post<any>(
      `/v1/subscribers`,
      payload || {}
    );
  }
  /* Tenant */

  public getPublisher$(): Observable<Publisher> {
    return this.http.get<Publisher>(`/v1/tenant`);
  }

  public getPublisherById$(id): Observable<PublisherBeforeDelete> {
    return this.http.get<PublisherBeforeDelete>(`/v1/tenant/by-id?id=` + id);
  }

  public createPublisher$(payload: CreatePublisherPayload): Observable<any> {
    return this.http.post<any>(`/v1/tenant?defaultCurrency=` + (environment.language == 'ru' ? 'RUB' : 'USD'), payload);
  }

  public patchPublisher$(payload: any): Observable<any> {
    return this.http.patch<any>(`/v1/tenant`, payload);
  }

  public getPaginatedPublishers$({
      size,
      offset,
      filter,
      userRole
    }: {
    size: number;
    offset: number;
    filter: any;
    userRole: string;
  }): Observable<any> {
    const payload = {...filter, size, offset};
    if (userRole === 'SuperAdmin') {
      return this.http.post<any>(`/v1/tenants/sa`, payload);
    }
    return this.http.post<any>(`/v1/tenants`, payload);
  }

  public deletePublisher$(uid: string, deleteType: string): Observable<any> {
    if (deleteType === 'hard') {
      return this.http.delete(`/v1/tenant/deletehard/?uid=${uid}`);
    }

    if (deleteType === 'soft') {
      return this.http.delete(`/v1/tenant/deletesoft/?uid=${uid}`);
    }
  }

  public blockPublisher$(uid: string, block: boolean): Observable<any> {
    return this.http.post(`/v1/tenant/block/?uid=${uid}&block=${block}`, {});
  }

  public getPublisherLogo$(): Observable<any> {
    // Null or File in the response;
    return this.http.get<any>(`/v1/tenant/image`);
  }

  // #todo Check responses and set right types;
  public putPublisherLogo$({
    image,
  }: {
    image: File;
  }): Observable<boolean> {
    const formData = new FormData();
    formData.append('fileKey', image);
    return this.http.patch<boolean>(`/v1/tenant/image`, formData);
  }

  public getPublishersImages(): Observable<string[]> {
    return this.http.get<string[]>(`/v1/tenant/images`);
  }


  /* User */

  public getPaginatedUsers$({
      size,
      offset,
      parameter,
      body,
      userRole
    }: {
    size: number;
    offset: number;
    parameter?: number | string;
    body?: any;
    userRole?: string
  }): Observable<any> {
    const payload = {...body, size, offset, parameter};
    if (userRole === 'SuperAdmin') {
      return this.http.get<any>(`/v1/users/sa`, payload || {});
    }
    return this.http.post<any>(`/v1/users`, payload || {});
  }

  public getPaginatedReaders$({
      size,
      offset,
      body,
      parameter,
    }: {
    size: number;
    offset: number;
    body?: any;
    parameter?: boolean
  }): Observable<any> {
    const payload = {...body, size, sort: {column:'created', order:'desc'}, offset, parameter};
    return this.http.post<any>(`/v1/users/readers`, payload || {});
  }

  public sendAdminRegistrationRequest$(payload: {
    userName: string;
  }): Observable<any> {
    return this.http.post<any>('/v1/user/register/admin', payload);
  }

  public resendInvitation(userId: any): Observable<any> {
    return this.http.get(`/v1/user/resend-invitation/?uid=${userId}`);
  }

  public resetPassword(email: any): any {
    const payload = JSON.stringify(email);

    return this.http.post(`/v1/user/reset-password`,  payload, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  public registerUser(payload: any): Observable<any> {
    return this.http.post<User>(`/v1/user/register`, payload);
  }

  public changePassword(userId: any, oldPassword: string, newPassword: string): Observable<any> {
    return this.http.post(`/v1/user/change-password/?uid=${userId}&oldPassword=${oldPassword}&newPassword=${newPassword}`, {});
  }

  public confirmAdminRegistration$(payload: {
    name: string;
    uid: string;
    token: string,
    newPassword: string;
  }): Observable<Credentials> {
    // #toDo Check the response Type.
    // Supposed to return credentials on success. The credentials will be used for auto-login (with redirection);
    return this.http.post<Credentials>(`/v1/user/set-password-with-token/?uid=${payload.uid}&token=${encodeURIComponent(payload.token)}&newPassword=${payload.newPassword}&name=${payload.name}`, {});
  }

  public postUser$(payload: Partial<User>, uid?: string): Observable<User> {
    return uid
      ? this.http.patch<User>(`/v1/user/update`, {model: payload, uid})
      : this.http.post<User>(`/v1/user/register/admin`, payload);
  }

  public updateSelf(payload): any {
    return this.http.patch<any>(`/v1/user/update-self`, payload);
  }

  public deleteUser$(id: string): Observable<any> {
    return this.http.delete<User>(`/v1/user/?uid=${id}`);
  }

  public checkEmailNotTaken(newEmail: string): Observable<boolean> {
    if (!newEmail) {
      return of(true);
    }
    newEmail = newEmail.toLowerCase().trim();
    return this.http.get(`/v1/user/check-available-email/?email=${encodeURIComponent(newEmail)}`).pipe(
      map(res => res),
      map(res => !res)
    );
  }

  public getBooksAvailableToRead(): any {
    return this.http.get<any>(`/v1/user/books-can-read`);
  }


  /* UserProfile */

  public addUserToSubscriber$(uid: string, subscriberCode: string): Observable<any> {
    return this.http.post<any>(`/v1/user-profile/add-user-subscriber-code/?userUid=${uid}&subscriberCode=${subscriberCode}`, {});
  }

  public removeUserFromSubscriber$(uid: string, subscriberUid: string): Observable<any> {
    return this.http.post<any>(`/v1/user-profile/remove-user-subscriber-code/?userUid=${uid}&subscriberUid=${subscriberUid}`, {});
  }

  public getUserSubscribers$(uid: string): Observable<any> {
    const payload = {size: 10000, parameter: uid};
    return this.http.post<any>(`/v1/user-profile/subscribers`, payload);
  }

  public getUserPurchases$(uid: string): Observable<any> {
    const payload = {size: 10000, parameter: uid};
    return this.http.post<any>(`/v1/user-profile/purchases`, payload);
  }

  /* Currency */
  public GetAllCurrencies() :Observable<any>{
    return this.http.get<any>(`/v1/currencies`);
  }
  public getCurrentCurrency() :Observable<any>{
    return this.http.get<any>(`/v1/currencies/current`);
  }
  
   /* ReadersRequest */
   public SendReadersRequest(subscriberId: string, message: string) :Observable<any>{
    const payload = {subscriberId: subscriberId, message: message};
    return this.http.post<any>(`/v1/readers-requests/user`, payload);
   }

   public acceptRequest(requestId: string) :Observable<any>{
    return this.http.post<any>(`/v1/readers-requests/accept?id=`+requestId, null);
   }

   public rejectRequest(requestId: string) :Observable<any>{
    return this.http.post<any>(`/v1/readers-requests/reject?id=`+requestId, null);
   }

   public GetTenantRequests({size,
    offset,
    parameter} : {
      size: number;
      offset: number;
      parameter?: string
    }) :Observable<any>{
    const payload = {size, offset, parameter};
    return this.http.post<any>(`/v1/readers-requests/tenant`, payload);
   }

  /* Global */
  public authorizeUser(userData: any): Observable<any> {
    const payload = new HttpParams({
      fromObject: userData,
      encoder: {
        encodeKey(key: string): string {
          return encodeURIComponent(key);
        },
        encodeValue(value: string): string {
          return encodeURIComponent(value);
        },
        decodeKey(key: string): string {
          return decodeURIComponent(key);
        },
        decodeValue(value: string): string {
          return decodeURIComponent(value);
        }
      } as HttpUrlEncodingCodec
    });
    return this.http.post<any>(`/token`, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      withCredentials: true
    });
  }

  public selectActiveTenant(activeTenant: string): Observable<any> {
    const payload = new HttpParams({
      fromObject: {activeTenant},
      encoder: {
        encodeKey(key: string): string {
          return encodeURIComponent(key);
        },
        encodeValue(value: string): string {
          return encodeURIComponent(value);
        },
        decodeKey(key: string): string {
          return decodeURIComponent(key);
        },
        decodeValue(value: string): string {
          return decodeURIComponent(value);
        }
      } as HttpUrlEncodingCodec
    });
    return this.http.post<any>(`/selectTenant`, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      withCredentials: true
    });
  }



  public verifyToken$(): Observable<Credentials> {
    return this.http.get<Credentials>( `/verify`, { withCredentials: true });
  }

  public logOut$(): Observable<unknown> {
    return this.http.post<any>( `/signout`, {});
  }


















  addUserLicense(user: Partial<User>, code: string): Observable<any> {
    return this.http.patch(`/v1/user/${user.id || '5f9393aa-588d-4928-aa65-74fa54545072'}`, {
    code: [code]
    });
  }



  // REGISTRATION








}
