<section class="publishers-list">
  <h2>{{'publishers_list_title' | translate}}</h2>
  <div class="publishers-filters">
    <div class="sort-dropdown"></div>
    <div class="filter-input"></div>
    <div class="view-switchers">
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="paginated-publishers">
    <div class="paginated-publisher" *ngFor="let publisher of publishers" [value]="publisher.uid">
      <div class="col col-1">
        <img class="paginated-publisher-logo" src="{{ publisher.logo }}" />
      </div>
      <div class="col col-2">
        <div class="paginated-publisher-header">
          <div class="paginated-publisher-suptitle">
            <span class="paginated-publisher-expertise">{{ publisher.expertise }}</span>
            <a class="paginated-publisher-prices-link" *ngIf="!!publisher.pricesLink"
              routerLink="{{ publisher.pricesLink }}">
              {{'publishers_list_price_link_text' | translate}}
            </a>
          </div>
          <a routerLink="/#" class="paginated-publisher-name">{{ publisher.name }}</a>
        </div>
        <div class="paginated-publisher-body">
          <p class="paginated-publisher-description">{{ publisher.description }}</p>
          <span class="paginated-publisher-books-amount">{{('publishers_list_number_of_books_text' | translate) + publisher.uploadedBooksAmount
            }}</span>
        </div>
        <div *ngIf="!!publisher.phone || !!publisher.email" class="paginated-publisher-footer">
          <span *ngIf="!!publisher.phone" class="paginated-publisher-phone">{{('publishers_list_phone' | translate) + publisher.phone }}</span>
          <span *ngIf="!!publisher.email" class="paginated-publisher-email">{{('publishers_list_email' | translate) + publisher.email }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="publishers-pagination">Pagination</div>
</section>
