import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ChangeDetectorRef,
  QueryList, AfterViewInit
} from '@angular/core';
import {ApplicationStateService} from 'src/app/shared/services/application-state.service';
import { BookType } from '../book-reader.component';
import { EpubReaderService } from 'src/app/shared/services/epub-reader.service';
import { PdfReaderService } from 'src/app/shared/services/pdf-reader.service';
import { Book, NavItem, Rendition } from 'epubjs';
import Navigation from 'epubjs/types/navigation';

import { Subscription } from 'rxjs';
@Component({
  selector: 'app-book-content-table',
  templateUrl: './content-table.component.html',
  styleUrls: ['./content-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContentTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public type: BookType;
  @Output() itemClicked = new EventEmitter<any>();

  public readonly BookType = BookType;
  public tableContent: string[];
  public tableContentHtml: string;
  private tableLoaded = false;

  public epubTableOfContent: Array<NavItem>;
  public currentChapter: string;

  @ViewChild('tableContentElement') private tableContentElement: ElementRef;
  private subs: Subscription[] = [];

  constructor(
    public state: ApplicationStateService,
    public pdfReaderService: PdfReaderService,
    private epubReaderService: EpubReaderService,
    private cd: ChangeDetectorRef
  ) {

  }

  public ngOnInit(): any {
    switch (this.type) {
      case BookType.PDF: {
        this.subs.push(
          this.state.currentBook$.subscribe(currentBook => {
            this.tableContentHtml = currentBook?.tableContents;
            this.cd.markForCheck();
          })
        );
        break;
      }
      case BookType.EPUB: {
        this.subs.push(
          this.epubReaderService.tableOfContent$.subscribe((toc: Array<NavItem>) => {
            this.epubTableOfContent = toc;
            this.cd.markForCheck();
          })
        );

        this.subs.push(
          this.epubReaderService.currentChapter$.subscribe((currentChapter: string) => {
            this.currentChapter = currentChapter;
            this.cd.markForCheck();
          })
        );

        break;
      }
    }
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  ngAfterViewInit(): void {
    if (!this.tableLoaded && this.tableContentElement?.nativeElement?.innerHTML) {
      this.tableContentElement.nativeElement.querySelectorAll('p').forEach(element => {
        const pageId = this.getPageId(element.innerHTML);
        if (pageId) {
          element.classList.add('clickable-item');
          element.addEventListener('click', () => {
            this.pdfReaderService.currentPage = parseInt(pageId, 10);
            this.pdfReaderService.changePage();
            this.pdfReaderService.loadRangeOfPages(parseInt(pageId, 10));
            this.itemClicked.emit(parseInt(pageId, 10));
            this.cd.markForCheck();
          });
        }
      });
      this.tableLoaded = true;
      this.tableContentElement.nativeElement.lastChild.style = 'padding-bottom: 60px';
    }
  }

  public getPageId(text: string): any {
    text = text.split('&nbsp;').join('');
    text = text.split('</span>').join('');
    let pageNumber = '';
    if (text[text.length - 1] === ')') {
      for (let i = text.length - 1; i > text.length - 7; i--) {
        if (text[i].match(RegExp(/\d/)) && text[i] !== '(') {
          pageNumber = text[i] + pageNumber;
        }
      }
      if (pageNumber.length < 5) {
        return pageNumber;
      }
      return;
    } else if (text.includes('...')) {
      for (let i = text.length - 1; i > text.length - 7; i--) {
        if (text[i].match(RegExp(/\d/))) {
          pageNumber = text[i] + pageNumber;
        }
      }
      if (pageNumber.length < 5) {
        return pageNumber;
      }
      return;
    }
    return;
  }

  public openChapter(item): void {
    if (this.currentChapter === item.href)
      return;
    
    this.epubReaderService.openChapter(item)
    this.currentChapter = item.href;
  }
}
