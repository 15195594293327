import {NgModule} from '@angular/core';
import { BookComponent } from './book.component';
import { BookDescriptionComponent } from './book-description/book-description.component';
import { BookReaderComponent } from './book-reader/book-reader.component';
import { Routes } from '@angular/router';

export const BOOK_ROUTES: Routes = [
  {
    path: ':bookId',
    component: BookComponent,
    children: [
      {
        path: '',
        component: BookDescriptionComponent
      },
      {
        path: 'read',
        component: BookReaderComponent
      }
    ]
  }
]