<div class="global-main-cnt global-flex-row-center-center">
  <div class="footer global-flex-row-space-between-center">
    <div class="footer-icons">
<!--
      <a href="#">
        <mat-icon [svgIcon]="CustomIcons.twitter"></mat-icon>
      </a>
-->
      <a href="#">
        <mat-icon [svgIcon]="CustomIcons.telegram"></mat-icon>
      </a>
<!--      <a href="#">
        <mat-icon [svgIcon]="CustomIcons.instagram"></mat-icon>
      </a>-->
    </div>
    <div class="footer-book">
      <mat-icon [svgIcon]="CustomIcons.footer_book" style="width: 100%;height: 78px;"></mat-icon>
    </div>
    <div>
      <div class="footer-text">© 2020 - {{currentYear}} {{('footer_elibrica' | translate)}}</div>
      <div class="footer-text">{{('footer_company_info' | translate)}}</div>
    </div>
    <!--    <span class="footer-text">{{ 'footer_left_text' | translate }}</span>-->
<!--    <span class="footer-text">{{ 'footer_right_text' | translate }}</span>-->
  </div>
</div>

