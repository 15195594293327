import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationApiService } from '../../../../shared/services/application-api.service';
import { EpubReaderService } from '../../../../shared/services/epub-reader.service';
import { Book } from '../../../../shared/app-interfaces';

@Component({
    selector: 'app-epub-viewer',
    templateUrl: './epub-viewer.component.html',
    styleUrls: ['./epub-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EpubViewerComponent implements OnInit, OnDestroy {
    @Input() book: Book;
    @Output() switchSidenavModeOnResize = new EventEmitter<any>();

    public loading$ = new BehaviorSubject<boolean>(false);
    @ViewChild('bookViewerRef') bookViewerRef: ElementRef; 
    public minHeight: string;

    private subs: Subscription[] = [];

    constructor(
        public applicationApiService: ApplicationApiService,
        public epubReaderService: EpubReaderService,
        private cd: ChangeDetectorRef
    ) {
        
    }

    ngOnInit(): void {
        this.epubReaderService.load(this.book.uid);

        this.subs.push(
            this.epubReaderService.chapterRendingStarted$.subscribe(() => {
                this.loading$.next(true);
            })
        );

        this.subs.push(
            this.epubReaderService.chapterRendered$.subscribe(section => {
                this.setMinHeight(undefined);
                setTimeout(() => {
                    this.setMinHeight(this.bookViewerRef?.nativeElement?.scrollHeight);
                })
                this.loading$.next(false);
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach(x => x.unsubscribe());
    }

    // public prev(event) {
    //     this.epubReaderService.prev();
    // }

    // public next(event) {
    //     this.epubReaderService.next();
    // }

    private setMinHeight(minHeight: any) {
        this.minHeight = minHeight ? minHeight.toString() + 'px' : minHeight;
        this.cd.markForCheck();
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event: any): void {
        // if (this.pdfReaderService.readerMode$.value === ReaderMode.FullScreen) {
        //     this.pdfReaderService.setHeightOnFullScreenMode();
        // }
        this.switchSidenavModeOnResize.emit(event);
    }

}
