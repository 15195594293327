<ng-container *ngIf="type === BookType.PDF; else epubContentTableTemplate">
    <div #tableContentElement class="sidebar-content-cnt content-container" [innerHtml]="tableContentHtml"></div>
</ng-container>

<ng-template #epubContentTableTemplate>
    <div style="padding: 20px;">
        <div 
            *ngFor="let item of epubTableOfContent" 
            class="clickable-item" 
            [ngClass]="{'active-chapter': currentChapter == item.href}">
            <span (click)="openChapter(item)">{{item.label}}</span>
        </div>
    </div>
</ng-template>
