<div class="global-main-cnt global-flex-column-space-between-center"
  *ngIf="{
    books: state.getSortedAndFilteredBooks$() | async,
    collections: state.collections$ | async,
    adminMode: state.adminMode$ | async,
    searchString: (state.bookSearchString$ | async).trim(),
    loading: loading$ | async
  } as data"
>
  <div class="options full-width">
    <div class="search">
      <mat-form-field
        [floatLabel]="'never'"
        class="global-mat-form-field"
      >
        <input
          #searchInput
          type="search"
          matInput
          [value]="data.searchString"
          [placeholder]="'admin_books_search_placeholder' | translate"
          autocomplete="off"
          (keyup)="state.bookSearchString$.next(searchInput.value)"
        >
        <mat-icon matSuffix class="global-icon-inactive">search</mat-icon>
      </mat-form-field>
    </div>
    <div class="btn-section">
      <button mat-button
              (click)="addBook()">
        <mat-icon [svgIcon]="CustomIcons.plus"></mat-icon><span>{{'admin_books_add_book_button' | translate}}</span>
      </button>
    </div>
  </div>
  <div class="table full-width" style="position: relative">
    <div *ngIf="data.loading" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>
    <div style="text-align: right;width: 100%; margin-right: 0px;">
      {{'books_count_found' | translate}}{{page.totalElements}}
    </div>
    <ngx-datatable #table
      [headerHeight]="60"
      [footerHeight]="60"
      [rowHeight]="'auto'"
      [columnMode]="'flex'"
      [rows]="rows"
      [columns]="columns"
      [virtualization]="false"
      [externalPaging]="true"
      [count]="page.totalElements"
      [offset]="page.offset"
      [limit]="page.limit"
      (page)="setPage($event)"
      [messages]="{emptyMessage: this.utils.getTableMessage('empty_table_message' | translate)}"
    >
      <ngx-datatable-column [flexGrow]="3" [name]="'column_header_title' | translate" prop="title" [sortable]="true">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{ column.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div 
            class="table-title"
            [ngClass]="{'admin-books-empty-book': !row.isCreated}"
          >
            <mat-icon 
              [matTooltip]="((row.isPublished && row.isCreated) ? 'admin_books_published' : 'admin_books_unpublished') | translate"
              [svgIcon]="(row.isPublished && row.isCreated) ? CustomIcons.checkbox_marked_circle_outline: CustomIcons.checkbox_failed_circle_outline">
            </mat-icon>
            <a
              (click)="edit(row.uid)"
              [innerHtml]="(row.title || ('admin_books_empty_book' | translate)) | highlight: (data.searchString)"
              class="global-link admin-books-main-link"
              ></a>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <!-- <ngx-datatable-column [flexGrow]="3" [name]="'column_header_published' | translate" prop="published" [sortable]="true">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{ column.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <mat-icon [svgIcon]="(row.isPublished && row.isCreated) ? CustomIcons.checkbox_marked_circle_outline: CustomIcons.checkbox_failed_circle_outline">
            </mat-icon>
        </ng-template>
      </ngx-datatable-column> -->

      <ngx-datatable-column [flexGrow]="1" [name]="'column_header_authors' | translate" prop="author" [sortable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{ column.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div style="display: flex; flex-direction: row; align-items: center;">
            <span [innerHtml]="row.authors | highlight: (data.searchString)"
            ></span>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="1" [name]="'column_header_collections' | translate" prop="collections" [sortable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{ column.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
<!--           Make data mapping for avoid recurrent search in names of collections -->
          <span>{{ row.collections }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        [width]="50"
        [resizeable]="false"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="false"
      >
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <button mat-icon-button [matMenuTriggerFor]="menu" class="table-edit-button">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item  (click)="edit(row.uid)">
              <mat-icon>edit</mat-icon>
              <span>{{'admin_books_edit_button' | translate}}</span>
            </button>
            <button 
              mat-menu-item 
              [disabled]="!row.isCreated"
              [routerLink]="['/book/' + row.uid]">
              <mat-icon>description</mat-icon>
              <span>{{'admin_books_open_book_in_catalog' | translate}}</span>
            </button> 
            <button mat-menu-item (click)="delete(row.uid)">
              <mat-icon>close</mat-icon>
              <span>{{'admin_books_delete_button' | translate}}</span>
            </button>
          </mat-menu>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-footer>
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-selectedCount="selectedCount"
          let-curPage="curPage"
          let-offset="offset"
          let-isVisible="isVisible">
          <div *ngIf="rows?.length > 0" class="page-limit-selection">
            <mat-select
              [value]="page.limit"
              style="width: 200px"
              class="global-select"
              (valueChange)="onLimitChange($event)">
              <mat-option
                *ngFor="let option of pageLimitOptions"
                [value]="option.value"
              >
                {{ option.value }} {{ 'pagination_on_page' | translate }}
              </mat-option>
            </mat-select>
          </div>
          <datatable-pager
            [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'"
            [page]="curPage"
            [size]="pageSize"
            [count]="rowCount"
            [hidden]="!((page.totalElements / pageSize) > 1)"
            (change)="table.onFooterPage($event)">
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>
</div>



