import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApplicationUtilsService } from '../../../shared/services/application-utils.service';
import {ApplicationAuthService} from '../../../shared/services/application-auth.service';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-select-account-template',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss'],
})
export class SelectAccountComponent implements OnInit {
  @Input() public tenants: any;
  @Input() public closeDialog: any;

  public form: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    public formBuilder: FormBuilder,
    public utils: ApplicationUtilsService,
    private auth: ApplicationAuthService,
    public api: ApplicationApiService,
    public cookieService: CookieService,
  ) {
  }

  public ngOnInit(): void {
  }

  save(tenant): void {
    this.cookieService.deleteAll();
    this.api.selectActiveTenant(tenant.uid).pipe().subscribe(res => {
      this.closeDialog(res);
    });
    /*this.auth.setSelectedTenant(tenant.uid);*/
  }
}
