import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, debounceTime, first, map, Subscription} from 'rxjs';
import {ApplicationApiService} from '../../../shared/services/application-api.service';
import {CookieService} from 'ngx-cookie-service';
import {ApplicationUtilsService} from '../../../shared/services/application-utils.service';
import {environment} from "../../../../environments/environment";
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-switch-tenant-dialog-template',
  templateUrl: 'switch-tenant-dialog-template.component.html',
  styleUrls: ['switch-tenant-dialog-template.component.scss']
})

export class SwitchTenantDialogTemplateComponent implements OnInit, OnDestroy {
  public loading$ = new BehaviorSubject<boolean>(true);
  private baseURL = `${environment.apiUrl}/api/v1`;

  public userTenants;
  public activeTenant;
  private subs: Subscription[] = [];
  constructor(
    public api: ApplicationApiService,
    public cookieService: CookieService,
    public utils: ApplicationUtilsService,
    private dialogRef: MatDialogRef<SwitchTenantDialogTemplateComponent>
  ) {
    this.subs.push(
      this.api.getPublisher$().subscribe(tenant => {
        this.activeTenant = tenant;
      })
    );

    this.subs.push(
      this.api.verifyToken$().subscribe((credentialsFromServer: any) => {
        this.userTenants = credentialsFromServer?.tenants;
        this.loading$.next(false);
      })
    );
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  public getPublisherLogoSrc(uid: string): string {
    return `url("${this.baseURL}/tenant/image/?uid=${uid}")`;
  }
  save(tenantUid: string): void {
    if (tenantUid === this.activeTenant.uid) {
      return;
    }
    this.cookieService.deleteAll();
    this.api.selectActiveTenant(tenantUid).pipe().subscribe(res => {
      this.dialogRef.close();
      window.location.reload();
    });
  }
}
