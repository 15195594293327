import { Component, OnInit , Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {BehaviorSubject, catchError, finalize, Observable} from 'rxjs';
import {ApplicationApiService} from '../../../../shared/services/application-api.service';
import {ApplicationUtilsService} from '../../../../shared/services/application-utils.service';
import {ApplicationStateService} from '../../../../shared/services/application-state.service';
import {HistoryItem, HistoryItemType} from '../../../../shared/app-interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-history-template',
  templateUrl: './history-form.template.html',
  styleUrls: ['./history-form.template.scss']
})

export class ViewHistoryComponent implements OnInit {
  public HistoryItemType = HistoryItemType;
  public form: FormGroup;
  public loading$ = new BehaviorSubject<boolean>(true);
  public history$: Observable<HistoryItem[]>;
  public history: HistoryItem[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {subscriptionId: string},
    public formBuilder: FormBuilder,
    private api: ApplicationApiService,
    public utils: ApplicationUtilsService,
    public state: ApplicationStateService,
    private dialogRef: MatDialogRef<ViewHistoryComponent>
  ) {}

  public ngOnInit(): void {
    this.history$ = this.api.getSubscriptionHistory$(this.data.subscriptionId);

    this.history$.pipe(
      catchError((err): any => {
        this.utils.showSnackbar('history_loading_error_msg');
      }),
      finalize(() => {
        this.loading$.next(false);
      })
    ).subscribe((history: HistoryItem[]) => {
      this.history = history;
    });
  }

  close():void{
    this.dialogRef.close();
  }
}
