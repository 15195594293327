import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationStateService } from '../../shared/services/application-state.service';
import {ApplicationUtilsService, CustomIcons} from '../../shared/services/application-utils.service';

@Component({
  selector: 'app-navi-bar',
  templateUrl: './navi-bar.component.html',
  styleUrls: ['./navi-bar.component.scss']
})
export class NaviBarComponent implements OnInit {
  public readonly CustomIcons = CustomIcons;

  constructor(public router: Router, public state: ApplicationStateService, public utils: ApplicationUtilsService) {
  }

  ngOnInit(): void {
  }

  isActive(route: string): boolean{
    return this.router.url.includes(route);
  }
}
