import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


export interface ConfirmDialogContent {
  text: string | string[];
  title: string;
  hideButtons?: boolean;
  hideCancel?: boolean;
  hideClose?: boolean;
  actionOnYes?: () => void;
  noButtonTitle?: string;
  yesButtonTitle?: string;
  additionalText?: string;
  toolTipMode?: boolean;
}

@Component({
  selector: 'lbp-confirm-dialog',
  templateUrl: './template.html',
  styleUrls: ['./style.scss']
})


export class ConfirmDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogContent,
              private dialogRef: MatDialogRef<ConfirmDialogComponent>) {
  }

  public isMultipleText(): boolean{
    return Array.isArray(this.data.text) && this.data.text.length > 1;
  }

  public decline = (): void => {
    this.dialogRef.close(false);
  }

  public confirm = (): void => {
    if (this.data.actionOnYes) {
      this.data.actionOnYes();
      return;
    }
    this.dialogRef.close(true);
  }
}
