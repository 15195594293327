<button mat-icon-button [matMenuTriggerFor]="menu" class="table-edit-button">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="editUser(row)">
      <mat-icon>edit</mat-icon>
      <span>{{'admin_users_edit_button' | translate}}</span>
    </button>
    <button mat-menu-item (click)="deleteUser(row.id)">
      <mat-icon>close</mat-icon>
      <span>{{'admin_users_delete_button' | translate}}</span>
    </button>
    <button mat-menu-item (click)="changePassword(row)">
      <mat-icon>lock</mat-icon>
      <span>{{'admin_users_change_password_button' | translate}}</span>
    </button>
    <button mat-menu-item (click)="resendInvitation(row.id)">
      <mat-icon>send</mat-icon>
      <span>{{'admin_users_invitation_button' | translate}}</span>
    </button>
  </mat-menu>      