<ng-container *ngIf="{
currentBook: currentBook$ | async,
loading: loading$ | async
} as data">

  <div *ngIf="data.loading" class="global-spinner-cnt">
    <mat-spinner color="primary" diameter="50"></mat-spinner>
  </div>

  <div class="book-reader-viewer" *ngIf="bookType">

    <app-book-reader-header [type]="bookType" [sidenav]="sidenav" [book]="data.currentBook"></app-book-reader-header>

    <mat-sidenav-container 
        [ngClass]="{ 'sidenav-over-mode': sidenavOverMode }">
      <mat-sidenav #sidenav 
                   class="sidenav" 
                   [mode]="sidenavOverMode ? 'over' : 'side'" 
                   [opened]="true" 
                   [disableClose]="false" >
        <app-book-content-table [type]="bookType" (itemClicked)="hideSidenav()" class="content-table"></app-book-content-table>
        <mat-icon class="clickable-icon close-side-nav" (click)="hideSidenav()">close</mat-icon>
      </mat-sidenav>

      <div class="sidenav-content">
        <ng-container *ngIf="bookType === BookType.PDF">
          <app-pdf-viewer *ngIf="data.currentBook" [book]="data.currentBook"
            (switchSidenavModeOnResize)="switchSidenavModeOnResize($event)"></app-pdf-viewer>
        </ng-container>
        <ng-container *ngIf="bookType === BookType.EPUB">
          <app-epub-viewer *ngIf="data.currentBook" [book]="data.currentBook"
            (switchSidenavModeOnResize)="switchSidenavModeOnResize($event)"></app-epub-viewer>
        </ng-container>
      </div>
    </mat-sidenav-container>
  </div>
</ng-container>