import { Component, OnInit } from '@angular/core';
import { CustomIcons } from '../../shared/services/application-utils.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public readonly CustomIcons = CustomIcons;
  public currentYear = new Date().getFullYear();
  constructor() {
  }

  ngOnInit(): void {
  }

}
