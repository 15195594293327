import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ApplicationApiService} from '../../shared/services/application-api.service';
import {ApplicationAuthService} from '../../shared/services/application-auth.service';
import {ApplicationStateService} from '../../shared/services/application-state.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-consent-dialog',
    templateUrl: './consent-dialog.component.html',
    styleUrls: ['./consent-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsentDialogComponent implements OnInit{
 constructor(
   public api: ApplicationApiService,
   public auth: ApplicationAuthService,
   public state: ApplicationStateService,
   private dialogRef: MatDialogRef<ConsentDialogComponent>
 ) {
 }

 ngOnInit(): void {
   this.state.consentDialogOpened$.next(true);
 }

  save(): void {
   this.api.consentAgree$().subscribe(res => {
     this.api.consentCheck$().subscribe(response => {
       if (!response) {
         this.auth.logout();
       }
     });
   });
   this.close();
   window.location.reload();
 }

 close(): void {
  this.dialogRef.close();
 }
}
