<mat-dialog-content>
  <h3 class="dialog-title">{{ 'add_user_to_subscriber_dialog_title' | translate }}</h3>
  <div class="create-admin-form-template">
    <div *ngIf="loading$ | async" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>

    <form [formGroup]="form" (submit)="save()">
      <input class="global-input"
            formControlName="subscriberCode"
            type="text"
            [placeholder]="'add_user_to_subscriber_input_placeholder' | translate">
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="buttons">
    <button class="global-button default" (click)="cancel()">
      {{ 'dialog_options_cancel' | translate }}
    </button>
    <button class="global-button" (click)="save()">
      {{ 'dialog_options_save' | translate }}
    </button>
  </div>
</mat-dialog-actions>