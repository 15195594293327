<div *ngIf="{
    users: this.state.users$ | async,
    isLoading$: isLoading$ | async,
    subscribers: state.subscribers$ | async,
    selectedSubscriber: state.selectedSubscriber$ | async,
    searchString: (state.usersSearchString$ | async).trim()
  } as data">

    <div class="options" style="gap: 20px; margin-top: 20px;">
        <div class="search" style="margin-bottom: 0">
            <mat-form-field
                [floatLabel]="'never'"
                class="global-mat-form-field">
                <mat-select [placeholder]="'admin_readers_subscriber_select_placeholder' | translate">
                    <mat-select-trigger>
                        <div>{{data.selectedSubscriber?.title}}</div>
                    </mat-select-trigger>
                    <mat-option (click)="state.selectedSubscriber$.next(null)">
                    --
                    </mat-option>
                    <mat-option *ngFor="let subscriber of filteredSubscribers" [value]="subscriber.uid">
                        <div class="global-flex-row-space-between-center">
                            <span (click)="changeSelectedSubscriber(subscriber.uid)" style="flex: 1">
                            {{subscriber.title}}
                            </span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div style="text-align: right;width: 100%; margin-right: 0px;">
        {{'readers_count_found' | translate}}{{page.totalElements}}
    </div>
    <div class="table" style="position: relative">
        <div *ngIf="data.isLoading$" class="global-spinner-cnt">
          <mat-spinner color="primary" diameter="50"></mat-spinner>
        </div>
        
        <ngx-datatable #table [headerHeight]="60" [footerHeight]="0" [rowHeight]="50"
            [columnMode]="'force'" [rows]="rows" [columns]="columns" [virtualization]="false" [externalPaging]="false"
            [count]="(page.totalElements + rows?.length)" [offset]="page.offset" [limit]="page.limit"
            (page)="setPage($event)" [messages]="{emptyMessage: this.utils.getTableMessage('empty_table_message' | translate)}">
        
            <ngx-datatable-column [name]="'column_header_user' | translate" prop="email" [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span class="mobile-hidden">{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span class="mobile-hidden">{{ row.email }}</span>
          </ng-template>
        </ngx-datatable-column>
  
        <ngx-datatable-column [name]="'column_header_date' | translate" prop="created" [sortable]="true">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span class="mobile-hidden">{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span class="mobile-hidden">{{ formattedDate(row.createdDate) }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <user-menu [(page)]="page" [(isLoading$)]="isLoading$" [(reload)]="getFilteredPaginatedReaders" [row]="row"></user-menu>
          </ng-template>
        </ngx-datatable-column>
        <!-- <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <button mat-icon-button [matMenuTriggerFor]="menu" class="table-edit-button">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editUser(row)">
                <mat-icon>edit</mat-icon>
                <span>{{'admin_users_edit_button' | translate}}</span>
              </button>
              <button mat-menu-item (click)="deleteUser(row.id)">
                <mat-icon>close</mat-icon>
                <span>{{'admin_users_delete_button' | translate}}</span>
              </button>
              <button mat-menu-item (click)="changePassword(row)">
                <mat-icon>lock</mat-icon>
                <span>{{'admin_users_change_password_button' | translate}}</span>
              </button>
              <button mat-menu-item (click)="resendInvitation(row.id)">
                <mat-icon>send</mat-icon>
                <span>{{'admin_users_invitation_button' | translate}}</span>
              </button>
            </mat-menu>
          </ng-template>
        </ngx-datatable-column> -->
      </ngx-datatable>
    </div>   
</div>