import { ChangeDetectionStrategy, Component, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { compareAsc, differenceInBusinessDays, differenceInDays, format, set } from 'date-fns';
import {BehaviorSubject, catchError, combineLatest, debounceTime, finalize, first, Subscription, switchMap} from 'rxjs';
import { Page, PageLimitOptions } from 'src/app/shared/app-global-variables';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { ApplicationUtilsService } from 'src/app/shared/services/application-utils.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/component';
import { ApplicationApiService } from 'src/app/shared/services/application-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscriber } from 'src/app/shared/app-interfaces';

@Component({
    selector: 'readers-requests-mode',
    templateUrl: 'readers-requests.component.html',
    styleUrls: ['readers-requests.component.scss']
  })
  export class ReadersRequestComponent{
    public isLoading$ = new BehaviorSubject<boolean>(false);
    private subs: Subscription[] = [];
    rows: any;
    public pageLimitOptions = PageLimitOptions;
    public subscriber$ = new BehaviorSubject<Subscriber>(null);
    page = new Page();

    public filteredSubscribers: Subscriber[] = null;

    constructor(
      public state: ApplicationStateService,
      public utils: ApplicationUtilsService,
      public cd: ChangeDetectorRef,
      public api: ApplicationApiService
    ) {
      this.page.offset = 0;
      this.page.limit = 25;
      this.setPage(this.page);

      this.state.tenantRearersRequests$.subscribe(x => this.rows = x);
      this.subscriber$.subscribe(x => this.reload());

      this.subs.push(combineLatest([this.state.subscribers$, this.state.subscriptions$]).pipe(debounceTime(500))
      .subscribe(([subscribers, subscriptions]) => this.filteredSubscribers = subscribers.sort(x => { 
        return subscriptions.filter(sub => sub.subscriber.uid == x.uid && this.isExpired(sub)).length > 1 ? 1 : -1;
      })));
    }

    onLimitChange(limit: any): any {
      this.page.limit = Number(limit);
      this.reload();
    }

    reload(){
      this.setPage(this.page);
    }

    setPage(pageInfo): any {
      this.isLoading$.next(true);
      this.page.offset = pageInfo.offset;
        this.state.getTenantReadersRequests(this.page.limit, this.page.offset * this.page.limit, this.subscriber$.value?.uid).subscribe((res) => {
          const {data, page} = res;
          this.rows = data;
          this.page.totalElements = page.totalElements;
          this.page.totalPages = page.totalElements / this.page.limit >= 1 ? page.totalElements / this.page.limit : 1;
          this.isLoading$.next(false);
        });
      this.cd.markForCheck();
    }

    selectSubscriber(newSub: Subscriber){
      this.subscriber$.next(newSub);
    }

    accept(id: string){
      this.isLoading$.next(true)
      this.api.acceptRequest(id).pipe(
        catchError((e: HttpErrorResponse): any => {
        this.utils.showSnackbar('something_went_wrong_msg', 2000);
      }),
        finalize(() => this.isLoading$.next(false))
      ).subscribe(res => {
        if(!res){
          this.utils.showSnackbar('something_went_wrong_msg', 2000);
          return;
        }
        this.utils.showSnackbar('request_accepted_msg', 2000);
        this.reload();
      });
    }

    reject(id: string){
      this.isLoading$.next(true)
      this.api.rejectRequest(id).pipe(
        catchError((e: HttpErrorResponse): any => {
        this.utils.showSnackbar('something_went_wrong_msg', 2000);
      }),
        finalize(() => this.isLoading$.next(false))
      ).subscribe(res => {
        if(!res){
          this.utils.showSnackbar('something_went_wrong_msg', 2000);
          return;
        }
        this.utils.showSnackbar('request_rejected_msg', 2000);
        this.reload();
      });
    }

    isExpired(row: any): boolean {
      if (row.licenseType === 'Individual') {
        return !row?.enabled;
      }
      return this.getLeftDays(row) === 0;
    }

    getLeftDays(row: any): any {
      if (compareAsc(new Date(), new Date(row?.end)) !== -1) {
        return 0;
      } else {
        return differenceInDays(
          new Date(row?.end),
          new Date()
        );
      }
    }
  }