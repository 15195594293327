import {ChangeDetectorRef, Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {format} from 'date-fns';
import { environment } from 'src/environments/environment';
import {ApplicationTranslateService} from './application-translate.service';

export enum CustomIcons {
  logo = 'logo',
  arrow_right_bold_box = 'arrow_right_bold_box',
  plus = 'plus',
  checkbox_marked_circle_outline = 'checkbox_marked_circle_outline',
  checkbox_failed_circle_outline = 'checkbox_failed_circle_outline',
  cursor = 'cursor',
  pdf = 'pdf',
  footer_book = 'footer_book',
  instagram = 'instagram',
  telegram = 'telegram',
  twitter = 'twitter',
  view_grid = 'view_grid',
  view_sequential = 'view_sequential',
  main_catalogue_icon = 'main_catalogue_icon'
}

@Injectable({providedIn: 'root'})
export class ApplicationUtilsService {

  // #todo Do something with this hardcoded url;
  private baseURL = `${environment.apiUrl}/api/v1`;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private snackBarService: MatSnackBar,
    private translateService: ApplicationTranslateService,
    private domSanitizer: DomSanitizer) {
  }

  public registerIcons(): void {
    this.loadIcons(Object.keys(CustomIcons));
  }

  private loadIcons(iconKeys: string[]): void {
    iconKeys.forEach((key: string) => {
      this.matIconRegistry.addSvgIcon(
        key,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${window.location.origin}/assets/svg-icons/${key}.svg`)
      );
    });
  }

  public showSnackbar(text: string, duration?: number, translate = true): void {
    this.snackBarService.open(this.translateService.getTranslation(text), null, {duration: duration ?? 3000});
  }

  getBookTitleImage(id: string): string {
    return `${this.baseURL}/book/image/?uid=${id}`;
  }

  public getPublisherLogoSrc(uid: string): string {
    return `${this.baseURL}/tenant/image/?uid=${uid}`;
  }

  getformattedDate(date): any {
    return date ? format(new Date(date), 'dd.MM.yyyy') : "";
  }

  translate(key: string, langKey?: string): any {
    return this.translateService.getTranslation(key, langKey);
  }

  hasParentClass(child, classname): boolean {
    if (child.className && typeof child.className === 'string' && child.className.split(' ').indexOf(classname) >= 0) {
      return true;
    }
    try {
      return child.parentNode && this.hasParentClass(child.parentNode, classname);
    } catch (TypeError) {
      return false;
    }
  }

  getTableMessage(message): string {
    return `
        <div class="global-table-message-container">
          <span class="global-table-message-text">${message}</span>
        </div>`;
  }
}
