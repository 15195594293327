<div class="admin-users global-main-cnt global-flex-column-space-between-center">
  <mat-tab-group  style="width: 100%;" animationDuration="0ms"
   [(selectedIndex)]="selected">
    <mat-tab label="{{'admin_readers_option_all_text' | translate}}">
       <all-mode-readers></all-mode-readers>
    </mat-tab>
    <mat-tab label="{{'admin_readers_option_regular_text' | translate}}">
       <regular-mode-readers></regular-mode-readers>
    </mat-tab>
    <mat-tab label="{{'admin_readers_option_individual_text' | translate}}">
       <individual-mode-readers></individual-mode-readers>
    </mat-tab>
    <mat-tab label="{{'admin_readers_option_wait_request_text' | translate}}">
      <readers-requests-mode></readers-requests-mode>
   </mat-tab>
  </mat-tab-group>
</div>