import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/component';
import { CustomIcons, ApplicationUtilsService } from '../../../shared/services/application-utils.service';
import { Collection } from '../../../shared/app-interfaces';
import { ApplicationStateService } from '../../../shared/services/application-state.service';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject, catchError, finalize, first, switchMap } from 'rxjs';
import { ApplicationApiService } from '../../../shared/services/application-api.service';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Page, PageLimitOptions } from '../../../shared/app-global-variables';
import { format } from 'date-fns';
import { EditCollectionComponent } from './edit-collection-template/edit-collection.component';

@Component({
  selector: 'app-admin-collections',
  templateUrl: './admin-collections.component.html',
  styleUrls: ['./admin-collections.component.scss']
})

export class AdminCollectionsComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;

  public readonly CustomIcons = CustomIcons;
  public isLoading$ = new BehaviorSubject<boolean>(false);

  public pageLimitOptions = PageLimitOptions;
  public searchString = '';
  public temp = [];
  public rows: Collection[];
  public columns = [];
  public page = new Page();
  public sort: any = [{field: 'id', order: 'asc'}];

  constructor(
    public state: ApplicationStateService,
    private dialog: MatDialog,
    public api: ApplicationApiService,
    public utils: ApplicationUtilsService,
    public cd: ChangeDetectorRef,
    public router: Router
  ) {
    this.page.offset = 0;
    this.page.limit = 10000;
    this.setPage({offset: 0});
  }

  ngOnInit(): void {
    this.state.getBooks();
  }

  refresh(): any {
    this.setPage({offset: 0});
  }

  setPage(pageInfo): any {
    this.isLoading$.next(true);
    this.page.offset = pageInfo.offset;
    this.state.getCollections(true, this.page.limit, this.page.offset * this.page.limit).subscribe((res) => {
      const {data, page} = res;
      this.temp = data;
      this.page.totalElements = page.totalElements;
      this.page.totalPages = page.totalElements / this.page.limit >= 1 ? page.totalElements / this.page.limit : 1;
      this.rows = this.temp;
      this.isLoading$.next(false);
      this.cd.markForCheck();
    });
  }

  onLimitChange(limit: any): any {
    this.page.limit = Number(limit);
    this.refresh();
  }

  createCollection(): any {
    return this.dialog
      .open(EditCollectionComponent, {
        maxWidth: '1000px',
        width: '100%',
        minHeight: '200px',
        disableClose: true,
        autoFocus: false,
        data: {title: 'create_collection_dialog_title'}
      })
      .afterClosed().subscribe((res) => {
        if(res){
          this.setPage({offset: this.page.offset});
        }
      });
  }

  editCollection(collection): any {
    return this.dialog
      .open(EditCollectionComponent, {
        maxWidth: '1000px',
        width: '100%',
        minHeight: '200px',
        disableClose: true,
        autoFocus: false,
        data: {collection: collection , title: 'edit_collection_dialog_title'}
      }).afterClosed().subscribe((res) => {
        if(res){
          collection.title = res.title;
          collection.price = res.price;
          collection.books = res.books;
        }
      });
  }

  deleteCollection(id: string): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        disableClose: true,
        autoFocus: false,
        data: {text: 'delete_collection_confirm_text', title: 'delete_collection_confirm_title'}
      })
      .afterClosed()
      .pipe(first()).subscribe(yes => {
      if (!yes) {
        return;
      }
      this.isLoading$.next(true);
      this.api.deleteCollection$(id).pipe(
        first(),
        catchError((): any => {
          this.utils.showSnackbar('delete_collection_error_msg');
        }),
        finalize(() => this.isLoading$.next(false)),
        switchMap(() => {
          return this.state.getCollections(true);
        })
      )
        .subscribe(() => {
          this.isLoading$.next(false);
          this.state.getBooks();
          this.setPage({offset: this.page.offset});
          this.utils.showSnackbar('delete_collection_success_msg');
        });
    });
  }

  formattedDate(date: string): string {
    return date ? format(new Date(date), 'dd.MM.yyyy hh:mm') : "";
  }
}