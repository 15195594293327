<mat-dialog-content>
  <h3 class="dialog-title">{{ 'switch_tenant_dialog_title' | translate }}</h3>
  <div>
    <div *ngIf="loading$ | async" class="global-spinner-cnt">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>
    <div class="select-account-template" *ngIf="userTenants">
      <div *ngFor="let tenant of userTenants">
        <div [ngClass]="{'active-tenant-container': (tenant?.uid === activeTenant?.uid)}"
            class="global-flex-column-start-center tenant-container"
            (click)="save(tenant?.uid)">

          <div style="height: 100px">
            <p class="tenant-name">
              {{tenant?.name}}
            </p>
          </div>

          <div class="img-container" [ngStyle]="{'background-image': getPublisherLogoSrc(tenant.uid),
            'background-repeat': 'no-repeat',
            'background-position': 'center',
            'background-size': 'contain'}">
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>