<div style="width: 100vw;"
     *ngIf="{
      adminMode: state.adminMode$ | async
   } as data">
    <div class="table" style="width: 100vw;">
      <ngx-datatable
        #myTable
        class="material expandable"
        [rows]="rows"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [virtualization]="false"
        [sorts]="[{prop: 'created', dir: 'asc'}]"
        [groupExpansionDefault]="false"
        [messages]="{emptyMessage: this.utils.getTableMessage('empty_table_message' | translate)}"
      >
        <ngx-datatable-column [flexGrow]="1"  [name]="'column_header_title' | translate" prop="name" [sortable]="false">
          <ng-template ngx-datatable-cell-template
                       let-rowIndex="rowIndex"
                       let-value="value"
                       let-row="row"
                       let-group="group">
            <div>
              {{row?.name}}
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1"  [name]="'column_header_status' | translate" prop="status" [sortable]="false">
          <ng-template ngx-datatable-cell-template
                       let-rowIndex="rowIndex"
                       let-value="value"
                       let-row="row"
                       let-group="group">
            <div style="display: flex; flex-direction: row; align-items: center;">
              <div [ngStyle]="{'color': row?.blocked ? '#E86F00' : '#29BC29'}">
                {{(row?.blocked ? 'tenants_management_status_blocked' : 'tenants_management_status_active') | translate}}
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        
        <ngx-datatable-column [flexGrow]="1"  [name]="'column_header_books_count' | translate" prop="booksCount" [sortable]="false">
          <ng-template ngx-datatable-cell-template
                       let-rowIndex="rowIndex"
                       let-value="value"
                       let-row="row"
                       let-group="group">
            <div style="display: flex; flex-direction: row; align-items: center;">
              <div>
                {{row.booksCount}}
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1"  [name]="'column_header_collections_count' | translate" prop="booksCount" [sortable]="false">
          <ng-template ngx-datatable-cell-template
                       let-rowIndex="rowIndex"
                       let-value="value"
                       let-row="row"
                       let-group="group">
            <div style="display: flex; flex-direction: row; align-items: center;">
              <div>
                {{row.collectionsCount}}
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1"  [name]="'column_header_subscribtions_count' | translate" prop="booksCount" [sortable]="false">
          <ng-template ngx-datatable-cell-template
                       let-rowIndex="rowIndex"
                       let-value="value"
                       let-row="row"
                       let-group="group">
            <div style="display: flex; flex-direction: row; align-items: center;">
              <div>
                {{row.subscriptionsCount}}
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        
        <ngx-datatable-column [flexGrow]="1"  [name]="'column_header_active_subscribtions_count' | translate" prop="booksCount" [sortable]="false">
          <ng-template ngx-datatable-cell-template
                       let-rowIndex="rowIndex"
                       let-value="value"
                       let-row="row"
                       let-group="group">
            <div style="display: flex; flex-direction: row; align-items: center;">
              <div>
                {{row.activeSubscriptionsCount}}
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <!-- <ngx-datatable-column [flexGrow]="1"  [name]="'column_header_published' | translate" prop="published" [sortable]="false">
          <ng-template ngx-datatable-cell-template
                       let-rowIndex="rowIndex"
                       let-value="value"
                       let-row="row"
                       let-group="group">
            <div style="display: flex; flex-direction: row; align-items: center;">
              <mat-icon
                style="width: 20px; height: 20px; margin-right: 10px;"
                [svgIcon]="row?.published
                  ? CustomIcons.checkbox_marked_circle_outline
                  : CustomIcons.checkbox_failed_circle_outline
                ">
              </mat-icon>
            </div>
          </ng-template>
        </ngx-datatable-column> -->

        <ngx-datatable-column [flexGrow]="1"  [name]="'column_header_mail' | translate" prop="email" [sortable]="false">
          <ng-template ngx-datatable-cell-template
                       let-rowIndex="rowIndex"
                       let-value="value"
                       let-row="row"
                       let-group="group">
            <div>
              {{row?.email}}
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1"  [name]="'column_header_created' | translate" prop="created" [sortable]="false">
          <ng-template ngx-datatable-cell-template
                       let-rowIndex="rowIndex"
                       let-value="value"
                       let-row="row"
                       let-group="group">
            <div>
              {{utils.getformattedDate(row?.created)}}
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [width]="50"
          [resizeable]="false"
          [sortable]="false"
          [draggable]="false"
          [canAutoResize]="false"
        >
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <button mat-icon-button [matMenuTriggerFor]="menu" class="table-edit-button">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="selectTenant(row?.uid)">
                <mat-icon>move_to_inbox</mat-icon>
                <span>{{'tenants_management_select_button' | translate}}</span>
              </button>
              <button mat-menu-item (click)="blockTenant(row.uid, row?.blocked)">
                <mat-icon>{{ row?.blocked ? 'lock' : 'lock_open' }}</mat-icon>
                <span>{{ (row?.blocked ? 'tenants_management_unblock_button' : 'tenants_management_block_button') | translate}}</span>
              </button>
              <button mat-menu-item (click)="deleteTenant(row)">
                <mat-icon>delete</mat-icon>
                <span>{{'tenants_management_delete_button' | translate}}</span>
              </button>
            </mat-menu>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template  ngx-datatable-footer-template>
            <div>Total: {{rows?.length}}</div>
          </ng-template>
        </ngx-datatable-footer>

      </ngx-datatable>
    </div>
</div>
