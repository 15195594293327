<!-- <div stype="height: 20px;"></div> -->

<div
    #bookViewerRef 
    class="book-viewer-container" 
    [ngStyle]="{'min-height': minHeight}">

    <!-- *ngIf="loading$ | async"  -->
    <div *ngIf="loading$ | async" class="book-spinner-cnt">
        <div class="book-spinner-centring">
            <mat-spinner color="primary" diameter="50"></mat-spinner>
        </div>
    </div>
    
    <div id="bookViewer"></div>

</div>

<div class="book-viewer-controls" [ngStyle]="{'pointer-events': (loading$ | async) ? 'none': 'auto'}">
    <div><span *ngIf="epubReaderService?.hasPrevChapter" (click)="epubReaderService?.prev()" class="navlink">{{ 'book_reader_prev_button' | translate }}</span></div>
    <div><span *ngIf="epubReaderService?.hasNextChapter" (click)="epubReaderService?.next()" class="navlink">{{ 'book_reader_next_button' | translate }}</span></div>
</div>
