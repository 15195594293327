import { Injectable } from '@angular/core';
import ePub, { Book, NavItem, Rendition } from 'epubjs';
import Locations from 'epubjs/types/locations';
import Navigation from 'epubjs/types/navigation';
import Section from 'epubjs/types/section';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationAuthService } from './application-auth.service';

const A4_HEIGHT = 877; // px
const A4_WIDTH = 720; // px

@Injectable({ providedIn: 'root' })
export class EpubReaderService {
  private book: any;
  private rendition: Rendition;
  private displayed: any;
  //private currentSectionIndex: number;
  public tableOfContent$ = new BehaviorSubject<Array<NavItem>>(null);

  public chapterRendingStarted$ = new BehaviorSubject<boolean>(false);
  public chapterRendered$ = new BehaviorSubject<Section>(null);
  public navigation: any;
  public hasPrevChapter: Boolean;
  public hasNextChapter: Boolean;
  public currentChapter$ = new BehaviorSubject<string>(null);

  //public currentPage: number;
  //public totalPages: number;

  constructor(public applicationAuthService: ApplicationAuthService) {
  }

  public load(bookId: string, currentSectionIndex?: number) {
    const isLocalhost = window.location.hostname.indexOf('localhost') !== -1
    //this.currentPage = 1;
    // Load the opf
    const credentials = this.applicationAuthService.tryToGetParsedCredentialsFromStorage();
    this.book = ePub(`${!isLocalhost ? environment.apiUrl : 'http://localhost:4200'}/v1/reader/${bookId}/epub/opf`, {
      requestHeaders: {
        'Accept': 'application/xml',
        'Authorization': 'Bearer ' + credentials.token
      }
    });
    // Render view
    this.rendition = this.book.renderTo("bookViewer", {
      width: "100%",
      flow: "scrolled-doc",
      fullsize: false
    });
    //this.currentSectionIndex = currentSectionIndex;
    this.displayed = this.rendition.display(currentSectionIndex);

    this.displayed.then(() => {
      console.log('rendition.currentLocation():', this.rendition.currentLocation());
    });

    // Generate location and pagination
    this.book.ready.then(() => {
      const stored = localStorage.getItem(this.book.key() + '-locations');
      if (stored) {
        return this.book.locations.load(stored);
      } else {
        return this.book.locations.generate(1024); // Generates CFI for every X characters (Characters per/page)
      }
    }).then((location) => {
      localStorage.setItem(this.book.key() + '-locations', this.book.locations.save());
    });

    this.rendition.on("started", (section: Section) => {
      this.chapterRendingStarted$.next(true);
    })

    this.rendition.on("rendered", (section: Section) => {
      this.hasPrevChapter = !!section.prev();
      this.hasNextChapter = !!section.next();
      this.chapterRendered$.next(section);
      this.currentChapter$.next(section?.href);
    });

    // const next = document.getElementById("next");
    // const prev = document.getElementById("prev");

    // this.rendition.on("rendered", (section) => {
    //   const nextSection = section.next();
    //   let nextLabel = '';
    //   if (nextSection) {
    //     let nextNav = this.book.navigation.get(nextSection.href);

    //     if (nextNav) {
    //       nextLabel = nextNav.label;
    //     } else {
    //       nextLabel = "next";
    //     }

    //     next.textContent = nextLabel + " »";
    //   } else {
    //     next.textContent = "";
    //   }

    //   const prevSection = section.prev();
    //   let prevLabel = '';
    //   if (prevSection) {
    //     let prevNav = this.book.navigation.get(prevSection.href);

    //     if (prevNav) {
    //       prevLabel = prevNav.label;
    //     } else {
    //       prevLabel = "previous";
    //     }

    //     prev.textContent = "« " + prevLabel;
    //   } else {
    //     prev.textContent = "";
    //   }

    // });

    this.loadNavigation();
  }

  public loadNavigation() {
    // Navigation loaded
    this.book.loaded.navigation.then((navigation: Navigation) => {
      this.navigation = navigation;
      this.tableOfContent$.next(this.navigation?.toc);
    });
  }

  public getZoomString() {
    return '100%';
  }

  public next() {
    this.chapterRendingStarted$.next(true);
    this.rendition.next();
    //this.currentPage++;
  }

  public prev() {
    this.chapterRendingStarted$.next(true);
    this.rendition.prev();
    //this.currentPage--;
  }

  public openChapter(item) {
    //const index = this.navigation.tocByHref[item.href];
    this.chapterRendingStarted$.next(true);
    this.rendition.display(item.href);
  }
}
