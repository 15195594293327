<section class="publisher-registration-form">
  <div class="global-flex-column-center-center">
    <h2>{{ 'publisher_registration_title' | translate }}</h2>
    <p style="font-size: 18px"><b>{{ 'publisher_registration_subtitle' | translate }}</b></p>
  </div>
  <div class="form-container">
    <img src="assets/publisher_registration_form_img.svg" alt="publishers-question-form-illustration">

    <form id="form" [formGroup]="form" (ngSubmit)="submit()" style="margin-top: 80px">
      <p class="error-message" *ngIf="displayEmailIsTakenError">
        {{'publisher_registration_form_error_msg_first_part_text' | translate}}
        <a routerLink="/authorization">{{'publisher_registration_form_error_msg_link_text' | translate}}</a>
        {{'publisher_registration_form_error_msg_second_part_text' | translate}}
        </p>

      <div class="form-fields">
        <input class="global-input" id="name" name="name" formControlName="name" type="text" required
          placeholder="{{ 'input_placeholder_publisher_name' | translate }} *" />
        <input class="global-input" id="email" name="email" formControlName="email" type="email" required
          placeholder="{{ 'input_placeholder_email' | translate }} *"
          (change)="displayEmailIsTakenError ? removeEmailIsTakenError : null" />
        <input class="global-input" id="password" name="password" formControlName="password" type="password"
          placeholder="{{ 'authorization_password_placeholder' | translate }} *" />
        <input class="global-input" id="repeatPassword" name="repeatPassword" formControlName="repeatPassword"
          type="password" placeholder="{{ 'authorization_repeat_password_placeholder' | translate }} *" />
      </div>

      <button type="submit" class="submit-button" [disabled]="isSubmitDisabled">{{ 'authorization_register_btn' |
        translate | uppercase }}</button>

      <div
        class="global-flex-row-end-center full-width"
        *ngIf="form.controls['email']?.hasError('email')
           && form.controls['email']?.value
           && form.controls['email']?.touched;
          "
      >
        <span class="error-message">{{ 'authorization_email_error_msg' | translate }}</span>
      </div>

      <div
        class="global-flex-row-end-center full-width"
        *ngIf="form.controls['email']?.hasError('emailTaken')"
      >
        <span class="error-message">{{ 'user_name_is_already_taken_msg' | translate }}</span>
      </div>

      <div
        class="global-flex-row-end-center full-width"
        *ngIf="!form.controls['email']?.hasError('email') &&
               ((form.controls['password']?.hasError('minlength') && form.controls['password'].touched)
               || (form.controls['repeatPassword']?.hasError('minlength') && form.controls['repeatPassword'].touched))
            "
      >
        <span class="error-message">{{ 'authorization_password_error_msg' | translate }}</span>
      </div>

      <div
        class="global-flex-row-end-center full-width"
        *ngIf="!form.controls['email']?.hasError('email')
               && form.controls['repeatPassword']?.hasError('MatchPassword')
               && form.controls['repeatPassword']?.value
               && form.controls['repeatPassword']?.touched
            "
      >
        <span class="error-message">{{ 'authorization_password_matching_error_msg' | translate }}</span>
      </div>
      <app-google-registration style="display: flex; justify-content: center"></app-google-registration>
    </form>
  </div>
  <!-- <span style="display: block; text-align: center; margin-top: 60px;">
      {{ 'registration_hint' | translate }}
    </span> -->
</section>
