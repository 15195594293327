import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

const modes: string[] = [
  'all',
  'regular',
  'individual',
  'waiting-request'
]

@Component({
  selector: 'app-admin-readers',
  templateUrl: 'admin-readers.component.html',
  styleUrls: ['admin-readers.component.scss']
})
export class AdminReadersComponent implements OnDestroy{
  private mode: string | undefined;
  private _selected: number = 0;

  public get selected():number{
    return this._selected;
  }
  public set selected(value: number){
    this.router.navigateByUrl(this.router.url.replace(this.mode, modes[value]));
  }

  private subscription: Subscription;
  constructor(activateRoute: ActivatedRoute, private router: Router){
    this.subscription = activateRoute.params.subscribe(
      params => {
        this.mode = params['mode'];
        this._selected = modes.indexOf(this.mode);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}