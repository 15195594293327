import { Component } from '@angular/core';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';

@Component({
  selector: 'app-publisher-registration',
  templateUrl: './publisher-registration.component.html',
  styleUrls: ['./publisher-registration.component.scss']
})
export class PublisherRegistrationComponent {
  
  constructor(
    public state: ApplicationStateService,
  ) {}

  goToRegistrationForm() {
    const element = document.querySelector("#form")
    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}